/*
 * Copyright © 2018 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import angular from 'angular';

angular.module('kitAdmin')
    .run(updateTemplates);

export * from './click-2-edit/dvb-click-2-edit/dvb-click-2-edit';
export * from './click-2-edit/dvb-click2edit-iso-locale/dvb-click2edit-iso-locale';
export * from './click-2-edit/dvb-editable-contextual-adresse/dvb-editable-contextual-adresse';
export * from './click-2-edit/dvb-editable-label/dvb-editable-label';
export * from './click-2-edit/dvb-editable-pensum/dvb-editable-pensum';
export * from './click-2-edit/dvb-editable-select/dvb-editable-select';
export * from './click-2-edit/dvb-editable-text-area/dvb-editable-text-area';

export * from './component/adresse/dvb-adresse-form/dvb-adresse-form';
export * from './component/adresse/dvb-adresse/dvb-adresse';
export * from './component/dvb-belegung-interval-header/dvb-belegung-interval-header';
export * from './component/dvb-belegung-zeiten/dvb-belegung-zeiten';
export * from './component/dvb-belegungs-zustand-icon/dvb-belegungs-zustand-icon';
export * from './component/dvb-betreuungsfaktor-icon/dvb-betreuungsfaktor-icon';
export * from './component/dvb-betreuungswunsch/dvb-betreuungswunsch';
export * from './component/dvb-contextual-adresse/dvb-contextual-adresse';
export * from './component/dvb-custom-field-values/dvb-custom-field-values';
export * from './component/dvb-date-switcher/dvb-date-switcher';
export * from './component/dvb-dokument-layout/dvb-dokument-layout';
export * from './component/dvb-draggable-dropzone/dvb-draggable-dropzone';
export * from './component/dvb-draggable-element/dvb-draggable-element';
export * from './component/dvb-entity-list/dvb-entity-list';
export * from './component/dvb-file-list/dvb-file-list';
export * from './component/dvb-file-upload/dvb-file-upload';
export * from './component/dvb-filter-list/dvb-filter-list';
export * from './component/dvb-filter-option-popover/dvb-filter-option-popover';
export * from './component/dvb-filter-option/dvb-filter-option';
export * from './component/dvb-filter-toggler/dvb-filter-toggler';
export * from './component/dvb-gueltigkeit/dvb-gueltigkeit';
export * from './component/dvb-image-blob-preview/dvb-image-blob-preview';
export * from './component/dvb-image-upload/dvb-image-upload';
export * from './component/dvb-individual-gueltigkeit/dvb-individual-gueltigkeit';
export * from './component/dvb-kind-austritt-collapsible-panel/dvb-kind-austritt-collapsible-panel';
export * from './component/dvb-kinderort-fraktionen/dvb-kinderort-fraktionen';
export * from './component/dvb-kitas-fraktions-selection/dvb-kitas-fraktions-selection';
export * from './component/dvb-kitas-selection/dvb-kitas-selection';
export * from './component/dvb-kontaktperson-offene-posten-liste/dvb-kontaktperson-offene-posten-liste';
export * from './component/dvb-leistungsrechnung-warnings-badge/dvb-leistungsrechnung-warnings-badge';
export *
    from './component/dvb-limited-entity-assignment/dvb-limited-entity-assignment-controls/dvb-limited-entity-assignment-controls';
export *
    from './component/dvb-limited-entity-assignment/dvb-limited-entity-assignment-controls/dvb-limited-entity-assignment-controls';
export * from './component/dvb-limited-entity-assignment/dvb-limited-entity-content/dvb-limited-entity-content';
export * from './component/dvb-limited-entity-assignment/dvb-limited-entity-form/dvb-limited-entity-form';
export * from './component/dvb-limited-entity-assignment/dvb-limited-entity-heading/dvb-limited-entity-heading';
export * from './component/dvb-limited-entity-assignment/dvb-limited-entity-verlauf/dvb-limited-entity-verlauf';
export * from './component/dvb-list-summary/dvb-list-summary';
export * from './component/dvb-multi-select/dvb-multi-select';
export * from './component/dvb-multiple-files-upload/dvb-multiple-files-upload';
export * from './component/dvb-page/dvb-page';
export * from './component/dvb-pages/dvb-pages';
export * from './component/dvb-parameter-kinderkontaktliste/dvb-parameter-kinderkontaktliste';
export * from './component/dvb-pensum/dvb-pensum';
export * from './component/dvb-period-selector/dvb-period-selector';
export * from './component/dvb-platz-typ-name/dvb-platz-typ-name';
export * from './component/dvb-submit-cancel-buttons/dvb-submit-cancel-buttons';
export * from './component/dvb-tasks-badge/dvb-tasks-badge';
export * from './component/dvb-translatable-editable-label/dvb-translatable-editable-label';
export * from './component/dvb-translatable-editable-text-area/dvb-translatable-editable-text-area';
export * from './component/dvb-translatable-label/dvb-translatable-label';
export * from './component/dvb-translatable-language/dvb-translatable-language';
export * from './component/dvb-versandart-badge/dvb-versandart-badge';
export * from './component/dvb-wochen-belegung/dvb-wochen-belegung';
export * from './component/dvb-wochen-plaetze/dvb-wochen-plaetze';
export * from './component/dvb-wochen-zeitplan/dvb-wochen-zeitplan';
export * from './component/icons/dvb-delete-icon';

export * from './directive/adresse/dvb-adresse-verlauf/dvb-adresse-verlauf';
export * from './directive/dvb-begin-of-month';
export * from './directive/dvb-begin-of-week';
export * from './directive/dvb-belegung/dvb-belegung';
export * from './directive/dvb-betreuungswunsch-header/dvb-betreuungswunsch-header';
export * from './directive/dvb-capitalize-first-letter/dvb-capitalize-first-letter';
export * from './directive/dvb-collapsible-panel/dvb-collapsible-panel';
export * from './directive/dvb-datepicker-text-field/dvb-datepicker-text-field';
export * from './directive/dvb-end-of-month-formatter';
export * from './directive/dvb-end-of-month-parser';
export * from './directive/dvb-end-of-prev-month';
export * from './directive/dvb-end-of-week';
export * from './directive/dvb-focus-if/dvb-focus-if';
export * from './directive/dvb-focus-out';
export * from './directive/dvb-ignore-mouse-wheel';
export * from './directive/dvb-inside-scroll';
export * from './directive/dvb-iso-locale/dvb-iso-locale';
export * from './directive/dvb-kapazitaet-header/dvb-kapazitaet-header';
export * from './directive/dvb-kapazitaet/dvb-kapazitaet';
export * from './directive/dvb-kindergarten-icon/dvb-kindergarten-icon';
export * from './directive/dvb-loading-button/dvb-loading-button';
export * from './directive/dvb-pagination';
export * from './directive/dvb-platz-typen/dvb-platz-typen';
export * from './directive/dvb-protocol-prepender';
export * from './directive/dvb-right-click/dvb-right-click';
export * from './directive/dvb-select-on-click/dvb-select-on-click';
export * from './directive/dvb-time-input/dvb-time-input';
export * from './directive/dvb-translatable-input/dvb-translatable-input';
export * from './directive/dvb-translatable/dvb-translatable';
export * from './directive/dvb-umzug/dvb-umzug';
export * from './directive/dvb-wochenplan/dvb-wochenplan';
export * from './directive/layout';
export * from './directive/validators/dvb-anwesenheits-zeit-constraint/dvb-anwesenheits-zeit-constraint';
export * from './directive/validators/dvb-currency-value/dvb-currency-value';
export * from './directive/validators/dvb-einzahlungsschein-konto/dvb-einzahlungsschein-konto';
export * from './directive/validators/dvb-integer/dvb-integer';
export * from './directive/validators/dvb-is-valid/dvb-is-valid';
export * from './directive/validators/dvb-min-exclusive/dvb-min-exclusive';
export * from './directive/validators/dvb-moment/dvb-moment';

export * from './filter/abs';
export * from './filter/copy';
export * from './filter/dvbI18n';
export * from './filter/dvbEnumeration';
export * from './filter/dvb-limited-month';
export * from './filter/filterCapitalize';
export * from './filter/roundTo';
export * from './filter/isNumber';
export * from './filter/sortKontingenteByStichtag';
export * from './timeline/attachments-download/attachments-download.component';
export * from './timeline/timeline-detail/timeline-detail.component';
export * from './timeline/timeline-icon/timeline-icon.component';
export * from './download/download.service';

// Add New Component JS Above

updateTemplates.$inject = ['$templateCache'];

function updateTemplates($templateCache: angular.ITemplateCacheService): void {
    $templateCache.put('dvb-datepicker-text-field-popup-template.html',
        require('./directive/dvb-datepicker-text-field/dvb-datepicker-text-field-popup-template.html'));

    $templateCache.put('dvb-dialog.html',
        '<div class="dvb-modal-container">' +
        '    <div class="modal-dialog modal-lg">' +
        '        <div class="modal-content" uib-modal-transclude></div>' +
        '    </div>' +
        '</div>');
}
