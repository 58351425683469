/*
 * Copyright © 2019 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {Belegung, Betreuungsfaktor, Kind, KinderOrtFraktionId, KinderOrtId, PlatzTypen} from '@dv/kitadmin/models';
import type {AuthStore} from '@dv/shared/angular';
import {PERMISSION} from '@dv/shared/authentication/model';
import type {BelegungsZustand, Persisted} from '@dv/shared/code';
import {checkPresent, DvbDateUtil, DvbUtil, KindergartenBelegung} from '@dv/shared/code';
import angular from 'angular';
import type moment from 'moment';
import type {FraktionService} from '../../common/service/rest/kinderort/fraktionService';

const componentConfig: angular.IComponentOptions = {
    transclude: false,
    bindings: {
        kind: '<',
        betreuungsfaktoren: '<',
        isBetreuungsfaktorLoading: '<',
    },
    template: require('./dvb-kind-header.html'),
    controllerAs: 'vm',
};

export class DvbKindHeader implements angular.IController {

    public static $inject: readonly string[] = ['fraktionService', 'authStore', '$q'];

    public kind!: Persisted<Kind>;
    public betreuungsfaktoren: Betreuungsfaktor[] = [];
    public isBetreuungsfaktorLoading!: boolean;

    public platzTypen: PlatzTypen | null = null;
    public showPlatzTypen: boolean = false;
    public belegungsZustand: BelegungsZustand | null = null;

    private today: moment.Moment = DvbDateUtil.today();

    public constructor(
        private readonly fraktionService: FraktionService,
        private readonly authStore: AuthStore,
        private readonly $q: angular.IQService,
    ) {
    }

    public $onInit(): void {
        this.calcBelegungsZustand();
    }

    public isKindergarten(): boolean {
        const platzTypen = this.platzTypen;

        if (!platzTypen) {
            return false;
        }

        return platzTypen.kindergarten && platzTypen.kindergarten !== KindergartenBelegung.KEINE;
    }

    public getKindergarten(): KindergartenBelegung | null {
        return this.platzTypen ? this.platzTypen.kindergarten : null;
    }

    // noinspection JSMethodCanBeStatic
    public isBetreuungsFaktorNotOne(betreuungsfaktor: Betreuungsfaktor): boolean {
        return betreuungsfaktor.betreuungsfaktor !== 1;
    }

    private calcBelegungsZustand(): void {
        const belegung = DvbDateUtil.getEntityOn(this.kind.belegungen, this.today);

        if (belegung && Array.isArray(belegung.gruppenBelegungen)) {
            this.calcPlatzTypen(belegung);
        }

        this.belegungsZustand = belegung?.belegungsZustand ? belegung.belegungsZustand : null;
    }

    private calcPlatzTypen(belegung: Belegung): void {
        const belegteGruppenIds = belegung.gruppenBelegungen.map(gb => checkPresent(gb.gruppeId));
        const kitaIdByGruppenId: { [k in KinderOrtFraktionId]: KinderOrtId } = {};
        const params = {cache: true};
        const kitaPromises = belegteGruppenIds.map(gruppeId => this.fraktionService.getKinderOrt(gruppeId, params)
            .then(kita => {
                const kinderOrtId = checkPresent(kita.id);

                kitaIdByGruppenId[gruppeId] = kinderOrtId;

                return kinderOrtId;
            }));

        this.$q.all(kitaPromises).then(kitaIds => {
            const permissions = DvbUtil.uniqueArray(kitaIds).map(kitaId => PERMISSION.KITA.CONTROL + kitaId);

            const grantedPermissions = this.authStore.hasPermissions(permissions);

            const grantedKitaIds = kitaIds.filter((_id, index) => grantedPermissions[index]);

            const grantedGruppenIds = belegteGruppenIds
                .filter(gruppeId => grantedKitaIds.includes(kitaIdByGruppenId[gruppeId]));

            this.platzTypen = belegung.platzTypen(this.kind.kontingente, grantedGruppenIds);
            this.showPlatzTypen = grantedKitaIds.length > 0;
        });
    }
}

componentConfig.controller = DvbKindHeader;
angular.module('kitAdmin').component('dvbKindHeader', componentConfig);
