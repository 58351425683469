/*
 * Copyright © 2023 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {ErrorService} from '@dv/kitadmin/core/errors';
import type { MandantSearchFilter} from '@dv/kitadmin/search';
import {ANY_MANDANT} from '@dv/kitadmin/search';
import type {SearchResultEntry} from '@dv/shared/code';
import {DvbUtil} from '@dv/shared/code';
import angular from 'angular';
import type {AdministrationService} from '../../service/administrationService';

const componentConfig: angular.IComponentOptions = {
    transclude: false,
    bindings: {},
    template: require('./dvb-maintenance-delete-angestellte.html'),
    controllerAs: 'vm',
};

export class DvbMaintenanceDeleteAngestellte implements angular.IController {
    public static $inject: readonly string[] = ['administrationService', 'errorService'];

    public isLoading: boolean = false;
    public angestellteToDelete: SearchResultEntry[] = [];
    public mandantFilter: MandantSearchFilter = ANY_MANDANT;

    public constructor(
        private readonly administrationService: AdministrationService,
        private readonly errorService: ErrorService,
    ) {
    }

    public deleteAngestellte(form: angular.IFormController): void {
        this.errorService.clearAll();

        const valid = form.$valid;

        this.errorService.handleValidationError(valid, 'ERRORS.ERR_INCOMPLETE_FORM');

        if (!valid) {
            return;
        }

        const ids = {ids: this.angestellteToDelete.map(DvbUtil.mapToIdChecked)};
        this.isLoading = true;
        this.administrationService.deleteAngestellte(ids)
            .then(() => {
                this.angestellteToDelete = [];
            })
            .finally(() => {
                this.isLoading = false;
            });
    }
}

componentConfig.controller = DvbMaintenanceDeleteAngestellte;
angular.module('kitAdmin').component('dvbMaintenanceDeleteAngestellte', componentConfig);
