<ng-container *transloco="let t">
    <dvlib-day-of-week-form [templateRef]="expectedTimeTemplate"/>

    <ng-template #expectedTimeTemplate let-dayOfWeek let-index="index">
        <div [ngModelGroup]="'daily-expected-time-' + index">
            <input type="text"
                   class="form-control input-sm time-input text-center"
                   dvMinutesInput
                   [placeholder]="t('PERSONAL.ANSTELLUNG.DAILY_EXPECTED_MINUTES_PLACEHOLDER')"
                   [tooltip]="t('PERSONAL.ANSTELLUNG.DAILY_EXPECTED_MINUTES')"
                   [id]="idsByDayOfWeek[dayOfWeek]"
                   name="dailyMinutes"
                   [attr.aria-label]="t('PERSONAL.ANSTELLUNG.EXPECTED_MINUTES_WITH_DAY', {day: dayOfWeek | dvDayOfWeekFormat})"
                   [(ngModel)]="dailyAnstellungen()[dayOfWeek].expectedMinutes"/>
        </div>
    </ng-template>

    <output name="actualSum" [for]="idsSpaceSeparated">
        @if (expectedMinutes() !== null) {
            {{ t('PERSONAL.ANSTELLUNG.EXPECTED_MINUTES_SUM', {minutes: expectedMinutes() | dvMinutesFormat}) }}
        }
    </output>
</ng-container>
