<ng-container *transloco="let t">
    <div class="table-header">
        <div class="row">
            <div class="col-xs-6">{{ t('COMMON.POSITION') }}</div>
            <div class="col-xs-2 text-right">{{ t('COMMON.ANZAHL') }}</div>
            <div class="col-xs-2 text-right">{{ t('COMMON.BETRAG') }}</div>
            <div class="col-xs-2 text-right">{{ t('COMMON.TOTAL') }}</div>
        </div>
    </div>

    <div class="detail small-abstand-top">
        @if (rechnung().rechnungsPositionen.length === 0) {
            <div class="small-abstand-top">{{ t('ADMINISTRATION.KEINE_RECHNUNGS_POSITIONEN') }}</div>
        }
        @if ((rechnung().kitaCount || 0) > 0) {
            <div class="small-abstand-top">
                <strong>
                    {{ t('ADMINISTRATION.VERRECHENBAR_KINDERORT.MF', {count: rechnung().kitaCount, showcount: true}) }}
                </strong>
            </div>
        }
        @for (position of kitaPositions(); track position) {
            <dv-administration-mandant-rechnungs-position [rechnungsPosition]="position"/>
        }

        @if (kitaPositionsNichtVerrechenbar().length > 0) {
            <div class="small-abstand-top">
                <strong>
                    {{
                        t('ADMINISTRATION.NICHT_VERRECHENBAR_KINDERORT.MF',
                            {count: kitaPositionsNichtVerrechenbar().length, showcount: true})
                    }}
                </strong>
            </div>
        }
        @for (position of kitaPositionsNichtVerrechenbar(); track position.id) {
            <dv-administration-mandant-rechnungs-position [rechnungsPosition]="position"/>
        }

        @if ((rechnung().tageselternCount ?? 0) > 0) {
            <div class="small-abstand-top">
                <strong>
                    {{ t('COMMON.TAGES_ELTERN.MF', {count: rechnung().tageselternCount, showcount: true}) }}
                </strong>
            </div>
        }
        @for (position of tageselternPositions(); track position) {
            <dv-administration-mandant-rechnungs-position [rechnungsPosition]="position"/>
        }

        @if (rechnung().customPositionen.length > 0 || formContext.editMode) {
            <div class="small-abstand-top">
                <strong>{{ t('ADMINISTRATION.CUSTOM_RECHNUNG_POSITION') }}</strong>
            </div>
        }
        @if (!formContext.editMode) {
            @for (position of customPositions(); track position.id) {
                <dv-administration-mandant-rechnungs-position [rechnungsPosition]="position"
                                                              (onDelete)="delete(position)"/>
            }

            <div class="small-abstand-top">
                <button type="button" class="btn btn-primary" (click)="formContext.enableEditMode()">
                    {{ t('ADMINISTRATION.CUSTOM_RECHNUNG_POSITIONEN_ANPASSEN') }}
                </button>
            </div>
        } @else {
            <div class="small-abstand-top">
                <dv-administration-custom-rechnung-position-form
                    [existingCustomPositionen]="rechnung().customPositionen"
                    [formContext]="formContext"
                    [positionType]="'mandantCustomPosition'"
                    (onCancel)="formContext.close()"
                    (onUpdate)="saveAndUpdatePositionen($event.customPositionen)"/>
            </div>
        }
    </div>
</ng-container>
