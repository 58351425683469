<form name="ausgleichszahlungConfirmForm" (ngSubmit)="submit(null)"
      novalidate>
    <div class="modal-body">
        <h1 [transloco]="'COMMON.CONFIRM_DELETE'"></h1>
        <h2 [transloco]="'COMMON.WARN_AUSGLEICHSZAHLUNG_WIRD_STORNIERT'"
            [translocoParams]="{type: text, rechnungsNummer: rechnung.rechnungsNummer}">
        </h2>
    </div>
    <div class="modal-footer">
        <dvlib-button-list>
            <dvlib-loading-button [type]="'submit'" [isLoading]="isLoading$ | async">
                <span [transloco]="'COMMON.BESTAETIGEN'"></span>
            </dvlib-loading-button>
            <button type="button"
                    class="btn btn-link"
                    [transloco]="'COMMON.ABBRECHEN'"
                    (click)="hide()">
            </button>
        </dvlib-button-list>
    </div>
</form>
