/*
 * Copyright © 2018 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {FavoritService} from '@dv/kitadmin/favoriten';
import type {SearchService, MandantSearchFilter} from '@dv/kitadmin/search';
import type {AggragatedEntitySearchTypes, IPersistable, SearchResultEntry} from '@dv/shared/code';
import {ENTITY_TO_SEARCH, isNamedEntityType, TypeUtil} from '@dv/shared/code';
import type {IController, IDirective, IOnDestroy, IOnInit} from 'angular';
import angular from 'angular';
import type {Subscription} from 'rxjs';
import {firstValueFrom} from 'rxjs';

const directive: IDirective = {
    restrict: 'E',
    scope: {
        entityToSearch: '@?',
        onSelectClear: '<',
        placeholderKey: '@',
        placeholderTranslated: '<?',
        selectEntity: '&onSelect',
        focus: '<?',
        expandEntity: '<?',
        filterSource: '&?',
        disabledEntries: '<?',
        onlyNamed: '<?',
        mandantFilter: '<?',
        entitiesToSearchFrom: '<?',
    },
    template: require('./dvb-search-entity.html'),
    controllerAs: 'vm',
    bindToController: true,
    link: (_scope, element, _attrs, controller): void => {
        element.on('keypress', event => {
            const dropdown = element.find('ul');
            // eslint-disable-next-line @typescript-eslint/no-magic-numbers
            if (event.key === 'Enter') {
                // Enter pressed

                const input = element.find('input');

                if (!input) {
                    return;
                }

                const value = input.val() as string;

                if (!value?.trim()) {
                    return;
                }

                event.preventDefault();
                // navigate to the global search results
                (controller as DvbSearchEntity).onSelect();
                // lose the focus on the input field
                input.trigger('blur');
                // hide the dropdown with the search results
                dropdown.hide();

                return;
            }

            // re-enable the dropdown
            if (!dropdown.is(':visible')) {
                dropdown.show();
            }
        });
    },
};

export class DvbSearchEntity implements IController, IOnInit, IOnDestroy {
    public static $inject: readonly string[] = ['$element', 'searchService', 'favoritService', '$q', '$filter'];

    public entityToSearch?: Readonly<AggragatedEntitySearchTypes>;
    public onSelectClear!: Readonly<boolean>;
    public placeholderKey!: Readonly<string>;
    public placeholderTranslated!: Readonly<boolean | undefined>;
    public selectEntity!: (props: { item: SearchResultEntry }) => void;
    public focus: Readonly<boolean> = false;
    public expandEntity: Readonly<boolean> = false;
    public filterSource?: (props: { $source: any }) => boolean;
    public disabledEntries: readonly IPersistable[] = [];
    public onlyNamed: boolean = false;
    public mandantFilter?: MandantSearchFilter;
    public entitiesToSearchFrom?: SearchResultEntry[];

    public searchInput?: string | SearchResultEntry;
    public showingFavorites: boolean = false;

    private favoritesSearchEnabled: boolean = false;
    private entitesToSearch: string = '';

    private subscription?: Subscription;

    public constructor(
        $element: JQLite,
        private readonly searchService: SearchService,
        private readonly favoritService: FavoritService,
        private readonly $q: angular.IQService,
        private readonly $filter: angular.IFilterService,
    ) {
        $element.addClass('highlight-input-fields-when-invalid');
    }

    public $onInit(): void {
        const id = 'dvb-search-entity';
        if (!angular.isString(this.placeholderKey)) {
            throw new Error(`You must provide a valid string for attribute placeholder-key ${id}`);
        }
        if (!TypeUtil.isFunction(this.selectEntity)) {
            throw new Error(`You must provide a valid function reference for attribute select-entity ${id}`);
        }
        this.favoritesSearchEnabled = !this.mandantFilter;
        if (this.favoritesSearchEnabled) {
            // eagerly load favoriten
            this.subscription = this.favoritService.getAll$().subscribe();
        }
        const entitiesToSearch = this.entityToSearch ?
            ENTITY_TO_SEARCH[this.entityToSearch] || [] :
            Object.values(ENTITY_TO_SEARCH).flat().filter(e => !this.onlyNamed || isNamedEntityType(e));

        this.entitesToSearch = `(${entitiesToSearch.join(',')})`;
    }

    public searchResults(searchText: string): angular.IPromise<SearchResultEntry[]> | null {

        const results = this.fetchResults(searchText);

        if (!this.disabledEntries) {
            return results;
        }

        const disabledIds = this.disabledEntries.map(entry => entry.id);

        return results?.then(res => {
            res.forEach(entry => {
                entry.isDisabled = disabledIds.includes(entry.id);
            });

            return res;
        }) ?? null;
    }

    public fetchResults(searchText: string): angular.IPromise<SearchResultEntry[]> | null {
        if (!searchText || searchText.length === 0) {
            if (this.favoritesSearchEnabled && !this.expandEntity && this.entityToSearch) {
                this.showingFavorites = true;

                return this.fetchFavorites(this.entityToSearch);
            }

            return null;
        }

        this.showingFavorites = false;

        let results: angular.IPromise<SearchResultEntry[]>;
        if (this.entitiesToSearchFrom) {
            results = searchText ?
                this.$q.resolve(this.$filter('filter')(this.entitiesToSearchFrom, searchText)) :
                this.$q.resolve(this.entitiesToSearchFrom);
        } else {
            const params = {
                entities: this.entitesToSearch,
                expandEntity: this.expandEntity,
            };
            results = firstValueFrom(this.searchService.searchEntity$(searchText, params, this.mandantFilter));
        }
        if (!this.expandEntity) {
            return results;
        }

        return results.then(entries => entries
            .filter(entry => this.filterSource ? this.filterSource({$source: entry.source}) : true));
    }

    public $onDestroy(): void {
        this.subscription?.unsubscribe();
    }

    public onSelect(
        $item?: SearchResultEntry,
        _$model?: SearchResultEntry,
        _$label?: string,
        $event?: KeyboardEvent,
    ): void {
        if (!$item) {
            return;
        }

        if ($item.isDisabled) {
            this.searchInput = '';

            return;
        }

        if (this.showingFavorites && !!$event && $event.type === 'keydown' && $event.key === 'Tab') {
            // When showing favorites and the user tabs through the form, we do not want the first entry to be
            // selected for him. But once he's actually searching, Tab should select the entry.

            this.searchInput = '';

            return;
        }

        if (this.onSelectClear) {
            this.searchInput = '';
        }

        this.selectEntity({item: $item});
    }

    private fetchFavorites(entityToSearch: AggragatedEntitySearchTypes): angular.IPromise<SearchResultEntry[]> {
        // type casting does not work with array filter and type guard :-(
        const types: any = ENTITY_TO_SEARCH[entityToSearch].filter(isNamedEntityType);
        if (types.length === 0) {
            return this.$q.resolve([]);
        }

        return firstValueFrom(this.favoritService.getByType$(...types))
            .then(favoriten => favoriten.map(favorit => favorit.toSearchResultEntry()));
    }
}

directive.controller = DvbSearchEntity;
angular.module('kitAdmin').directive('dvbSearchEntity', () => directive);
