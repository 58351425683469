/*
 * Copyright © 2018 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {ErrorService} from '@dv/kitadmin/core/errors';
import {KitaPermission} from '@dv/kitadmin/models';
import type {AccordionContext} from '@dv/kitadmin/ui/ajs';
import type {KitaPermissionSet} from '@dv/shared/backend/model/kita-permission-set';
import type {PersonalPermissionSet} from '@dv/shared/backend/model/personal-permission-set';
import {WorkTimeControllingPermissionSet} from '@dv/shared/backend/model/work-time-controlling-permission-set';
import type {IPersistable, SearchResultEntry} from '@dv/shared/code';
import {checkPresent, DvbUtil} from '@dv/shared/code';
import type {UserRole} from '@dv/shared/roles';
import {AVAILABLE_PERM_BY_KITA_PERM, AVAILABLE_PERM_BY_PERSONAL_BERM, AVAILABLE_PERM_BY_ROLE} from '@dv/shared/roles';
import angular from 'angular';
import {DvbRestUtilAngularJS} from 'src/app/common/service/rest/dvbRestUtilAngularJS';
import type {KitaPermissionsService} from '../../../common/service/rest/benutzer/kitaPermissionsService';
import type {Benutzer} from '../../model/Benutzer';

const componentConfig: angular.IComponentOptions = {
    transclude: false,
    bindings: {
        benutzer: '<',
        kitaPermissions: '<',
        readOnly: '<',
    },
    template: require('./dvb-kita-rechte.html'),
    controllerAs: 'vm',
};

class DvbKitaRechte implements angular.IController {

    public static $inject: readonly string[] = ['errorService', 'kitaPermissionsService'];

    public benutzer!: Benutzer;
    public kitaPermissions: KitaPermission[] = [];
    public readOnly: boolean = true;

    public newKitaPermission: KitaPermission | null = null;
    public showNewKitaPermissionForm: boolean = false;
    public isAddKitaPermissionLoading: boolean = false;
    public kitaSearchResultEntry: SearchResultEntry | null = null;
    public kinderOrte: IPersistable[] = [];

    public readonly availablePermissionsByRole
        : { [k in UserRole]: { kita: KitaPermissionSet[]; personal: PersonalPermissionSet[] } } =
        AVAILABLE_PERM_BY_ROLE;

    public readonly availableKitaPermissionSets
        : Map<KitaPermissionSet | null, KitaPermissionSet[]> = AVAILABLE_PERM_BY_KITA_PERM;

    public readonly availablePersonalPermissionSets
        : Map<PersonalPermissionSet | null, PersonalPermissionSet[]> = AVAILABLE_PERM_BY_PERSONAL_BERM;

    public readonly workTimeControllingPermissionSets = Object.values(WorkTimeControllingPermissionSet);

    public constructor(
        private errorService: ErrorService,
        private kitaPermissionsService: KitaPermissionsService,
    ) {
    }

    public createWorkingCopy(kitaPermission: KitaPermission): KitaPermission {
        this.updateKinderOrte();

        return angular.copy(kitaPermission);
    }

    public update(
        workingCopyKitaPermission: KitaPermission,
        kitaPermission: KitaPermission,
        context: AccordionContext,
    ): void {
        this.kitaPermissionsService.updateKitaPermission(workingCopyKitaPermission)
            .then(() => angular.extend(kitaPermission, workingCopyKitaPermission))
            .then(() => context.completeSubmission())
            .finally(() => context.cancelSubmission());
    }

    public cancelUpdate(workingCopyKitaPermission: KitaPermission, kitaPermission: KitaPermission): void {
        angular.extend(workingCopyKitaPermission, kitaPermission);
    }

    public deleteKitaPermission(kitaPermission: KitaPermission): angular.IPromise<void> {
        return this.kitaPermissionsService.deleteKitaPermission(checkPresent(kitaPermission.id)).then(() => {
            DvbUtil.removeFromArray(kitaPermission, this.kitaPermissions);
            this.updateKinderOrte();
        });
    }

    public showAddKitaPermission(): void {
        this.showNewKitaPermissionForm = true;
        this.newKitaPermission = new KitaPermission();
        this.newKitaPermission.benutzerId = this.benutzer.id;
    }

    public addKitaPermission(form: angular.IFormController): void {
        this.errorService.handleValidationError(form.$valid, 'ERRORS.ERR_INCOMPLETE_FORM');

        if (!form.$valid) {
            return;
        }

        this.isAddKitaPermissionLoading = true;

        const kitaPermission = checkPresent(this.newKitaPermission);

        this.kitaPermissionsService.createKitaPermission(kitaPermission).then(response => {
            kitaPermission.id = DvbRestUtilAngularJS.parseEntityIdFromResponse(response);
            this.kitaPermissions.push(kitaPermission);
            this.newKitaPermission = null;
            this.kitaSearchResultEntry = null;
            this.showNewKitaPermissionForm = false;
            this.updateKinderOrte();
        }).finally(() => {
            this.isAddKitaPermissionLoading = false;
        });
    }

    public updateKita(): void {
        const kitaPermission = checkPresent(this.newKitaPermission);

        if (!this.kitaSearchResultEntry) {
            kitaPermission.kitaId = null;
            kitaPermission.kitaName = null;

            return;
        }

        kitaPermission.kitaId = checkPresent(this.kitaSearchResultEntry).id;
        kitaPermission.kitaName = checkPresent(this.kitaSearchResultEntry).text;
    }

    public cancel(): void {
        this.newKitaPermission = null;
        this.showNewKitaPermissionForm = false;
        this.kitaSearchResultEntry = null;
    }

    private updateKinderOrte(): void {
        this.kinderOrte = this.kitaPermissions.map(kitaPermission => {
            return {id: kitaPermission.kitaId};
        });
    }
}

componentConfig.controller = DvbKitaRechte;
angular.module('kitAdmin').component('dvbKitaRechte', componentConfig);
