/*
 * Copyright © 2024 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {EntityId} from '@dv/shared/backend/model/entity-id';
import type {JaxMonatsBlattEntries} from '@dv/shared/backend/model/jax-monats-blatt-entries';
import {DvbRestUtil} from '@dv/shared/code';
import {MonatsBlattEntry} from './MonatsBlattEntry';

export class MonatsBlattEntries {
    public constructor(
        public entries: MonatsBlattEntry[],
        public parameterIdsWithStundenKontingentRelevance: EntityId[],
    ) {
    }

    public static apiResponseTransformer(data: JaxMonatsBlattEntries): MonatsBlattEntries {
        const entries = DvbRestUtil.transformArray(data.entries, MonatsBlattEntry);

        return new MonatsBlattEntries(entries, data.parameterIdsWithStundenKontingentRelevance);
    }
}
