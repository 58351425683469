<div class="dvb-kita-rechte">
    <div>
        <h2 data-translate="BENUTZER.GRANULAR_PERMISSIONS.TITLE"></h2>
    </div>

    <div class="big-abstand-bottom" dvb-accordions>
        <p ng-if="vm.permissions.length === 0" data-translate="BENUTZER.GRANULAR_PERMISSIONS.NONE"></p>
        <div ng-repeat="permission in vm.permissions"
             ng-init="searchResults = vm.createSearchResults(permission)">
            <dvb-accordion on-submit="vm.update(permission, searchResults, context)"
                           on-delete="vm.delete(permission, context)"
                           on-cancel="searchResults = vm.createSearchResults(permission)"
                           enable-delete="true"
                           enable-edit="true"
                           ng-disabled="vm.readOnly"
                           is-first="$first"
                           entity-name="'BENUTZER.GRANULAR_PERMISSIONS.TITLE' | translate">
                <panel-header class="full-width-block">
                    <div class="row">
                        <div class="col-xs-6">
                            <p ng-bind="('BENUTZER.GRANULAR_PERMISSIONS.TYPE.' + permission.permission) | translate"></p>
                        </div>
                        <div class="col-xs-6 text-right">
                            <p ng-if="permission.kinderOrtNamesById.length === 0">
                                <span class="badge"
                                      data-translate="BENUTZER.GRANULAR_PERMISSIONS.FOR_ALL">
                                </span>
                            </p>
                            <p ng-if="permission.kinderOrtNamesById.length > 0"
                               class="truncate"
                               ng-init="kinderOrtNames = vm.getKinderOrtNamesTruncated(permission)"
                               uib-tooltip="{{vm.getKinderOrtNamesTooltip(permission)}}"
                               tooltip-placement="top-right">
                                <span class="badge" ng-bind="kinderOrtNames"></span>
                            </p>
                        </div>
                    </div>
                </panel-header>
                <edit-content>
                    <div class="row dvb-form">
                        <div class="col-md-12">
                            <dvb-search-list entity-to-search="KINDERORT"
                                             ng-model="searchResults"
                                             placeholder-key="COMMON.KINDERORT.AUSWAEHLEN">
                            </dvb-search-list>
                        </div>
                    </div>
                </edit-content>
            </dvb-accordion>
        </div>
    </div>

    <div ng-show="!vm.newPermissionVisible && !vm.readOnly">
        <button class="btn btn-link"
                type="button"
                data-translate="BENUTZER.GRANULAR_PERMISSIONS.ADD"
                ng-click="vm.newPermissionVisible = true"
                ng-disabled="vm.availablePermissions.length < 1">
        </button>
    </div>

    <script type="text/ng-template" id="permissionPreviewTemplate.html">
        <a href="" ng-bind="match.model.text"></a>
    </script>
    <div class="row">
        <div class="col-md-6">
            <div ng-show="vm.newPermissionVisible && !vm.readOnly"
                 class="input-group form-row dvb-search-entity">
                <label for="new-granular-permission"
                       class="sr-only"
                       data-translate="BENUTZER.GRANULAR_PERMISSIONS.TITLE">
                </label>
                <input type="text"
                       class="form-control"
                       id="new-granular-permission"
                       ng-model="vm.newPermissionType"
                       ng-attr-placeholder="{{'BENUTZER.GRANULAR_PERMISSIONS.ADD' | translate}}"
                       uib-tooltip="{{'BENUTZER.GRANULAR_PERMISSIONS.ADD' | translate}}"
                       tooltip-placement="top-left"
                       uib-typeahead="permission as permission.text for permission in vm.availablePermissions"
                       typeahead-on-select="vm.addPermission()"
                       typeahead-template-url="permissionPreviewTemplate.html"
                       typeahead-min-length="0">
                <span class="input-group-addon"><i class="fa fa-search"></i></span>
            </div>
        </div>
    </div>

</div>
