import {Pipe, PipeTransform} from '@angular/core';
import {WorkTimeModel} from '@dv/kitadmin/models';
import {DvMinutesFormatPipe} from '@dv/shared/angular';
import {isPresent} from '@dv/shared/code';

@Pipe({
    name: 'workTimeModelDisplayName',
    standalone: true,
})
export class WorkTimeModelDisplayNamePipe implements PipeTransform {

    private dvMinutesFormatPipe = new DvMinutesFormatPipe();

    public transform(model: WorkTimeModel): string {
        const expectedHoursAndMinutes = this.dvMinutesFormatPipe.transform(model.weeklyMinutes);

        return isPresent(model.name) && model.name !== '' ?
            `${model.name} ${expectedHoursAndMinutes}` :
            `${expectedHoursAndMinutes}`;
    }
}
