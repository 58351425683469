/*
 * Copyright © 2018 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {ApplicationRole, GranularKinderOrtPermission, KitaPermission} from '@dv/kitadmin/models';
import {WILDCARD_TOKEN} from '@dv/shared/angular';
import {PERMISSION} from '@dv/shared/authentication/model';
import {checkPresent} from '@dv/shared/code';
import angular from 'angular';
import type {AuthorisationService} from '../../../authorisation/service/authorisation.service';
import type {BenutzerService} from '../../../common/service/rest/benutzer/benutzerService';
import type {
    GranularKinderOrtPermissionsService,
} from '../../../common/service/rest/benutzer/granularKinderOrtPermissionsService';
import type {KitaPermissionsService} from '../../../common/service/rest/benutzer/kitaPermissionsService';
import type {Benutzer} from '../../model/Benutzer';

const componentConfig: angular.IComponentOptions = {
    transclude: false,
    bindings: {
        benutzer: '<',
        kitaPermissions: '<',
        granularKinderOrtPermissions: '<',
    },
    template: require('./dvb-benutzer-rechte.html'),
    controllerAs: 'vm',
};

class DvbBenutzerRechte implements angular.IController {

    public static $inject: readonly string[] = [
        'authorisationService',
        'benutzerService',
        'kitaPermissionsService',
        'granularKinderOrtPermissionsService',
    ];

    public benutzer!: Benutzer;
    public kitaPermissions: KitaPermission[] = [];
    public granularKinderOrtPermissions: GranularKinderOrtPermission[] = [];
    public isStandardReadOnly: boolean = true;
    public isKitaReadOnly: boolean = true;
    public hasPrivilegedRole: boolean = false;

    public constructor(
        private authorisationService: AuthorisationService,
        private benutzerService: BenutzerService,
        private kitaPermissionsService: KitaPermissionsService,
        private granularKinderOrtPermissionsService: GranularKinderOrtPermissionsService,
    ) {
    }

    public $onChanges(changes: angular.IOnChangesObject): void {
        if (!changes.benutzer?.currentValue) {
            return;
        }

        const readOnly = this.authorisationService.isBenutzerPrincipal(changes.benutzer.currentValue) ||
            this.userIsMorePrivilegedThanCurrent();
        this.isKitaReadOnly = readOnly || !this.authorisationService.hasPermission(PERMISSION.SHARED.MANAGE_USERS_ANY);
        this.isStandardReadOnly =
            readOnly || !this.authorisationService.hasPermission(PERMISSION.SHARED.MANAGE_USERS + WILDCARD_TOKEN);

        this.updateHasPrivilegedRole(changes.benutzer.currentValue.role);
    }

    public updateBenutzer(): void {
        this.benutzerService.get(checkPresent(this.benutzer.id))
            .then(benutzer => {
                this.benutzer = benutzer;
                this.updateHasPrivilegedRole(benutzer.role);

                this.kitaPermissionsService.getKitaPermissions(checkPresent(benutzer.id))
                    .then(permissions => {
                        this.kitaPermissions = permissions;
                    });

                this.granularKinderOrtPermissionsService.getForBenutzer(checkPresent(benutzer.id))
                    .then(permissions => {
                        this.granularKinderOrtPermissions = permissions;
                    });
            });
    }

    private userIsMorePrivilegedThanCurrent(): boolean {
        return this.authorisationService.isRoleGreaterThanPrincipals(this.benutzer.role.userRole);
    }

    private updateHasPrivilegedRole(role: ApplicationRole): void {
        this.hasPrivilegedRole = this.authorisationService.hasPrivilegedRole(role);
    }
}

componentConfig.controller = DvbBenutzerRechte;
angular.module('kitAdmin').component('dvbBenutzerRechte', componentConfig);
