<fieldset class="form-section" *transloco="let t">
    <legend>
        <h3>{{ t('COMMON.STANDORT.AUSWAEHLEN') }}</h3>
    </legend>

    <div class="row">
        <div class="col-md-6">
            <div class="checkbox">
                <label>
                    <input
                        type="checkbox"
                        name="alleKinderOrte"
                        [(ngModel)]="anstellungStandort().alleKinderOrte"/>
                    {{ t('COMMON.ALLE_KINDERORTE') }}
                </label>
            </div>
        </div>
        @if (!anstellungStandort().alleKinderOrte) {
            <div class="col-md-6">
                <div class="checkbox">
                    <label>
                        <input
                            type="checkbox"
                            name="alleFraktionen"
                            [(ngModel)]="anstellungStandort().alleFraktionen"/>
                        {{ t('COMMON.ALLE_GRUPPEN') }}
                    </label>
                </div>
            </div>
        }
    </div>
    <div class="row">
        @if (!anstellungStandort().alleKinderOrte) {
            <div class="col-md-6">
                <dv-search-list
                    entityToSearch="KITA"
                    name="selectedKinderOrte"
                    [(ngModel)]="anstellungStandort().selectedKinderOrte"
                    [orderByText]="true"
                    [placeholder]="t('COMMON.KINDERORT.AUSWAEHLEN')"
                    [required]="!anstellungStandort().alleKinderOrte"/>
            </div>
        }
        @if (!anstellungStandort().alleKinderOrte && !anstellungStandort().alleFraktionen) {
            <div class="col-md-6">
                <dv-search-list
                    entityToSearch="GRUPPE"
                    name="selectedFraktionen"
                    [(ngModel)]="anstellungStandort().selectedFraktionen"
                    [orderByText]="true"
                    [placeholder]="t('COMMON.KINDERORT_FRAKTION.AUSWAEHLEN')"
                    [required]="!anstellungStandort().alleFraktionen"/>
            </div>
        }
    </div>

    <div class="row">
        <div class="checkbox col-md-6">
            <label>
                <input
                    type="checkbox"
                    name="vertretungsperson"
                    [(ngModel)]="anstellungStandort().vertretungsperson"/>
                {{ t('PERSONAL.ANSTELLUNG.VERTRETUNGSPERSON') }}
            </label>
        </div>
    </div>

    <div class="normal-abstand-top" *dvlibRequirePermission="'module:work_time_controlling'">
        <div class="row">
            <div class="col-md-6">
                <div class="normal-abstand-bottom">
                    <h3>{{ t('WORK_TIME_CONTROLLING.WORK_TIME_MODEL.TITLE') }}</h3>
                </div>
                <select class="form-control"
                        [name]="'workTimeModel'"
                        [id]="workTimeModelUiId"
                        [(ngModel)]="anstellungStandort().workTimeModelId"
                        [disabled]="workTimeModels().length < 1"
                        [tooltip]="t('WORK_TIME_CONTROLLING.WORK_TIME_MODEL.SELECT')"
                        [attr.aria-label]="t('WORK_TIME_CONTROLLING.WORK_TIME_MODEL.TITLE')">
                    <option [ngValue]="null">
                        {{ t('WORK_TIME_CONTROLLING.WORK_TIME_MODEL.WITHOUT') }}
                    </option>
                    @for (model of workTimeModels(); track model.id) {
                        <option [ngValue]="model.id">{{ model | workTimeModelDisplayName }}</option>
                    }
                </select>
            </div>
            <div class="col-md-6">
                <div class="normal-abstand-bottom">
                    <h3>{{ t('PERSONAL.ANSTELLUNG.PENSUM') }}</h3>
                </div>
                <div>
                    <input
                        type="number"
                        name="pensumProzent"
                        [id]="pensumProzentUiId"
                        class="form-control"
                        min="0"
                        max="100"
                        [(ngModel)]="anstellungStandort().arbeitspensumProzent"
                        [placeholder]="t('PERSONAL.ANSTELLUNG.ARBEITSPENSUM_PROZENT')"
                        [attr.aria-label]="t('PERSONAL.ANSTELLUNG.ARBEITSPENSUM_PROZENT')"
                        [tooltip]="t('PERSONAL.ANSTELLUNG.ARBEITSPENSUM_PROZENT')"/>
                </div>
            </div>
        </div>
        <output name="expectedSum" [for]="workTimeModelUiId + ' ' + pensumProzentUiId">
            @if (expectedTotalWeeklyMinutes() !== null) {
                <div class="row">
                    <div class="col-md-12">
                        {{
                            t('PERSONAL.ANSTELLUNG.EXPECTED_MINUTES_SUM',
                                {minutes: expectedTotalWeeklyMinutes() | dvMinutesFormat})
                        }}
                    </div>
                </div>
            }
        </output>
    </div>

    @if (anstellungStandort().dailyAnstellungen) {
        <div *dvlibRequirePermission="'module:work_time_controlling'">
            <div class="small-abstand-bottom normal-abstand-top flex">
                <h3>
                    {{ t('PERSONAL.ANSTELLUNG.DAILY_EXPECTED_MINUTES') }}
                    @if (expectedTotalWeeklyMinutes()) {
                        <button type="button"
                                class="btn btn-link smaller-abstand-left"
                                (click)="autofillExpectedTime.emit(expectedTotalWeeklyMinutes())"
                                [tooltip]="'PERSONAL.ANSTELLUNG.DAILY_EXPECTED_TIME_AUTOFILL' | transloco"
                                [attr.aria-label]="'PERSONAL.ANSTELLUNG.DAILY_EXPECTED_TIME_AUTOFILL' | transloco">
                            <i class="fa fa-magic" aria-hidden="true"></i>
                        </button>
                    }
                </h3>
            </div>
            <dv-angestellte-anstellung-expected-time
                [dailyAnstellungen]="anstellungStandort().dailyAnstellungen"
                [ngModelGroup]="'sollzeit'">
            </dv-angestellte-anstellung-expected-time>
        </div>
        <div>
            <div class="normal-abstand-bottom normal-abstand-top">
                <h3>{{ t('PERSONAL.ANSTELLUNG.DAILY_AVAILABILITY') }}</h3>
            </div>
            <dv-angestellte-anstellung-zeiten
                [dailyAnstellungen]="anstellungStandort().dailyAnstellungen"
                [ngModelGroup]="'anstellungZeiten'">
            </dv-angestellte-anstellung-zeiten>
        </div>
    }
</fieldset>
