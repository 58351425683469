/*
 * Copyright © 2018 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import {BetreuungsVereinbarungsKonfiguration, BetreuungsVereinbarungsKonfigurationType} from '@dv/kitadmin/models';
import type {Persisted} from '@dv/shared/code';
import {checkPersisted, checkPresent} from '@dv/shared/code';
import type angular from 'angular';
import {CONFIG} from '../../../../../config';
import {DvbRestUtilAngularJS} from '../dvbRestUtilAngularJS';

export class BetreuungsVereinbarungsKonfigurationService {
    public static $inject: readonly string[] = ['$http'];

    private static baseUrl: string = `${CONFIG.restBackend}/api/v1/vereinbarungskonfiguration`;

    public constructor(
        private $http: angular.IHttpService,
    ) {
    }

    public get(
        configId: string,
        config?: angular.IRequestShortcutConfig,
    ): angular.IPromise<BetreuungsVereinbarungsKonfiguration> {

        const url = BetreuungsVereinbarungsKonfigurationService.baseUrl;

        return DvbRestUtilAngularJS.getModelByIdAndParams(url, BetreuungsVereinbarungsKonfiguration, configId, config)
            .then(checkPresent);
    }

    public update(konfiguration: BetreuungsVereinbarungsKonfiguration): angular.IPromise<unknown> {

        DvbRestUtilAngularJS.clearHttpCache();
        const url = `${BetreuungsVereinbarungsKonfigurationService.baseUrl}/${encodeURIComponent(
            checkPresent(konfiguration.id))}`;

        return this.$http.put(url, konfiguration.toRestObject());
    }

    public create(
        konfigurationTypeId: string,
        konfiguration: BetreuungsVereinbarungsKonfiguration,
    ): angular.IPromise<unknown> {

        DvbRestUtilAngularJS.clearHttpCache();
        const url = `${BetreuungsVereinbarungsKonfigurationService.baseUrl}/betreuungsvereinbarungstype/${
            encodeURIComponent(konfigurationTypeId)}`;

        return this.$http.post(url, konfiguration.toRestObject());
    }

    public delete(id: string): angular.IPromise<any> {

        const url = `${BetreuungsVereinbarungsKonfigurationService.baseUrl}/${encodeURIComponent(id)}`;

        return this.$http.delete(url);
    }

    public getAllTypes(
        kitaId: string,
        config?: angular.IRequestShortcutConfig,
    ): angular.IPromise<Persisted<BetreuungsVereinbarungsKonfigurationType>[]> {

        const url = `${BetreuungsVereinbarungsKonfigurationService.baseUrl}/type/kinderort/${encodeURIComponent(kitaId)}`;

        return DvbRestUtilAngularJS.getModelsArray(url,
            BetreuungsVereinbarungsKonfigurationType,
            'items',
            {},
            false,
            config).then(array => array.map(checkPersisted));
    }

    public createType(
        kinderOrtId: string,
        konfigurationType: BetreuungsVereinbarungsKonfigurationType,
    ): angular.IPromise<unknown> {
        DvbRestUtilAngularJS.clearHttpCache();

        const url = `${BetreuungsVereinbarungsKonfigurationService.baseUrl}/type/kinderort/${encodeURIComponent(
            kinderOrtId)}`;

        return this.$http.post(url, konfigurationType.toRestObject());
    }

    public updateType(type: BetreuungsVereinbarungsKonfigurationType): angular.IPromise<unknown> {
        DvbRestUtilAngularJS.clearHttpCache();
        const url = `${BetreuungsVereinbarungsKonfigurationService.baseUrl}/type/${
            encodeURIComponent(checkPresent(type.id))}`;

        return this.$http.put(url, type.toRestObject());
    }

    public deleteType(konfigurationTypeId: string): angular.IPromise<any> {

        const url = `${BetreuungsVereinbarungsKonfigurationService.baseUrl}/type/${encodeURIComponent(
            konfigurationTypeId)}`;

        return this.$http.delete(url);
    }
}
