/*
 * Copyright © 2023 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import type {ExternalAnmeldung} from '@dv/kitadmin/models';
import type {CompletableDialogModel} from '@dv/kitadmin/ui';
import {CompletableDialogBase} from '@dv/kitadmin/ui';
import {ButtonListComponent, LoadingButtonComponent} from '@dv/shared/angular';
import {Persisted} from '@dv/shared/code';
import {TranslocoModule} from '@jsverse/transloco';

export interface ExternalAnmeldungLoeschenDialogModel
    extends CompletableDialogModel<boolean> {
    anmeldung: Persisted<ExternalAnmeldung>;
}

@Component({
    selector: 'dv-external-anmeldung-loeschen-component',
    templateUrl: './external-anmeldung-loeschen.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [TranslocoModule, LoadingButtonComponent, ButtonListComponent],
})
export class ExternalAnmeldungLoeschenComponent extends CompletableDialogBase<boolean>
    implements ExternalAnmeldungLoeschenDialogModel {

    @Input() public anmeldung!: Persisted<ExternalAnmeldung>;
}
