/*
 * Copyright © 2018 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {RestInclude} from '@dv/shared/code';
import {checkPresent, DvbRestUtil} from '@dv/shared/code';
import type angular from 'angular';
import type moment from 'moment';
import {DvbRestUtilAngularJS} from 'src/app/common/service/rest/dvbRestUtilAngularJS';
import {CONFIG} from '../../../config';
import {MonatsBlattEntries} from '../models/MonatsBlattEntries';
import type {MonatsBlattEntryTarif} from '../models/MonatsBlattEntry';

export class MonatsBlattService {
    public static $inject: readonly string[] = ['$http'];

    private static readonly BASE_URL: string = `${CONFIG.restBackend}/api/v1/monatsblatt`;

    public constructor(
        private readonly $http: angular.IHttpService,
    ) {
    }

    public getMonatsBlattEntries(
        fraktionId: string,
        month: moment.Moment,
        params?: RestInclude & { kindId?: string },
    ): angular.IPromise<MonatsBlattEntries> {

        const matrixParams: any = {};

        if (params?.includes) {
            matrixParams.includes = params.includes;
        }
        if (params?.kindId) {
            matrixParams.kindId = params.kindId;
        }
        matrixParams.stichtag = DvbRestUtil.momentToLocalDate(month);

        const url = `${MonatsBlattService.BASE_URL}/${fraktionId}`;

        return DvbRestUtilAngularJS.getModelByUrlAndParams(url, MonatsBlattEntries, matrixParams, {cache: true})
            .then(checkPresent);
    }

    public updateStunden(
        kinderOrtFraktionId: string,
        kindId: string,
        entryTarif: MonatsBlattEntryTarif,
    ): angular.IPromise<unknown> {
        const data = {
            gueltigAb: DvbRestUtil.momentToLocalDate(entryTarif.gueltigAb),
            gueltigBis: DvbRestUtil.momentToLocalDate(entryTarif.gueltigBis),
            tarifId: entryTarif.tarif.id,
            kindId,
            kinderOrtFraktionId,
            leistungsTypDiscriminator: entryTarif.leistungsTyp,
            kontingentId: entryTarif.kontingentId,
            stunden: entryTarif.stunden,
        };

        DvbRestUtilAngularJS.clearHttpCache();

        return this.$http.post(MonatsBlattService.BASE_URL, data);
    }

    public createZusatzFraktionBelegung(
        kinderOrtFraktionId: string,
        kindId: string,
        gueltigAb: moment.Moment,
        gueltigBis: moment.Moment,
    ): angular.IHttpPromise<any> {

        DvbRestUtilAngularJS.clearHttpCache();

        const data = {
            kindId,
            kinderOrtFraktionId,
            gueltigAb: DvbRestUtil.momentToLocalDate(gueltigAb),
            gueltigBis: DvbRestUtil.momentToLocalDate(gueltigBis),
        };

        return this.$http.post(`${MonatsBlattService.BASE_URL}/zusatzbelegung`, data);
    }

    public deleteZusatzFratkionBelegung(id: string): angular.IHttpPromise<any> {
        DvbRestUtilAngularJS.clearHttpCache();

        return this.$http.delete(`${MonatsBlattService.BASE_URL}/zusatzbelegung/${encodeURIComponent(id)}`);
    }

}
