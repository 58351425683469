@if (readonly ? this.gridEntry.dayInfo?.content : true) {
    <div class="header-info" *transloco="let t">
        <button dvlibButton="bare"
                cdkOverlayOrigin
                #trigger="cdkOverlayOrigin"
                [tooltip]="t(calendarTranslation.dayInfo)"
                (click)="toggleOverlay()"
                (keydown)="toggleOverlay()">
            <i class="fa" [ngClass]="{'fa-comment-o': !gridEntry.dayInfo, 'fa-comment': gridEntry.dayInfo}"> </i>
        </button>

        <ng-template cdkConnectedOverlay
                     [cdkConnectedOverlayOrigin]="trigger"
                     [cdkConnectedOverlayOpen]="!!gridEntry.overlayOpen"
                     [cdkConnectedOverlayHasBackdrop]="true"
                     [cdkConnectedOverlayBackdropClass]="'cdk-overlay-transparent-backdrop'"
                     [cdkConnectedOverlayMinWidth]="readonly ? 'unset' : '400px'"
                     (backdropClick)="gridEntry.overlayOpen = false"
                     (attach)="dayInfoOverlayContent = gridEntry.dayInfo?.content">
            <div class="day-info-overlay">
                @if (readonly) {
                    <div class="text-nowrap">{{ gridEntry.dayInfo?.content }}</div>
                } @else {
                    <textarea name="bemerkung"
                              #tagesinfoInput
                              [(ngModel)]="dayInfoOverlayContent"
                              [placeholder]="t(calendarTranslation.dayInfo)"
                              [tooltip]="t(calendarTranslation.dayInfo)"
                              dvlibElasticTextInput>
                    </textarea>
                }

                @if (!readonly) {
                    <dvlib-button-list class="small-abstand-top">
                        <button dvlibButton="link-button" type="submit" (click)="editDayInfo()">
                            {{ t('COMMON.SPEICHERN') }}
                        </button>
                        <button dvlibButton="link-button" (click)="gridEntry.overlayOpen = false">
                            {{ t('COMMON.ABBRECHEN') }}
                        </button>
                    </dvlib-button-list>
                }
            </div>
        </ng-template>
    </div>
}
