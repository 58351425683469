/*
 * Copyright © 2023 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import {TempBlob} from '@dv/kitadmin/models';
import type {AngestellteService} from '@dv/shared/backend/api/angestellte.service';
import type {AngestellteKorrespondenzType} from '@dv/shared/backend/model/angestellte-korrespondenz-type';
import type {IconName} from '@dv/shared/code';
import type {Translator} from '@dv/shared/translator';
import type {Observable} from 'rxjs';
import {map} from 'rxjs';
import type {ITimelineService} from '../../../../common/timeline/ITimelineService';
import type {AngestellteKorrespondenz} from '../../../../communication/models/AngestellteKorrespondenz';

export class AngestellteKorrespondenzTimelineService implements ITimelineService<AngestellteKorrespondenz> {

    public icon: { [k in AngestellteKorrespondenzType]: IconName };
    public iconText: { [k in AngestellteKorrespondenzType]: string };
    public tooltip: { [k in AngestellteKorrespondenzType]: (item: AngestellteKorrespondenz) => string };

    public constructor(
        private readonly translateService: Translator,
        private readonly angestellteService: AngestellteService,
    ) {
        this.icon = {
            BULK_EMAIL: 'mail',
        };
        this.iconText = {
            BULK_EMAIL: this.translateService.instant('COMMUNICATION.BULK_EMAILS.NAME'),
        };
        this.tooltip = {
            BULK_EMAIL: item => this.getTooltipAngestellteEmail(item),
        };
    }

    public getTimelineDate(entry: AngestellteKorrespondenz): string {
        return entry.timestampErstellt.format('DD.MM.YYYY');
    }

    public getTimelineTime(entry: AngestellteKorrespondenz): string {
        return entry.timestampErstellt.format('HH:mm');
    }

    public getIcon(entry: AngestellteKorrespondenz): IconName {
        return this.icon[entry.type];
    }

    public getIconTooltipText(entry: AngestellteKorrespondenz): string {
        return this.iconText[entry.type];
    }

    public getTooltipText(entry: AngestellteKorrespondenz): string {
        return this.tooltip[entry.type](entry);
    }

    public getAttachment$(entryId: string, blobId: string): Observable<TempBlob> {
        return this.angestellteService.getAttachment$({
            korrespondenzId: entryId,
            blobId,
        }).pipe(map(jax => TempBlob.apiResponseTransformer(jax)));
    }

    private getTooltipAngestellteEmail(kontaktKorrespondenz: AngestellteKorrespondenz): string {
        const interpolateParams = {
            replyToName: kontaktKorrespondenz.replyToName,
            replyToAddr: kontaktKorrespondenz.replyToAdresse,
            to: kontaktKorrespondenz.empfaengerAdresse,
            sentBy: kontaktKorrespondenz.userErstellt,
        };

        return this.translateService.instant('COMMUNICATION.EMAIL.HEADER_INFO', interpolateParams);
    }
}
