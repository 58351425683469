/*
 * Copyright © 2024 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {StateProvider} from '@uirouter/angularjs/lib/stateProvider';
import type {Transition} from '@uirouter/core';
import angular from 'angular';

angular.module('kitAdmin').config(searchConfig);

export * from './component/dvb-global-search/dvb-global-search';
export * from './component/dvb-search-list/dvb-search-list';
export * from './directive/dvb-search-entity/dvb-search-entity';
export * from './directive/dvb-search-get-single-entity/dvb-search-get-single-entity';
export * from './directive/dvb-search-on-click-entity/dvb-search-on-click-entity';
export * from './directive/dvb-search-input/dvb-search-input';
// Add New Component JS Above

searchConfig.$inject = ['$stateProvider'];

function searchConfig($stateProvider: StateProvider): void {

    $stateProvider.state('base.global', {
        url: '/search/global/:searchText',
        template: '<dvb-global-search></dvb-global-search>',
        resolve: {
            $title: ['$transition$', ($transition$: Transition): string => $transition$.params().searchText],
        },
        params: {
            searchText: '',
        },
    });
    /* Add New States Above */
}
