/*
 * Copyright © 2024 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import {withDevtools} from '@angular-architects/ngrx-toolkit';
import {computed, inject} from '@angular/core';
import {createWorkTimeControllingTableData, withWorkTimeControllingTable} from '@dv/kitadmin/work-time-controlling';
import {AuthStore, handleResponse} from '@dv/shared/angular';
import type {
    WorkTimeControllingServiceGetDaysForAngestellteRequestParams,
    WorkTimeControllingServiceSaveManualEditRequestParams,
} from '@dv/shared/backend/api/work-time-controlling.service';
import {WorkTimeControllingService} from '@dv/shared/backend/api/work-time-controlling.service';
import type {EntityId} from '@dv/shared/backend/model/entity-id';
import type {JaxWorkTimeControlling} from '@dv/shared/backend/model/jax-work-time-controlling';
import {checkPresent, Displayable, DvbRestUtil, isPresent} from '@dv/shared/code';
import {patchState, signalStore, withHooks, withMethods, withState} from '@ngrx/signals';
import {setAllEntities} from '@ngrx/signals/entities';
import {rxMethod} from '@ngrx/signals/rxjs-interop';
import type moment from 'moment';
import {concatMap, filter, pipe, switchMap, tap} from 'rxjs';

type KinderOrtControllingState = {
    kinderOrtId: EntityId | null;
};

const initialState: KinderOrtControllingState = {
    kinderOrtId: null,
};

export const KinderOrtWorkTimeControllingStore = signalStore(
    withDevtools('kinderOrtWorkTimeControlling'),
    withWorkTimeControllingTable(),
    withState(initialState),
    withMethods((
        store,
        service: WorkTimeControllingService = inject(WorkTimeControllingService),
        authStore: AuthStore = inject(AuthStore),
    ) => ({
        setKinderOrtId(id: EntityId) {
            patchState(store, {kinderOrtId: id});
        },
        load: rxMethod<{ kinderOrtId: EntityId | null; firstOfMonth: moment.Moment }>(pipe(
            filter(params => isPresent(params.kinderOrtId)),
            tap(() => patchState(store, {isLoading: true})),
            switchMap(({kinderOrtId, firstOfMonth}) => service.getForKinderOrt$({
                    kinderOrtId: checkPresent(kinderOrtId),
                    kinderOrtIdMatrix: {
                        stichtag: DvbRestUtil.momentToLocalDateChecked(firstOfMonth),
                    },
                }).pipe(
                    handleResponse({
                        next: (data: JaxWorkTimeControlling) => {
                            const wtcData =
                                createWorkTimeControllingTableData(data.angestellteWorkDays, authStore, firstOfMonth);
                            const kinderOrte: Displayable[] = data.kinderOrte.map(
                                kinderOrt => Displayable.apiResponseTransformer(kinderOrt));
                            const kinderOrtFraktionen: Displayable[] = data.kinderOrtFraktionen.map(
                                fraktion => Displayable.apiResponseTransformer(fraktion));
                            patchState(
                                store,
                                setAllEntities(wtcData),
                                setAllEntities(kinderOrte, {collection: 'kinderOrte'}),
                                setAllEntities(kinderOrtFraktionen, {collection: 'kinderOrtFraktionen'}));
                        },
                        finalize: () => patchState(store, {isLoading: false}),
                    }),
                ),
            ),
        )),
    })),
    withMethods((
        store,
        service: WorkTimeControllingService = inject(WorkTimeControllingService),
    ) => ({
        confirmEdit: rxMethod<WorkTimeControllingServiceSaveManualEditRequestParams>(pipe(
            tap(() => patchState(store, {isLoading: true})),
            concatMap(payload => service.saveManualEdit$(payload).pipe(
                    handleResponse({
                        next: () => {
                            const params: WorkTimeControllingServiceGetDaysForAngestellteRequestParams = {
                                angestellteId: payload.angestellteId,
                                angestellteIdMatrix: {
                                    stichtag: DvbRestUtil.momentToLocalDateChecked(store.selectedMonth()),
                                },
                                kinderOrtId: store.kinderOrtId()!,
                            };
                            store.loadDaysForMandant(params);
                        },
                        finalize: () => patchState(store, {isLoading: false}),
                    }),
                ),
            ),
        )),
    })),
    withHooks({
        onInit(store) {
            const reloadTrigger = computed(() => {
                const kinderOrtId = store.kinderOrtId();
                const firstOfMonth = store.selectedMonth();

                return {kinderOrtId, firstOfMonth};
            });
            store.load(reloadTrigger);
        },
    }),
);
