<div class="form-row">
    <div class="row selected-entity"
         ng-repeat="searchResultEntry in vm.entries">
        <div class="col-xs-8 entity truncate">
            <dv-search-result-icon [entry]="searchResultEntry"></dv-search-result-icon>
            <span data-translate="SEARCH.ENTITY_TITLE_MF"
                  data-translate-value-text="{{searchResultEntry.text}}"
                  data-translate-value-entity="{{searchResultEntry.entity}}"
                  data-translate-interpolation="messageformat">
            </span>
        </div>
        <div class="col-xs-4 edit">
            <button class="btn btn-link"
                    type="button"
                    ng-click="vm.onRemove(searchResultEntry)"
                    data-translate="COMMON.LOESCHEN">
            </button>
        </div>
    </div>
</div>
<dvb-search-entity class="form-row"
                   ng-class="{'ng-invalid': vm.ngModelCtrl.$invalid}"
                   entity-to-search="{{vm.entityToSearch}}"
                   on-select="vm.onSelect(item)"
                   on-select-clear="true"
                   placeholder-key="{{vm.placeholderKey}}"
                   disabled-entries="vm.entries"
                   mandant-filter="vm.mandantFilter"
                   entities-to-search-from="vm.entitiesToSearchFrom">
</dvb-search-entity>
