/*
 * Copyright © 2021 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import {GranularKinderOrtPermission} from '@dv/kitadmin/models';
import {checkPresent} from '@dv/shared/code';
import type angular from 'angular';
import {CONFIG} from '../../../../../config';
import {DvbRestUtilAngularJS} from '../dvbRestUtilAngularJS';

export class GranularKinderOrtPermissionsService {
    public static $inject: readonly string[] = ['$http'];

    private static readonly BASE_URL: string = `${CONFIG.restBackend}/api/v1/granular-kinder-ort-permissions`;

    public constructor(
        private $http: angular.IHttpService,
    ) {
    }

    public getForBenutzer(benutzerId: string): angular.IPromise<GranularKinderOrtPermission[]> {
        const url = `${GranularKinderOrtPermissionsService.BASE_URL}/benutzer/${encodeURIComponent(benutzerId)}`;

        return DvbRestUtilAngularJS.getModelsArray(url, GranularKinderOrtPermission, 'items');
    }

    public create(permission: GranularKinderOrtPermission): angular.IHttpPromise<unknown> {
        DvbRestUtilAngularJS.clearHttpCache();

        return this.$http.post(GranularKinderOrtPermissionsService.BASE_URL, permission.toRestObject());
    }

    public update(permission: GranularKinderOrtPermission): angular.IPromise<unknown> {
        DvbRestUtilAngularJS.clearHttpCache();
        const url = `${GranularKinderOrtPermissionsService.BASE_URL}/${encodeURIComponent(checkPresent(permission.id))}`;

        return this.$http.put(url, permission.toRestObject());
    }

    public delete(permissionId: string): angular.IPromise<unknown> {
        DvbRestUtilAngularJS.clearHttpCache();

        const url = `${GranularKinderOrtPermissionsService.BASE_URL}/${encodeURIComponent(permissionId)}`;

        return this.$http.delete(url);
    }
}
