/*
 * Copyright © 2021 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import {PERMISSION} from '@dv/shared/authentication/model';
import type {TerminTypeService} from '@dv/shared/backend/api/termin-type.service';
import type {JaxTerminType} from '@dv/shared/backend/model/jax-termin-type';
import type {Persisted, RequiredPermission} from '@dv/shared/code';
import {DvbUtil} from '@dv/shared/code';
import {GENERAL_SUFFIX} from '@dv/shared/roles';
import type {Ng2StateDeclaration} from '@uirouter/angular';
import type {Ng1StateDeclaration} from '@uirouter/angularjs';
import type {Transition} from '@uirouter/core';
import angular from 'angular';
import {firstValueFrom, map} from 'rxjs';
import { UrlParameterTypes } from '@dv/kitadmin/core/uirouter';
import type {Benutzer} from '../../benutzer/model/Benutzer';
import {trans} from '../../common/i18n/trans';
import type {BenutzerService} from '../../common/service/rest/benutzer/benutzerService';
import {
    AngestellteAnstellungFormComponent,
} from './component/angestellte-anstellung-form/angestellte-anstellung-form.component';
import {
    AngestellteKorrespondenzComponent,
} from './component/angestellte-korrespondenz/angestellte-korrespondenz.component';
import {AngestellteZuweisungComponent} from './component/angestellte-zuweisung/angestellte-zuweisung.component';
import type {Angestellte} from './models/Angestellte';
import type {Anstellung} from './models/Anstellung';
import type {Ausbildung} from './models/Ausbildung';
import type {AngestellteService} from './service/angestellteService';
import type {AusbildungService} from './service/ausbildungService';

/* eslint-disable sonarjs/no-duplicate-string */
export const ANGESTELLTE_BASE_STATE = {
    abstract: true,
    name: 'base.personal.angestellte',
    url: '/angestellte',
} satisfies Ng1StateDeclaration;

export const ANGESTELLTE_CREATE_STATE = {
    name: `${ANGESTELLTE_BASE_STATE.name}.create`,
    url: '/erfassen?:benutzerId',
    params: {
        benutzerId: '',
    },
    component: 'dvbAngestellteErstellen',
    resolve: {
        benutzer: [
            '$transition$', 'benutzerService', '$q', (
                $transition$: Transition,
                benutzerService: BenutzerService,
                $q: angular.IQService,
            ): angular.IPromise<Benutzer | null> => DvbUtil.isNotEmptyString($transition$.params().benutzerId) ?
                benutzerService.get($transition$.params().benutzerId) :
                $q.resolve(null),
        ],
    },
    data: {
        permission: [
            PERMISSION.SHARED.MANAGE_USERS_ANY,
            {
                oneOf: [
                    PERMISSION.MODULE.PERSONALPLANUNG,
                    PERMISSION.FEATURE.STUNDENERFASSUNG_TFO + GENERAL_SUFFIX,
                ],
            },
        ],
    },
} satisfies Ng1StateDeclaration;

export const ANGESTELLTE_MAIN_STATE = {
    name: `${ANGESTELLTE_BASE_STATE.name}.main`,
    abstract: true,
    url: '/{id:[0-9]+}',
    component: 'dvbAngestellteMain',
    resolve: {
        angestellte: [
            'angestellteService', '$transition$', (
                angestellteService: AngestellteService,
                $transition$: Transition,
            ): angular.IPromise<Persisted<Angestellte>> =>
                angestellteService.get($transition$.params().id, {includes: '(betreuungspersonen.fields(kita))'}),
        ],
    },
    data: {
        permission(toParams: { id: string }): RequiredPermission {
            return {
                oneOf: [
                    PERMISSION.PERSONAL.VIEW_ANY,
                    PERMISSION.ANGESTELLTE.READ + toParams.id,
                ],
            };
        },
    },
} satisfies Ng1StateDeclaration;

export const ANGESTELLTE_PROFIL_STATE = {
    name: `${ANGESTELLTE_MAIN_STATE.name}.profil`,
    url: '',
    component: 'dvbAngestellteProfil',
    data: {
        permission(toParams: { id: string }): RequiredPermission {
            return {
                oneOf: [
                    PERMISSION.PERSONAL.VIEW_ANY,
                    [PERMISSION.FEATURE.STUNDENERFASSUNG_TFO + GENERAL_SUFFIX, PERMISSION.KITA.CONTROL_ANY],
                    PERMISSION.ANGESTELLTE.READ + toParams.id,
                ],
            };
        },
    },
} satisfies Ng1StateDeclaration;

export const ANSTELLUNGEN_STATE = {
    name: `${ANGESTELLTE_MAIN_STATE.name}.anstellungen`,
    url: '/anstellungen',
    abstract: true,
    resolve: {
        anstellungen: [
            'angestellteService', '$transition$',
            (
                angestellteService: AngestellteService,
                $transition$: Transition,
            ): angular.IPromise<Anstellung[]> => {
                const includes = '(kinderOrte,kinderOrtFraktionen,dailyAnstellungen)';

                return angestellteService.getAnstellungen($transition$.params().id, {includes});
            },
        ],
    },
} satisfies Ng1StateDeclaration;

export const ANSTELLUNGEN_VERLAUF_STATE = {
    name: `${ANSTELLUNGEN_STATE.name}.verlauf`,
    url: '',
    component: 'dvbAngestellteAnstellung',
} satisfies Ng1StateDeclaration;

export const ANSTELLUNGEN_FORM_STATE = {
    name: `${ANSTELLUNGEN_STATE.name}.form`,
    resolve: {
        ausbildungen: [
            'ausbildungService',
            (ausbildungService: AusbildungService): angular.IPromise<Ausbildung[]> => ausbildungService.getAll(),
        ],
        angestellteId: [
            '$transition$', (transition: Transition): string => transition.params().id,
        ],
    },
} satisfies Ng1StateDeclaration;

export const NEW_ANSTELLUNG_STATE = {
    name: `${ANSTELLUNGEN_FORM_STATE.name}.create`,
    url: '/erfassen',
    component: AngestellteAnstellungFormComponent,
    resolve: {
        title: [
            '$translate',
            ($translate: angular.translate.ITranslateService): angular.IPromise<string> =>
                trans($translate, 'PERSONAL.ANSTELLUNG.CREATE'),
        ],
    },
} satisfies Ng2StateDeclaration;

export const EDIT_ANSTELLUNG_STATE = {
    name: `${ANSTELLUNGEN_FORM_STATE.name}.edit`,
    url: '/{anstellungId:[0-9]+}/bearbeiten',
    component: AngestellteAnstellungFormComponent,
    resolve: {
        anstellung: [
            'anstellungen', '$transition$', '$q', (
                anstellungen: Anstellung[],
                $transition$: Transition,
                $q: angular.IQService,
            ): angular.IPromise<Anstellung> => {
                const anstellung = anstellungen.find(a => a.id === $transition$.params().anstellungId);
                if (!anstellung) {
                    return $q.reject();
                }

                return $q.resolve(angular.copy(anstellung));
            },
        ],
        title: [
            '$translate',
            ($translate: angular.translate.ITranslateService): angular.IPromise<string> =>
                trans($translate, 'PERSONAL.ANSTELLUNG.EDIT'),
        ],
    },
} satisfies Ng2StateDeclaration;

export const ZUWEISUNG_STATE = {
    name: `${ANGESTELLTE_MAIN_STATE.name}.zuweisung`,
    url: '/zuweisung',
    component: AngestellteZuweisungComponent,
} satisfies Ng2StateDeclaration;

export const STUNDENERFASSUNG_STATE = {
    name: `${ANGESTELLTE_MAIN_STATE.name}.stundenerfassung`,
    url: '/stundenerfassung?:datum',
    component: 'dvbAngestellteStundenerfassung',
    params: {
        datum: {
            type: UrlParameterTypes.MOMENT_DATE,
            dynamic: true,
            value: null,
        },
    },
    data: {
        permission(toParams: { id: string }): RequiredPermission {
            return [
                PERMISSION.FEATURE.STUNDENERFASSUNG_TFO + GENERAL_SUFFIX,
                {
                    oneOf: [
                        PERMISSION.KITA.CONTROL_ANY,
                        PERMISSION.ANGESTELLTE.READ + toParams.id,
                    ],
                },
            ];
        },
    },
} satisfies Ng1StateDeclaration;

export const ANGESTELLTE_TERMIN_STATE = {
    name: `${ANGESTELLTE_MAIN_STATE.name}.termine`,
    url: '/termine',
    component: 'dvbTermine',
    resolve: {
        terminTypes: [
            'terminTypeService', (
                terminTypeService: TerminTypeService,
            ): Promise<JaxTerminType[]> =>
                firstValueFrom(terminTypeService.getAll$().pipe(map(p => p.items))),
        ],
    },
} satisfies Ng1StateDeclaration;

export const ANGESTELLTE_KORRESPONDENZ_STATE = {
    name: `${ANGESTELLTE_MAIN_STATE.name}.korrespondenz`,
    url: '/korrespondenz',
    component: AngestellteKorrespondenzComponent,
} satisfies Ng2StateDeclaration;

export const ANSTELLUNG_STATES = {
    ANGESTELLTE_BASE_STATE,
    ANGESTELLTE_CREATE_STATE,
    ANGESTELLTE_MAIN_STATE,
    ANGESTELLTE_PROFIL_STATE,
    ZUWEISUNG_STATE,
    ANSTELLUNGEN_STATE,
    ANSTELLUNGEN_VERLAUF_STATE,
    ANSTELLUNGEN_FORM_STATE,
    NEW_ANSTELLUNG_STATE,
    EDIT_ANSTELLUNG_STATE,
    STUNDENERFASSUNG_STATE,
    TERMIN_STATE: ANGESTELLTE_TERMIN_STATE,
    KORRESPONDENZ_STATE: ANGESTELLTE_KORRESPONDENZ_STATE,
};
