/*
 * Copyright © 2018 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {UserLanguageService} from '@dv/shared/angular';
import {DvbDateUtil, isPresent, LogFactory} from '@dv/shared/code';
import angular from 'angular';
import moment from 'moment';
import type {Benutzer} from '../../../benutzer/model/Benutzer';
import {directiveSubSink} from '../../../common/directive/directive-scope-subsink';

const LOG = LogFactory.createLog('DvbDashboardGreeting');

const componentConfig: angular.IComponentOptions = {
    bindings: {
        benutzer: '<',
    },
    template: require('./dvb-dashboard-greeting.html'),
    controllerAs: 'vm',
};

export class DvbDashboardGreeting implements angular.IOnInit {
    public static $inject: readonly string[] = ['$scope', '$translate', 'userLanguageService'];

    public benutzer!: Benutzer;

    public date: { [index: string]: string } | null = null;
    private currentMoment: moment.Moment = moment();

    public constructor(
        private readonly $scope: angular.IScope,
        private $translate: angular.translate.ITranslateService,
        private userLanguageService: UserLanguageService,
    ) {
    }

    public $onInit(): void {

        const subSink = directiveSubSink(this.$scope);
        subSink.sink = this.userLanguageService.userLanguage$
            .subscribe({
                next: () => {
                    this.currentMoment = moment();
                    this.date = {
                        month: this.currentMoment.format('MM'),
                        day: this.currentMoment.format('DD'),
                        weekday: this.currentMoment.format('dd').replace(/\./g, ''),
                    };
                },
                error: (err: unknown) => LOG.error(err),
            });
    }

    public getGreeting(): string {
        const translationParams = {vorname: this.benutzer.vorName};

        const noon = 12;
        const endOfAfternoon = 17;

        if (isPresent(this.benutzer.angestellte?.geburtsTag)
            && DvbDateUtil.isSameMonthAndDay(this.currentMoment, this.benutzer.angestellte.geburtsTag)) {
            return this.$translate.instant('DASHBOARD.HAPPY_BIRTHDAY', translationParams);
        }

        if (this.currentMoment.hour() < noon) {
            // hour is before noon
            return this.$translate.instant('DASHBOARD.GOOD_MORNING', translationParams);
        }

        if (this.currentMoment.hour() >= noon && this.currentMoment.hour() <= endOfAfternoon) {
            // Hour is from noon to 5pm (actually to 5:59 pm)
            return this.$translate.instant('DASHBOARD.GOOD_AFTERNOON', translationParams);
        }

        return this.$translate.instant('DASHBOARD.GOOD_EVENING', translationParams);
    }
}

componentConfig.controller = DvbDashboardGreeting;
angular.module('kitAdmin').component('dvbDashboardGreeting', componentConfig);
