/*
 * Copyright © 2018 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {BetreuungsPerson, Kind} from '@dv/kitadmin/models';
import type {AuthStore} from '@dv/shared/angular';
import {PERMISSION} from '@dv/shared/authentication/model';
import {checkPresent, DvbDateUtil, DvbUtil} from '@dv/shared/code';
import angular from 'angular';
import type moment from 'moment';
import type {MonatsBlattService} from '../../../../service/monatsBlattService';

const componentConfig: angular.IComponentOptions = {
    transclude: false,
    bindings: {
        betreuungsPerson: '<',
    },
    template: require('./dvb-betreuungsperson-kinder-list.html'),
    controllerAs: 'vm',
};

export class DvbBetreuungspersonKinderList implements angular.IController, angular.IOnInit {
    public static $inject: readonly string[] = ['monatsBlattService', 'authStore'];

    public betreuungsPerson!: BetreuungsPerson;
    public kinderList?: Kind[] = [];
    public today: moment.Moment = DvbDateUtil.today();
    public isLoading: boolean = false;

    public constructor(
        private monatsBlattService: MonatsBlattService,
        private authStore: AuthStore,
    ) {
    }

    public $onInit(): void {
        this.kinderList = [];
        if (!this.authStore.hasPermission(PERMISSION.KITA.CONTROL + checkPresent(this.betreuungsPerson.kita).id!)) {
            return;
        }
        this.isLoading = true;
        this.monatsBlattService.getMonatsBlattEntries(
            checkPresent(this.betreuungsPerson.id),
            checkPresent(this.today))
            .then(monatsBlattEntries => {
                this.kinderList = DvbUtil.uniqueArray(monatsBlattEntries.entries
                    .map(entry => checkPresent(entry.kind)), kind => kind.id);
            })
            .finally(() => {
                this.isLoading = false;
            });
    }
}

componentConfig.controller = DvbBetreuungspersonKinderList;
angular.module('kitAdmin').component('dvbBetreuungspersonKinderList', componentConfig);
