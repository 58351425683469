/*
 * Copyright © 2018 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {SearchService} from '@dv/kitadmin/search';
import { ArchiveSearchMode} from '@dv/kitadmin/search';
import type {SearchResultEntry, SearchResultType} from '@dv/shared/code';
import {SEARCH_RESULT_TYPES} from '@dv/shared/code';
import type {UIRouterGlobals} from '@uirouter/core';
import angular from 'angular';
import {finalize, tap} from 'rxjs';

const componentConfig: angular.IComponentOptions = {
    transclude: false,
    bindings: {},
    template: require('./dvb-global-search.html'),
    controllerAs: 'vm',
};

export class DvbGlobalSearch implements angular.IController, angular.IOnInit {

    public static $inject: readonly string[] = ['$uiRouterGlobals', 'searchService', '$translate'];

    public searchText: string;
    public results: SearchResultEntry[] = [];
    public checkAll: boolean = true;
    public isLoading: boolean = false;

    /**
     * Map of { entity-type => displayed-name }
     */
    public distinctEntities: { [key in SearchResultType]?: string } = {};
    /**
     * Map of { entity-type => checked (true/false) }
     */
    public checkedEntities: { [key in SearchResultType]?: boolean } = {};

    public archiveSearchModes: ArchiveSearchMode[] = Object.values(ArchiveSearchMode);
    public archiveSearchMode: ArchiveSearchMode = ArchiveSearchMode.ANY;

    public constructor(
        private $uiRouterGlobals: UIRouterGlobals,
        private searchService: SearchService,
        private $translate: angular.translate.ITranslateService,
    ) {
        this.searchText = this.$uiRouterGlobals.params.searchText ?? '';
    }

    private static getSelectionCount(myCheckedEntries: { [key in SearchResultType]?: boolean }): number {
        return Object.values(myCheckedEntries).filter(val => val).length;
    }

    public $onInit(): void {
        if (this.searchText.length < 2) {
            return;
        }
        this.searchEntities();
    }

    public changeSearchMode(newMode: ArchiveSearchMode): void {
        if (newMode === this.archiveSearchMode) {
            return;
        }
        this.archiveSearchMode = newMode;
        this.searchEntities();
    }

    public isKindSelected(): boolean | undefined {
        return Object.keys(this.distinctEntities).some(entityType => entityType === 'KIND');
    }

    public kindSelected(): boolean | undefined {
        return this.checkAll || this.checkedEntities.KIND;
    }

    public changeCheckboxAll(): void {
        if (this.checkAll) {
            this.checkedEntities = this.getEmptySelection(this.distinctEntities, false);
        } else {
            // kann durch klick nicht deaktiviert werden, da nicht definierbar ist, was mit den Entities geschehen
            // soll
            this.checkAll = true;
        }
    }

    public changeCheckboxEntity(): void {
        const selected = DvbGlobalSearch.getSelectionCount(this.checkedEntities);
        this.checkAll = selected === 0;

        if (!this.kindSelected()) {
            this.archiveSearchMode = ArchiveSearchMode.ANY;
        }
    }

    /**
     * @param actual all fields (including "entity") and other fields(!) of a SearchResultEntry
     */
    public selectionComparator(actual: SearchResultType): boolean {
        if (this.checkAll) {
            return true;
        }

        if (!angular.isDefined(this.checkedEntities[actual])) {
            return false;
        }

        return !!this.checkedEntities[actual];
    }

    public getEntryHref(entity: SearchResultType, id: string): string {
        return this.searchService.getEntityHref(entity, id);
    }

    private searchEntities(): void {
        this.isLoading = true;
        this.searchService.searchGlobal$(this.searchText, true, this.archiveSearchMode).pipe(
            tap(response => {
                this.results = response;
                this.distinctEntities = this.findDistinctEntities(response);
                this.checkAll = true;
                this.checkedEntities = this.getEmptySelection(this.distinctEntities, false);
            }),
            finalize(() => {
                this.isLoading = false;
            }),
        ).subscribe();
    }

    private findDistinctEntities(entries: SearchResultEntry[]): { [key in SearchResultType]?: string } {
        const entities: { [key in SearchResultType]?: string } = {};

        entries.forEach(value => {
            entities[value.entity] = this.$translate.instant(`COMMON.${value.entity}.PLURAL`);
        });

        return entities;
    }

    /**
     * Convert distinctEntities to a map for the selection buttons.
     *
     * @param myDistinctEntities
     * @param selected should the entries in the newly generated map be selected or deselected?
     */
    private getEmptySelection(
        myDistinctEntities: { [key in SearchResultType]?: string },
        selected: boolean,
    ): { [key in SearchResultType]?: boolean } {

        const entities: { [key in SearchResultType]?: boolean } = {};

        Object.keys(myDistinctEntities).forEach(key => {
            entities[SEARCH_RESULT_TYPES.check(key)] = selected;
        });

        return entities;
    }
}

componentConfig.controller = DvbGlobalSearch;
angular.module('kitAdmin').component('dvbGlobalSearch', componentConfig);
