<form name="rechnungGebuehrenBearbeitenForm"
      #rechnungGebuehrenBearbeitenForm="ngForm"
      (ngSubmit)="submitForm(rechnungGebuehrenBearbeitenForm)"
      novalidate>
    <div class="modal-body">
        <div class="text-left">
            <h1 [transloco]="'FAKTURA.RECHNUNGSGEBUEHREN_BEARBEITEN'"></h1>

            <h2 [transloco]="'FAKTURA.GEBUEHREN'"></h2>

            @if (customPositions.length === 0) {
                <p class="small-abstand-bottom" [transloco]="'FAKTURA.KEINE_GEBUEHREN'"></p>
            }

            @for (position of customPositions; track position; let i = $index) {
                <div data-testid="custom-position" class="row">
                    <div class="col-xs-4">
                        <input class="form-control"
                               type="text"
                               [name]="'bezeichnung' + i"
                               [(ngModel)]="position.text"
                               [disabled]="position.gebuehrType === depotType"
                               [placeholder]="'COMMON.BEZEICHNUNG' | transloco"
                               [tooltip]="'COMMON.BEZEICHNUNG' | transloco"
                               required>
                    </div>
                    <div class="col-xs-4">
                        <input class="form-control"
                               type="number"
                               [name]="'betrag' + i"
                               [(ngModel)]="position.betrag"
                               [disabled]="position.gebuehrType === depotType"
                               [placeholder]="'COMMON.BETRAG_CHF' | transloco"
                               [tooltip]="'COMMON.BETRAG_CHF' | transloco"
                               min="0"
                               required>
                    </div>
                    @if (editable) {
                        <div class="col-xs-4">
                            <button type="button"
                                    class="btn btn-link delete-gebuehr"
                                    [transloco]="'FAKTURA.GEBUEHR_LOSCHEN'"
                                    (click)="removeGebuehr(position)">
                            </button>
                        </div>
                    }
                </div>
            }

            @if (editable) {
                <button type="button"
                        class="btn btn-link"
                        (click)="addGebuehr()"
                        [transloco]="'FAKTURA.GEBUEHR_HINZUFUEGEN'">
                </button>
            }
        </div>
    </div>
    <div class="modal-footer text-left big-abstand-top">
        <div class="text-left">
            <dvlib-button-list *dvlibLet="(isLoading$ | async) as isLoading">
                @if (editable) {
                    <dvlib-loading-button [type]="'submit'" [isLoading]="isLoading">
                        <span [transloco]="'COMMON.SPEICHERN'"></span>
                    </dvlib-loading-button>
                }
                <button type="button"
                        class="btn btn-link"
                        [ngClass]="{'invisible': isLoading}"
                        [transloco]="'COMMON.ABBRECHEN'"
                        (click)="hide()">
                </button>
            </dvlib-button-list>
        </div>
    </div>
</form>
