import {ChangeDetectionStrategy, Component, effect, inject, Input, OnInit} from '@angular/core';
import {KinderOrt} from '@dv/kitadmin/models';
import {WorkTimeControllingTableComponent} from '@dv/kitadmin/work-time-controlling';
import {DateSwitcherComponent, SpinnerComponent} from '@dv/shared/angular';
import {isPresent, Persisted} from '@dv/shared/code';
import type {UiOnParamsChanged} from '@uirouter/angular';
import {Transition} from '@uirouter/core';
import {KinderOrtWorkTimeControllingStore} from './kinder-ort-work-time-controlling.store';

@Component({
    selector: 'dv-kinder-ort-work-time-controlling',
    standalone: true,
    imports: [
        DateSwitcherComponent,
        SpinnerComponent,
        WorkTimeControllingTableComponent,
    ],
    templateUrl: './kinder-ort-work-time-controlling.component.html',
    styles: `
        :host {
            display: block;
        }
    `,
    providers: [KinderOrtWorkTimeControllingStore],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class KinderOrtWorkTimeControllingComponent implements OnInit, UiOnParamsChanged {

    @Input({required: true}) public kinderOrt!: Persisted<KinderOrt>;

    public readonly store = inject(KinderOrtWorkTimeControllingStore);

    // ui-router is not compatible with signal-inputs
    @Input({required: true})
    public $transition$!: Transition;

    private pathParamsInitialized = false;
    // noinspection JSUnusedLocalSymbols
    private applyPathParamsEffect = effect(() => {
        const date = this.store.selectedMonth();

        if (this.pathParamsInitialized) {
            this.$transition$.router.stateService.go('.', {date}, {location: true});
        }

        this.pathParamsInitialized = true;
    });

    public ngOnInit(): void {
        this.store.setKinderOrtId(this.kinderOrt.id);
        this.uiOnParamsChanged(this.$transition$.params());
    }

    public uiOnParamsChanged(newParams: { [paramName: string]: any }): void {
        if (isPresent(newParams.date)) {
            this.store.applyMonth(newParams.date);
        }
    }
}
