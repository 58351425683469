<div class="dvb-entity-list row" dvb-accordions>
    <div class="col-md-7 big-abstand-bottom">

        <h2 data-translate="{{vm.titleKey}}" data-translate-interpolation="messageformat"></h2>

        <dvb-pages items="vm.items"
                   total-items="vm.items.length"
                   is-loading="vm.isLoading"
                   items-per-page="vm.itemsPerPage"
                   current-page="vm.currentPage"
                   entity-name="vm.titleKey"
                   on-update-page="vm.updatePage(page)"
                   on-update-items-per-page="vm.onUpdateItemsPerPage(itemsPerPage)">
            <page-items>
                <dvb-accordion is-first="$first"
                               ng-disabled="true"
                               show-content-disabled="false"
                               ng-repeat="item in vm.items | limitTo: vm.itemsPerPage : ((vm.currentPage - 1) * vm.itemsPerPage)">
                    <panel-header class="full-width-block">
                        <div class="row layout-row items-center flex-start">
                            <div class="col-xs-8">
                                <a ui-sref="{{vm.baseState + '({id: item.entityId()})'}}"
                                   ng-bind="item.getDisplayName()">
                                </a>
                                <span class="small-abstand-left fa fa-shield"
                                      ng-if="vm.type === 'benutzer' && item.isOidcUser()"
                                      uib-tooltip="{{'AUTHENTICATION.OIDC_USER' | translate}}"
                                      tooltip-placement="top-left">
                                </span>
                            </div>
                            <div class="col-xs-4 text-right" ng-if="item.badgeText()">
                                <span class="badge"
                                      ng-bind="item.badgeText()"
                                      uib-tooltip="{{item.tooltipText()}}"></span>
                            </div>
                        </div>
                    </panel-header>
                </dvb-accordion>
            </page-items>
            <total-summary>
                <dvb-list-summary>
                    <div class="floating-text">
                        <strong data-translate="{{vm.titleKey}}"
                                data-translate-value-count="{{vm.items.length}}"
                                data-translate-value-showcount="true"
                                data-translate-interpolation="messageformat">
                        </strong>
                    </div>
                </dvb-list-summary>
            </total-summary>
        </dvb-pages>
    </div>

    <div class="col-md-4 col-md-offset-1"
         dvb-auth
         require-permission="{{vm.createPermission}}"
         require-condition="vm.createLinkTitleKey || vm.isSlotFilled('aside')">
        <!-- STAMMDATEN -->
        <h2 data-translate="COMMON.AKTIONEN"></h2>
        <a ui-sref="{{vm.baseStateCreate}}" class="full-width-block normal-abstand-bottom">
            + <span data-translate="{{vm.createLinkTitleKey}}"></span>
        </a>

        <ng-transclude ng-transclude-slot="aside"></ng-transclude>
    </div>
</div>
