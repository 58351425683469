/*
 * Copyright © 2022 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import {CommonModule} from '@angular/common';
import type {OnInit} from '@angular/core';
import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Input} from '@angular/core';
import type {NgForm} from '@angular/forms';
import {FormsModule} from '@angular/forms';
import {ErrorService} from '@dv/kitadmin/core/errors';
import type {BulkRechnungEmail, Rechnung} from '@dv/kitadmin/models';
import {BulkEmailResult, EmailVersandProperties, Kita, KitaRechnungen} from '@dv/kitadmin/models';
import type {DialogModel} from '@dv/shared/angular';
import {ButtonListComponent, LoadingButtonComponent} from '@dv/shared/angular';
import type {Persisted} from '@dv/shared/code';
import {checkPresent, DvbUtil} from '@dv/shared/code';
import {TranslocoModule} from '@jsverse/transloco';
import {UIRouterModule} from '@uirouter/angular';
import {StateService} from '@uirouter/core';
import {BsModalRef} from 'ngx-bootstrap/modal';
import {TooltipModule} from 'ngx-bootstrap/tooltip';
import {SpfInfoComponent} from '../../../../common/component/spf-info/spf-info.component';
import {FakturaService} from '../../../../common/service/rest/fakturaService';
import {RechnungenFilter} from '../../../../filter/RechnungenFilter';

export interface RechnungEmailMassenVersandBestaetigenDialogModel extends DialogModel {
    kitaRechnungen?: KitaRechnungen;
    kita?: Kita;
    rechnungenFilter?: RechnungenFilter;
    fakturaService: FakturaService;
}

@Component({
    selector: 'dv-rechnung-email-massen-versand-bestaetigen',
    templateUrl: './rechnung-email-massen-versand-bestaetigen.component.html',
    styleUrl: './rechnung-email-massen-versand-bestaetigen.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        CommonModule,
        FormsModule,
        TranslocoModule,
        TooltipModule,
        UIRouterModule,
        SpfInfoComponent,
        LoadingButtonComponent,
        ButtonListComponent,
    ],
})
export class RechnungEmailMassenVersandBestaetigenComponent
    implements RechnungEmailMassenVersandBestaetigenDialogModel, OnInit {

    @Input() public kitaRechnungen?: KitaRechnungen;
    @Input() public kita?: Kita;
    @Input() public rechnungenFilter?: RechnungenFilter;
    @Input() public fakturaService!: FakturaService;

    public emailVersandProperties: EmailVersandProperties = new EmailVersandProperties();
    public isLoading: boolean = false;

    public failed: Persisted<Rechnung>[] = [];
    public missingEmail: { [kontaktpersonId: string]: Persisted<Rechnung>[] } = {};
    public hasMissingEmail: boolean = false;
    public successCount: number = 0;

    public constructor(
        private readonly bsModalRef: BsModalRef,
        private readonly stateService: StateService,
        private readonly errorService: ErrorService,
        private readonly changeDetection: ChangeDetectorRef,
    ) {
    }

    public ngOnInit(): void {
        this.emailVersandProperties.replyToAddress = this.kita ? this.kita.email : null;
        this.emailVersandProperties.sendEmails = true;
    }

    public submit(form: NgForm): void {
        this.errorService.clearAll();
        this.errorService.handleValidationError(!form.errors?.required, 'ERRORS.ERR_INCOMPLETE_FORM');

        if (form.invalid) {
            return;
        }

        this.isLoading = true;

        this.fakturaService.sendFilteredRechnungen(
            checkPresent(this.kita).id!,
            this.emailVersandProperties,
            this.rechnungenFilter!)
            .then(result => {
                if (this.isSuccess(result)) {
                    this.bsModalRef.hide();
                }
            }).catch(err => {
            this.errorService.handleError(err);
        }).finally(() => {
            this.isLoading = false;
            this.stateService.reload();
            this.changeDetection.detectChanges();
        });
    }

    public hide(): void {
        this.bsModalRef.hide();
    }

    private isSuccess(result: BulkRechnungEmail[]): boolean {
        this.successCount = result.filter(item => item.result === BulkEmailResult.SUCCESS).length;

        this.failed = result
            .filter(item => item.result === BulkEmailResult.FAILURE)
            .map(item => item.rechnung)
            .sort((a, b) => a.kontaktpersonDisplayName!.localeCompare(b.kontaktpersonDisplayName!)
                || b.rechnungsNummer! - a.rechnungsNummer!);

        // avoid resending successfully sent emails
        this.kitaRechnungen!.rechnungen = this.failed;
        this.kitaRechnungen!.stats.count = this.failed.length;

        const missingEmail: Persisted<Rechnung>[] = result
            .filter(item => item.result === BulkEmailResult.MISSING_EMAIL)
            .map(item => item.rechnung);

        this.missingEmail = {};
        this.hasMissingEmail = missingEmail.length > 0;
        missingEmail.forEach(rechnung => {
            this.missingEmail[checkPresent(rechnung.kontaktpersonId)] ??= [];
            this.missingEmail[checkPresent(rechnung.kontaktpersonId)].push(rechnung);
        });

        Object.keys(this.missingEmail).forEach(id => this.missingEmail[id].sort(DvbUtil.persistableComparatorAsc));

        return this.failed.length === 0 && missingEmail.length === 0;
    }
}

