/*
 * Copyright © 2019 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {ErrorService} from '@dv/kitadmin/core/errors';
import type {ContextualAdresse, Mandant} from '@dv/kitadmin/models';
import {PERMISSION} from '@dv/shared/authentication/model';
import type {KitadminModul} from '@dv/shared/backend/model/kitadmin-modul';
import type {Adresse, Persisted} from '@dv/shared/code';
import {DvbDateUtil, DvbUtil} from '@dv/shared/code';
import angular from 'angular';
import type {AuthorisationService} from '../../../authorisation/service/authorisation.service';
import type {MandantService} from '../../../common/service/rest/mandantService';

const componentConfig: angular.IComponentOptions = {
    transclude: false,
    bindings: {
        mandant: '<',
    },
    template: require('./dvb-mandant-profil.html'),
    controllerAs: 'vm',
};

export class DvbMandantProfil implements angular.IController {

    public static $inject: readonly string[] = ['mandantService', 'errorService', 'authorisationService'];

    public mandant!: Persisted<Mandant>;
    public availableModules: KitadminModul[] = [];

    public isAdresseEditMode: boolean = false;
    public isMandantAdmin: boolean = false;

    public constructor(
        private readonly mandantService: MandantService,
        private readonly errorService: ErrorService,
        private readonly authorisationService: AuthorisationService,
    ) {
    }

    public $onInit(): void {
        this.isMandantAdmin = this.authorisationService.isMandantAdmin();
        this.mandantService.getAvailableKitadminModules().then(modules => {
            this.availableModules = modules;
        });
    }

    public isValidMoment(value: any): boolean {
        return DvbDateUtil.isValidMoment(value);
    }

    public isNotEmptyString(param: any): boolean {
        const valid = DvbUtil.isNotEmptyString(param);
        this.errorService.handleValidationError(valid, 'ERRORS.VALUE_REQUIRED');

        return valid;
    }

    public isInteger(param: any): boolean {
        const valid = DvbUtil.isInteger(param);
        this.errorService.handleValidationError(valid, 'ERRORS.ERR_INTEGER');

        return valid;
    }

    public editAdresse(): void {
        if (!this.authorisationService.hasPermission(PERMISSION.MANDANT.UPDATE + this.mandant.id)) {
            return;
        }

        this.isAdresseEditMode = true;
    }

    public saveAdresse(adresse: Adresse): void {
        const origAdresse = this.mandant.adresse;
        this.mandant.adresse = adresse;
        this.updateMandant().catch(() => {
            this.mandant.adresse = origAdresse;
        });
    }

    public saveContextualAdresse(contextualAdresse: ContextualAdresse): void {
        this.mandant.contextualAdresse = contextualAdresse;
        this.updateMandant();
    }

    public updateMandant(): angular.IHttpPromise<void> {
        return this.mandantService.update(this.mandant);
    }

    public disable(): void {
        this.mandantService.disable(this.mandant, this.mandant.disabled);
    }
}

componentConfig.controller = DvbMandantProfil;
angular.module('kitAdmin').component('dvbMandantProfil', componentConfig);
