<div class="wochenplan">
    <div class="tag" ng-if="vm.showZeiten || vm.showNames">
        <div class="belegungseinheiten">
            <div class="header">&nbsp;</div>
            <div ng-repeat="zeitraum in vm.distinctBetreuungsZeitraeume"
                 class="belegungseinheit"
                 ng-class="{'zeitraum-zeit': vm.showZeiten, 'zeitraum-name': vm.showNames}">
                <div class="feld">
                    <p ng-if="vm.showNames" ng-bind="zeitraum.name"></p>
                    <p ng-if="vm.showZeiten" ng-bind="zeitraum.von.format('HH:mm')"></p>
                    <p ng-if="vm.showZeiten && ($last || vm.distinctBetreuungsZeitraeume[$index + 1].von.diff(zeitraum.bis, 'minutes') !== 0)"
                       ng-bind="zeitraum.bis.format('HH:mm')"></p>
                </div>
            </div>
        </div>
    </div>
    <div class="tag" ng-repeat="dayOfWeek in vm.weekDays">

        <div ng-if="!vm.selectableZeitraumCollections || vm.selectableZeitraumCollections.length <= 0 || !vm.activeWeekDays[dayOfWeek]"
             class="header"
             ng-bind="vm.printDayOfWeek(dayOfWeek)">
        </div>

        <div ng-if="vm.selectableZeitraumCollections.length > 0 && vm.activeWeekDays[dayOfWeek]"
             class="header" uib-dropdown data-keyboard-nav>
            <button type="button"
                    class="btn btn-link dropdown-toggle"
                    uib-dropdown-toggle>
                <span ng-bind="vm.printDayOfWeek(dayOfWeek)"></span>
                <span class="caret"></span>
            </button>
            <ul class="dropdown-menu" uib-dropdown-menu role="menu">
                <li ng-repeat="collection in vm.selectableZeitraumCollections">
                    <a href=""
                       ng-click="vm.selectZeitraumCollection(collection, dayOfWeek)"
                       ng-bind="collection.getDisplayName()">
                    </a>
                </li>
            </ul>
        </div>

        <div class="belegungseinheiten">
            <div class="belegungseinheit" ng-repeat="zeitraum in vm.distinctBetreuungsZeitraeume">
                <div class="feld"
                     ng-class="[{
                     'freie-plaetze': vm.getCurrentField(dayOfWeek, zeitraum).hatFreiePlaetze,
                     'no-feld': vm.getCurrentField(dayOfWeek, zeitraum) === null ||  (vm.isEditable && vm.getCurrentField(dayOfWeek, zeitraum).value.length === 0),
                     'no-value': !vm.showNullValueAsPlain && (vm.getCurrentField(dayOfWeek, zeitraum).value === null || vm.getCurrentField(dayOfWeek, zeitraum).value.length === 0),
                     'interactive': !vm.isDisabled && vm.interactive && (vm.getCurrentField(dayOfWeek, zeitraum) !== null),
                     'selectededitable': vm.getCurrentField(dayOfWeek, zeitraum).selected && vm.interactive,
                     'selected':vm.getCurrentField(dayOfWeek, zeitraum).selected && !vm.interactive,
                     'active': vm.getCurrentField(dayOfWeek, zeitraum).active,
                     'error': vm.getCurrentField(dayOfWeek, zeitraum).hasError,
                     'maximum-ueberschritten': vm.getCurrentField(dayOfWeek, zeitraum).maximumUeberschritten},
                     vm.getCurrentField(dayOfWeek, zeitraum).selectionType,
                     vm.getCurrentField(dayOfWeek, zeitraum).cssClass]"
                     ng-click="vm.toggleZeitraumFeldSelection(vm.getCurrentField(dayOfWeek, zeitraum), $event)"
                     ng-mousedown="vm.mouseDown($event)"
                     dvb-right-click="vm.rightClick(vm.getCurrentField(dayOfWeek, zeitraum), $event)"
                     uib-tooltip="{{vm.getCurrentField(dayOfWeek, zeitraum).tooltip}}"
                     tooltip-placement="top">
                    <div class="zeitraum-name" ng-if="vm.showZeitraumName(dayOfWeek, zeitraum)">
                        <div>
                            <span ng-bind="zeitraum.getDisplayName()"></span>
                        </div>
                    </div>
                    <input ng-if="vm.isEditable && vm.getCurrentField(dayOfWeek, zeitraum)"
                           type="text"
                           dvb-select-on-click
                           class="zeitraum-feld-input"
                           ng-model="vm.getCurrentField(dayOfWeek, zeitraum).value"
                           maxlength="4"
                           ng-blur="vm.onZeitraumFeldEdited({zeitraumFeld: vm.getCurrentField(dayOfWeek, zeitraum)})">
                    <span ng-if="!vm.isEditable"
                          ng-bind="vm.getCurrentField(dayOfWeek, zeitraum).value"></span>
                    <span ng-if="!vm.isEditable && vm.getCurrentField(dayOfWeek, zeitraum).icon"
                          class="zeitraum-feld-icon"
                          ng-bind-html="vm.getCurrentField(dayOfWeek, zeitraum).icon"></span>
                    <span ng-if="!vm.isEditable && vm.getCurrentField(dayOfWeek, zeitraum).customIcon"
                          class="zeitraum-feld-icon">
                        <dv-pensum-icon
                            [value]="vm.getCurrentField(dayOfWeek, zeitraum).customIcon.value"
                            [color-mode]="vm.getCurrentField(dayOfWeek, zeitraum).customIcon.colorMode"
                            [tooltip]="vm.getCurrentField(dayOfWeek, zeitraum).customIcon.tooltip"
                            [tooltip-translated]="true"
                            [tooltip-placement]="'bottom-left'">
                        </dv-pensum-icon>
                    </span>
                </div>
            </div>
        </div>
    </div>
</div>
