/* eslint-disable max-lines */
/*
 * Copyright © 2023 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {Signal} from '@angular/core';
import {computed, inject, Injectable, signal} from '@angular/core';
import {takeUntilDestroyed, toObservable, toSignal} from '@angular/core/rxjs-interop';
import type {KinderOrt, Termin} from '@dv/kitadmin/models';
import {DialogService} from '@dv/kitadmin/ui';
import {AuthStore, handleResponse, handleResponseError} from '@dv/shared/angular';
import {PERMISSION} from '@dv/shared/authentication/model';
import {apiStore} from '@dv/shared/backend/api-util';
import {AngestellteZuweisungService} from '@dv/shared/backend/api/angestellte-zuweisung.service';
import {AusbildungService} from '@dv/shared/backend/api/ausbildung.service';
import {DienstService} from '@dv/shared/backend/api/dienst.service';
import {
    PersonalTimeRangeBedarfService,
    PersonalTimeRangeBedarfServiceGetTimeRangesForKinderOrtRequestParams,
} from '@dv/shared/backend/api/personal-time-range-bedarf.service';
import {PersonalplanungTagesinfoService} from '@dv/shared/backend/api/personalplanung-tagesinfo.service';
import {WorkTimeControllingService} from '@dv/shared/backend/api/work-time-controlling.service';
import {BackendLocalDate} from '@dv/shared/backend/model/backend-local-date';
import {EntityId} from '@dv/shared/backend/model/entity-id';
import {JaxPersonalSortOrder} from '@dv/shared/backend/model/jax-personal-sort-order';
import {JaxTimeRange} from '@dv/shared/backend/model/jax-time-range';
import {RestIncludes} from '@dv/shared/backend/model/rest-includes';
import {
    checkPersisted,
    checkPresent,
    displayableComparator,
    DvbDateUtil,
    DvbRestUtil,
    DvbUtil,
    hasOwnPropertyGuarded,
    isNullish,
    isPresent,
    ITimeRange,
    Persisted,
    TimeRangeUtil,
    toRestObject,
} from '@dv/shared/code';
import {shareState} from '@dv/shared/rxjs-utils';
import {Translator} from '@dv/shared/translator';
import moment from 'moment';
import type {BsModalRef} from 'ngx-bootstrap/modal';
import {catchError, combineLatest, EMPTY, filter, merge, Observable, of, startWith, switchMap, tap} from 'rxjs';
import {combineLatestWith, map} from 'rxjs/operators';
import type {CalendarDayInfo} from '../../../../calendar/timeline/model/CalendarDayInfo';
import type {CalendarEditDayInfoEvent} from '../../../../calendar/timeline/model/CalendarEditDayInfoEvent';
import type {CalendarEvent} from '../../../../calendar/timeline/model/CalendarEvent';
import type {CalendarGroup} from '../../../../calendar/timeline/model/CalendarGroup';
import type {CalendarDeleteResourceEvent} from '../../../../calendar/timeline/model/CalendarGroupDeleteEvent';
import type {CalendarGroupDropEvent} from '../../../../calendar/timeline/model/CalendarGroupDropEvent';
import type {
    CalendarGroupResizeCompleteEvent,
} from '../../../../calendar/timeline/model/CalendarGroupResizeCompleteEvent';
import type {CalendarGroupResizeEvent} from '../../../../calendar/timeline/model/CalendarGroupResizeEvent';
import type {CalendarGroupResourceAddEvent} from '../../../../calendar/timeline/model/CalendarGroupResourceAddEvent';
import type {
    CalendarGroupResourceRemoveEvent,
} from '../../../../calendar/timeline/model/CalendarGroupResourceRemoveEvent';
import type {CalendarResource} from '../../../../calendar/timeline/model/CalendarResource';
import type {LayerConfigByLayerIndex} from '../../../../calendar/timeline/model/LayerConfig';
import type {ResizeType} from '../../../../calendar/timeline/model/ResizeType';
import {DEFAULT_END_TIME, DEFAULT_START_TIME} from '../../../../calendar/timeline/service/timeline-calendar.service';
import {FilterOption} from '../../../../filter/shared/FilterOption';
import {Angestellte} from '../../../../personal/anstellung/models/Angestellte';
import {Ausbildung} from '../../../../personal/anstellung/models/Ausbildung';
import {KinderOrtTimeRangeBedarf} from '../../../../personal/bedarf/models/KinderOrtTimeRangeBedarf';
import {Dienst} from '../../../../personal/konfiguration/Dienst';
import {PersonalKonfigurationApiService} from '../../../../personal/konfiguration/personal-konfiguration-api.service';
import {AngestellteZuweisungen} from '../../../../personal/model/AngestellteZuweisungen';
import {AngestellteZuweisungZeit} from '../../../../personal/model/AngestellteZuweisungZeit';
import type {FraktionZuweisungen} from '../../../../personal/model/FraktionZuweisungen';
import {KinderOrtZuweisungen} from '../../../../personal/model/KinderOrtZuweisungen';
import {AusbildungUtil} from '../../../../personal/service/AusbildungUtil';
import {PersonalZeitraumUtil} from '../../../../personal/service/personalZeitraumUtil';
import {PersonalTimelineStore} from '../service/personal-timeline.store';
import {isAngestellteZuweisungEvent, isTerminEvent, resetToOriginalEvent} from './calendar-event-util';
import {EventGueltigkeitService} from './converter/event-gueltigkeit.service';
import {fraktionIndependentZeitToEvent} from './converter/fraktion-independent-zeit-to-calendar-event';
import {resourceMultipleGroups} from './converter/resource-processing';
import {terminToCalendarEvent} from './converter/termin-to-calendar-event';
import {filterBedarfsRelevanteTermineFn} from './converter/termine-filter';
import {verfuegbarkeitToCalendarEvent} from './converter/verfuegbarkeit-to-calendar-event';
import {zuweisungZeitToCalendarEvent} from './converter/zuweisung-zeit-to-calendar-event';
import type {CopyRange} from './copy/copy-event.type';
import {PersonalplanungCopyStore} from './copy/personalplanung-copy.store';
import {findDienst, findMatchingDienst} from './find-matching-dienst';
import {LayerType} from './LayerType';
import {PersonalplanungEditTimesStore} from './personalplanung-edit-times.store';
import {PersonalplanungTermineStore, terminDragData} from './personalplanung-termine.store';

const KINDER_ORT_SCHLUESSEL_CALENDAR_GROUP_ID = 'kinderOrt';
const WITHOUT_ZUWEISUNG_CALENDAR_GROUP_ID = 'withoutZuweisung';
const MIN_TIME_RANGE_DURATION = 5;

interface GroupSortDialogData {
    open: boolean;
    group?: CalendarGroup;
    sortOrder?: JaxPersonalSortOrder[];
}

// eslint-disable-next-line @angular-eslint/use-injectable-provided-in
@Injectable()
export class PersonalplanungStore {

    private timelineStore = inject(PersonalTimelineStore);
    public copyStore = inject(PersonalplanungCopyStore);
    public termineStore = inject(PersonalplanungTermineStore);
    public editTimesStore = inject(PersonalplanungEditTimesStore);
    private authStore = inject(AuthStore);

    private angestellteApi = inject(AngestellteZuweisungService);
    private dienstApi = inject(DienstService);
    private tagesinfoApi = inject(PersonalplanungTagesinfoService);
    private bedarfApi = inject(PersonalTimeRangeBedarfService);
    private ausbildungApi = inject(AusbildungService);
    private configApi = inject(PersonalKonfigurationApiService);
    private translator = inject(Translator);
    private dialogService = inject(DialogService);
    private eventGueltigkeitService = inject(EventGueltigkeitService);
    private workTimeControllingService = inject(WorkTimeControllingService);

    public kinderOrt = signal<Persisted<KinderOrt> | undefined>(undefined);
    public selectedFraktionen = signal([] as EntityId[]);
    public dragActive = signal(false);
    public personalSortDialog = signal<GroupSortDialogData>({open: false});
    public groups = signal([] as CalendarGroup[]);
    private tempAddedAngestellte: { [groupId: string]: AngestellteZuweisungen[] } = {};
    public filterBedarfsRelevanteTermine = signal(false);
    public saveControllingDataDialogOpen = signal(false);
    public controllingDataSaveIsLoading = signal(false);

    public arbeitszeitVon = signal(DEFAULT_START_TIME);
    public arbeitszeitBis = signal(DEFAULT_END_TIME);

    public readonly = computed(() => {
        const kinderOrtId = this.kinderOrt()?.id;

        return isNullish(kinderOrtId) ? true : !this.authStore.hasPermission(PERMISSION.PERSONAL.MANAGE + kinderOrtId);
    });

    public datesInRange = computed(() =>
        DvbDateUtil.createDatesFromRange(this.timelineStore.startDate(), this.timelineStore.endDate()));

    public sortedFraktionen = computed(() => {
        const fraktionen = this.kinderOrt()?.gruppen ?? [];

        return [...fraktionen].sort(displayableComparator);
    });

    public fraktionFilterOptions = computed(() => {
        const fraktionen = DvbDateUtil.getEntitiesIn(
            this.sortedFraktionen(),
            this.timelineStore.startDate(),
            this.timelineStore.endDate());

        return fraktionen.map(grp => new FilterOption(grp.id, grp.getDisplayName()));
    });

    public kinderOrtZuweisungParams = computed(() => {
        const kinderOrt = this.kinderOrt();
        const startDate = this.timelineStore.startDate();
        const endDate = this.timelineStore.endDate();

        if (!kinderOrt) {
            return undefined;
        }

        return {
            kinderOrtId: kinderOrt.id,
            kinderOrtIdMatrix: {
                gueltigAb: DvbRestUtil.momentToLocalDateChecked(startDate),
                gueltigBis: DvbRestUtil.momentToLocalDateChecked(endDate),
            },
        };
    });

    public kinderOrtPersonalTimeRangeBedarfParams = computed(() => {
        const kinderOrt = this.kinderOrt();

        if (!kinderOrt) {
            return undefined;
        }

        const bedarfParams: PersonalTimeRangeBedarfServiceGetTimeRangesForKinderOrtRequestParams = {
            id: kinderOrt.id,
            idMatrix: {
                gueltigAb: DvbRestUtil.momentToLocalDateChecked(this.timelineStore.startDate()),
                gueltigBis: DvbRestUtil.momentToLocalDateChecked(this.timelineStore.endDate()),
            },
        };

        return bedarfParams;
    });

    public previousDisplayMode: string = this.timelineStore.displayMode();

    public dienste: Signal<Persisted<Dienst>[]> = toSignal(toObservable(this.kinderOrt).pipe(
        filter(isPresent),
        switchMap(kinderOrt => this.dienstApi.getForKinderOrt$({id: kinderOrt.id}).pipe(
            map(result => result.items.map(Dienst.apiResponseTransformer).map(checkPersisted)),
            catchError(e => {
                handleResponseError(e, 'could not load dienste');

                return of([]);
            }),
        )),
    ), {initialValue: []});

    private ausbildungen: Signal<Persisted<Ausbildung>[]> = toSignal(toObservable(this.kinderOrt).pipe(
        filter(isPresent),
        switchMap(_kinderOrt => this.ausbildungApi.getAll$({
            ausbildungen: {includes: new RestIncludes('(children)')},
        }).pipe(
            map(result => result.items.map(Ausbildung.apiResponseTransformer).map(checkPersisted)),
            catchError(e => {
                handleResponseError(e, 'could not load ausbildungen');

                return of([]);
            }),
        )),
    ), {initialValue: []});

    public ausbildungenById = computed(() => {
        const ausbildungenById: { [id: string]: Persisted<Ausbildung> } = {};

        this.ausbildungen().forEach(a => AusbildungUtil.mapAusbildungenById(a, ausbildungenById));

        return ausbildungenById;
    });

    private sortKinderOrtStore =
        apiStore(this.angestellteApi.updateAngestellteOrderKinderOrt$.bind(this.angestellteApi));
    private sortFraktionStore =
        apiStore(this.angestellteApi.updateAngestellteOrderFraktion$.bind(this.angestellteApi));
    private addStore = apiStore(this.angestellteApi.addDienstToAngestellte$.bind(this.angestellteApi));
    private updateStore = apiStore(this.angestellteApi.update$.bind(this.angestellteApi));
    private deleteStore = apiStore(this.angestellteApi.deleteZuweisungenInRange$.bind(this.angestellteApi),
        () => this.deleteDialogRef?.hide());
    private deleteDialogRef: BsModalRef<any> | undefined;

    // zuweisungen shall reload after new params and after api calls.
    private zuweisungenSource$ = combineLatest([
        toObservable(this.kinderOrtZuweisungParams).pipe(tap(() => this.clearEvents())),
        this.sortKinderOrtStore.request$.pipe(startWith(undefined)),
        this.sortFraktionStore.request$.pipe(startWith(undefined)),
        this.addStore.request$.pipe(startWith(undefined)),
        this.updateStore.request$.pipe(startWith(undefined)),
        this.deleteStore.request$.pipe(startWith(undefined)),
        this.copyStore.copy$.pipe(startWith(undefined)),
    ]).pipe(
        map(([params]) => params),
    );

    private terminSource$ = combineLatest([
        toObservable(this.kinderOrtZuweisungParams),
        this.termineStore.addStore.request$.pipe(startWith(undefined)),
        this.termineStore.deleteStore.request$.pipe(startWith(undefined)),
        this.termineStore.updateStore.request$.pipe(startWith(undefined)),
        this.termineStore.termineChanged.pipe(startWith(undefined)),
    ]).pipe(map(([params]) => params));

    private zuweisungenLoading = signal(false);
    public zuweisungen$ = merge(this.zuweisungenSource$, this.terminSource$).pipe(
        filter(isPresent),
        tap(() => this.zuweisungenLoading.set(true)),
        switchMap(params => this.angestellteApi.getKinderOrtZuweisungen$(params).pipe(
            map(KinderOrtZuweisungen.apiResponseTransformer),
            catchError(e => {
                handleResponseError(e, 'could not load zuweisungen');

                return of(new KinderOrtZuweisungen());
            }),
        )),
        tap(() => this.zuweisungenLoading.set(false)),
        shareState(),
    );

    public angestellte$: Observable<Angestellte[]> = this.zuweisungen$.pipe(
        map(zuweisung => Object.values(zuweisung.angestellte)),
    );

    public sortOrder$: Observable<JaxPersonalSortOrder[]> = this.zuweisungen$.pipe(
        map(zuweisung => Object.values(zuweisung.sortOrder)),
    );

    public sortOrder: Signal<{ [p: string]: JaxPersonalSortOrder[] }> = toSignal(this.sortOrder$.pipe(
        map(personalSortOrder => {
            const result: {
                [key: string]: JaxPersonalSortOrder[];
            } = {};

            personalSortOrder.forEach(order => {
                const orderId = isNullish(order.kinderOrtFraktionId)
                    ? KINDER_ORT_SCHLUESSEL_CALENDAR_GROUP_ID
                    : order.kinderOrtFraktionId;

                if (!result[orderId]) {
                    result[orderId] = [];
                }

                result[orderId].push(order);
            });

            return result;
        })), {initialValue: {}});

    public bedarfSource$ = toObservable(this.kinderOrtPersonalTimeRangeBedarfParams);
    private bedarfLoading = signal(false);

    public bedarf$ = this.bedarfSource$.pipe(
        filter(isPresent),
        combineLatestWith(this.terminSource$, this.zuweisungenSource$),
        tap(() => {
            this.bedarfLoading.set(true);

            if (this.previousDisplayMode !== this.timelineStore.displayMode()) {
                this.clearBadges();
            }
        }),
        switchMap(([params, _termin, _zuweisung]) =>
            this.bedarfApi.getTimeRangesForKinderOrt$(params).pipe(
                map(value => KinderOrtTimeRangeBedarf.apiResponseTransformer(value)),
                catchError(e => {
                    handleResponseError(e, 'could not load bedarf');

                    return of(new KinderOrtTimeRangeBedarf());
                }),
                startWith(new KinderOrtTimeRangeBedarf()),
            ),
        ),
        tap(bedarf => {
            if (bedarf.kinderOrtId) {
                this.bedarfLoading.set(false);
            }
        }),
        shareState(),
    );

    public arbeitszeit$ = combineLatest([
        toObservable(this.kinderOrt).pipe(filter(isPresent)),
        toObservable(this.timelineStore.startDate),
    ]).pipe(
        switchMap(([kinderOrt, startDate]) => this.configApi.getArbeitszeitForKinderOrtAndDate$(kinderOrt.id, startDate)
            .pipe(
                catchError(e => {
                    const msg = `could not load arbeitszeit for KinderOrt ${kinderOrt.id} at ${startDate.toISOString()}`;
                    handleResponseError(e, msg);

                    return EMPTY;
                }),
            )),
        shareState(),
    );

    private updateTagesinfoStore = apiStore(this.tagesinfoApi.edit$.bind(this.tagesinfoApi));

    private tagesinfosLoading = signal(false);
    public tagesinfos$: Observable<CalendarDayInfo[]> = combineLatest([
        toObservable(this.kinderOrtZuweisungParams),
        this.updateTagesinfoStore.request$.pipe(startWith(undefined)),
    ]).pipe(
        map(([params]) => params),
        filter(isPresent),
        tap(() => this.tagesinfosLoading.set(true)),
        switchMap(params => this.tagesinfoApi.getForKinderOrt$(params).pipe(
            map(container => container.items.map(info => {
                return {
                    data: info,
                    date: DvbRestUtil.localDateToMoment(info.date)!,
                    content: info.content,
                };
            })),
            catchError(e => {
                handleResponseError(e, 'could not load tagesinfos');

                return of([]);
            }),
        )),
        tap(() => this.tagesinfosLoading.set(false)),
        shareState(),
    );

    public isLoading = computed(() => {
        const loading = [
            this.zuweisungenLoading(),
            this.sortKinderOrtStore.isLoading(),
            this.sortFraktionStore.isLoading(),
            this.addStore.isLoading(),
            this.bedarfLoading(),
            this.updateStore.isLoading(),
            this.deleteStore.isLoading(),
            this.copyStore.copyLoading(),
            this.termineStore.addStore.isLoading(),
            this.termineStore.updateStore.isLoading(),
            this.termineStore.deleteStore.isLoading(),
            this.tagesinfosLoading(),
        ];

        return loading.includes(true);
    });

    public calendarGroups$ = combineLatest([
        this.zuweisungen$,
        this.bedarf$,
        toObservable(this.selectedFraktionen),
        toObservable(this.filterBedarfsRelevanteTermine),
    ]).pipe(
        filter(() => {
            if (this.previousDisplayMode !== this.timelineStore.displayMode()) {
                this.previousDisplayMode = this.timelineStore.displayMode();
            }

            return !this.zuweisungenLoading();
        }),
        map(([zuweisungen, bedarf, selectedFraktionen, filterBedarfsRelevanteTermine]) =>
            this.toCalendarGroups(zuweisungen, selectedFraktionen, bedarf, filterBedarfsRelevanteTermine)),
    );

    /**
     * Layer configuration for the timeline.
     * Each Layer Type needs a config for each case.
     */
    public timelineLayerConfig: Signal<LayerConfigByLayerIndex> = computed(() => {
        const layerConfig: LayerConfigByLayerIndex = new Map();
        const canEdit = !this.readonly();

        switch (this.timelineStore.displayMode()) {
            case 'day':
                layerConfig.set(LayerType.ZUWEISUNG, {resizable: canEdit, deletable: canEdit});
                layerConfig.set(LayerType.TERMIN, {resizable: canEdit, editable: canEdit, deletable: canEdit});
                layerConfig.set(LayerType.PAUSEZEIT, {
                    disableBorderRadius: true,
                    movable: canEdit,
                    resizable: canEdit,
                });
                layerConfig.set(LayerType.FRAKTION_INDEPENDENT, {customCssClass: 'fraktion-independent'});
                layerConfig.set(LayerType.VERFUEGBARKEIT,
                    {
                        disableBorderRadius: true,
                        movable: false,
                        resizable: false,
                        noRowSplitting: true,
                        customCssClass: 'nicht-verfuegbar',
                    });
                break;
            case 'week':
            case 'month':
                layerConfig.set(LayerType.ZUWEISUNG, {editable: canEdit, deletable: canEdit});
                layerConfig.set(LayerType.FRAKTION_INDEPENDENT, {customCssClass: 'fraktion-independent'});
                layerConfig.set(LayerType.TERMIN, {editable: canEdit, deletable: canEdit});
                layerConfig.set(LayerType.VERFUEGBARKEIT,
                    {
                        disableBorderRadius: true,
                        movable: false,
                        resizable: false,
                        noRowSplitting: true,
                        customCssClass: 'nicht-verfuegbar',
                    });
                break;
        }

        return layerConfig;
    });

    public constructor() {
        this.calendarGroups$.pipe(takeUntilDestroyed())
            .subscribe({
                next: data => this.groups.set(data),
            });

        this.arbeitszeit$.pipe(takeUntilDestroyed())
            .subscribe({
                next: data => {
                    const vonHours = checkPresent(data.von).hours();
                    const bis = checkPresent(data.bis);
                    const bisHours = bis.minute() ? bis.hours() + 1 : bis.hours();

                    this.arbeitszeitVon.set(vonHours);
                    this.arbeitszeitBis.set(bisHours);
                },
            });
    }

    public sortPersonal(group: CalendarGroup): void {
        this.personalSortDialog.set({open: true, group, sortOrder: this.sortOrder()[group.id] || []});
    }

    public updateSortOrder(order: Angestellte[]): void {
        const groupId = checkPresent(this.personalSortDialog().group).id;

        const jaxUpdateOrder = [];
        for (let i = 0; i < order.length; i++) {
            jaxUpdateOrder.push({id: order[i].id!, orderValue: i});
        }

        let request;
        if (groupId === KINDER_ORT_SCHLUESSEL_CALENDAR_GROUP_ID) {
            this.sortKinderOrtStore.source$.next({
                kinderOrtId: this.kinderOrt()!.id,
                jaxUpdateOrder: {items: jaxUpdateOrder},
            });
            request = this.sortKinderOrtStore.request$;
        } else {
            this.sortFraktionStore.source$.next({
                fraktionId: groupId,
                jaxUpdateOrder: {items: jaxUpdateOrder},
            });
            request = this.sortFraktionStore.request$;
        }

        request.pipe(
            tap(() => this.closePersonalSortDialog()),
        ).subscribe();
    }

    public closePersonalSortDialog(): void {
        this.personalSortDialog.set({open: false});
    }

    public assignDienstOrTermin(params: CalendarGroupDropEvent): void {
        if (params.data === terminDragData) {
            this.termineStore.openTerminDialog(params, this.kinderOrt());

            return;
        }

        this.assignDienst(params);
    }

    public assignDienst(params: CalendarGroupDropEvent): void {
        const resource = params.resource;
        const group = params.group;
        const dienstId = params.data;
        if (!(resource instanceof AngestellteZuweisungen)) {
            throw new Error(`assigning dienste is not supported for ${JSON.stringify(resource)}`);
        }

        // remove temp angestellte as she is no longer temporary
        this.removeTempAddedAngestellte(group.id, resource.id);

        const affectedZuweisungsIds = this.getAffectedZuweisungsIds(group);

        this.addStore.source$.next({
            jaxAngestellteAddDienst: {
                date: DvbRestUtil.momentToLocalDate(params.date)!,
                angestellteId: resource.angestellte.id,
                ...affectedZuweisungsIds,
                dienstId,
            },
        });
    }

    public resourceAdd(params: CalendarGroupResourceAddEvent): void {
        const group = params.group;
        const resource = params.resource;
        const angestellte = resource as Persisted<Angestellte>;
        const zuweisung = new AngestellteZuweisungen(angestellte, []);
        const termine =
            this.getRelevantTermine(angestellte, this.filterBedarfsRelevanteTermine());

        this.initEvents(zuweisung, group.id, termine, angestellte.zuweisungZeiten, angestellte.pauseZeiten);

        this.groups.update(value => {
                value.filter(calGroup => calGroup.id === group.id)
                    .forEach(calGroup => {
                        calGroup.resources.push(zuweisung);
                        calGroup.resources.sort((a, b) =>
                            this.sortAngestellte(
                                a as AngestellteZuweisungen,
                                b as AngestellteZuweisungen,
                                this.sortOrder()[group.id]));
                    });

                return [...value];
            },
        );

        this.addTempAngestellte(group, zuweisung);
    }

    public editZuweisungOrTermin(params: { element: Element; event: CalendarEvent }): void {
        const event = params.event;
        if (isTerminEvent(event)) {
            this.termineStore.openEditTerminDialog(event);

            return;
        }

        if (isAngestellteZuweisungEvent(event)) {
            this.editTimesStore.openEditTimesOverlay(event, params.element);

            return;
        }

        throw new Error(`unhandled event ${JSON.stringify(params)}`);
    }

    public updateZuweisungZeitWithTimes({event, times}: { event: CalendarEvent; times: ITimeRange }): void {
        if (!isAngestellteZuweisungEvent(event)) {
            throw new Error(`event is not a zuweisung ${JSON.stringify(event)}`);
        }

        const zuweisung = event.data.zuweisung;
        const index = zuweisung.zuweisungZeiten.findIndex(zeit => TimeRangeUtil.isSame(zeit, event));

        if (index === -1) {
            throw new Error('could not find zuweisungZeit');
        }

        zuweisung.zuweisungZeiten[index].von = times.von;
        zuweisung.zuweisungZeiten[index].bis = times.bis;

        this.updateStore.source$.next({
            jaxAngestellteZuweisung: {
                id: zuweisung.id,
                angestellteId: checkPresent(zuweisung.angestellteId),
                date: DvbRestUtil.momentToLocalDateChecked(event.gueltigAb),
                zuweisungZeiten: zuweisung.zuweisungZeiten.map(toRestObject),
                pauseZeiten: zuweisung.pauseZeiten.map(toRestObject),
            },
        });
    }

    public deleteZuweisungOrTermin(params: CalendarDeleteResourceEvent): void {
        const event = params.event;

        if (isTerminEvent(event)) {
            this.termineStore.openTerminDeleteDialog(event.data.termin, params.resource.id, event.gueltigAb);

            return;
        }

        if (isAngestellteZuweisungEvent(event)) {
            const resource = params.resource as AngestellteZuweisungen;
            this.deleteDialogRef = this.dialogService.openDeleteDialog({
                entityText: 'PERSONAL.ZUWEISUNG.TITLE',
                confirm: () => {
                    this.deleteStore.source$.next({
                        jaxAngestellteZuweisungInRange: {
                            angestellteId: resource.id,
                            gueltigAb: DvbRestUtil.momentToLocalDateChecked(event.gueltigAb),
                            gueltigBis: DvbRestUtil.momentToLocalDateChecked(event.gueltigBis),
                            ...this.getAffectedZuweisungsIds(params.group),
                            timeRanges: event.von ? [this.getTimeRange(event.von, event.bis!)] : [],
                        },
                    });

                    return this.deleteStore.request$.pipe(tap(() => {
                        if (resource.events.filter(e => isAngestellteZuweisungEvent(e)).length === 1) {
                            const tmpAngestellteZuweisung = new AngestellteZuweisungen(resource.angestellte, []);
                            tmpAngestellteZuweisung.inMultipleGroups = resource.inMultipleGroups;
                            this.addTempAngestellte(params.group, tmpAngestellteZuweisung);
                        }
                    }));
                },
            });

            return;
        }

        throw new Error(`unhandled event ${JSON.stringify(params)}`);
    }

    public updateZuweisungOrTermin(params: CalendarGroupResizeCompleteEvent): void {
        if (isTerminEvent(params.event)) {
            this.termineStore.update(params);

            return;
        }

        this.updateZuweisungZeit(params);
    }

    public updateZuweisungZeit(params: CalendarGroupResizeCompleteEvent): void {
        const resource = params.resource;
        const event = params.event;
        const originalEvent = params.originalEvent;

        if (!(event.layer === LayerType.ZUWEISUNG || event.layer === LayerType.PAUSEZEIT)) {
            resetToOriginalEvent(event, originalEvent);

            return;
        }

        if (!(resource instanceof AngestellteZuweisungen)) {
            throw new Error(`assigning dienste is not supported for ${JSON.stringify(resource)}`);
        }
        const zuweisung = checkPresent(resource.zuweisungen.find(z => z.date?.isSame(event.gueltigAb, 'day')));

        const zuweisungEvents: CalendarEvent[] = resource.events
            .filter(calEvent => calEvent.layer === LayerType.ZUWEISUNG);

        const eventIndex = resource.events.filter(e => e.layer === event.layer).indexOf(event);

        if (event.layer === LayerType.ZUWEISUNG) {
            const zuweisungZeit = zuweisung.zuweisungZeiten[eventIndex];
            if (TimeRangeUtil.isSame(event, zuweisungZeit)) {
                // no need to update model when it's equal
                return;
            }
        }

        if (event.layer === LayerType.PAUSEZEIT) {
            const pausenZeitIsEqual = zuweisung.pauseZeiten.some(pauseZeit => TimeRangeUtil.isSame(event, pauseZeit));
            if (pausenZeitIsEqual) {
                // no need to update model when it's equal
                return;
            }
        }

        // remove all pausen that don't fit into a Zuweisung
        const pauseZeiten = zuweisungEvents.flatMap(zuweisungEvent =>
            zuweisungEvent.subEvents.filter(subEvent => TimeRangeUtil.contains(zuweisungEvent, subEvent)));

        this.updateStore.source$.next({
            jaxAngestellteZuweisung: {
                id: zuweisung.id,
                angestellteId: checkPresent(zuweisung.angestellteId),
                date: DvbRestUtil.momentToLocalDateChecked(event.gueltigAb),
                zuweisungZeiten: zuweisungEvents.map(toRestObject),
                pauseZeiten: pauseZeiten.map(toRestObject),
            },
        });
    }

    public deleteZuweisungen(params: CalendarGroupResourceRemoveEvent): void {
        const group = params.group;
        const resource = params.resource;

        if (resource instanceof AngestellteZuweisungen && resource.zuweisungen.length < 1) {
            // no need to confirm removal for angestellte without termine or zuweisungen
            this.removeResource(group, resource);

            return;
        }

        this.deleteDialogRef = this.dialogService.openDeleteDialog({
            entityText: 'PERSONAL.ZUWEISUNGEN',
            confirm: () => {
                this.deleteStore.source$.next({
                    jaxAngestellteZuweisungInRange: {
                        angestellteId: resource.id,
                        gueltigAb: DvbRestUtil.momentToLocalDateChecked(this.timelineStore.startDate()),
                        gueltigBis: DvbRestUtil.momentToLocalDateChecked(this.timelineStore.endDate()),
                        ...this.getAffectedZuweisungsIds(group),
                        timeRanges: [],
                    },
                });

                return this.deleteStore.request$.pipe(tap(() => this.removeResource(group, resource)));
            },
        });
    }

    public resizing(params: CalendarGroupResizeEvent): void {
        const event = params.event.event;

        this.applyMinTimeRange(event, params.event.resizeEvent.type);

        this.groups.update(groups => {
            const dienst = findMatchingDienst(this.dienste(), event, event.subEvents);

            if (event.layer === LayerType.ZUWEISUNG) {
                event.backgroundColor = dienst?.backgroundColor ?? '';
                event.textColor = dienst?.textColor ?? '';
                event.hasHighLuminance = dienst?.hasHighLuminance ?? false;
                event.getDisplayName = () => dienst?.kuerzel ?? '';
            }

            if (event.layer === LayerType.PAUSEZEIT) {
                event.tooltip =
                    DvbDateUtil.getGueltigkeitTextWithOptionalTime(event, DvbDateUtil.today(), this.translator);
            }

            return [...groups];
        });
    }

    public copyZuweisungen(range: CopyRange): void {
        const kinderOrtId = checkPresent(this.kinderOrt()?.id);
        this.copyStore.copySource$.next({range, kinderOrtId});
    }

    public copyWithConflict(deleteExisting: boolean): void {
        this.copyStore.copyConflictSource$.next({deleteExisting});
    }

    public editDayInfo(event: CalendarEditDayInfoEvent): void {
        this.updateTagesinfoStore.source$.next({
            kinderOrtId: checkPresent(this.kinderOrt()?.id),
            jaxPersonalplanungTagesinfo: {
                date: DvbRestUtil.momentToLocalDateChecked(event.date),
                content: event.content,
            },
        });
    }

    public convertForControlling(): void {
        const kinderOrtId = checkPresent(this.kinderOrt()?.id);
        this.controllingDataSaveIsLoading.set(true);
        this.workTimeControllingService.storePlannedAsActual$({
            kinderOrtId,
            kinderOrtIdMatrix: {
                gueltigAb: DvbRestUtil.momentToLocalDateChecked(this.timelineStore.startDate()),
                gueltigBis: DvbRestUtil.momentToLocalDateChecked(this.timelineStore.endDate()),
            },
        }).pipe(handleResponse({
            next: () => this.saveControllingDataDialogOpen.set(false),
            finalize: () => this.controllingDataSaveIsLoading.set(false),
        })).subscribe();
    }

    private applyMinTimeRange(event: CalendarEvent, resizeEventType: ResizeType): void {
        if (resizeEventType === 'move' || DvbDateUtil.getTimeDiff(event.von!, event.bis!) >= MIN_TIME_RANGE_DURATION) {
            return;
        }

        switch (resizeEventType) {
            case 'start':
                event.von = moment(event.bis).subtract(MIN_TIME_RANGE_DURATION, 'minutes');
                break;
            case 'end':
                event.bis = moment(event.von).add(MIN_TIME_RANGE_DURATION, 'minutes');
                break;
            default:
            // nop
        }
    }

    private addTempAngestellte(group: CalendarGroup, zuweisung: AngestellteZuweisungen): void {
        if (!this.tempAddedAngestellte[group.id]) {
            this.tempAddedAngestellte[group.id] = [];
        }
        if (this.tempAddedAngestellte[group.id]
            .filter(z => z.angestellte.id === zuweisung.angestellte.id).length === 0) {
            this.tempAddedAngestellte[group.id].push(zuweisung);
        }
    }

    private getAffectedZuweisungsIds(group: CalendarGroup): {
        kinderOrtId: string | undefined;
        fraktionId: string | undefined;
    } {
        const fraktionZuweisungen = group;
        const fraktion = hasOwnPropertyGuarded(fraktionZuweisungen, 'fraktion') ?
            fraktionZuweisungen.fraktion :
            undefined;
        const fraktionId = fraktion && hasOwnPropertyGuarded(fraktion, 'id') && DvbUtil.isNotEmptyString(fraktion.id) ?
            fraktion.id :
            undefined;
        const kinderOrtId = fraktion ? undefined : checkPresent(this.kinderOrt()).id;

        return {
            kinderOrtId,
            fraktionId,
        };
    }

    private toCalendarGroups(
        kinderOrtZuweisungen: KinderOrtZuweisungen,
        fraktionen: EntityId[],
        bedarf: KinderOrtTimeRangeBedarf,
        filterBedarfsRelevanteTermine: boolean = false,
    ): CalendarGroup[] {
        const ausbildungen = this.ausbildungen();
        const ausbildungenById = this.ausbildungenById();

        const fraktionZuweisungenCalGroups = kinderOrtZuweisungen.fraktionZuweisungen
            .filter(fz => fraktionen.length === 0 || fraktionen.includes(fz.fraktion.id))
            .map(fraktionZuweisungen => {
                return this.setupFraktionZuweisung(
                    fraktionZuweisungen,
                    kinderOrtZuweisungen,
                    filterBedarfsRelevanteTermine,
                    bedarf,
                    ausbildungen,
                    ausbildungenById);
            });

        const badges = PersonalZeitraumUtil.getInfoBadgesFromTimeRangeBedarf(
            bedarf,
            undefined,
            ausbildungen,
            ausbildungenById,
        );

        const kinderOrtZuweisungenCalGroup: CalendarGroup = {
            id: KINDER_ORT_SCHLUESSEL_CALENDAR_GROUP_ID,
            extendable: !this.readonly(),
            sortable: !this.readonly(),
            resources: kinderOrtZuweisungen.kinderOrtZuweisungen.map(az => {
                const angestellte = kinderOrtZuweisungen.angestellte[az.angestellte.id];
                const termine = this.getRelevantTermine(angestellte, filterBedarfsRelevanteTermine);
                this.initEvents(
                    az,
                    KINDER_ORT_SCHLUESSEL_CALENDAR_GROUP_ID,
                    termine,
                    angestellte.zuweisungZeiten,
                    angestellte.pauseZeiten);

                return az;
            }),
            getDisplayName: () => this.translator.instant('PERSONAL.PLANUNG.KINDER_ORT_SCHLUESSEL'),
            infoBadges: badges,
        };

        const ohneZuweisungenCalGroup: CalendarGroup = {
            id: WITHOUT_ZUWEISUNG_CALENDAR_GROUP_ID,
            extendable: false,
            sortable: false,
            resources: kinderOrtZuweisungen.angestellteWithoutZuweisung.map(az => {
                const angestellte = kinderOrtZuweisungen.angestellte[az.angestellte.id];
                const termine =
                    angestellte.termine.filter(filterBedarfsRelevanteTermineFn(filterBedarfsRelevanteTermine));
                this.initEvents(az, null, termine, angestellte.zuweisungZeiten, angestellte.pauseZeiten);

                return az;
            }),
            infoBadges: [],
            getDisplayName: () => this.translator.instant('PERSONAL.PLANUNG.OTHER_ANGESTELLTE'),
        };

        const groups = [
            kinderOrtZuweisungenCalGroup,
            ...fraktionZuweisungenCalGroups.sort(displayableComparator),
            ohneZuweisungenCalGroup,
        ];
        groups.forEach(group => (this.tempAddedAngestellte[group.id] || [])
            .filter(z => !group.resources.some(resource => resource.id === z.id))
            .forEach(z => group.resources.push(z)));

        groups.forEach(group => group.resources.sort((a, b) => {
            return this.sortAngestellte(
                a as AngestellteZuweisungen,
                b as AngestellteZuweisungen,
                this.sortOrder()[group.id]);
        }));

        // map over CalendarGroups and check if any resource has already been assigned elsewhere based on resource.id
        return resourceMultipleGroups(groups);
    }

    private setupFraktionZuweisung(
        fraktionZuweisungen: FraktionZuweisungen,
        zuweisungen: KinderOrtZuweisungen,
        filterBedarfsRelevanteTermine: boolean,
        bedarf: KinderOrtTimeRangeBedarf,
        ausbildungen: Persisted<Ausbildung>[],
        ausbildungenById: { [p: string]: Persisted<Ausbildung> },
    ): FraktionZuweisungen {
        fraktionZuweisungen.angestellteZuweisungen.sort((a, b) => {
            return this.sortAngestellte(a, b, this.sortOrder()[fraktionZuweisungen.fraktion.id]);
        }).forEach(az => {
            const angestellte = zuweisungen.angestellte[az.angestellte.id];
            const termine = this.getRelevantTermine(angestellte, filterBedarfsRelevanteTermine);

            this.initEvents(
                az,
                fraktionZuweisungen.fraktion.id,
                termine,
                angestellte.zuweisungZeiten,
                angestellte.pauseZeiten);
        });

        fraktionZuweisungen.infoBadges = PersonalZeitraumUtil.getInfoBadgesFromTimeRangeBedarf(
            bedarf,
            fraktionZuweisungen.fraktion.id,
            ausbildungen,
            ausbildungenById,
        );

        fraktionZuweisungen.extendable = !this.readonly();
        fraktionZuweisungen.sortable = !this.readonly();

        return fraktionZuweisungen;
    }

    private sortAngestellte(
        a: AngestellteZuweisungen,
        b: AngestellteZuweisungen,
        sortOrder: JaxPersonalSortOrder[],
    ): number {

        if (isNullish(sortOrder) || sortOrder.length === 0) {
            return displayableComparator(a, b);
        }

        const orderA = this.findOrderValue(a, sortOrder);
        const orderB = this.findOrderValue(b, sortOrder);

        if (isNullish(orderA)) {
            return 1;
        }

        const result = isNullish(orderB) ? -1 : orderA - orderB;

        return result === 0 ? displayableComparator(a, b) : result;
    }

    private findOrderValue(zuweisungen: AngestellteZuweisungen, sortOrder: JaxPersonalSortOrder[]): number | undefined {
        return sortOrder.find(order => order.angestellteId === zuweisungen.angestellte.id)?.orderValue;
    }

    private initEvents(
        angestellteZuweisungen: AngestellteZuweisungen,
        calGroupId: string | null,
        termine: Termin[],
        fraktionIndependentZeiten: { [date: BackendLocalDate]: AngestellteZuweisungZeit[] },
        fraktionIndependentPausen: { [date: BackendLocalDate]: AngestellteZuweisungZeit[] },
    ): void {
        const dienste = this.dienste();

        const isDayMode = this.timelineStore.displayMode() === 'day';
        const selectedDate = isDayMode ? this.timelineStore.selectedDate() : undefined;

        const zuweisungEvents = angestellteZuweisungen.zuweisungen
            .flatMap(angestellteZuweisung => angestellteZuweisung.zuweisungZeiten.map(zuweisungZeit => {
                const zuweisungZeitDate = DvbRestUtil.momentToLocalDateChecked(angestellteZuweisung.date);

                const dienst = findDienst(
                    zuweisungZeit,
                    angestellteZuweisung.pauseZeiten,
                    dienste,
                    this.getFraktionIndependentTimesForCurrentKinderOrt(fraktionIndependentZeiten, zuweisungZeitDate),
                    fraktionIndependentPausen[zuweisungZeitDate] ?? []);

                return zuweisungZeitToCalendarEvent(
                    angestellteZuweisung,
                    zuweisungZeit,
                    dienst,
                    this.eventGueltigkeitService,
                    selectedDate);
            }));

        const terminEvents = termine
            .map(termin => terminToCalendarEvent(termin, this.eventGueltigkeitService, selectedDate));

        const fraktionIndependentEvents = Object.entries(fraktionIndependentZeiten)
            .flatMap(([date, timeranges]) => {
                const dateMoment = DvbRestUtil.localDateToMomentChecked(date);
                const zuweisungenOnDate = DvbDateUtil.getEntitiesOn(zuweisungEvents, dateMoment);
                const relevantRanges = this.findRelevantFraktionIndependentTimes(timeranges, calGroupId);

                return relevantRanges.map(z => fraktionIndependentZeitToEvent(
                    z,
                    fraktionIndependentPausen[date] || [],
                    dienste,
                    zuweisungenOnDate,
                    this.kinderOrt()?.id,
                    dateMoment));
            });

        const anstellungen = DvbDateUtil.getEntitiesIn(
            angestellteZuweisungen.angestellte.anstellungen,
            this.timelineStore.startDate(),
            this.timelineStore.endDate());
        const eventBlockers = verfuegbarkeitToCalendarEvent(
            anstellungen,
            this.kinderOrt()!,
            this.datesInRange(),
            this.timelineStore.displayMode(),
            this.translator);

        angestellteZuweisungen.events =
            [...zuweisungEvents, ...terminEvents, ...fraktionIndependentEvents, ...eventBlockers];
    }

    private getFraktionIndependentTimesForCurrentKinderOrt(
        fraktionIndependentZeiten: { [p: BackendLocalDate]: AngestellteZuweisungZeit[] },
        zuweisungZeitDate: string,
    ): AngestellteZuweisungZeit[] {

        const zeitenForDate = fraktionIndependentZeiten[zuweisungZeitDate];
        if (isNullish(zeitenForDate)) {
            return [];
        }

        const sameKinderOrtRanges = zeitenForDate.filter(jaxAngestellteTimeRange =>
            jaxAngestellteTimeRange.kinderOrtId === this.kinderOrt()?.id
            || this.kinderOrt()?.gruppen?.find(g => g.id === jaxAngestellteTimeRange.fraktionId));

        return this.findAndMergeIntersection(sameKinderOrtRanges, KINDER_ORT_SCHLUESSEL_CALENDAR_GROUP_ID);
    }

    private findRelevantFraktionIndependentTimes(
        timeranges: AngestellteZuweisungZeit[],
        calGroupId: string | null,
    ): AngestellteZuweisungZeit[] {
        const kinderOrtId = this.kinderOrt()?.id;
        const otherKinderOrtRanges: AngestellteZuweisungZeit[] = [];
        const sameKinderOrtRanges: AngestellteZuweisungZeit[] = [];

        timeranges.forEach(r => {
            if (r.kinderOrtId === kinderOrtId || this.kinderOrt()?.gruppen?.find(g => g.id === r.fraktionId)) {
                sameKinderOrtRanges.push(r);
            } else {
                otherKinderOrtRanges.push(r);
            }
        });

        // fraktion independent times on current kinderort are only relevant if they intersect and can be displayed
        // in a merged version
        const mergedKinderOrtRanges: AngestellteZuweisungZeit[] = this.findAndMergeIntersection(sameKinderOrtRanges,
            calGroupId);

        return [...otherKinderOrtRanges, ...mergedKinderOrtRanges];
    }

    /**
     * Finds any intersections within zeiten and returns a merged version of those.
     * Zeiten without intersections are ignored and not returned.
     */
    private findAndMergeIntersection(
        zeiten: AngestellteZuweisungZeit[],
        calGroupId: string | null,
    ): AngestellteZuweisungZeit[] {
        if (zeiten.length === 1 &&
            calGroupId === KINDER_ORT_SCHLUESSEL_CALENDAR_GROUP_ID &&
            zeiten[0].fraktionId === null) {
            return [];
        }

        return TimeRangeUtil.mergeOverlappingTimeRanges(zeiten.filter(range =>
            range.fraktionId !== calGroupId || zeiten.some(other =>
                range !== other && TimeRangeUtil.isIntersecting(range, other))),
        )
            .map(range => {
                return new AngestellteZuweisungZeit(
                    range.von,
                    range.bis,
                    this.kinderOrt()?.id,
                    null,
                    zeiten[0].kinderOrtDisplayName,
                    null);
            });
    }

    private clearEvents(): void {
        // clear all events
        this.groups.update(groups => {
            const result = [...groups];
            result.flatMap(g => g.resources).forEach(r => {
                r.events = [];
            });

            return result;
        });
    }

    private getTimeRange(von: moment.Moment, bis: moment.Moment): JaxTimeRange {
        return {
            von: checkPresent(DvbRestUtil.momentTolocaleHHMMTime(von)),
            bis: checkPresent(DvbRestUtil.momentTolocaleHHMMTime(bis)),
        };
    }

    private clearBadges(): void {
        this.groups.update(group => {
            const result = [...group];
            result.forEach(g => {
                g.infoBadges = [];
            });

            return result;
        });
    }

    private getRelevantTermine(angestellte: Angestellte, filterBedarfsRelevanteTermine: boolean): Termin[] {
        return angestellte.termine.filter(filterBedarfsRelevanteTermineFn(filterBedarfsRelevanteTermine));
    }

    private removeResource(group: CalendarGroup, resource: CalendarResource): void {
        // remove temp angestellte as it is no longer temporary
        this.removeTempAddedAngestellte(group.id, resource.id);

        this.groups.update(groups => {
            const toRemove = group.resources.findIndex(r => r.id === resource.id);
            group.resources.splice(toRemove, 1);

            return [...groups];
        });
    }

    private removeTempAddedAngestellte(groupId: string, resourceId: string): void {
        const tempIndex = this.tempAddedAngestellte[groupId]?.findIndex(z => z.id === resourceId);
        if (isPresent(tempIndex) && tempIndex >= 0) {
            this.tempAddedAngestellte[groupId].splice(tempIndex, 1);
        }
    }
}
