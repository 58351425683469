<ng-container *transloco="let t">
    <h2 class="normal-abstand-bottom">
        {{
            t(model.id ? 'PERSONAL.TERMIN.EDIT' : 'PERSONAL.TERMIN.ERSTELLEN',
                {entity: t('PERSONAL.TERMIN.SINGULAR')})
        }}
    </h2>

    <form #form="ngForm" (ngSubmit)="submitForm(form)" class="dvb-form" [dvlibValidator]="selectionValidator">

        <label class="sr-only" for="termin-form-title">{{ t('PERSONAL.TERMIN.NAME') }}</label>
        <input
            type="text"
            id="termin-form-title"
            class="form-control"
            #titleInput
            name="title"
            [maxlength]="DB_SHORT_LENGTH"
            [(ngModel)]="model.title"
            [placeholder]="t('PERSONAL.TERMIN.NAME')"
            [tooltip]="t('PERSONAL.TERMIN.NAME')"
            triggers="mouseenter:mouseleave">

        <div class="row">
            <div class="col-md-12">
                <select class="form-control"
                        name="terminTypeId"
                        required
                        [(ngModel)]="model.terminTypeId"
                        [attr.aria-invalid]="model.terminTypeId === undefined"
                        [attr.aria-label]="t('PERSONAL.TERMIN.TYPE.SINGULAR')"
                        [tooltip]="t('PERSONAL.TERMIN.TYPE.SINGULAR')"
                        placement="top left">
                    <option [ngValue]="undefined" disabled>{{ t('PERSONAL.TERMIN.TYPE.CHOOSE_TYPE') }}</option>
                    @for (option of terminTypes; track option.id) {
                        <option [ngValue]="option.id">
                            {{ option.name }}
                        </option>
                    }
                </select>
            </div>
        </div>

        <div class="row">
            <dvlib-datepicker-textfield
                class="form-row col-md-6"
                inputId="termin-form-gueltig-ab"
                name="gueltigAb"
                required
                [(ngModel)]="model.gueltigAb"
                [maxDate]="model.gueltigBis"
                [placeholder]="t('COMMON.GUELTIG_AB')">
            </dvlib-datepicker-textfield>
            <dvlib-datepicker-textfield
                class="form-row col-md-6"
                inputId="termin-form-gueltig-bis"
                name="gueltigBis"
                [(ngModel)]="model.gueltigBis"
                [minDate]="model.gueltigAb"
                [placeholder]="t('COMMON.GUELTIG_BIS')">
            </dvlib-datepicker-textfield>
        </div>

        <div class="row">
            <div class="col-md-6">
                <label class="sr-only" for="termin-form-von">{{ t('PERSONAL.TERMIN.VON') }}</label>
                <input
                    type="time"
                    class="form-control"
                    id="termin-form-von"
                    name="von"
                    [(ngModel)]="model.von"
                    validVon
                    [dateStart]="model.gueltigAb"
                    [dateEnd]="model.gueltigBis"
                    [timeEnd]="model.bis"
                    [placeholder]="t('PERSONAL.TERMIN.VON')"
                    [tooltip]="t('PERSONAL.TERMIN.VON')"/>
            </div>
            <div class="col-md-6">
                <label class="sr-only" for="termin-form-von">{{ t('PERSONAL.TERMIN.BIS') }}</label>
                <input
                    type="time"
                    class="form-control"
                    id="termin-form-bis"
                    name="bis"
                    [(ngModel)]="model.bis"
                    validBis
                    [dateStart]="model.gueltigAb"
                    [dateEnd]="model.gueltigBis"
                    [timeStart]="model.von"
                    [placeholder]="t('PERSONAL.TERMIN.BIS')"
                    [tooltip]="t('PERSONAL.TERMIN.BIS')"/>
            </div>
        </div>

        <label class="sr-only" for="termin-form-bemerkung">{{ t('PERSONAL.TERMIN.BEMERKUNG') }}</label>
        <textarea
            name="bemerkung"
            id="termin-form-bemerkung"
            [(ngModel)]="model.bemerkung"
            [maxlength]="DB_MAX_LENGTH"
            [placeholder]="t('PERSONAL.TERMIN.BEMERKUNG')"
            [tooltip]="t('PERSONAL.TERMIN.BEMERKUNG')"
            dvlibElasticTextInput>
    </textarea>

        <div class="row normal-abstand-bottom">
            <div class="col-md-6 layout-row flex-start items-center">
                <ngx-colors
                    ngx-colors-trigger
                    name="backgroundColor"
                    [(ngModel)]="model.backgroundColor"
                    [dvlibValidator]="colorValidator"
                    colorPickerControls="no-alpha"
                    tabindex="0"
                    format="hex"
                    [palette]="colorPalette"
                    [tooltip]="t('PERSONAL.TERMIN.BACKGROUND_COLOR')"
                    [acceptLabel]="t('COMMON.AUSWAEHLEN')"
                    [cancelLabel]="t('COMMON.ABBRECHEN')">
                </ngx-colors>
                <span [ngClass]="{'error-message': form.submitted && form.controls.backgroundColor.invalid}"
                      class="smaller-abstand-left">{{ t('PERSONAL.TERMIN.BACKGROUND_COLOR') }}</span>
            </div>
            <div class="col-md-6 layout-row flex-start items-center">
                <ngx-colors
                    ngx-colors-trigger
                    name="textColor"
                    [(ngModel)]="model.textColor"
                    [dvlibValidator]="colorValidator"
                    colorPickerControls="no-alpha"
                    tabindex="0"
                    format="hex"
                    [palette]="textColorPalette"
                    [tooltip]="t('PERSONAL.TERMIN.TEXT_COLOR')"
                    [acceptLabel]="t('COMMON.AUSWAEHLEN')"
                    [cancelLabel]="t('COMMON.ABBRECHEN')">
                </ngx-colors>
                <span class="smaller-abstand-left"
                      [ngClass]="{'error-message': form.submitted && form.controls.textColor.invalid}">
                    {{ t('PERSONAL.TERMIN.TEXT_COLOR') }}
                </span>
            </div>
        </div>

        @if (selectedAngestellte$ | async; as selectedAngestellte) {
            <div class="checkbox">
                <label>
                    <input
                        type="checkbox"
                        name="alleAngestellte"
                        [(ngModel)]="model.alleAngestellte"
                        (ngModelChange)="onAlleAngestellteChange(model.alleAngestellte)"
                        [dvlibSetValue]="selectedAngestellte.length === 0"/>
                    {{ t('PERSONAL.ANGESTELLTE.AUSWAEHLEN') }}
                </label>
            </div>
            @if (showAngestellteSearch()) {
                <dv-search-list
                    entityToSearch="ANGESTELLTE"
                    name="selectedAngestellte"
                    [(ngModel)]="model.selectedAngestellte"
                    [dvlibSetValue]="selectedAngestellte"
                    [disabled]="model.alleAngestellte === true"
                    [required]="model.alleKinderOrte === false"
                    [orderByText]="true"
                    [placeholder]="t('PERSONAL.ANGESTELLTE.AUSWAEHLEN')">
                </dv-search-list>
            } @else {
                <p transloco="PERSONAL.ANGESTELLTE.EXCLUDED"></p>
                <dv-search-list
                    entityToSearch="ANGESTELLTE"
                    name="selectedExcludedAngestellte"
                    [(ngModel)]="model.selectedExcludedAngestellte"
                    [dvlibSetValue]="(selectedExcludedAngestellte$ | async) || []"
                    [disabled]="model.alleAngestellte === false"
                    [orderByText]="true"
                    [placeholder]="t('PERSONAL.ANGESTELLTE.EXCLUDED_AUSWAEHLEN')">
                </dv-search-list>
            }
        } @else {
            <dvlib-spinner [show]="true"/>
        }

        @if (selectedKinderOrte$ | async; as kinderOrte) {
            <div class="checkbox">
                <label>
                    <input
                        type="checkbox"
                        name="alleKinderOrte"
                        [(ngModel)]="model.alleKinderOrte"
                        (ngModelChange)="onAlleKinderOrteChange(model.alleKinderOrte)">
                    {{ t('COMMON.ALLE_KINDERORTE') }}
                </label>
            </div>

            @if (showKinderOrtSearch()) {
                <dv-search-list
                    entityToSearch="KITA"
                    name="selectedKinderOrte"
                    [(ngModel)]="model.selectedKinderOrte"
                    [dvlibSetValue]="kinderOrte"
                    [disabled]="model.alleKinderOrte === true"
                    [required]="model.alleKinderOrte === false"
                    [orderByText]="true"
                    [placeholder]="t('COMMON.KINDERORT.AUSWAEHLEN')">
                </dv-search-list>
            }
        } @else {
            <dvlib-spinner [show]="true"/>
        }

        <div class="row">
            <div class="col-md-12">
                <div class="checkbox">
                    <label>
                        <input type="checkbox" name="wiederkehrend" [(ngModel)]="model.wiederkehrend"/>
                        {{ t('PERSONAL.TERMIN.WIEDERKEHRENDE') }}
                    </label>
                </div>
            </div>
        </div>

        @if (model.wiederkehrend) {
            <div class="form-section" ngModelGroup="rrule">
                <div class="row">
                    <div class="col-md-4">
                        <div class="radio">
                            <label>
                                <input
                                    type="radio"
                                    [(ngModel)]="rruleModel.frequenzTyp"
                                    required
                                    name="frequenzTyp"
                                    [value]="frequenzyTypes.DAILY"/>
                                {{ t('PERSONAL.TERMIN.FREQUENZ_TYP.DAILY.TITLE') }}
                            </label>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="radio">
                            <label>
                                <input
                                    type="radio"
                                    [(ngModel)]="rruleModel.frequenzTyp"
                                    required
                                    name="frequenzTyp"
                                    [value]="frequenzyTypes.WEEKLY"/>
                                {{ t('PERSONAL.TERMIN.FREQUENZ_TYP.WEEKLY.TITLE') }}
                            </label>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="radio">
                            <label>
                                <input
                                    type="radio"
                                    [(ngModel)]="rruleModel.frequenzTyp"
                                    required
                                    name="frequenzTyp"
                                    [value]="frequenzyTypes.MONTHLY"/>
                                {{ t('PERSONAL.TERMIN.FREQUENZ_TYP.MONTHLY.TITLE') }}
                            </label>
                        </div>
                    </div>
                </div>
                <div class="frequency">
                    <div class="frequency-type">
                        <span>{{ t('PERSONAL.TERMIN.FREQUENZ_PREFIX') }}</span>
                        <input
                            type="number"
                            class="form-control input-inline"
                            name="interval"
                            [(ngModel)]="rruleModel.interval"
                            [tooltip]="t('PERSONAL.TERMIN.FREQUENZ_TYP.' + getFrequencyStr(rruleModel.frequenzTyp!) + '.INTERVAL')"
                            required
                            min="1"
                            step="1"/>
                        <span>
                            {{ t('PERSONAL.TERMIN.FREQUENZ_TYP.' + getFrequencyStr(rruleModel.frequenzTyp!) + '.INTERVAL') }}
                        </span>
                    </div>
                    <div class="recurrence-end-choice">
                        <span class="recurrence-end-title">{{ t('PERSONAL.TERMIN.BIS_TYPE.TITLE') }}</span>
                        <div>
                            <div class="radio radio-row">
                                <label>
                                    <input
                                        type="radio"
                                        name="bisType"
                                        [(ngModel)]="rruleModel.bisType"
                                        [value]="bisTypes.BIS_EWIG"/>
                                    <span>{{ t('PERSONAL.TERMIN.BIS_TYPE.BIS_EWIG') }}</span>
                                </label>
                            </div>
                            <div class="radio radio-row">
                                <label>
                                    <input
                                        type="radio"
                                        name="bisType"
                                        [(ngModel)]="rruleModel.bisType"
                                        [value]="bisTypes.BIS_DATUM"/>
                                    <span>{{ t('PERSONAL.TERMIN.BIS_TYPE.BIS_DATUM') }}</span>
                                </label>
                                <dvlib-datepicker-textfield
                                    name="endDate"
                                    [(ngModel)]="rruleModel.endDateLocal"
                                    [minDate]="model.gueltigBis || model.gueltigAb"
                                    [required]="rruleModel.bisType === bisTypes.BIS_DATUM"
                                    [placeholder]="t('PERSONAL.TERMIN.BIS_TYPE.BIS_DATUM')">
                                </dvlib-datepicker-textfield>
                            </div>
                            <div class="radio radio-row">
                                <label>
                                    <input
                                        type="radio"
                                        name="bisType"
                                        [(ngModel)]="rruleModel.bisType"
                                        [value]="bisTypes.ANZAHL"/>
                                    <span>{{ t('PERSONAL.TERMIN.BIS_TYPE.ANZAHL') }}</span>
                                </label>
                                <input
                                    class="form-control input-inline"
                                    type="number"
                                    name="repetitions"
                                    [(ngModel)]="rruleModel.repetitions"
                                    [tooltip]="t('PERSONAL.TERMIN.WIEDERHOLUNGEN')"
                                    [required]="rruleModel.bisType === bisTypes.ANZAHL"
                                    min="1"
                                    [max]="MAX_REPETITIONS"
                                    step="1"/>
                                <span>{{ t('PERSONAL.TERMIN.WIEDERHOLUNGEN') }}</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="normal-abstand-bottom">
                    <p>{{ t('PERSONAL.TERMIN.EXCLUSIONS') }}</p>
                    @for (excluded of excludedDatesModel; track excluded; let i = $index) {
                        <div
                            class="row selected-entity">
                            <div class="col-xs-8">
                                <dvlib-backend-limited-with-optional-time [backendLimited]="excluded"/>
                            </div>
                            <div class="col-xs-4 edit">
                                <button type="button" class="btn btn-link" (click)="removeExcludedDate(i)">
                                    {{ t('COMMON.LOESCHEN') }}
                                </button>
                            </div>
                        </div>
                    }
                    <div class="row small-abstand-top">
                        <div class="col-sm-4">
                            <dvlib-datepicker-textfield
                                name="exclusionAb"
                                [placeholder]="t('COMMON.GUELTIG_AB')"
                                [(ngModel)]="exclusionPickerModel.gueltigAb">
                            </dvlib-datepicker-textfield>
                        </div>
                        <div class="col-sm-4">
                            <dvlib-datepicker-textfield
                                name="exclusionBis"
                                [placeholder]="t('COMMON.GUELTIG_BIS')"
                                [(ngModel)]="exclusionPickerModel.gueltigBis">
                            </dvlib-datepicker-textfield>
                        </div>
                        <div class="col-sm-4 text-right">
                            <button
                                type="button"
                                class="btn btn-link form-linker"
                                (click)="addExcludedDate()">
                                {{ t('PERSONAL.TERMIN.ADD_EXCLUSION') }}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        }

        <dv-submit-cancel-buttons [isLoading]="loadingState.isLoading()" on-cancel="cancel.emit()"/>

    </form>
</ng-container>
