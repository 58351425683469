<div class="dvb-firma-erstellen">
    <div class="row dvb-form">
        <div class="col-md-6 col-md-offset-3">

            <h1 data-translate="COMMON.PLATZART.ERSTELLEN"></h1>

            <form name="firmaErstellenForm"
                  ng-submit="!firmaErstellenForm.$pending && vm.validateAndCreateFirma(firmaErstellenForm.$valid)"
                  novalidate>

                <div class="form-section-minus-normal-abstand">
                    <div class="row">
                        <div class="col-md-10">
                            <input type="text"
                                   name="firmaName"
                                   class="form-control"
                                   ng-attr-placeholder="{{'COMMON.NAME' | translate}}"
                                   uib-tooltip="{{'COMMON.NAME' | translate}}"
                                   tooltip-placement="top-left"
                                   maxlength="255"
                                   ng-model="vm.firma.name"
                                   required>
                        </div>

                        <div class="col-md-2">
                            <input type="text"
                                   name="symbol"
                                   class="form-control"
                                   ng-attr-placeholder="{{'COMMON.PLATZART.SYMBOL_DEFINIEREN' | translate}}"
                                   uib-tooltip="{{'COMMON.PLATZART.SYMBOL_DEFINIEREN' | translate}}"
                                   tooltip-placement="top-left"
                                   min="1"
                                   maxlength="1"
                                   ng-model="vm.firma.symbol"
                                   required>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-md-6">
                            <div class="checkbox">
                                <label>
                                    <input type="checkbox"
                                           ng-model="vm.isFirmenplatz"
                                           ng-change="vm.setFirmenPlatz(vm.isFirmenplatz)">
                                    <span data-translate="COMMON.FIRMENPLATZ.SINGULAR"></span>
                                </label>
                            </div>
                        </div>

                        <div class="col-md-6">
                            <div class="checkbox">
                                <label>
                                    <input type="checkbox" ng-model="vm.isFirmenkontingentDefinieren">
                                    <span data-translate="COMMON.PLATZART.KONTINGENT_DEFINIEREN"></span>
                                </label>
                            </div>
                        </div>

                        <div class="col-md-6" dvb-auth require-permission="module:bsv">
                            <div class="checkbox">
                                <label>
                                    <input type="checkbox"
                                           ng-model="vm.firma.bsvRelevant"
                                           ng-change="vm.saveFirmenPlatz()">
                                    <span data-translate="COMMON.FIRMENPLATZ.BSV_RELEVANT"></span>
                                </label>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="form-section" ng-if="vm.isFirmenkontingentDefinieren">
                    <h2 data-translate="COMMON.FIRMENKONTINGENT.SINGULAR"></h2>

                    <div class="row">
                        <div class="col-md-12">
                            <input type="text"
                                   name="kontingentName"
                                   class="form-control"
                                   maxlength="255"
                                   ng-attr-placeholder="{{'FIRMA.BEZEICHNUNG_KONTINGENT' | translate}}"
                                   uib-tooltip="{{'FIRMA.BEZEICHNUNG_KONTINGENT' | translate}}"
                                   tooltip-placement="top-left"
                                   ng-model="vm.firmenkontingent.name"
                                   required>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-md-6">
                            <dvb-datepicker-text-field ng-model="vm.firmenKontingentValue.gueltigAb"
                                                       required
                                                       ng-attr-placeholder="{{'COMMON.GUELTIG_AB' | translate}}">
                            </dvb-datepicker-text-field>
                        </div>
                        <div class="col-md-6">
                            <input type="number"
                                   name="anzahlPlaetze"
                                   class="form-control"
                                   ng-attr-placeholder="{{'COMMON.ANZAHL_PLAETZE' | translate}}"
                                   uib-tooltip="{{'COMMON.ANZAHL_PLAETZE' | translate}}"
                                   tooltip-placement="top-left"
                                   ng-model="vm.firmenKontingentValue.plaetze"
                                   required
                                   ng-pattern="/^\d+(\.\d[05]?)?$/"
                                   min="0">
                        </div>
                    </div>
                    <div class="form-row" ng-show="vm.isKitaSet()">
                        <div class="row selected-entity"
                             ng-repeat="kita in vm.firmenKontingentValue.kitas"
                             ng-init="searchResultEntry = kita.toSearchResultEntry()">
                            <div class="col-xs-8 entity">
                                <dv-search-result-icon [entry]="searchResultEntry"></dv-search-result-icon>
                                <span ng-bind="kita.getDisplayName()"></span>
                            </div>
                            <div class="col-xs-4 edit">
                                <a href=""
                                   ng-click="vm.firmenKontingentValue.removeKita(kita)"
                                   data-translate="COMMON.LOESCHEN">
                                </a>
                            </div>
                        </div>
                    </div>
                    <dvb-search-entity class="form-row"
                                       entity-to-search="KINDERORT"
                                       on-select="vm.addKita(item)"
                                       on-select-clear="true"
                                       placeholder-key="COMMON.KINDERORT.AUSWAEHLEN"
                                       disabled-entries="vm.firmenKontingentValue.kitas"
                                       ng-class="{ 'ng-invalid' : !vm.isKitaSet() }">
                    </dvb-search-entity>

                    <dvb-search-get-single-entity entity-to-search="TARIF"
                                                  display-label="FIRMA.TITLE_TARIF"
                                                  ng-model="vm.tarifSearchResultEntry"
                                                  ng-change="vm.setTarif(vm.firmenKontingentValue)"
                                                  placeholder-key="COMMON.TARIF.AUSWAEHLEN">
                    </dvb-search-get-single-entity>

                </div>


                <div class="form-section-minus-normal-abstand" ng-if="vm.isFirmenplatz">
                    <h2 data-translate="COMMON.KONTAKT"></h2>

                    <div class="row">
                        <div class="col-md-6">
                            <input type="text"
                                   class="form-control"
                                   name="kontaktFamilienName"
                                   maxlength="255"
                                   ng-model="vm.firmenKontakt.familienName"
                                   ng-attr-placeholder="{{'COMMON.NAME' | translate}}"
                                   uib-tooltip="{{'COMMON.NAME' | translate}}"
                                   tooltip-placement="top-left">
                        </div>
                        <div class="col-md-6">
                            <input type="text"
                                   class="form-control"
                                   name="kontaktVorName"
                                   maxlength="255"
                                   ng-model="vm.firmenKontakt.vorName"
                                   ng-attr-placeholder="{{'COMMON.VORNAME' | translate}}"
                                   uib-tooltip="{{'COMMON.VORNAME' | translate}}"
                                   tooltip-placement="top-left">
                        </div>
                    </div>

                    <dvb-adresse-form ng-model="vm.firmenKontakt.adresse"></dvb-adresse-form>

                    <div class="row">
                        <div class="col-md-6">
                            <input type="text"
                                   class="form-control"
                                   name="kontaktTelefon"
                                   maxlength="100"
                                   ng-model="vm.firmenKontakt.telefon"
                                   ng-attr-placeholder="{{'COMMON.TELEFON' | translate}}"
                                   uib-tooltip="{{'COMMON.TELEFON' | translate}}"
                                   tooltip-placement="top-left"
                                   required
                                   autocomplete="tel">
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-6">
                            <input type="email"
                                   class="form-control"
                                   name="kontaktEmail"
                                   maxlength="255"
                                   ng-model="vm.firmenKontakt.email"
                                   ng-attr-placeholder="{{'COMMON.E-MAIL' | translate}}"
                                   uib-tooltip="{{'COMMON.E-MAIL' | translate}}"
                                   tooltip-placement="top-left"
                                   autocomplete="email">
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-6">
                            <input type="text"
                                   class="form-control"
                                   name="kontaktWebsite"
                                   maxlength="255"
                                   ng-model="vm.firmenKontakt.website"
                                   dvb-protocol-prepender
                                   ng-attr-placeholder="{{'COMMON.WEBSITE' | translate}}"
                                   uib-tooltip="{{'COMMON.WEBSITE' | translate}}"
                                   tooltip-placement="top-left"
                                   autocomplete="url">
                        </div>
                    </div>
                </div>


                <div class="form-section-minus-normal-abstand" ng-if="!vm.isFirmenplatz">
                    <h2 data-translate="KIBON_ABZUG.TITLE"></h2>

                    <p class="nl2br" data-translate="KIBON_ABZUG.INFO_PLATZ_ART"></p>

                    <button class="btn btn-link form-linker small-abstand-bottom"
                            ng-if="!vm.isAddAlternativeKibonAbzugEnabled"
                            type="button"
                            ng-click="vm.isAddAlternativeKibonAbzugEnabled = !vm.isAddAlternativeKibonAbzugEnabled">
                        + <span data-translate="COMMON.PLATZART.ALTERNATIVE_KIBON_ABZUG_DEFINIEREN"></span>
                    </button>
                    <div class="row" ng-if="vm.isAddAlternativeKibonAbzugEnabled">
                        <div class="col-xs-10">
                            <input type="number"
                                   name="kibonAbzugProTag"
                                   class="form-control"
                                   min="0"
                                   step=".01"
                                   max="20.00"
                                   ng-attr-placeholder="{{'COMMON.PLATZART.ALTERNATIVE_KIBON_ABZUG' | translate}}"
                                   uib-tooltip="{{'COMMON.PLATZART.ALTERNATIVE_KIBON_ABZUG' | translate}}"
                                   tooltip-placement="top-left"
                                   ng-model="vm.firma.alternativeKibonAbzug"
                                   ng-pattern="/^\d*(\.\d{0,2})?$/"
                                   required>
                        </div>
                        <div class="col-xs-2">
                            <p class="form-linker">
                                <a href=""
                                   ng-click="vm.removeKibonAbzugProTag()"
                                   data-translate="COMMON.LOESCHEN">
                                </a>
                            </p>
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-md-12">
                        <ul class="list-inline">
                            <li>
                                <dvb-loading-button type="submit"
                                                    is-loading="firmaErstellenForm.$pending || vm.isLoading">
                                    <span data-translate="COMMON.PLATZART.ERSTELLEN"></span>
                                </dvb-loading-button>
                            </li>
                            <li><a href="" data-translate="COMMON.ABBRECHEN" ng-click="vm.onCancel()"></a></li>
                        </ul>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>
