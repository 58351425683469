<div class="dvb-standard-recht" dvb-accordions>
    <h2 data-translate="BENUTZER.RECHTE_ALLE_KINDERORTE"></h2>

    <dvb-accordion on-submit="vm.save(context)"
                   on-cancel="vm.cancel()"
                   enable-edit="true"
                   is-first="true"
                   ng-disabled="vm.readOnly">
        <panel-header class="full-width-block">
            <div class="row layout-row items-center">
                <div class="col-xs-6">
                    <p data-translate="BENUTZER.STANDARD"></p>
                </div>
                <div class="col-xs-6 text-right">
                    <p>
                        <span ng-if="vm.benutzer.role.userRole"
                              class="badge"
                              data-translate="BENUTZER.{{vm.benutzer.role.userRole}}">
                        </span>
                        <span ng-if="vm.benutzer.role.kitaPermissionSet"
                              class="badge"
                              data-translate="BENUTZER.KITA_PERMISSION.{{vm.benutzer.role.kitaPermissionSet}}">
                        </span>
                        <span ng-if="vm.benutzer.role.personalPermissionSet"
                              class="badge"
                              data-translate="BENUTZER.PERSONAL_PERMISSION.{{vm.benutzer.role.personalPermissionSet}}">
                        </span>
                        <span ng-if="vm.benutzer.role.workTimeControllingPermissionSet"
                              class="badge"
                              data-translate="BENUTZER.WORK_TIME_CONTROLLING_ROLE.{{vm.benutzer.role.workTimeControllingPermissionSet}}">
                        </span>
                    </p>
                </div>
            </div>
        </panel-header>
        <edit-content>
            <div class="row form-row">
                <div class="col-md-6">
                    <label data-translate="BENUTZER.ROLE"></label>
                    <select class="form-control"
                            uib-tooltip="{{'BENUTZER.ROLLE_AUSWAEHLEN' | translate}}"
                            tooltip-placement="top-left"
                            ng-model="vm.workingCopyBenutzer.role.userRole"
                            ng-disabled="vm.workingCopyBenutzer.role.kitaPermissionSet || vm.workingCopyBenutzer.role.personalPermissionSet || vm.workingCopyBenutzer.role.workTimeControllingPermissionSet"
                            ng-options="role as ('BENUTZER.' + role | translate) for role in vm.userRoles">
                        <option ng-value="null" data-translate="BENUTZER.KITA_OR_PERSONAL_PERMISSION"></option>
                    </select>
                </div>
            </div>

            <div class="row form-row" ng-if="!vm.workingCopyBenutzer.role.userRole">
                <div class="col-md-6">
                    <label data-translate="BENUTZER.KITA_PERMISSION.TITLE"></label>
                    <select class="form-control"
                            uib-tooltip="{{'BENUTZER.KITA_PERMISSION_AUSWAEHLEN' | translate}}"
                            tooltip-placement="top-left"
                            ng-model="vm.workingCopyBenutzer.role.kitaPermissionSet"
                            ng-disabled="vm.workingCopyBenutzer.role.userRole"
                            ng-options="permission as ('BENUTZER.KITA_PERMISSION.' + permission | translate) for permission in vm.kitaPermissionSets">
                        <option ng-value="null" data-translate="BENUTZER.KITA_PERMISSION.KEINE"></option>
                    </select>
                </div>
                <div class="col-md-6">
                    <label data-translate="BENUTZER.PERSONAL_PERMISSION.TITLE"></label>
                    <select class="form-control"
                            uib-tooltip="{{'BENUTZER.PERSONAL_PERMISSION_AUSWAEHLEN' | translate}}"
                            tooltip-placement="top-left"
                            ng-model="vm.workingCopyBenutzer.role.personalPermissionSet"
                            ng-disabled="vm.workingCopyBenutzer.role.userRole"
                            ng-options="permission as ('BENUTZER.PERSONAL_PERMISSION.' + permission | translate) for permission in vm.personalPermissionSets">
                        <option ng-value="null" data-translate="BENUTZER.PERSONAL_PERMISSION.KEINE"></option>
                    </select>
                </div>
                <div class="col-md-6">
                    <label data-translate="BENUTZER.WORK_TIME_CONTROLLING_ROLE.TITLE"></label>
                    <select class="form-control"
                            uib-tooltip="{{'BENUTZER.WORK_TIME_CONTROLLING_ROLE.CHOOSE' | translate}}"
                            tooltip-placement="top-left"
                            ng-model="vm.workingCopyBenutzer.role.workTimeControllingPermissionSet"
                            ng-disabled="vm.workingCopyBenutzer.role.userRole"
                            ng-options="role as ('BENUTZER.WORK_TIME_CONTROLLING_ROLE.' + role | translate) for role in vm.workTimeControllingPermissionSets">
                        <option ng-value="null" data-translate="BENUTZER.WORK_TIME_CONTROLLING_ROLE.NONE"></option>
                    </select>
                </div>
            </div>
        </edit-content>
    </dvb-accordion>
</div>
