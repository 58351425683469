/*
 * Copyright © 2024 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import {inject, Injectable} from '@angular/core';
import type {AccordionState} from '@dv/kitadmin/ui';
import {createAccordionAdapter, createAccordionState} from '@dv/kitadmin/ui';
import {idFromLocation, splitIdRequestSources, toIdRequestSource} from '@dv/shared/angular';
import {DienstService} from '@dv/shared/backend/api/dienst.service';
import type {EntityId} from '@dv/shared/backend/model/entity-id';
import type {Persisted} from '@dv/shared/code';
import {checkPersisted} from '@dv/shared/code';
import {adapt} from '@state-adapt/angular';
import {createAdapter, joinAdapters} from '@state-adapt/core';
import {Source, splitRequestSources, toRequestSource, toSource} from '@state-adapt/rxjs';
import {concatMap, map, merge, Subject, switchMap, withLatestFrom} from 'rxjs';
import {Dienst} from '../../../../personal/konfiguration/Dienst';

type DienstAdministrationState = {
    kinderOrtId?: EntityId;
    items: AccordionState<Persisted<Dienst>>;
};

const initialState = (): DienstAdministrationState => ({
    items: createAccordionState(),
});

const adapter = joinAdapters<DienstAdministrationState>()({
    kinderOrtId: createAdapter<EntityId | undefined>()({}),
    items: createAccordionAdapter<Persisted<Dienst>>(),
})();

@Injectable({
    providedIn: 'root',
})
export class DienstAdministrationService {

    public readonly createDienst$ = new Source<Dienst>(
        '[DienstAdministration] create dienst');
    public readonly deleteDienst$ = new Source<EntityId>(
        '[DienstAdministration] delete dienst');
    public readonly confirmDeleteDienst$ = new Subject<void>();
    public readonly updateDienst$ = new Source<Persisted<Dienst>>('[DienstAdministration] update dienst');

    public store = adapt(initialState(), {
        path: '[DienstAdministration]',
        adapter,
        sources: store => {
            const dienstService = inject(DienstService);

            const updateSource$ = this.updateDienst$.pipe(
                map(({payload}) => payload.id),
                toSource('[DienstAdministration] update'),
            );

            const deleteSource$ = this.confirmDeleteDienst$.pipe(
                withLatestFrom(this.deleteDienst$),
                map(([, {payload}]) => payload),
                toSource('[DienstAdministration] confirm delete dienst'),
            );

            const deleteRequest = splitIdRequestSources('[DienstAdministration API] delete', deleteSource$.pipe(
                concatMap(({payload}) => dienstService._delete$({id: payload}).pipe(
                    toIdRequestSource('[DienstAdministration API] delete', payload),
                )),
            ));

            const createRequest = splitRequestSources('[DienstAdministration API] create', this.createDienst$.pipe(
                withLatestFrom(store.kinderOrtId$),
                concatMap(([{payload}, kinderOrtId]) => dienstService.create$({
                    id: kinderOrtId,
                    jaxDienst: payload.toRestObject(),
                }, 'response').pipe(
                    map((response): Persisted<Dienst> => {
                        payload.id = idFromLocation(response);

                        return checkPersisted(payload);
                    }),
                    toRequestSource('[DienstAdministration API] create'),
                )),
            ));

            const updateRequest = splitIdRequestSources('[DienstAdministration API] update', this.updateDienst$.pipe(
                map(({payload}) => payload),
                concatMap(dienst => dienstService.update$({id: dienst.id, jaxDienst: dienst.toRestObject()}).pipe(
                    map(() => dienst),
                    toIdRequestSource('[DienstAdministration API] update', dienst.id),
                )),
            ));

            const reload$ = merge(
                store.kinderOrtId$,
                createRequest.success$,
            );

            const getAllRequest = splitRequestSources('[DienstAdministration API] getAll', reload$.pipe(
                withLatestFrom(store.kinderOrtId$),
                switchMap(([, id]) => dienstService.getForKinderOrt$({id}).pipe(
                    map(response => response.items
                        .map(Dienst.apiResponseTransformer)
                        .map(checkPersisted),
                    ),
                    toRequestSource('[DienstAdministration API] getAll'),
                )),
            ));

            return {
                setItemsIsLoadingTrue: [
                    this.createDienst$,
                    deleteSource$,
                ],
                setItemsIsLoadingFalse: [
                    createRequest.error$,
                    getAllRequest.success$,
                    getAllRequest.error$,
                    deleteRequest.success$,
                    deleteRequest.error$,
                ],
                addItems: createRequest.success$,
                setItemsItemIsLoadingTrue: updateSource$,
                updateItems: updateRequest.success$,
                deleteItems: deleteRequest.success$,
                errorItems: [
                    updateRequest.error$,
                    deleteRequest.error$,
                ],
                setItemsShowCreateModeFalse: createRequest.success$,
                setItemsShowDeleteDialogTrue: this.deleteDienst$,
                setItemsShowDeleteDialogFalse: deleteRequest.success$,
                initItemsWithEntities: getAllRequest.success$,
            };
        },
    });

    // the following methods are only here because typescript inference in angular templates doesn't work well in
    // intellij. It looks like the methods don't exist, even though they do.
    public enableCreateMode(): void {
        this.store.setItemsShowCreateModeTrue();
    }

    public disableCreateMode(): void {
        this.store.setItemsShowCreateModeFalse();
    }

    public hideDeleteDialog(): void {
        this.store.setItemsShowDeleteDialogFalse();
    }

    public setReadonlyMode(entity: Persisted<Dienst>): void {
        this.store.setItemsReadonlyMode(entity.id);
    }

    public setEditMode(entity: Persisted<Dienst>): void {
        this.store.setItemsEditMode(entity.id);
    }

    public toggleExpanded(entity: Persisted<Dienst>): void {
        this.store.toggleItemsExpanded(entity.id);
    }
}
