/*
 * Copyright © 2018 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {ErrorService} from '@dv/kitadmin/core/errors';
import type {Password} from '@dv/kitadmin/models';
import {BenutzerType} from '@dv/kitadmin/models';
import type {ConfirmDialogModel, DialogService} from '@dv/kitadmin/ui';
import type {AuthStore} from '@dv/shared/angular';
import {WILDCARD_TOKEN} from '@dv/shared/angular';
import {PERMISSION} from '@dv/shared/authentication/model';
import type {FormContext, Persisted} from '@dv/shared/code';
import {checkPresent, DvbUtil} from '@dv/shared/code';
import type {StateService} from '@uirouter/core';
import angular from 'angular';
import type {Observable} from 'rxjs';
import {from, take, tap} from 'rxjs';
import type {AuthService} from '../../../authentication/service/authService';
import type {AuthorisationService} from '../../../authorisation/service/authorisation.service';
import type {BenutzerService} from '../../../common/service/rest/benutzer/benutzerService';
import {DASHBOARD_STATE} from '../../../dashboard/dashboard-state';
import {ANSTELLUNG_STATES} from '../../../personal/anstellung/anstellung-states';
import type {AngestellteService} from '../../../personal/anstellung/service/angestellteService';
import type {Benutzer} from '../../model/Benutzer';

const componentConfig: angular.IComponentOptions = {
    transclude: false,
    bindings: {
        benutzer: '<',
    },
    template: require('./dvb-benutzer-profil.html'),
    controllerAs: 'vm',
};

class DvbBenutzerProfil implements angular.IController {

    public static $inject: readonly string[] = [
        '$state',
        'authService',
        'authStore',
        'errorService',
        'benutzerService',
        'dialogService',
        'angestellteService',
        'authorisationService',
    ];

    public benutzer!: Persisted<Benutzer>;

    public workingCopyBenutzer!: Persisted<Benutzer>;
    public canChangeUsernameAndPassword: boolean = false;
    public anstellungStates: typeof ANSTELLUNG_STATES = ANSTELLUNG_STATES;

    public editAllowed: boolean = false;
    public deleteAllowed: boolean = false;

    public isPasskeyLoading: boolean = false;

    private relevantKinderOrte: string[] = [];

    public constructor(
        private $state: StateService,
        private authService: AuthService,
        private authStore: AuthStore,
        private errorService: ErrorService,
        private benutzerService: BenutzerService,
        private dialogService: DialogService,
        private angestellteService: AngestellteService,
        private authorisationService: AuthorisationService,
    ) {
    }

    public $onInit(): void {
        this.workingCopyBenutzer = angular.copy(this.benutzer);
        this.canChangeUsernameAndPassword = this.benutzer.dtype === BenutzerType.MANDANT_USER;

        this.benutzerService.getRelevantKinderOrte(this.benutzer.id).then(response => {
            this.relevantKinderOrte = response;
            this.initPrivileges();
        });
    }

    public initPrivileges(): void {
        const userHasLessPrivileges = !this.userIsMorePrivilegedThanCurrent() &&
            this.authStore.hasPermission(PERMISSION.SHARED.MANAGE_USERS_ANY);

        const userHasLessGlobalPrivilges = !this.benutzerHasGlobalPermissions() ||
            this.currentBenutzerHasGlobalManageUsersPermissions();

        const userHasCurrentKitaPermissions = this.relevantKinderOrte.length === 0 ||
            this.relevantKinderOrte.every(id => this.authStore.hasPermission(PERMISSION.SHARED.MANAGE_USERS + id));

        this.editAllowed = this.isBenutzerPrincipal() || (userHasLessPrivileges &&
            userHasCurrentKitaPermissions &&
            userHasLessGlobalPrivilges);
        this.deleteAllowed = this.editAllowed && !this.isBenutzerPrincipal();
    }

    public isRequired(param: string): boolean {
        const valid = DvbUtil.isNotEmptyString(param);
        this.errorService.handleValidationError(valid, 'ERRORS.VALUE_REQUIRED');

        return valid;
    }

    public saveBasisData(): void {
        if (!this.workingCopyBenutzer.isValid()) {
            // Dies sollte nie passieren...
            console.error('Ungültiger Benutzer respektive Benutzer nicht geändert');

            return;
        }

        this.benutzerService.update(this.workingCopyBenutzer).then(() => {
            this.benutzer = angular.copy(this.workingCopyBenutzer);
        });
    }

    public updateUsername(): void {
        if (this.benutzer.id !== this.authService.getPrincipal().userId) {
            this.saveBasisData();

            return;
        }

        const confirm = (): Observable<unknown> => from(this.benutzerService.update(this.workingCopyBenutzer)
            .catch(() => {
                // Should the update request fail we reset the data
                this.workingCopyBenutzer = angular.copy(this.benutzer);
            })
            .then(() => this.authService.logoutAndGoToLoginPage()));

        const options: ConfirmDialogModel = {
            title: 'BENUTZER.USERNAME_AENDERN_CONFIRM',
            confirmActionText: 'BENUTZER.USERNAME_AENDERN',
            confirm,
            cancel: () => {
                this.workingCopyBenutzer = angular.copy(this.benutzer);
            },
        };

        this.dialogService.openConfirmDialog(options);
    }

    public updatePassword(password: Password, formContext: FormContext): angular.IPromise<void> {
        formContext.startLoading();

        return this.benutzerService.updatePassword(this.workingCopyBenutzer, password)
            .then(() => formContext.close())
            .finally(() => formContext.finishLoading());
    }

    public showConfirmDeleteBenutzerModal(): void {
        this.dialogService.openDeleteDialog({
            entityText: this.benutzer.getDisplayName(),
            confirm: () => from(this.benutzerService.benutzerLoeschen(checkPresent(this.benutzer.id)))
                .pipe(take(1), tap(() => this.$state.go(DASHBOARD_STATE.name))),
        });
    }

    public removeAngestellte(): void {
        if (!this.benutzer.angestellte) {
            return;
        }

        this.angestellteService.removeBenutzer(this.benutzer.angestellte.id)
            .then(() => this.$state.reload());
    }

    public createPasskey(): void {
        this.errorService.clearAll();
        this.isPasskeyLoading = true;
        this.benutzerService.createPasskey(this.benutzer.id)
            .then(() => {
                this.errorService.handleSuccess('COMMON.BENUTZER.PASSKEY_SUCCESS');
            })
            .finally(() => {
                this.isPasskeyLoading = false;
            });
    }

    private userIsMorePrivilegedThanCurrent(): boolean {
        return this.authorisationService.isRoleGreaterThanPrincipals(this.benutzer.role.userRole);
    }

    private isBenutzerPrincipal(): boolean {
        return this.authService.isBenutzerPrincipal(this.benutzer);
    }

    private benutzerHasGlobalPermissions(): boolean {
        return this.benutzer.role.kitaPermissionSet !== null ||
            this.benutzer.role.personalPermissionSet !== null;
    }

    private currentBenutzerHasGlobalManageUsersPermissions(): boolean {
        return this.authStore.hasPermission(PERMISSION.SHARED.MANAGE_USERS + WILDCARD_TOKEN);
    }
}

componentConfig.controller = DvbBenutzerProfil;
angular.module('kitAdmin').component('dvbBenutzerProfil', componentConfig);
