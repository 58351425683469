/*
 * Copyright © 2024 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import {EnvironmentProviders, inject, Injectable, makeEnvironmentProviders} from '@angular/core';
import {ANY_MANDANT, ArchiveSearchMode, MandantSearchFilter, SearchService} from '@dv/kitadmin/search';
import {SearchService as SearchApi} from '@dv/shared/backend/api/search.service';
import type {JaxSearchResult} from '@dv/shared/backend/model/jax-search-result';
import {RestIncludes} from '@dv/shared/backend/model/rest-includes';
import {DvbUtil, ENTITY_SEARCH_TYPE, isNullish, SearchResultEntry, SearchResultType, TypeUtil} from '@dv/shared/code';
import {StateService} from '@uirouter/core';
import type {Observable} from 'rxjs';
import {map} from 'rxjs';
import {ENTITY_TRANSFORMER} from './model/EntitySearchType';
import {SEARCH_RESULT_ROUTES} from './model/SearchResultRoutes';

export const SEARCH_IN_ALL_MANDANTEN = 'searchInAllMandanten';
export const SEARCH_ENTITIES = 'searchEntities';

@Injectable({
    providedIn: 'root',
})
export class SearchServiceProvider extends SearchService {
    private searchApi = inject(SearchApi);

    public constructor(
        private readonly $state: StateService,
    ) {
        super();
    }

    public override getEntityHrefArgs(
        entityName: SearchResultType,
        id: string,
        subRoute?: string,
    ): { to: string; params: { [index: string]: string } } {
        let to = SEARCH_RESULT_ROUTES[entityName].to;

        if (TypeUtil.isFunction(to)) {
            to = to(subRoute);
        }

        const idParam = SEARCH_RESULT_ROUTES[entityName].idParam;
        const params: { [index: string]: string } = {};
        params[idParam] = id;

        return {to, params};
    }

    public override transformSource(entry: SearchResultEntry, source: unknown): unknown {
        if (!source) {
            return;
        }

        if (ENTITY_SEARCH_TYPE.guard(entry.entity)) {
            return ENTITY_TRANSFORMER[entry.entity]?.apiResponseTransformer(source);
        }

        throw new Error(`No source transformer implementation for ${JSON.stringify(entry.entity)}`);
    }

    public override setSearchEntities(entities: string): void {
        localStorage.setItem(SEARCH_ENTITIES, entities);
    }

    public override getSearchEntities(): string | null {
        return localStorage.getItem(SEARCH_ENTITIES);
    }

    public override setSearchInAllMandanten(value: boolean): void {
        localStorage.setItem(SEARCH_IN_ALL_MANDANTEN, String(value));
    }

    public override getSearchInAllMandanten(): boolean {
        return localStorage.getItem(SEARCH_IN_ALL_MANDANTEN) === 'true';
    }

    public override searchGlobal$(
        searchText: string,
        findAll?: boolean,
        archiveMode?: ArchiveSearchMode,
    ): Observable<SearchResultEntry[]> {
        const entities = this.getSearchEntities();
        const anyMandant = this.getSearchInAllMandanten();

        return this.searchApi.global$({
            searchString: searchText,
            searchStringMatrix: {
                ...findAll && {findAll: true},
                ...DvbUtil.isNotEmptyString(entities) && {entities: new RestIncludes(`(${entities})`)},
                ...anyMandant && {anyMandant: true},
                ...archiveMode && {archiveMode},
            },
        }).pipe(
            map((result: any) => result.entries.map(SearchResultEntry.apiResponseTransformer)),
        );
    }

    public override redirectToEntity(
        entityName: SearchResultType,
        id: string,
        openInNewWindow: boolean = false,
        subRoute?: string,
    ): void {

        if (openInNewWindow) {
            window.open(this.getEntityHref(entityName, id, subRoute));

            return;
        }

        const {to, params} = this.getEntityHrefArgs(entityName, id, subRoute);
        this.$state.go(to, params);
    }

    public override getEntityHref(
        entityName: SearchResultType,
        id: string,
        subRoute?: string,
    ): string {

        const {to, params} = this.getEntityHrefArgs(entityName, id, subRoute);

        return this.$state.href(to, params, {absolute: true});
    }

    /**
     * Execute search in the given entity.
     */
    public override searchEntity$(
        searchText: string,
        params: {
            entities: string;
            expandEntity: boolean;
        },
        mandantFilter?: MandantSearchFilter,
    ): Observable<SearchResultEntry[]> {
        return this.searchEntity(searchText, params, mandantFilter).pipe(map((result: any) => {
            const entries = result.entries;
            if (!params.expandEntity) {
                return result.entries;
            }

            // Hydrate `entry.source`
            entries.forEach((entry: any) => {
                entry.source = this.transformSource(entry, entry.source);
            });

            return entries;
        }));
    }

    private searchEntity(
        searchText: string,
        params: {
            entities: string;
            expandEntity: boolean;
        },
        mandantFilter?: MandantSearchFilter,
    ): Observable<JaxSearchResult> {
        const searchParams = {
            searchString: searchText,
            searchStringMatrix: {
                ...params.entities && {entities: new RestIncludes(params.entities)},
                ...params.expandEntity && {expandEntity: true},
            },
        };

        if (isNullish(mandantFilter)) {
            return this.searchApi.entity$(searchParams);
        }

        if (mandantFilter === ANY_MANDANT) {
            return this.searchApi.searchAllMandanten$(searchParams);
        }

        return this.searchApi.searchAMandantenEntity$({...searchParams, mandantId: mandantFilter.id});
    }
}

export function provideSearchService(): EnvironmentProviders {

    return makeEnvironmentProviders([
        {provide: SearchService, useClass: SearchServiceProvider},
    ]);
}
