/*
 * Copyright © 2023 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {IDisplayable, ILimited, ITimeRange} from '@dv/shared/code';
import type moment from 'moment';

export type CalendarEventId = number;

/**
 * An event, defined by a display name, a date and a time.
 */
export interface CalendarEvent extends IDisplayable, ITimeRange, ILimited {
    id: CalendarEventId;
    data?: Record<string, unknown>;
    gueltigAb: moment.Moment;
    gueltigBis: moment.Moment;
    tooltip?: string;
    /**
     * The duration of the event in minutes
     * and defines the width of the event element
     */
    completeDuration: number;
    /**
     * defines the width of a spacer element before the event
     */
    spaceBefore: number;
    /**
     * defines the width of a spacer element after the event
     */
    spaceAfter: number;
    backgroundColor: string;
    textColor: string;
    hasHighLuminance: boolean;
    outline?: string;
    subEvents: CalendarSubEvent[];
    layer: number;
    linkedEvent?: CalendarEventId;
}

export interface CalendarSubEvent extends CalendarEvent {
    parent: CalendarEventId;
}

let id = 0;

export function calendarEventIdGenerator(): CalendarEventId {
    return id++;
}
