import {
    BaseRechnungsPosition,
    MandantCustomPosition,
    MandantCustomPositionType,
    MandantRechnungConfigPosition,
} from '@dv/kitadmin/models';
import type {JaxMandantCustomPosition} from '@dv/shared/backend/model/jax-mandant-custom-position';
import type {JaxMandantRechnungConfigPosition} from '@dv/shared/backend/model/jax-mandant-rechnung-config-position';
import {checkPresent} from '@dv/shared/code';

export class FormPosition extends BaseRechnungsPosition {
    public notManualMandantCustomPos: boolean = false;
    public position: BaseRechnungsPosition;

    public constructor(
        pos: BaseRechnungsPosition,
    ) {
        super(pos.text, pos.count, pos.cost);

        this.position = pos;

        if (pos instanceof MandantCustomPosition) {
            this.notManualMandantCustomPos = pos.type !== MandantCustomPositionType.MANUAL;
        }
    }

    public toRestObject(): JaxMandantCustomPosition | JaxMandantRechnungConfigPosition {

        if (this.position instanceof MandantCustomPosition || this.position instanceof MandantRechnungConfigPosition) {
            this.position.text = checkPresent(this.text);
            this.position.count = checkPresent(this.count);
            this.position.cost = checkPresent(this.cost);

            return this.position.toRestObject();
        }

        throw new Error('Not implemented');
    }
}
