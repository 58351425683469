/*
 * Copyright © 2018 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {ErrorService} from '@dv/kitadmin/core/errors';
import {ApplicationRole, Mandant} from '@dv/kitadmin/models';
import type {KitadminModul} from '@dv/shared/backend/model/kitadmin-modul';
import {SubscriptionModel} from '@dv/shared/code';
import {UserRole} from '@dv/shared/roles';
import type {StateService} from '@uirouter/core';
import angular from 'angular';
import {createBenutzerFormModel} from '../../../benutzer/component/dvb-benutzer-form/benutzer-form.util';
import type {DvbStateService} from '../../../common/service/dvbStateService';
import type {MandantService} from '../../../common/service/rest/mandantService';
import type {MandantConfigurationService} from '../../service/mandantConfigurationService';

const componentConfig: angular.IComponentOptions = {
    transclude: false,
    bindings: {
        availableModules: '<',
    },
    template: require('./dvb-mandant-erstellen.html'),
    controllerAs: 'vm',
};

const defaultWarningIntervalInDays = 125;

export class DvbMandantErstellen implements angular.IController {

    public static $inject: readonly string[] = [
        '$state',
        'mandantService',
        'mandantConfigurationService',
        'errorService',
        'dvbStateService',
    ];

    public availableModules: KitadminModul[] = [];
    public mandant: Mandant = new Mandant();
    public supportAdminBenutzer = createBenutzerFormModel(new ApplicationRole(UserRole.SUPPORT_ADMIN));
    public adminBenutzer = createBenutzerFormModel(new ApplicationRole(UserRole.ADMIN));
    public isDemoMandant: boolean = false;

    public isLoading: boolean = false;
    public defaultWarningIntervalInDays: number = defaultWarningIntervalInDays;
    public hasStandardWochenplan: boolean = true;

    public constructor(
        private readonly $state: StateService,
        private readonly mandantService: MandantService,
        private readonly mandantConfigurationService: MandantConfigurationService,
        private readonly errorService: ErrorService,
        private readonly dvbStateService: DvbStateService,
    ) {
    }

    public $onInit(): void {
        this.mandant.subscriptionModel = SubscriptionModel.PREMIUM;
        this.mandant.defaultLanguage = 'DE';

        this.mandantConfigurationService.createDefaultAdresse().then(adresse => {
            this.mandant.adresse = adresse;
        });
    }

    // noinspection FunctionWithMoreThanThreeNegationsJS
    public validateAndSaveMandant(form: angular.IFormController): void {
        this.errorService.clearAll();

        if (this.mandant.warningIntervalInDays === null) {
            this.mandant.warningIntervalInDays = this.defaultWarningIntervalInDays;
        }

        const isValidMandantAndForm = form.$valid && this.mandant.isValid();
        this.errorService.handleValidationError(isValidMandantAndForm, 'ERRORS.ERR_INCOMPLETE_FORM');

        const validAbrechnungVorrausZusatzMonate = !form.abrechnungVorausZusatzMonate.$error.integer;
        this.errorService.handleValidationError(validAbrechnungVorrausZusatzMonate,
            'ERR_ABRECHUNG_VORAUS_ZUSATZ_MONATE');

        const validSupportPasswordConfirmation = !form.supportAdminBenutzer.$error.passwordConfirmation;
        const validSupportPasswordStrength = !form.supportAdminBenutzer.$error.passwordStrength;
        const validAdminPasswordConfirmation = !form.adminBenutzer.$error.passwordConfirmation;
        const validAdminPasswordStrength = !form.adminBenutzer.$error.passwordStrength;
        // Momentan gibt es nur 1 Fehlermeldung, auch wenn bei beiden Passwort-Formularen etwas nicht stimmt
        const validPasswordConfirmation = validSupportPasswordConfirmation && validAdminPasswordConfirmation;
        const validPasswordStrength = validSupportPasswordStrength && validAdminPasswordStrength;
        this.errorService.handleValidationError(validPasswordConfirmation, 'ERRORS.ERR_DIFFERENT_PASSWORD');
        this.errorService.handleValidationError(validPasswordStrength, 'ERRORS.ERR_STRONG_PASSWORD');

        if (!isValidMandantAndForm || !validPasswordConfirmation || !validPasswordStrength) {
            return;
        }

        this.isLoading = true;
        const params = {
            mandant: this.mandant,
            supportAdminBenutzer: this.supportAdminBenutzer,
            adminBenutzer: this.adminBenutzer,
            hasStandardWochenplan: this.hasStandardWochenplan,
            demoMandant: this.isDemoMandant,
        };

        this.mandantService.createMandant(params)
            .then(() => this.$state.go('base.administration.mandanten'))
            .finally(() => {
                this.isLoading = false;
            });
    }

    public goBack(): void {
        this.dvbStateService.goToPreviousState();
    }
}

componentConfig.controller = DvbMandantErstellen;
angular.module('kitAdmin').component('dvbMandantErstellen', componentConfig);
