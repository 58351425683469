/*
 * Copyright © 2024 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import {AsyncPipe, DecimalPipe, Location} from '@angular/common';
import {ChangeDetectionStrategy, Component, computed, Input, signal, Signal, WritableSignal} from '@angular/core';
import {toSignal} from '@angular/core/rxjs-interop';
import {FormsModule, NgForm} from '@angular/forms';
import {ErrorService} from '@dv/kitadmin/core/errors';
import {WorkTimeModel} from '@dv/kitadmin/models';
import {SubmitCancelButtonsComponent} from '@dv/kitadmin/ui';
import {
    AuthStore,
    DatepickerTextfieldComponent,
    type FormGroupSpec,
    LoadingState,
    MaxDateDirective,
    ValidatorDirective,
} from '@dv/shared/angular';
import {PERMISSION} from '@dv/shared/authentication/model';
import {AngestellteService} from '@dv/shared/backend/api/angestellte.service';
import {WorkTimeModelService} from '@dv/shared/backend/api/work-time-model.service';
import {EntityId} from '@dv/shared/backend/model/entity-id';
import {checkPresent, DayOfWeek, DvbRestUtil, IPersistable, isNullish, Persisted} from '@dv/shared/code';
import {TranslocoModule} from '@jsverse/transloco';
import {StateService} from '@uirouter/core';

import {BsDropdownModule} from 'ngx-bootstrap/dropdown';
import {finalize, map, of, tap} from 'rxjs';
import {
    AusbildungNodeCheckboxComponent,
} from '../../../component/ausbildung-node-checkbox/ausbildung-node-checkbox.component';
import {
    AusbildungNodeCheckboxFormModel,
} from '../../../component/ausbildung-node-checkbox/ausbildung-node-checkbox.model';
import {
    ausbildungToAusbildungFormModel,
} from '../../../component/ausbildung-node-checkbox/ausbildung-node-checkbox.util';
import {ANSTELLUNGEN_VERLAUF_STATE} from '../../anstellung-states';
import {Anstellung} from '../../models/Anstellung';
import {AnstellungFormModel, AnstellungStandortFormModel} from '../../models/anstellung-form-model';
import type {Ausbildung} from '../../models/Ausbildung';
import {anstellungStandortToModel, formModelToAnstellung, toFormModel} from './angestellte-anstellung-form.util';
import {AnstellungStandortFormComponent} from './anstellung-standort-form/anstellung-standort-form.component';

const HUNDRED = 100;

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'dv-angestellte-anstellung-form',
    standalone: true,
    imports: [
        DatepickerTextfieldComponent,
        FormsModule,
        AusbildungNodeCheckboxComponent,
        SubmitCancelButtonsComponent,
        ValidatorDirective,
        TranslocoModule,
        MaxDateDirective,
        AnstellungStandortFormComponent,
        BsDropdownModule,
        DecimalPipe,
        AsyncPipe,
    ],
    providers: [DecimalPipe],
    templateUrl: './angestellte-anstellung-form.component.html',
})
export class AngestellteAnstellungFormComponent {

    @Input({required: true}) public title!: string;
    @Input({required: true}) public angestellteId!: EntityId;

    private _ausbildungen: WritableSignal<Persisted<Ausbildung>[]> = signal([]);
    @Input({required: true})
    public set ausbildungen(value: Persisted<Ausbildung>[]) {
        this._ausbildungen.set(value);
    }

    private _anstellung: WritableSignal<Anstellung | undefined> = signal(undefined);
    @Input()
    public set anstellung(anstellung: Anstellung) {
        this._anstellung.set(anstellung);
    }

    public ausbildungenModel: Signal<AusbildungNodeCheckboxFormModel[]> = computed(() => {
        const availableAusbildungen = this._ausbildungen();
        const selectedAusbildungen = (this._anstellung()?.ausbildungen ?? [])
            .map(ausbildung => checkPresent(ausbildung.id));

        return availableAusbildungen.map(ausbildung => {
            return ausbildungToAusbildungFormModel(ausbildung, selectedAusbildungen);
        });
    });

    public readonly hasWorkTimeControllingPermission =
        this.authStore.hasPermission(PERMISSION.MODULE.WORK_TIME_CONTROLLING);
    private workTimeModels$ = this.hasWorkTimeControllingPermission ?
        this.workTimeModelService.getAll$().pipe(map(data => DvbRestUtil.transformArray(data.models, WorkTimeModel))) :
        of([]);
    public workTimeModels: Signal<WorkTimeModel[]> = toSignal(this.workTimeModels$, {initialValue: []});

    public model: Signal<Partial<AnstellungFormModel & IPersistable>> = computed(() => {
        const anstellung = this._anstellung();
        const ausbildungen = this.ausbildungenModel();
        const workTimeModels = this.workTimeModels();

        return toFormModel(anstellung, ausbildungen, workTimeModels);
    });

    public readonly loadingState = new LoadingState();
    public anstellungStandorte: AnstellungStandortFormModel[] = [];

    public constructor(
        private readonly $state: StateService,
        private readonly errorService: ErrorService,
        private readonly angestellteService: AngestellteService,
        private readonly workTimeModelService: WorkTimeModelService,
        private readonly location: Location,
        private readonly authStore: AuthStore,
    ) {
    }

    public submit(form: NgForm): void {

        const formGroup = form.form as FormGroupSpec<AnstellungFormModel>;
        const formValid = form.valid;
        if (!formValid) {
            this.errorService.addValidationError('ERRORS.ERR_INCOMPLETE_FORM');
        }

        const {gueltigAb} = formGroup.controls;
        this.errorService.handleControlError(gueltigAb, 'ERRORS.ERR_INVALID_DATES');

        this.errorService.handleValidationError(isNullish(formGroup.errors?.selection),
            'ERRORS.ERR_INVALID_KINDERORT_ANGESTELLTE');

        const anstellung = formModelToAnstellung(this.model());

        const validateExpectedMinutes = this.validateExpectedMinutes(anstellung);
        const validAusbildungen = anstellung.ausbildungIds.length > 0;
        this.errorService.handleValidationError(validAusbildungen, 'PERSONAL.ERROR.AUSBILDUNG_REQUIRED');

        const validAnstellung = anstellung.isValid() || false;
        if (!validAnstellung) {
            this.errorService.addValidationError('ERRORS.ERR_INCOMPLETE_FORM');
        }

        if (!(formValid && validAusbildungen && validAnstellung && validateExpectedMinutes)) {
            return;
        }

        this.loadingState.startLoading();
        this.angestellteService.createAnstellung$({
            angestellteId: this.angestellteId,
            jaxAnstellung: anstellung.toRestObject(),
        }).pipe(
            tap(() => this.$state.go(ANSTELLUNGEN_VERLAUF_STATE.name, {}, {reload: true})),
            finalize(() => {
                this.loadingState.finishLoading();
            }),
        ).subscribe();
    }

    public removeAnstellungStandort(idx: number): void {
        this.model().anstellungStandorte?.update(standorte => {
            const standorteCopy = standorte.slice();
            standorteCopy.splice(idx, 1);

            return standorteCopy;
        });
    }

    public addAnstellungStandort(): void {
        this.model().anstellungStandorte?.update(standorte => {
            return [...standorte, anstellungStandortToModel(this.workTimeModels())];
        });
    }

    public handleCancel(): void {
        this.errorService.clearAll();
        this.location.back();
    }

    private validateExpectedMinutes(anstellung: Anstellung): boolean {
        if (!this.hasWorkTimeControllingPermission) {
            return true;
        }

        const validExpectedMinutes = anstellung.anstellungStandorte.every(anstellungStandort => {
            const workTimeModel = this.workTimeModels().find(model => model.id === anstellungStandort.workTimeModelId);
            if (isNullish(workTimeModel)) {
                return true;
            }

            if (isNullish(anstellungStandort.arbeitspensumProzent)) {
                return false;
            }

            const totalMinutesRequired =
                workTimeModel.weeklyMinutes * anstellungStandort.arbeitspensumProzent / HUNDRED;

            const totalMinutesDistributed = anstellungStandort.dailyAnstellungen
                .map(s => s.expectedMinutes ?? 0)
                .reduce((a, b) => a + b, 0);

            return totalMinutesRequired === totalMinutesDistributed;
        });

        this.errorService.handleValidationError(validExpectedMinutes, 'ERRORS.ERR_INVALID_HOURS');

        return validExpectedMinutes;
    }

    public autofillExpectedTimes(weeklyMinutes: number | null, idx: number): void {
        if (isNullish(weeklyMinutes)) {
            return;
        }

        this.model().anstellungStandorte?.update(standorte => {
            const standortCopy = Object.assign({}, checkPresent(standorte[idx]));

            // reset everything
            Object.values(standortCopy.dailyAnstellungen).forEach(day => {
                day.expectedMinutes.set(undefined);
            });

            // find days with availability
            const availableDays = Object.values(DayOfWeek)
                .filter(dayOfWeek => dayOfWeek !== DayOfWeek.SA && dayOfWeek !== DayOfWeek.SU)
                .map(dayOfWeek => standortCopy.dailyAnstellungen[dayOfWeek])
                .filter(day => !day.nichtVerfuegbar());

            const dailyMinutes = weeklyMinutes / availableDays.length;

            // set average daily hours on all days with availability
            availableDays.forEach(day => {
                day.expectedMinutes.set(dailyMinutes);
            });

            const standorteCopy = standorte.slice();
            standorteCopy[idx] = standortCopy;

            return standorteCopy;
        });
    }
}
