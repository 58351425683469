<div class="dvb-notifications">
    <div class="col-md-7 big-abstand-bottom">

        <h2 data-translate="NOTIFICATION.TITLE"></h2>

        <dvb-pages items="vm.pageContainer.items"
                   total-items="vm.pageContainer.count"
                   is-loading="vm.isLoading"
                   items-per-page="vm.itemsPerPage"
                   current-page="vm.currentPage"
                   entity-name="'NOTIFICATION.MF'"
                   on-update-page="vm.updatePage(page)"
                   on-update-items-per-page="vm.onUpdateItemsPerPage(itemsPerPage)">
            <page-items dvb-accordions>
                <dvb-accordion is-first="$first"
                               enable-content="false"
                               ng-repeat="item in vm.pageContainer.items | limitTo: vm.itemsPerPage : ((vm.currentPage - 1) * vm.itemsPerPage)">
                    <panel-header class="full-width-block">
                        <div class="row layout-row items-center">
                            <div class="col-md-8">
                                <span ng-bind="item.notification" uib-tooltip="{{item.notification}}"></span>
                            </div>
                            <div class="col-md-4 text-right">
                                <span ng-bind="item.dueDate | amDateFormat:'D.M.YYYY HH:mm'"></span>
                            </div>
                        </div>
                    </panel-header>

                    <panel-footer class="full-width-block">
                        <ul class="list-inline list-inline-row">
                            <li>
                                <dvb-loading-button type="button"
                                                    is-loading="vm.isLoading"
                                                    as-link="true"
                                                    ng-click="vm.delete(item)">
                                    <span data-translate="COMMON.LOESCHEN"></span>
                                </dvb-loading-button>
                            </li>
                        </ul>
                    </panel-footer>

                </dvb-accordion>
            </page-items>
        </dvb-pages>
    </div>

    <div class="col-md-4 col-md-offset-1">
        <h2 data-translate="COMMON.AKTIONEN"></h2>

        <p ng-if="!vm.createMode">
            <button class="btn btn-link"
                    data-translate="NOTIFICATION.CREATE"
                    ng-click="vm.createMode = true">
            </button>
        </p>

        <form ng-if="vm.createMode"
              class="dvb-form"
              name="notificationForm"
              ng-submit="vm.create(notificationForm)"
              novalidate>
            <div class="row">
                <div class="col-md-6">
                    <dvb-datepicker-text-field ng-model="vm.newNotification.dueDate"
                                               required
                                               ng-attr-placeholder="{{'COMMON.GUELTIG_AB' | translate}}">
                    </dvb-datepicker-text-field>
                </div>
                <div class="col-md-6">
                    <input type="text"
                           ng-model="vm.newNotificationTime"
                           dvb-select-on-click
                           dvb-time-input
                           dvb-moment
                           maxlength="10"
                           class="form-control time-input"
                           ng-attr-placeholder="{{'NOTIFICATION.DUE_TIME' | translate}}"
                           uib-tooltip="{{'NOTIFICATION.DUE_TIME' | translate}}"
                           tooltip-placement="top-left">
                </div>
            </div>
            <div class="row">
                <div class="col-md-12">
                    <textarea ng-model="vm.newNotification.notification"
                              class="form-control"
                              msd-elastic
                              ng-attr-placeholder="{{'NOTIFICATION.SINGULAR' | translate}}"
                              uib-tooltip="{{'NOTIFICATION.SINGULAR' | translate}}"
                              tooltip-placement="top-left"
                              maxlength="1000"
                              required>
                    </textarea>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12">
                    <dvb-submit-cancel-buttons on-cancel="vm.createMode = false"
                                               is-loading="vm.isLoading">
                    </dvb-submit-cancel-buttons>
                </div>
            </div>
        </form>

    </div>
</div>
