/*
 * Copyright © 2018 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {Mandant} from '@dv/kitadmin/models';
import type {PageContainer, Persisted, RestInclude, RestPaginated} from '@dv/shared/code';
import {DvbRestUtil} from '@dv/shared/code';
import type angular from 'angular';
import {DvbRestUtilAngularJS} from 'src/app/common/service/rest/dvbRestUtilAngularJS';
import {CONFIG} from '../../../config';
import {Benutzer} from '../../benutzer/model/Benutzer';

export class AdministrationService {
    public static $inject: readonly string[] = ['$http'];

    public static readonly BASE_URL: string = `${CONFIG.restBackend}/api/v1/administration`;

    public constructor(
        private readonly $http: angular.IHttpService,
    ) {
    }

    public getActiveUsers(
        params?: RestInclude & RestPaginated,
        config?: angular.IRequestShortcutConfig,
    ): angular.IPromise<PageContainer<Benutzer>> {
        const url = `${AdministrationService.BASE_URL}/activeUsers`;

        return DvbRestUtilAngularJS.getPagedItems(url, Benutzer, {...params}, config);
    }

    public sendEmailTest(to: string, subject: string, rechnungId?: string | null): angular.IPromise<unknown> {
        const matrixParams = {
            rechnungId: rechnungId ?? undefined,
        };

        const url = `${AdministrationService.BASE_URL}/testemail/` +
            `${encodeURIComponent(to)}/${encodeURIComponent(subject)}${DvbRestUtil.encodeMatrixParams(matrixParams)}`;

        return this.$http.post(url, {});
    }

    public migrateKontaktIban(mandant: Persisted<Mandant>, customFieldName: string): angular.IHttpPromise<unknown> {
        const matrixParams = {customFieldName};
        const url = `${AdministrationService.BASE_URL}/migrateKontaktIban/${mandant.id}`;

        return this.$http.get(url + DvbRestUtil.encodeMatrixParams(matrixParams));
    }

    public deleteAngestellte(
        angestellteIds: { ids: string[] },
    ): angular.IHttpPromise<unknown> {
        const url = `${AdministrationService.BASE_URL}/deleteAngestellte/`;

        return this.$http.post(url, angestellteIds);
    }
}
