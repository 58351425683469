/*
 * Copyright © 2021 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {JaxDailyAnstellung} from '@dv/shared/backend/model/jax-daily-anstellung';
import type {JaxTimeRange} from '@dv/shared/backend/model/jax-time-range';
import type {DayOfWeek, IPersistable, IRestModel} from '@dv/shared/code';

export class DailyAnstellung implements IPersistable, IRestModel<JaxDailyAnstellung> {

    public constructor(
        public id: string | null = null,
        public dayOfWeek: DayOfWeek | null = null,
        public anstellungZeiten: JaxTimeRange[] = [],
        public expectedMinutes?: number,
        public nichtVerfuegbar: boolean = false,
    ) {
    }

    public static apiResponseTransformer(data: any): DailyAnstellung {
        return new DailyAnstellung(
            data.id,
            data.dayOfWeek,
            data.anstellungZeiten,
            data.expectedMinutes,
            data.nichtVerfuegbar,
        );
    }

    public toRestObject(): JaxDailyAnstellung {
        return {
            id: this.id ?? undefined,
            dayOfWeek: this.dayOfWeek as unknown as object,
            anstellungZeiten: this.anstellungZeiten,
            expectedMinutes: this.expectedMinutes,
            nichtVerfuegbar: this.nichtVerfuegbar,
        };
    }
}
