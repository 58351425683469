<nav class="dvb-navigation navbar navbar-default navbar-fixed-top">
    <div class="container kitadmin-content">
        <div class="navbar-header">
            <button class="navbar-toggle btn btn-menu-toggle"
                    ng-click="vm.isNavCollapsed = !vm.isNavCollapsed"
                    aria-label="{{'COMMON.MENU' | translate}}"
                    type="button">
                <i class="fa fa-bars"></i>
            </button>
            <a class="navbar-brand" ng-href="{{vm.getLogoStateLink()}}">
                <span class="sr-only">kitAdmin</span>
                <dvlib-logo></dvlib-logo>
            </a>
        </div>

        <div class="navbar-collapse" uib-collapse="vm.isNavCollapsed">
            <dvb-search-input dvb-auth
                              require-permission="shared:employee_view:general"
                              class="navbar-left">
            </dvb-search-input>
            <ul class="nav navbar-nav navbar-right">
                <li class="dropdown" uib-dropdown data-keyboard-nav>
                    <button class="dropdown-toggle dropdown-toggle-user btn btn-menu-toggle user-icon"
                            uib-dropdown-toggle
                            data-testid="menu-user"
                            type="button"
                            title="{{vm.displayName}}">
                        <span class="visible-lg visible-xl visible-xs username"
                              ng-bind="vm.displayName"></span>
                        <i class="visible-md-inline visible-sm-inline fa fa-user nav-icon"></i>
                    </button>
                    <ul class="dropdown-menu" uib-dropdown-menu>
                        <li dvb-auth require-permission="shared:employee_view:general">
                            <a ui-sref="base.benutzer.profil({id: vm.principal.userId})"
                               data-translate="BASE.BENUTZER_PROFIL">
                            </a>
                        </li>
                        <li dvb-auth
                            require-permission="feature:stundenerfassung_tfo:general"
                            require-condition="vm.stundenerfassungRef">
                            <a ng-if="vm.stundenerfassungRef"
                               ng-href="{{vm.stundenerfassungRef}}"
                               data-translate="PERSONAL.STUNDENERFASSUNG.TITLE">
                            </a>
                        </li>
                        <li dvb-auth
                            require-permission="module:personalplanung"
                            require-condition="vm.zuweisungRef">
                            <a ng-if="vm.zuweisungRef"
                               ng-href="{{vm.zuweisungRef}}"
                               data-translate="PERSONAL.ZUWEISUNG.TITLE">
                            </a>
                        </li>
                        <li>
                            <a href="" ng-click="vm.logoutHandler()"
                               data-translate="BASE.MENU_LOGOUT_USER">
                            </a>
                        </li>
                    </ul>
                </li>

                <li class="dropdown"
                    uib-dropdown
                    data-keyboard-nav
                    dvb-auth
                    require-permission="shared:employee_view:general">
                    <button class="dropdown-toggle dropdown-toggle-user btn btn-menu-toggle"
                            uib-dropdown-toggle
                            data-testid="menu-support"
                            type="button"
                            title="{{'COMMON.NAV_SUPPORT' | translate}}">
                        <i class="fa fa-question hidden-xs"></i>
                        <span class="visible-xs" data-translate="COMMON.NAV_SUPPORT"></span>
                    </button>
                    <ul class="dropdown-menu dropdown-support" uib-dropdown-menu>
                        <li>
                            <a href="tel:{{ 'COMMON.NAV_SUPPORT_PHONE' | translate }}"
                               data-translate="COMMON.NAV_SUPPORT_PHONE"
                               tooltip-placement="bottom-left"
                               uib-tooltip="{{'COMMON.NAV_SUPPORT_OPENING_HOURS' | translate}}"></a>
                        </li>
                        <li>
                            <a href="mailto:{{'COMMON.NAV_SUPPORT_EMAIL' | translate }}"
                               data-translate="COMMON.NAV_SUPPORT_EMAIL"></a>
                        </li>
                        <li>
                            <a href={{vm.supportTeamViewer}}
                               target="blank"
                               data-translate="COMMON.NAV_TEAM_VIEWER"></a>
                        </li>
                    </ul>
                </li>


                <li dvb-auth
                    require-permission="{{ {oneOf: ['kita:manage:general', 'shared:manage_users:general']} }}"
                    class="dropdown"
                    uib-dropdown
                    data-keyboard-nav>
                    <button class="dropdown-toggle dropdown-toggle-user btn btn-menu-toggle"
                            uib-dropdown-toggle
                            data-testid="menu-erstellen"
                            type="button"
                            title="{{'COMMON.NAV_ERSTELLEN' | translate}}">
                        <i class="custom-icon custom-icon-plus hidden-xs nav-icon"></i>
                        <span class="visible-xs" data-translate="COMMON.NAV_ERSTELLEN"></span>
                    </button>
                    <ul class="dropdown-menu" uib-dropdown-menu>
                        <!--region Anmeldung-->
                        <li dvb-auth require-permission="kita:manage:general">
                            <a ui-sref="base.anmeldung" data-translate="BASE.MENU_KIND_ANMELDEN"></a>
                        </li>
                        <li dvb-auth require-permission="kita:manage:general">
                            <a ui-sref="base.direktzuweisung" data-translate="COMMON.MENU_KIND_DIREKTZUWEISUNG"></a>
                        </li>
                        <li dvb-auth require-permission="kita:administrate:general" class="divider"></li>
                        <!--endregion-->
                        <!--region Administration-->
                        <li dvb-auth require-permission="kita:betreuung_admin:*">
                            <a ui-sref="base.kinderort-erstellen" data-translate="COMMON.KINDERORT.ERSTELLEN"></a>
                        </li>
                        <li dvb-auth require-permission="kita:administrate:general">
                            <a ui-sref="base.fraktion-erstellen.gruppe" data-translate="COMMON.GRUPPE.ERSTELLEN"></a>
                        </li>
                        <li dvb-auth require-permission="{{['kita:administrate:general', 'module:tageseltern']}}">
                            <a ui-sref="base.fraktion-erstellen.betreuungs_person"
                               data-translate="COMMON.BETREUUNGS_PERSON.ERSTELLEN"></a>
                        </li>
                        <li dvb-auth require-permission="kita:betreuung_admin:*">
                            <a ui-sref="base.firma-erstellen" data-translate="COMMON.PLATZART.ERSTELLEN"></a>
                        </li>
                        <li ng-if="vm.hasMandantOrUserCreatePrivileges" class="divider"></li>
                        <li dvb-auth require-permission="shared:manage_users:general">
                            <a ui-sref="base.neuer-benutzer" data-translate="COMMON.BENUTZER.ERSTELLEN"></a>
                        </li>
                        <li dvb-auth require-role="MANDANT_ADMIN">
                            <a ui-sref="base.mandant-erstellen" data-translate="COMMON.MANDANT.ERSTELLEN"></a>
                        </li>
                        <!--endregion-->
                    </ul>
                </li>
                <li dvb-auth
                    require-permission="shared:employee_view:general"
                    class="dropdown"
                    uib-dropdown
                    data-keyboard-nav>
                    <button class="dropdown-toggle dropdown-toggle-menu btn btn-menu-toggle"
                            uib-dropdown-toggle
                            data-testid="menu-hamburger"
                            type="button"
                            title="{{'COMMON.MENU' | translate}}">
                        <i class="fa fa-bars hidden-xs"></i>
                        <span class="visible-xs" data-translate="BASE.NAVIGATION_OEFFNEN"></span>
                    </button>
                    <ul class="dropdown-menu" uib-dropdown-menu>
                        <!--region Konfiguration-->
                        <li dvb-auth require-permission="shared:employee_view:general">
                            <a ui-sref="base.organisation.mandant"
                               data-translate="COMMON.NAV_ORGANISATION">
                            </a>
                        </li>
                        <li ng-if="vm.voreinstellungenStateName !== null">
                            <a ui-sref="{{vm.voreinstellungenStateName}}"
                               data-translate="COMMON.NAV_VOREINSTELLUNGEN">
                            </a>
                        </li>
                        <li dvb-auth
                            require-permission="{{ {oneOf: [['feature:stundenerfassung_tfo:general', 'kita:control:general'], 'personal:view:general']} }}">
                            <a ui-sref="{{vm.personalStates.ANGESTELLTE_LIST_STATE.name}}"
                               data-translate="COMMON.NAV_PERSONAL_SETTINGS">
                            </a>
                        </li>
                        <li dvb-auth
                            require-permission="{{ vm.workTimeControllingState.data.permission }}">
                            <a ui-sref="{{vm.workTimeControllingState.name}}"
                               data-translate="COMMON.NAV_WORK_TIME_CONTROLLING">
                            </a>
                        </li>
                        <li dvb-auth require-permission="kita:view:general">
                            <a ui-sref="base.report.category.betrieb"
                               data-translate="COMMON.NAV_REPORTS">
                            </a>
                        </li>
                        <li dvb-auth
                            require-permission="personal:view:general"
                            require-condition="!vm.hasKitaViewPrivileges">
                            <a ui-sref="base.report.category.personal"
                               data-translate="COMMON.NAV_REPORTS">
                            </a>
                        </li>
                        <li dvb-auth require-permission="{{['kita:manage:general', 'module:kommunikation']}}">
                            <a ui-sref="{{vm.communicationState.name}}"
                               data-translate="COMMON.NAV_COMMUNICATION">
                            </a>
                        </li>
                        <li dvb-auth require-permission="{{this.dataIntegrityState.data.permission}}">
                            <a ui-sref="{{vm.dataIntegrityState.name}}"
                               data-translate="DATA_INTEGRITY.DATA_INTEGRITY">
                            </a>
                        </li>
                        <li dvb-auth require-permission="kita:control:general">
                            <a ui-sref="{{vm.kibonState.name}}"
                               data-translate="KIBON.KIBON">
                            </a>
                        </li>
                        <li dvb-auth require-role="MANDANT_ADMIN">
                            <a ui-sref="base.administration.mandanten"
                               data-translate="COMMON.ADMINISTRATION">
                            </a>
                        </li>
                        <li dvb-auth require-permission="kita:view:general" class="divider"></li>
                        <!--endregion-->

                        <!--region Faktura-->
                        <li dvb-auth require-permission="{{['kita:control:general', 'module:faktura']}}">
                            <a ui-sref="base.kontenabgleich.transaktionen"
                               data-translate="FAKTURA.KONTENABGLEICH.KONTENABGLEICH">
                            </a>
                        </li>
                        <li dvb-auth require-permission="{{['kita:control:general', 'module:faktura']}}">
                            <a ui-sref="base.faktura.konfiguration"
                               data-translate="FAKTURA.FAKTURAEINSTELLUNGEN">
                            </a>
                        </li>
                        <li class="divider"
                            dvb-auth
                            require-permission="{{['kita:control:general', 'module:faktura']}}"></li>
                        <!--endregion-->

                        <!--region Schulung-->
                        <li dvb-auth require-permission="kita:view:general">
                            <a ui-sref="base.schulung"
                               data-translate="SCHULUNG.SCHULUNG_ABSOLVIEREN">
                            </a>
                        </li>
                        <!--endregion-->
                    </ul>
                </li>
            </ul>
        </div>
    </div>
</nav>
