<ng-container *transloco="let t">
    <table class="table table-responsive table-striped">
        <caption><h2>{{ t('ADMINISTRATION.STATISTICS.TITLE') }}</h2></caption>
        <thead>
        <tr>
            <th scope="col">{{ t('ADMINISTRATION.STATISTICS.ENTITY') }}</th>
            <th scope="col">{{ t('COMMON.ANZAHL') }}</th>
        </tr>
        </thead>
        <tbody>
        <tr>
            <td>{{ t('COMMON.MANDANT.MF', {count: entityCounts().mandanten}) }}</td>
            <td>{{ entityCounts().mandanten | number }}</td>
        </tr>
        <tr>
            <td>{{ t('COMMON.BENUTZER.MF', {count: entityCounts().benutzer}) }}</td>
            <td>{{ entityCounts().benutzer | number }}</td>
        </tr>
        <tr>
            <td>{{ t('COMMON.KINDERORT.MF', {count: entityCounts().kinderOrte}) }}</td>
            <td>{{ entityCounts().kinderOrte | number }}</td>
        </tr>
        <tr>
            <td>{{ t('COMMON.KIND.MF', {count: entityCounts().kinder}) }}</td>
            <td>{{ entityCounts().kinder | number }}</td>
        </tr>
        <tr>
            <td>{{ t('COMMON.RECHNUNG.MF', {count: entityCounts().rechnungen}) }}</td>
            <td>{{ entityCounts().rechnungen | number }}</td>
        </tr>
        <tr>
            <td>{{ t('COMMUNICATION.EMAIL.MF', {count: entityCounts().kontaktEmails}) }}</td>
            <td>{{ entityCounts().kontaktEmails | number }}</td>
        </tr>
        <tr>
            <td>{{ t('KIBON.VERFUEGUNGEN.MF', {count: entityCounts().zugewieseneVerfuegungen}) }}</td>
            <td>{{ entityCounts().zugewieseneVerfuegungen | number }}</td>
        </tr>
        </tbody>
    </table>
</ng-container>
