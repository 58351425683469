/*
 * Copyright © 2023 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import {UrlParameterTypes} from '@dv/kitadmin/core/uirouter';
import type {
    BetreuungsGutscheinGroup,
    Kind,
    LimitedAdresse,
    RelationshipWithKontaktperson,
    StundenKontingent,
    TarifParameter,
    TarifParameterHistoryEntries,
    Wochenplan,
} from '@dv/kitadmin/models';
import {ExtraPlatz, FixPositionTyp} from '@dv/kitadmin/models';
import {PERMISSION} from '@dv/shared/authentication/model';
import type {KindAbweichungsMeldungService} from '@dv/shared/backend/api/kind-abweichungs-meldung.service';
import type {JaxKindAbweichungsMeldung} from '@dv/shared/backend/model/jax-kind-abweichungs-meldung';
import {TarifParameterType} from '@dv/shared/backend/model/tarif-parameter-type';
import type {Nullish} from '@dv/shared/code';
import {DvbDateUtil, DvbRestUtil, isNullish} from '@dv/shared/code';
import type {Ng2StateDeclaration} from '@uirouter/angular';
import type {Ng1StateDeclaration} from '@uirouter/angularjs';
import type {Transition} from '@uirouter/core';
import type angular from 'angular';
import type moment from 'moment';
import {lastValueFrom, map} from 'rxjs';
import {trans} from '../common/i18n/trans';
import {BELEGUNG_INCLUDES} from '../common/service/rest/kind/belegungsService';
import type {KindService} from '../common/service/rest/kind/kindService';
import type {TarifService} from '../common/service/rest/tarifService';
import type {WochenplanService} from '../common/service/rest/wochenplanService';
import {TagesinfoComponent} from './checkin/tagesinfo/tagesinfo.component';
import {EingewoehnungpositionErfassenComponent} from './leistungen';

/* eslint-disable sonarjs/no-duplicate-string */
export const BASE_STATE = {
    name: 'base.kind',
    abstract: true,
    url: '/kind/{kindId:[0-9]+}',
    views: {
        'header@base.kind': {
            // Defining default page header
            template: '<dvb-kind-header ' +
                'kind="$resolve.kind" ' +
                'betreuungsfaktoren="betreuungsfaktoren" ' +
                'is-betreuungsfaktor-loading="isBetreuungsfaktorLoading"></dvb-kind-header>',
        },
        '': {
            controller: 'KindMainCtrl',
            template: '<div><div ui-view="header"></div><div ui-view></div></div>',
        },
    },
    resolve: {
        kind: [
            'kindService', '$transition$',
            (kindService: KindService, $transition$: Transition): angular.IPromise<Kind | null> => {
                const params = {
                    includes: '(' +
                        'geschwister,' +
                        'anhaengeAllgemein,' +
                        'anhaengeVertraulich,' +
                        'betreuungsGruendeIds,' +
                        'customFieldValues,' +
                        `${BELEGUNG_INCLUDES},` +
                        'bewerbungen.fields(firmen, kitas, belegteEinheiten.fields(belegungsEinheitenIds)),' +
                        'kontingente)',
                };

                return kindService.get($transition$.params().kindId, params);
            },
        ],
        $title: ['kind', (kind: Kind): string => kind.getDisplayName()],
    },
    data: {
        permission: PERMISSION.KITA.VIEW_ANY,
    },
} satisfies Ng1StateDeclaration;

export const PROFIL_STATE = {
    name: 'base.kind.profil',
    url: '',
    template: '<dvb-kind-profil kind="$resolve.kind"></dvb-kind-profil>',
} satisfies Ng1StateDeclaration;

export const KONTAKT_STATE = {
    name: 'base.kind.kontakt',
    url: '/kontakt',
    component: 'dvbKindKontakt',
    resolve: {
        kontakte: [
            'kindService', '$transition$',
            (
                kindService: KindService,
                $transition$: Transition,
            ): angular.IPromise<RelationshipWithKontaktperson[]> =>
                kindService.getAllRelationshipsWithKontaktpersonen($transition$.params().kindId, {
                    includes: '(kontaktperson.fields(adressen),relationship)',
                }),
        ],
        adressen: [
            'kindService', '$transition$',
            (kindService: KindService, $transition$: Transition): angular.IPromise<LimitedAdresse[]> =>
                kindService.getWohnsitze($transition$.params().kindId),
        ],
    },
} satisfies Ng1StateDeclaration;

export const NEUE_KONTAKTPERSON_STATE = {
    name: 'base.kind.neue-kontaktperson',
    url: '/neue-kontaktperson',
    component: 'dvbKindNeueKontaktperson',
    data: {
        permission: PERMISSION.KITA.MANAGE_ANY,
    },
} satisfies Ng1StateDeclaration;

export const BETREUUNG_STATE = {
    name: 'base.kind.betreuung',
    url: '/betreuung',
    params: {
        expandAb: {
            type: UrlParameterTypes.MOMENT_DATE,
            value: null,
        },
        expandBis: {
            type: UrlParameterTypes.MOMENT_DATE,
            value: null,
        },
    },
    template: '<dvb-kind-betreuung ' +
        'wochenplan="$resolve.wochenplan" ' +
        'expand-ab="$resolve.expandAb" ' +
        'expand-bis="$resolve.expandBis" ' +
        'kind="$resolve.kind"></dvb-kind-betreuung>',
    resolve: {
        wochenplan: [
            'wochenplanService', (wochenplanService: WochenplanService): angular.IPromise<Wochenplan | null> =>
                wochenplanService.getWochenplanAnmeldungDeep({cache: true}),
        ],
        expandAb: [
            '$transition$', ($transition$: Transition): angular.IPromise<moment.Moment | undefined> =>
                $transition$.params().expandAb,
        ],
        expandBis: [
            '$transition$', ($transition$: Transition): angular.IPromise<moment.Moment | undefined> =>
                $transition$.params().expandBis,
        ],
    },
} satisfies Ng1StateDeclaration;

export const EXTRA_PLATZ_STATE = {
    name: 'base.kind.extra-platz',
    url: '/abweichung',
    component: 'dvbExtraPlatzCreation',
    params: {
        startDate: null,
        meldung: null,
    },
    resolve: {
        kindId: [
            '$transition$', ($transition$: Transition): angular.IPromise<string | null> =>
                $transition$.params().kindId,
        ],
        startDate: [
            '$transition$', ($transition$: Transition): angular.IPromise<string | null> =>
                $transition$.params().startDate,
        ],
        meldung: [
            '$transition$', ($transition$: Transition): angular.IPromise<JaxKindAbweichungsMeldung | null> =>
                $transition$.params().meldung,
        ],
        tempExtraPlaetze: [
            'kindAbweichungsMeldungService',
            '$transition$',
            (
                api: KindAbweichungsMeldungService,
                $transition$: Transition,
            ): angular.IPromise<ExtraPlatz[]> => {
                const meldung = $transition$.params().meldung;
                if (isNullish(meldung)) {
                    return Promise.resolve([]);
                }

                const observable = api.getConvertedKindAbweichungsMeldung$({
                    abweichungsMeldungId: meldung.id!,
                    jaxKindAbweichungsMeldungToConvert: {
                        gueltigAb: meldung.gueltigAb,
                        gueltigBis: meldung.gueltigBis,
                        von: meldung.von,
                        bis: meldung.bis,
                        extraPlatzCategory: meldung.extraPlatzCategory,
                    },
                }).pipe(map(response => DvbRestUtil.transformArray(response.items, ExtraPlatz)));

                return lastValueFrom(observable);
            },
        ],
    },
    data: {
        permission: [
            PERMISSION.MODULE.DYNAMISCHER_BELEGUNGSPLAN,
            PERMISSION.KITA.MODIFY_EXTRA_PLATZ_ANY,
        ],
    },
} satisfies Ng1StateDeclaration;

// anmeldung states

export const ANMELDUNG_STATE = {
    name: 'base.anmeldung',
    url: '/anmeldung/:geschwisterId',
    component: 'dvbAnmeldung',
    resolve: {
        wochenplan: [
            'wochenplanService', (wochenplanService: WochenplanService): angular.IPromise<Wochenplan | null> =>
                wochenplanService.getWochenplanAnmeldungDeep({cache: true}),
        ],
        $title: [
            '$translate', ($translate: angular.translate.ITranslateService): angular.IPromise<string> =>
                trans($translate, 'KIND.ANMELDUNG'),
        ],
    },
    params: {
        geschwisterId: '',
    },
    data: {
        permission: PERMISSION.KITA.MANAGE_ANY,
    },
} satisfies Ng1StateDeclaration;

export const DIRTEKTZUWEISUNG_STATE = {
    name: 'base.direktzuweisung',
    url: '/direktzuweisung',
    component: 'dvbDirektzuweisung',
    data: {
        permission: PERMISSION.KITA.MANAGE_ANY,
    },
    resolve: {
        $title: [
            '$translate', ($translate: angular.translate.ITranslateService): angular.IPromise<string> =>
                trans($translate, 'COMMON.MENU_KIND_DIREKTZUWEISUNG'),
        ],
    },
} satisfies Ng1StateDeclaration;

// tarif states
export const TARIFE_STATE = {
    name: 'base.kind.tarife',
    url: '/tarife',
    abstract: true,
    template: '<div ui-view></div>',
    data: {
        permission: PERMISSION.KITA.CONTROL_ANY,
    },
} satisfies Ng1StateDeclaration;

export const UEBRSICHT_STATE = {
    name: 'base.kind.tarife.uebersicht',
    url: '',
    template: '<dvb-kind-tarife ' +
        'kind="$resolve.kind" ' +
        'kind-parameter="$resolve.kindParameter" ' +
        'tarif-parameter="$resolve.tarifParameter" ' +
        'betreuungs-gutscheine-groups="$resolve.betreuungsGutscheineGroups" ' +
        'stunden-kontingente="$resolve.stundenKontingente">' +
        '</dvb-kind-tarife>',
    resolve: {
        kindParameter: [
            'kindService', '$transition$',
            (kindService: KindService, $transition$: Transition): angular.IPromise<TarifParameterHistoryEntries> =>
                kindService.getTarifParameterHistory($transition$.params().kindId, TarifParameterType.GLOBAL_PARAM),
        ],
        tarifParameter: [
            'tarifService', (tarifService: TarifService): angular.IPromise<TarifParameter[]> =>
                tarifService.getNotDeletedTarifParameters(TarifParameterType.GLOBAL_PARAM),
        ],
        betreuungsGutscheineGroups: [
            'kindService', '$transition$',
            (kindService: KindService, $transition$: Transition): angular.IPromise<BetreuungsGutscheinGroup []> =>
                kindService.getBetreuungsGutscheineGroups($transition$.params().kindId),
        ],
        stundenKontingente: [
            'kindService', '$transition$',
            (
                kindService: KindService,
                $transition$: Transition,
            ): angular.IPromise<StundenKontingent[]> =>
                kindService.getStundenKontingente($transition$.params().kindId),
        ],
    },
} satisfies Ng1StateDeclaration;

export const KITAX_STATE = {
    name: 'base.kind.tarife.kitax',
    url: '/kitax',
    template: '<dvb-kitax-import kind="$resolve.kind"></dvb-kitax-import>',
} satisfies Ng1StateDeclaration;

// kibon
export const KIBON_STATE = {
    name: 'base.kind.kibon',
    url: '/kibon',
    component: 'dvbKindKibon',
    data: {
        permission: [
            PERMISSION.KITA.CONTROL_ANY,
        ],
    },
} satisfies Ng1StateDeclaration;

// leistungen
export const LEISTUNGEN_STATE = {
    name: 'base.kind.leistungen',
    url: '/leistungen/:year?:month',
    component: 'dvbKindLeistungen',
    resolve: {
        year: ['$transition$', ($transition$: Transition): number | Nullish => $transition$.params().year],
        month: ['$transition$', ($transition$: Transition): number | Nullish => $transition$.params().month],
    },
    params: {
        year: null,
        month: null,
    },
    data: {
        permission: PERMISSION.KITA.CONTROL_ANY,
    },
} satisfies Ng1StateDeclaration;

export const GEBUEHREN_STATE = {
    name: 'base.kind.gebuehren',
    url: '/gebuehren',
    template: '<dvb-fixposition-erfassen ' +
        'typ="{{$resolve.typ}}" ' +
        'kind-id="$resolve.kind.id">' +
        '</dvb-fixposition-erfassen>',
    resolve: {
        typ: (): string => FixPositionTyp.GEBUEHR,
    },
    data: {
        permission: PERMISSION.KITA.MANAGE_ANY,
    },
};

export const GUTSCHRIFTEN_STATE = {
    name: 'base.kind.gutschriften',
    url: '/gutschriften',
    template: '<dvb-fixposition-erfassen ' +
        'typ="{{$resolve.typ}}" ' +
        'kind-id="$resolve.kind.id">' +
        '</dvb-fixposition-erfassen>',
    resolve: {
        typ: (): string => FixPositionTyp.GUTSCHRIFT,
    },
    data: {
        permission: PERMISSION.KITA.MANAGE_ANY,
    },
} satisfies Ng1StateDeclaration;

export const EINGEWOEHNUNG_STATE = {
    name: `${BASE_STATE.name}.eingewoehnung`,
    url: '/eingewoehnung',
    component: EingewoehnungpositionErfassenComponent,
    resolve: {
        kindId: [
            '$transition$', ($transition$: Transition): angular.IPromise<string | null> =>
                $transition$.params().kindId,
        ],
    },
    data: {
        permission: [PERMISSION.KITA.MANAGE_ANY],
    },
} satisfies Ng2StateDeclaration;

export const TAGESINFO_STATE = {
    name: `${BASE_STATE.name}.tagesinfo`,
    url: '/tagesinfo?:datum',
    params: {
        datum: {
            type: UrlParameterTypes.BACKEND_LOCAL_DATE,
            dynamic: true,
            value: DvbDateUtil.todayAsLocalDate(),
        },
    },
    component: TagesinfoComponent,
    resolve: {
        datum: [
            '$transition$', ($transition$: Transition) => $transition$.params().datum ?? DvbDateUtil.todayAsLocalDate(),
        ],
    },
    data: {
        permission: [PERMISSION.MODULE.GROUP_ADMIN, PERMISSION.KITA.CHECK_IN_CHECK_OUT_ANY],
    },
} satisfies Ng2StateDeclaration;

/* Add New States Above */

export const KIND_STATES = {
    BASE_STATE,
    PROFIL_STATE,
    KONTAKT_STATE,
    NEUE_KONTAKTPERSON_STATE,
    BETREUUNG_STATE,
    EXTRA_PLATZ_STATE,
    ANMELDUNG_STATE,
    DIRTEKTZUWEISUNG_STATE,
    TARIFE_STATE,
    UEBRSICHT_STATE,
    KITAX_STATE,
    KIBON_STATE,
    LEISTUNGEN_STATE,
    GEBUEHREN_STATE,
    GUTSCHRIFTEN_STATE,
    EINGEWOEHNUNG_STATE,
    TAGESINFO_STATE,
};

