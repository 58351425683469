<div class="row dvb-form">
    <div class="col-md-6 col-md-offset-3">

        <h2 data-translate="KIND.BETREUUNGSWUNSCH_HINZUFUEGEN"></h2>

        <form name="bewerbungForm" ng-submit="vm.validateAndSaveBewerbung(bewerbungForm.$valid)" novalidate>

            <div class="form-section">

                <div class="form-row" ng-show="vm.bewerbung.kitas && vm.bewerbung.kitas.length > 0">
                    <div class="row selected-entity"
                         ng-repeat="kita in vm.bewerbung.kitas"
                         ng-init="searchResultEntry = kita.toSearchResultEntry()">
                        <div class="col-xs-8 entity">
                            <dv-search-result-icon [entry]="searchResultEntry"></dv-search-result-icon>
                            <span ng-bind="kita.getDisplayName()"></span>
                        </div>
                        <div class="col-xs-4 edit">
                            <a href="" ng-click="vm.bewerbung.removeKita(kita)" data-translate="COMMON.LOESCHEN"></a>
                        </div>
                    </div>
                </div>
                <div>
                    <dvb-search-entity class="form-row"
                                       entity-to-search="KINDERORT"
                                       on-select="vm.addKita(item)"
                                       on-select-clear="true"
                                       placeholder-key="COMMON.KINDERORT.AUSWAEHLEN"
                                       disabled-entries="vm.bewerbung.kitas"
                                       ng-class="{ 'ng-invalid' : !vm.bewerbung.isKitaSet() }">
                    </dvb-search-entity>
                </div>

                <div class="row">
                    <div class="col-xs-3">
                        <input type="number"
                               name="pensum"
                               class="form-control"
                               min="1"
                               ng-blur="vm.bewerbung.pensum.update()"
                               ng-model="vm.bewerbung.pensum.von"
                               ng-attr-placeholder="{{'COMMON.PENSUM_IN_PROZENT' | translate}}"
                               uib-tooltip="{{'COMMON.PENSUM_IN_PROZENT' | translate}}"
                               tooltip-placement="top-left"
                               required>
                    </div>
                    <div class="col-xs-1 form-linker" data-translate="COMMON.BIS"></div>
                    <div class="col-xs-3">
                        <input type="number"
                               class="form-control"
                               min="1"
                               ng-blur="vm.bewerbung.pensum.update()"
                               ng-model="vm.bewerbung.pensum.bis"
                               ng-attr-placeholder="{{'COMMON.PENSUM_IN_PROZENT' | translate}}"
                               uib-tooltip="{{'COMMON.PENSUM_IN_PROZENT' | translate}}"
                               tooltip-placement="top-left"
                               required>
                    </div>
                    <div class="col-xs-5 form-linker">
                        <i class="fa fa-info-circle"
                           uib-tooltip="{{'EXTERNAL_ANMELDUNG.BEWERBUNG.BERECHNETES_PENSUM' | translate:'{pensum: \'' + vm.externalAnmeldung.pensum.von + '\'}'}}"
                           tooltip-placement="top-left"
                           ng-if="vm.pensumAutomaticallyGenerated">
                        </i>
                    </div>
                </div>
                <div class="row form-row">
                    <div class="col-md-12">
                        <dvb-wochenplan distinct-betreuungs-zeitraeume="vm.distinctZeitraeume"
                                        week-days="vm.enabledDays"
                                        interactive="true"
                                        on-zeitraum-feld-clicked="vm.handleZeitraumFeldToggle(zeitraumFeld)"
                                        show-null-value-as-plain="true"
                                        zeitraum-felder="vm.zeitraumFelder"
                                        show-betreuungs-zeitraum-name="vm.wochenplan.showBetreuungsZeitraumName">
                        </dvb-wochenplan>
                    </div>
                </div>

                <div class="form-row">
                    <div class="row">
                        <div class="col-md-6">
                            <div class="checkbox">
                                <label>
                                    <input type="checkbox" value="true" ng-model="vm.bewerbung.subventionierterPlatz">
                                    <span data-translate="COMMON.SUBVENTIONIERTER_PLATZ"></span>
                                </label>
                            </div>
                            <div class="checkbox">
                                <label>
                                    <input type="checkbox" value="true" ng-model="vm.bewerbung.privaterPlatz">
                                    <span data-translate="COMMON.PRIVATER_PLATZ"></span>
                                </label>
                            </div>
                            <div class="checkbox" ng-repeat="firma in vm.bewerbung.firmenSelection">
                                <label>
                                    <input type="checkbox" value="firma.id" ng-model="firma.selected">
                                    <span data-translate="COMMON.PLATZART.TITLE"
                                          data-translate-value-name="{{firma.name}}"></span>
                                </label>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <span class="error-message"
                                  ng-show="!vm.bewerbung.isPlatzSelected()"
                                  data-translate="ERRORS.ERR_REQUIRED_PLATZ"></span>
                        </div>
                    </div>
                    <dvb-search-on-click-entity entity-to-search="FIRMA"
                                                on-select="vm.addFirmaToSelection(item)"
                                                on-select-clear="true"
                                                add-label-key="COMMON.PLATZART.HINZUFUEGEN"
                                                placeholder-key="COMMON.PLATZART.AUSWAEHLEN">
                    </dvb-search-on-click-entity>
                </div>

                <div class="row">
                    <div class="col-md-6">
                        <dvb-datepicker-text-field ng-model="vm.bewerbung.gewuenschteBetreuungAb"
                                                   ng-attr-placeholder="{{'KIND.EINTRITTSDATUM_AB' | translate}}"
                                                   required>
                        </dvb-datepicker-text-field>
                    </div>
                </div>

                <div class="row form-row">
                    <div class="col-md-12">
                        <div class="checkbox">
                            <label>
                                <input type="checkbox" ng-model="vm.bewerbung.kindergarten">
                                <span data-translate="COMMON.KINDERGARTEN"></span>
                            </label>
                        </div>
                    </div>
                </div>

                <dvb-bewerbung-prioritaet prioritaet="vm.bewerbung.prioritaet"
                                          on-change="vm.bewerbung.prioritaet = prioritaet"
                                          layout="row">
                </dvb-bewerbung-prioritaet>

            </div>

            <div class="row">
                <div class="col-md-12">
                    <ul class="list-inline">
                        <li>
                            <dvb-loading-button type="submit" is-loading="vm.isLoading">
                                <span data-translate="KIND.BETREUUNGSWUNSCH_HINZUFUEGEN"></span>
                            </dvb-loading-button>
                        </li>
                        <li><a href="" data-translate="COMMON.ABBRECHEN" ng-click="vm.goBack()"></a></li>
                    </ul>
                </div>
            </div>
        </form>
    </div>
</div>
