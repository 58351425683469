/*
 * Copyright © 2023 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import {CommonModule} from '@angular/common';
import {ChangeDetectionStrategy, Component} from '@angular/core';
import type {NgForm} from '@angular/forms';
import {FormsModule} from '@angular/forms';
import {ErrorService} from '@dv/kitadmin/core/errors';
import type {CompletableDialogModel} from '@dv/kitadmin/ui';
import {CompletableDialogBase} from '@dv/kitadmin/ui';
import {
    ButtonComponent,
    ButtonListComponent,
    DatepickerTextfieldComponent,
    ElasticTextInputDirective,
    LoadingButtonComponent,
} from '@dv/shared/angular';
import {checkPresent, DvbDateUtil} from '@dv/shared/code';
import {TranslocoModule} from '@jsverse/transloco';
import type moment from 'moment';
import {TooltipModule} from 'ngx-bootstrap/tooltip';

export interface KontaktpersonRueckerstattungDialogResult {
    auszahlungsDatum: moment.Moment;
    generatePainFile: boolean;
    zahlungszweck: string;
}

@Component({
    selector: 'dv-kontaktperson-rueckerstattung-dialog',
    templateUrl: './kontaktperson-rueckerstattung-dialog.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        CommonModule,
        FormsModule,
        TranslocoModule,
        LoadingButtonComponent,
        DatepickerTextfieldComponent,
        TooltipModule,
        ButtonComponent,
        ElasticTextInputDirective,
        ButtonListComponent,
    ],
})
export class KontaktpersonRueckerstattungDialogComponent
    extends CompletableDialogBase<KontaktpersonRueckerstattungDialogResult>
    implements CompletableDialogModel<KontaktpersonRueckerstattungDialogResult> {

    public auszahlungsDatum: moment.Moment | null = null;
    public generatePainFile: boolean = false;
    public zahlungszweck: string = '';

    public constructor(
        private errorService: ErrorService,
    ) {
        super();
    }

    public submitForm(form: NgForm): void {

        this.errorService.clearAll();

        const formValid = !form.invalid;
        this.errorService.handleValidationError(formValid, 'ERRORS.ERR_INCOMPLETE_FORM');

        const validMoment = DvbDateUtil.isValidMoment(this.auszahlungsDatum);
        this.errorService.handleValidationError(validMoment, 'ERRORS.ERR_INVALID_DATE');

        if (formValid && validMoment) {
            super.submit({
                auszahlungsDatum: checkPresent(this.auszahlungsDatum),
                generatePainFile: this.generatePainFile,
                zahlungszweck: this.zahlungszweck,
            });
        }
    }
}
