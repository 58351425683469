/*
 * Copyright © 2020 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {ErrorService} from '@dv/kitadmin/core/errors';
import type { MandantSearchFilter} from '@dv/kitadmin/search';
import {ANY_MANDANT} from '@dv/kitadmin/search';
import type {SearchResultEntry} from '@dv/shared/code';
import {checkPresent, DvbDateUtil} from '@dv/shared/code';
import angular from 'angular';
import type {ReportService} from '../../../report/service/reportService';
import type {MaintenanceService} from '../../service/maintenanceService';

const componentConfig: angular.IComponentOptions = {
    transclude: false,
    bindings: {},
    template: require('./dvb-recalculate-controlling-kinderort.html'),
    controllerAs: 'vm',
};

export class DvbRecalculateControllingKinderort implements angular.IController {
    public static $inject: readonly string[] = ['maintenanceService', 'errorService', 'reportService'];

    public isLoading: boolean = false;
    public kinderOrt: SearchResultEntry | null = null;
    public year: number = DvbDateUtil.today().year();
    public mandantFilter: MandantSearchFilter = ANY_MANDANT;

    public constructor(
        private readonly maintenanceService: MaintenanceService,
        private readonly errorService: ErrorService,
        private readonly reportService: ReportService,
    ) {
    }

    public recalculateControllingData(form: angular.IFormController): void {
        if (this.hasData(form)) {
            this.maintenanceService.recalculateKinderOrtControllingData(this.getKinderOrtId(), this.year);
        }
    }

    public getControllingData(form: angular.IFormController): void {
        if (this.hasData(form)) {
            this.reportService.getControlling({year: this.year, kinderOrtId: this.getKinderOrtId()});
        }
    }

    public deleteControllingData(form: angular.IFormController): void {
        if (this.hasData(form)) {
            this.maintenanceService.deleteKinderOrtControllingData(this.getKinderOrtId(), this.year);
        }
    }

    private hasData(form: angular.IFormController): boolean {
        this.errorService.clearAll();
        this.errorService.handleValidationError(form.$valid, 'ERRORS.ERR_INCOMPLETE_FORM');

        return form.$valid;
    }

    private getKinderOrtId(): string {
        return checkPresent(this.kinderOrt?.id);
    }
}

componentConfig.controller = DvbRecalculateControllingKinderort;
angular.module('kitAdmin').component('dvbRecalculateControllingKinderort', componentConfig);
