/*
 * Copyright © 2023 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import {CommonModule} from '@angular/common';
import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import {FormsModule} from '@angular/forms';
import {KitaRechnungsKonfigurationEditStats} from '@dv/kitadmin/models';
import type {CompletableDialogModel} from '@dv/kitadmin/ui';
import {CompletableDialogBase} from '@dv/kitadmin/ui';
import {ButtonListComponent, LoadingButtonComponent} from '@dv/shared/angular';
import {TranslocoModule} from '@jsverse/transloco';

export interface KitaRechnungsKonfigurationBearbeitenConfirmationDialogModel extends CompletableDialogModel<null> {
    stats: KitaRechnungsKonfigurationEditStats;
}

@Component({
    selector: 'dv-kita-rechnungs-konfiguration-bearbeiten-confirmation',
    templateUrl: './kita-rechnungs-konfiguration-bearbeiten-confirmation.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        CommonModule,
        FormsModule,
        TranslocoModule,
        LoadingButtonComponent,
        ButtonListComponent,
    ],
})
export class KitaRechnungsKonfigurationBearbeitenConfirmationComponent extends CompletableDialogBase<null>
    implements KitaRechnungsKonfigurationBearbeitenConfirmationDialogModel {

    @Input() public stats!: KitaRechnungsKonfigurationEditStats;
}
