/**
 * Kita-Scheduler
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import {
    HttpClient,
    HttpContext,
    HttpEvent,
    HttpHeaders,
    HttpParameterCodec,
    HttpParams,
    HttpResponse,
} from '@angular/common/http';
import {Inject, Injectable, Optional} from '@angular/core';
import {Observable} from 'rxjs';
import {Configuration} from '../configuration';
import {CustomHttpParameterCodec} from '../encoder';

import type {JaxLocalDate} from '@dv/shared/backend/model/jax-local-date';
import type {JaxMandantRechnungen} from '@dv/shared/backend/model/jax-mandant-rechnungen';
import type {JaxMandantRechnungsPDF} from '@dv/shared/backend/model/jax-mandant-rechnungs-pdf';
import type {JaxTempBlob} from '@dv/shared/backend/model/jax-temp-blob';
import type {MandantRechnungResourceGetMandantRechnungenMandantrechnungenParameter} from '@dv/shared/backend/model/mandant-rechnung-resource-get-mandant-rechnungen-mandantrechnungen-parameter';
import type {TemplateLanguage} from '@dv/shared/backend/model/template-language';

// @ts-ignore
import {BASE_PATH, COLLECTION_FORMATS} from '../variables';

export interface MandantRechnungenServiceDownloadPDFsRequestParams {
    language?: TemplateLanguage;
    jaxMandantRechnungsPDF?: JaxMandantRechnungsPDF;
}

export interface MandantRechnungenServiceGetMandantRechnungenRequestParams {
    mandantrechnungen: MandantRechnungResourceGetMandantRechnungenMandantrechnungenParameter;
}

export interface MandantRechnungenServiceRecalculateMandantRechnungRequestParams {
    jaxLocalDate?: JaxLocalDate;
}

@Injectable({
  providedIn: 'root'
})
export class MandantRechnungenService {

    protected basePath = '/kitadmin';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();
    public encoder: HttpParameterCodec;

    constructor(
        protected httpClient: HttpClient,
        @Optional() @Inject(BASE_PATH) basePath: string | string[],
        @Optional() configuration: Configuration,
    ) {
        if (configuration) {
            this.configuration = configuration;
        }
        if (typeof this.configuration.basePath !== 'string') {
            const firstBasePath = Array.isArray(basePath) ? basePath[0] : undefined;
            if (firstBasePath != undefined) {
                basePath = firstBasePath;
            }

            if (typeof basePath !== 'string') {
                basePath = this.basePath;
            }
            this.configuration.basePath = basePath;
        }
        this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
    }


    // @ts-ignore: Sometimes, this method is unused
    private addToHttpParams(httpParams: HttpParams, value: any, key?: string): HttpParams {
        if (typeof value === "object" && !(value instanceof Date)) {
            httpParams = this.addToHttpParamsRecursive(httpParams, value);
        } else {
            httpParams = this.addToHttpParamsRecursive(httpParams, value, key);
        }
        return httpParams;
    }

    // @ts-ignore: Sometimes, this method is unused
    private addToHttpParamsRecursive(httpParams: HttpParams, value?: any, key?: string): HttpParams {
        if (value == null) {
            return httpParams;
        }

        if (typeof value === "object") {
            if (Array.isArray(value)) {
                // noinspection AssignmentResultUsedJS
                (value as any[]).forEach( elem => httpParams = this.addToHttpParamsRecursive(httpParams, elem, key));
            } else if (value instanceof Date) {
                if (key != null) {
                    httpParams = httpParams.append(key, (value as Date).toISOString().substring(0, 10));
                } else {
                   throw Error("key may not be null if value is Date");
                }
            } else {
                // noinspection AssignmentResultUsedJS
                Object.keys(value).forEach( k => httpParams = this.addToHttpParamsRecursive(
                    httpParams, value[k], key != null ? `${key}.${k}` : k));
            }
        } else if (key != null) {
            httpParams = httpParams.append(key, value);
        } else {
            throw Error("key may not be null if value is not object or array");
        }
        return httpParams;
    }

    /**
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to
     *     returning the body.
     * @param reportProgress flag to report request and response progress.
     * @param options additional configuration of the HttpClient.
     */
    public downloadPDFs$(requestParameters: MandantRechnungenServiceDownloadPDFsRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<JaxTempBlob>;
    public downloadPDFs$(requestParameters: MandantRechnungenServiceDownloadPDFsRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<JaxTempBlob>>;
    public downloadPDFs$(requestParameters: MandantRechnungenServiceDownloadPDFsRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<JaxTempBlob>>;
    public downloadPDFs$(requestParameters: MandantRechnungenServiceDownloadPDFsRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        const language = requestParameters.language;
        const jaxMandantRechnungsPDF = requestParameters.jaxMandantRechnungsPDF;

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (language !== undefined && language !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>language, 'language');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json',
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/api/v1/administration/mandantrechnungen/pdf`;
        return this.httpClient.request<JaxTempBlob>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: jaxMandantRechnungsPDF,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress,
            }
        );
    }

    /**
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to
     *     returning the body.
     * @param reportProgress flag to report request and response progress.
     * @param options additional configuration of the HttpClient.
     */
    public getMandantRechnungen$(requestParameters: MandantRechnungenServiceGetMandantRechnungenRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<JaxMandantRechnungen>;
    public getMandantRechnungen$(requestParameters: MandantRechnungenServiceGetMandantRechnungenRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<JaxMandantRechnungen>>;
    public getMandantRechnungen$(requestParameters: MandantRechnungenServiceGetMandantRechnungenRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<JaxMandantRechnungen>>;
    public getMandantRechnungen$(requestParameters: MandantRechnungenServiceGetMandantRechnungenRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        const mandantrechnungen = requestParameters.mandantrechnungen;
        if (mandantrechnungen === null || mandantrechnungen === undefined) {
            throw new Error('Required parameter mandantrechnungen was null or undefined when calling getMandantRechnungen$.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json',
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/api/v1/administration/mandantrechnungen${this.configuration.encodeParam({name: "mandantrechnungen", value: mandantrechnungen, in: "path", style: "matrix", explode: true, dataType: "MandantRechnungResourceGetMandantRechnungenMandantrechnungenParameter", dataFormat: undefined})}`;
        return this.httpClient.request<JaxMandantRechnungen>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress,
            }
        );
    }

    /**
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to
     *     returning the body.
     * @param reportProgress flag to report request and response progress.
     * @param options additional configuration of the HttpClient.
     */
    public recalculateMandantRechnung$(requestParameters: MandantRechnungenServiceRecalculateMandantRechnungRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext, transferCache?: boolean}): Observable<any>;
    public recalculateMandantRechnung$(requestParameters: MandantRechnungenServiceRecalculateMandantRechnungRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<any>>;
    public recalculateMandantRechnung$(requestParameters: MandantRechnungenServiceRecalculateMandantRechnungRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<any>>;
    public recalculateMandantRechnung$(requestParameters: MandantRechnungenServiceRecalculateMandantRechnungRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: undefined, context?: HttpContext, transferCache?: boolean}): Observable<any> {
        const jaxLocalDate = requestParameters.jaxLocalDate;

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/api/v1/administration/mandantrechnungen/recalculate`;
        return this.httpClient.request<any>('put', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: jaxLocalDate,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress,
            }
        );
    }

}
