/*
 * Copyright © 2023 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import {Directive, ElementRef, EventEmitter, Injector, Input, Output} from '@angular/core';
import {UpgradeComponent} from '@angular/upgrade/static';
import {OptionFilter} from '../../common/component/dvb-filter-list/OptionFilter';
import type {Filter} from '../../filter/shared/Filter';

@Directive({
    standalone: true,
    selector: 'dv-filter-list',
})
export class FilterListDirective extends UpgradeComponent {

    @Input({required: true}) public filter!: Filter[];
    @Input() public optionFilter?: OptionFilter;
    @Output() public readonly onSticky?: EventEmitter<void>;

    public constructor(elementRef: ElementRef, injector: Injector) {
        super('dvbFilterList', elementRef, injector);
    }
}
