/*
 * Copyright © 2024 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import {ChangeDetectionStrategy, Component, computed, input, output} from '@angular/core';
import {FormsModule} from '@angular/forms';
import {WorkTimeModel} from '@dv/kitadmin/models';
import {SearchListComponent} from '@dv/kitadmin/search';
import {WorkTimeModelDisplayNamePipe} from '@dv/kitadmin/work-time-controlling';
import {DvMinutesFormatPipe, formViewProvider, RequirePermissionStructuralDirective} from '@dv/shared/angular';
import {DvbDateUtil, isNullish, nextId} from '@dv/shared/code';
import {TranslocoModule} from '@jsverse/transloco';
import {TooltipModule} from 'ngx-bootstrap/tooltip';
import {AnstellungStandortFormModel} from '../../../models/anstellung-form-model';
import {
    AngestellteAnstellungExpectedTimeComponent,
} from '../angestellte-anstellung-expected-time/angestellte-anstellung-expected-time.component';
import {
    AngestellteAnstellungZeitenComponent,
} from '../angestellte-anstellung-zeiten/angestellte-anstellung-zeiten.component';

const ONE_HUNDRED = 100;

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'dv-anstellung-standort-form',
    standalone: true,
    imports: [
        TooltipModule,
        FormsModule,
        SearchListComponent,
        TranslocoModule,
        AngestellteAnstellungZeitenComponent,
        RequirePermissionStructuralDirective,
        AngestellteAnstellungExpectedTimeComponent,
        DvMinutesFormatPipe,
        WorkTimeModelDisplayNamePipe,
    ],
    templateUrl: './anstellung-standort-form.component.html',
    viewProviders: [formViewProvider],
})
export class AnstellungStandortFormComponent {
    public anstellungStandort = input.required<AnstellungStandortFormModel>();
    public workTimeModels = input.required<WorkTimeModel[]>();

    public readonly autofillExpectedTime = output<number | null>();

    public readonly today = DvbDateUtil.today();
    public readonly workTimeModelUiId = nextId();
    public readonly pensumProzentUiId = nextId();

    private selectedWorkTimeModel = computed(() => {
        const selectedWorkTimeModelId = this.anstellungStandort().workTimeModelId();

        return this.workTimeModels().find(m => m.id === selectedWorkTimeModelId);
    });

    public readonly expectedTotalWeeklyMinutes = computed(() => {
        const selectedWorkTimeModel = this.selectedWorkTimeModel();
        const arbeitspensumProzent = this.anstellungStandort().arbeitspensumProzent();

        if (isNullish(selectedWorkTimeModel) || isNullish(arbeitspensumProzent)) {
            return null;
        }

        return (selectedWorkTimeModel.weeklyMinutes * arbeitspensumProzent) / ONE_HUNDRED;
    });
}
