<form name="rueckerstattungDialogForm"
      #dateDialogForm="ngForm"
      (ngSubmit)="submitForm(dateDialogForm)"
      *transloco="let t"
      novalidate>
    <div class="modal-body">
        <h1>{{ t('COMMON.RUECKERSTATTUNGSBETRAG_AUSZAHLEN') }}</h1>
        <div class="row">
            <div class="col-md-6 col-md-offset-3">
                <dvlib-datepicker-textfield [(ngModel)]="auszahlungsDatum"
                                            name="auszahlungsDatum"
                                            [placeholder]="t('COMMON.AUSZAHLUNGSDATUM')"
                                            required>
                </dvlib-datepicker-textfield>
            </div>
        </div>
        <div class="row">
            <div class="col-md-6 col-md-offset-3 small-abstand-top text-left">
                <div class="checkbox">
                    <label>
                        <input type="checkbox" name="generatePainFile" [(ngModel)]="generatePainFile">
                        {{ t('COMMON.AUSZAHLUNG_PAINFILE_GENERIEREN') }}
                    </label>
                </div>
            </div>
        </div>
        @if (generatePainFile) {
            <div class="row">
                <div class="col-md-6 col-md-offset-3">
                    <textarea [(ngModel)]="zahlungszweck"
                              name="zahlungszweck"
                              [placeholder]="t('COMMON.AUSZAHLUNG_ZAHLUNGSZWECK')"
                              [tooltip]="t('COMMON.AUSZAHLUNG_ZAHLUNGSZWECK')"
                              dvlibElasticTextInput
                              maxlength="140"
                              required>
                    </textarea>
                </div>
            </div>
        }
    </div>
    <div class="modal-footer big-abstand-top">
        <dvlib-button-list>
            <dvlib-loading-button [type]="'confirm'"
                                  [isLoading]="isLoading$ | async">
                <span>{{ t('COMMON.AUSZAHLUNG_BESTAETIGEN') }}</span>
            </dvlib-loading-button>
            <button dvlibButton="link-button" (click)="hide()">
                {{ t('COMMON.ABBRECHEN') }}
            </button>
        </dvlib-button-list>
    </div>
</form>
