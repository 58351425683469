/*
 * Copyright © 2023 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import {AsyncPipe} from '@angular/common';
import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import {FormsModule} from '@angular/forms';
import {BetreuungsVereinbarungsKonfigurationType} from '@dv/kitadmin/models';
import type {CompletableDialogModel} from '@dv/kitadmin/ui';
import {CompletableDialogBase} from '@dv/kitadmin/ui';
import {ButtonListComponent, LoadingButtonComponent} from '@dv/shared/angular';
import type {Persisted} from '@dv/shared/code';
import {TranslocoModule} from '@jsverse/transloco';
import {DvbI18nPipe} from '../../filter/dvbI18n.pipe';

export interface BetreuungsVereinbarungsTypeSelectDialogModel
    extends CompletableDialogModel<Persisted<BetreuungsVereinbarungsKonfigurationType>> {

    types: Persisted<BetreuungsVereinbarungsKonfigurationType>[];
    selectedType: Persisted<BetreuungsVereinbarungsKonfigurationType>;
}

@Component({
    selector: 'dv-betreuungs-vereinbarungs-type-select',
    templateUrl: './betreuungs-vereinbarungs-type-select.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [TranslocoModule, FormsModule, LoadingButtonComponent, DvbI18nPipe, ButtonListComponent, AsyncPipe],
})
export class BetreuungsVereinbarungsTypeSelectComponent
    extends CompletableDialogBase<BetreuungsVereinbarungsKonfigurationType>
    implements BetreuungsVereinbarungsTypeSelectDialogModel {

    @Input() public types!: Persisted<BetreuungsVereinbarungsKonfigurationType>[];
    @Input() public selectedType!: Persisted<BetreuungsVereinbarungsKonfigurationType>;

    public submitForm(): void {
        this.submit(this.selectedType);
    }
}
