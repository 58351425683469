/*
 * Copyright © 2020 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import {WILDCARD_TOKEN} from '@dv/shared/angular';
import {PERMISSION} from '@dv/shared/authentication/model';
import type {Ng1StateDeclaration} from '@uirouter/angularjs';
import type {Transition} from '@uirouter/core';
import type angular from 'angular';
import type {Schliesstage} from './models/Schliesstage';
import type {SchliesstageService} from './service/schliesstageService';

export const BASE_STATE = {
    name: 'base.schliesstage',
    url: '/schliesstage/{id:[0-9]+}',
    template: '<dvb-schliesstage-main schliesstage="$resolve.schliesstage"></dvb-schliesstage-main>',
    resolve: {
        schliesstage: [
            'schliesstageService', '$transition$',
            (
                schliesstageService: SchliesstageService,
                $transition$: Transition,
            ): angular.IPromise<Schliesstage | null> =>
                schliesstageService.get($transition$.params().id, {cache: true}),
        ],
        $title: ['schliesstage', (schliesstage: Schliesstage): string => schliesstage.getDisplayName()],
    },
    data: {
        permission: PERMISSION.SHARED.SCHLIESSTAGE_VIEW_ANY,
    },
} satisfies Ng1StateDeclaration;

export const BASE_STATE_CREATE = {
    name: 'base.schliesstage-erstellen',
    url: '/schliesstage-erstellen',
    template: '<dvb-schliesstage-erstellen></dvb-schliesstage-erstellen>',
    resolve: {},
    data: {
        permission: PERMISSION.SHARED.SCHLIESSTAGE_MANAGE + WILDCARD_TOKEN,
    },
} satisfies Ng1StateDeclaration;
