<div class="dv-angestellte-stundenerfassung-summary-entry" *transloco="let t">

    <h2 class="normal-abstand-top">
        <span class="small-abstand-left">{{ kind | displayName }}</span>
    </h2>

    <div class="table-responsive">
        <table class="table text-nowrap text-right">
            <thead>
            <tr>
                <th>{{ t('COMMON.DATUM') }}</th>
                <th>{{ t('PERSONAL.STUNDENERFASSUNG.STUNDEN') }}</th>
                @for (param of tarifParams; track param.id) {
                    <th>{{ param.name }}</th>
                }
            </tr>
            </thead>
            <tbody>
                @for (entry of cleanedBetreuungen; track entry) {
                    <tr>
                        <td>{{ entry.stichtag.format('D.M.YYYY') }}</td>
                        <td>{{ entry.stunden.getValue() }}</td>
                        @for (param of tarifParams; track param.id) {
                            <td>
                                @if (entry.spesenByTarifParamId[param.id]) {
                                    <span>{{ entry.spesenByTarifParamId[param.id].getDisplayValue() }}</span>
                                }
                            </td>
                        }
                    </tr>
                }
            </tbody>
            <tfoot>
            <tr>
                <td></td>
                <td>{{ hourSum }}</td>
                @for (param of tarifParams; track param.id) {
                    <td>{{ paramSums[param.id] || 0 }}</td>
                }
            </tr>
            </tfoot>
        </table>
    </div>

    @for (entry of betreuungWithBemerkung; track entry) {
        <div class="row bemerkung">
            <div class="col-md-2">
                <p class="smaller-abstand-left">{{ entry.stichtag.format('D.M.YYYY') }}</p>
            </div>
            <div class="col-md-10">
                @if (entry.hasBemerkungOriginal()) {
                    <p [tooltip]="t('PERSONAL.STUNDENERFASSUNG.ERFASSUNGS_BEMERKUNG')">
                        <i class="fa fa-comments-o"></i>
                        <span class="smaller-abstand-left">{{ entry.bemerkung.original }}</span>
                    </p>
                }

                @if (entry.hasBemerkungCurrent()) {
                    <p [tooltip]="t('PERSONAL.STUNDENERFASSUNG.KORREKTUR_BEMERKUNG')">
                        <i class="fa fa-comments-o fa-flip-horizontal"></i>
                        <span class="smaller-abstand-left">{{ entry.bemerkung.current }}</span>
                    </p>
                }
            </div>
        </div>
    }

    @if (vertraglicheStunden) {
        <p class="vertragliche-stunden small-abstand-top smaller-abstand-left">
            <span>
                {{ t('PERSONAL.STUNDENERFASSUNG.VERTRAGLICHE_STUNDEN', {stunden: vertraglicheStunden}) }}
            </span>
            @if (hourSum < vertraglicheStunden) {
                <i class="fa fa-exclamation-triangle smaller-abstand-left"
                   [tooltip]="t('PERSONAL.STUNDENERFASSUNG.VERTRAGLICHE_STUNDEN_MISMATCH')">
                </i>
            }
        </p>
    }
</div>
