<div class="search-input dvb-search-entity">
    <script type="text/ng-template" id="searchPreviewTemplate.html">
        <a href=""
           ng-class="{disabled: match.model.isDisabled}"
           ng-disabled="match.model.isDisabled"
           ng-click="match.model.isDisabled ? $event.stopPropagation() : void">
            <dv-search-result-icon [entry]="match.model"></dv-search-result-icon>
            <span ng-bind-html="match.label"></span>
            <!-- das CSS erlaubt nur 1 Badge: mehrere würden übereinander positioniert -->
            <span class="badge search"
                  ng-if="match.model.mandantName && !match.model.isDisabled"
                  title="{{match.model.mandantName}}"
                  ng-bind="match.model.mandantName">
            </span>
            <span class="badge search"
                  ng-if="!match.model.mandantName && match.model.additionalInformation && !match.model.isDisabled"
                  title="{{match.model.additionalInformation}}"
                  ng-bind="match.model.additionalInformation">
            </span>
            <span class="badge search"
                  ng-if="match.model.isDisabled"
                  data-translate="COMMON.HINZUGEFUEGT"
                  uib-tooltip="{{'COMMON.BEREITS_HINZUGEFUEGT' | translate}}"
                  tooltip-placement="bottom"
                  tooltip-append-to-body="true"></span>
        </a>
    </script>

    <form name="searchForm" role="search" novalidate>
        <div class="input-group form-row">
            <input type="text"
                   class="form-control"
                   ng-attr-placeholder="{{vm.placeholderTranslated ? vm.placeholderKey : (vm.placeholderKey | translate)}}"
                   uib-tooltip="{{vm.placeholderTranslated ? vm.placeholderKey : (vm.placeholderKey | translate)}}"
                   tooltip-placement="top-left"
                   ng-model="vm.searchInput"
                   dvb-focus-if="vm.focus"
                   maxlength="150"
                   uib-typeahead="result as result.text for result in vm.searchResults($viewValue)"
                   typeahead-on-select="vm.onSelect($item, $model, $label, $event)"
                   typeahead-template-url="searchPreviewTemplate.html"
                   typeahead-focus-first="true"
                   typeahead-loading="loadingSearchResults"
                   typeahead-min-length="0"
                   typeahead-select-on-blur="false"
                   typeahead-wait-ms="150">
            <span class="input-group-addon"><i class="fa fa-search"></i></span>
        </div>
    </form>
</div>
