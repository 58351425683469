<form name="planungEmailForm" #planungEmailForm="ngForm" (ngSubmit)="submitForm(planungEmailForm)" novalidate>
    <div class="modal-body">
        <h1 transloco="PERSONAL.ZUWEISUNG.EMAIL_TITLE"></h1>

        <div class="row">
            <div class="col-md-6">
                <dvlib-datepicker-textfield [(ngModel)]="gueltigAb"
                                            (ngModelChange)="fetchRecipients()"
                                            name="gueltigAb"
                                            [placeholder]="'COMMON.GUELTIG_AB' | transloco"
                                            required>
                </dvlib-datepicker-textfield>
            </div>
            <div class="col-md-6">
                <dvlib-datepicker-textfield [(ngModel)]="gueltigBis"
                                            (ngModelChange)="fetchRecipients()"
                                            name="gueltigBis"
                                            [placeholder]="'COMMON.GUELTIG_BIS' | transloco"
                                            required>
                </dvlib-datepicker-textfield>
            </div>
        </div>

        <div class="normal-abstand-top text-left">
            <dvlib-spinner [show]="emailStore.fetchRecipientsStore.isLoading()"/>
            <h3 [transloco]="'PERSONAL.ZUWEISUNG.EMAIL_RECIPIENT_COUNT_MF'"
                [translocoParams]="{count: recipients().length}"
                class="normal-abstand-top">
            </h3>
            @if (recipients().length > 0) {
                <div>
                    @for (angestellte of recipients(); track angestellte.id) {
                        <div class="checkbox">
                            <label>
                                <input type="checkbox"
                                       name="recipient"
                                       [(ngModel)]="selectedRecipients[angestellte.entityId()]"
                                       [ngModelOptions]="{standalone: true}">
                                @if (springerRecipients().length) {
                                    <div class="icons smaller-abstand-right">
                                        @if (angestellte.springer) {
                                            <i class="custom-icon custom-icon-chess-knight"></i>
                                        }
                                    </div>
                                }
                                {{ angestellte | displayName }}
                            </label>
                        </div>
                    }
                    <dvlib-button-list class="small-abstand-top">
                        <button class="btn btn-link" (click)="toggleSelection(false); $event.preventDefault()">
                            {{ 'PERSONAL.ZUWEISUNG.ALLE_AN_ABWAEHLEN' | transloco }}
                        </button>
                        @if (springerRecipients().length) {
                            <button class="btn btn-link" (click)="toggleSelection(true); $event.preventDefault()">
                                <i class="custom-icon custom-icon-chess-knight"></i> {{ 'PERSONAL.ZUWEISUNG.VERTRETUNGSPERSONAL_AN_ABWAEHLEN' | transloco }}
                            </button>
                        }
                    </dvlib-button-list>
                </div>
            }
            @if (missingEmailRecipients().length > 0) {
                <div class="normal-abstand-top">
                    <h3>{{ 'PERSONAL.ZUWEISUNG.ANGESTELLTE_EMAIL_MISSING' | transloco }}</h3>
                    @for (angestellte of emailStore.recipientInfo().missingEmail; track angestellte.id) {
                        <p>
                            <a [uiSref]="angestellteState.name!" [uiParams]="{id: angestellte.id}">
                                {{ angestellte.getDisplayName() }}
                            </a>
                        </p>
                    }
                </div>
            }
        </div>


        <div class="modal-footer normal-abstand-top">
            <dv-submit-cancel-buttons [submitLabel]="'PERSONAL.ZUWEISUNG.SEND_EMAIL'"
                                      [isLoading]="emailStore.sendStore.isLoading()"
                                      (cancel)="this.emailStore.dialogOpen.set(false)"/>
        </div>
    </div>
</form>

