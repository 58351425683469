<div class="row small-abstand-bottom">
    <div class="col-xs-4">
        <p>
            <span>{{ 'KIND.EINGEWOEHNUNG_POSITION.NAME' | transloco }}</span>
            <button dvlibButton="link-button"
                    type="submit"
                    class="pull-right"
                    (click)="deleteEvent.emit()">
                {{ 'COMMON.LOESCHEN' | transloco }}
            </button>
        </p>
        <p>{{ gueltigkeit.gueltigAb | dvLocalDateFormat: 'DD.MM.' }}
            - {{ gueltigkeit.gueltigBis | dvLocalDateFormat: 'DD.MM.' }}</p>
        <p>{{ kitaName }}</p>
    </div>

    <div class="col-xs-8">
        <dv-leistungsposition [leistungsPosition]="leistungsPosition"></dv-leistungsposition>
    </div>
</div>
