/*
 * Copyright © 2020 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import {DvbDateUtil} from '@dv/shared/code';
import angular from 'angular';
import type moment from 'moment';
import type {Anstellung} from '../../models/Anstellung';

const componentConfig: angular.IComponentOptions = {
    transclude: false,
    bindings: {
        anstellungen: '<',
        expandDate: '<?',
    },
    template: require('./dvb-angestellte-anstellung-list.html'),
    controllerAs: 'vm',
};

export class DvbAngestellteAnstellungList implements angular.IController {
    public static $inject: readonly string[] = [];

    public anstellungen!: Anstellung[];
    public expandDate: moment.Moment = DvbDateUtil.today();

}

componentConfig.controller = DvbAngestellteAnstellungList;
angular.module('kitAdmin').component('dvbAngestellteAnstellungList', componentConfig);
