/*
 * Copyright © 2019 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {Mandant} from '@dv/kitadmin/models';
import {handleResponse} from '@dv/shared/angular';
import type {StatisticsService} from '@dv/shared/backend/api/statistics.service';
import type {EntityCounts} from '@dv/shared/backend/model/entity-counts';
import {DvbRestUtil} from '@dv/shared/code';
import angular from 'angular';
import type {DvbDownload} from '../../../base/directive/dvb-download/dvb-download';
import type {BenutzerService} from '../../../common/service/rest/benutzer/benutzerService';
import type {MandantService} from '../../../common/service/rest/mandantService';

const componentConfig: angular.IComponentOptions = {
    transclude: false,
    require: {dvbDownloadCtrl: '^dvbDownload'},
    bindings: {},
    template: require('./dvb-mandanten-overview.html'),
    controllerAs: 'vm',
};

const mandantOrder = ['disabled', 'resettable', '-subscriptionModel', '-timestampErstellt', 'name'];

export class DvbMandantenOverview implements angular.IController {

    public static $inject: readonly string[] = [
        'mandantService',
        'statisticsService',
        'benutzerService',
        '$filter',
        '$q',
        '$translate',
    ];

    public searchText: string = '';
    public mandanten: Mandant[] = [];
    public filteredMandanten: Mandant[] = [];
    public isLoading: boolean = false;
    public currentPage: number = 1;
    public readonly maxNumberPerPage: number = 10;

    public entityCounts?: EntityCounts;

    public isDownloading: boolean = false;
    public showNoDataAvailable: boolean = false;

    private readonly dvbDownloadCtrl!: DvbDownload;

    public constructor(
        private readonly mandantService: MandantService,
        private readonly statisticsService: StatisticsService,
        private readonly benutzerService: BenutzerService,
        private readonly $filter: angular.IFilterService,
        private readonly $q: angular.IQService,
        private readonly $translate: angular.translate.ITranslateService,
    ) {
    }

    public $onInit(): void {
        this.isLoading = true;
        this.mandantService.getAll({includes: '(contextualAdresse,mandantRechnungConfig)'})
            .then(mandanten => {
                this.mandanten = this.$filter('orderBy')(mandanten, mandantOrder);
                this.filteredMandanten = this.mandanten;
            })
            .finally(() => {
                this.isLoading = false;
            });

        this.statisticsService.statistics$().pipe(
            handleResponse({
                next: entityCounts => {
                    this.entityCounts = entityCounts;
                },
            }),
        ).subscribe();
    }

    public filterMandanten(): void {
        this.filteredMandanten = this.$filter('filter')(this.mandanten, this.searchText);
        this.currentPage = 1;
    }

    public getAttributes(mandant: Mandant): string {
        const attributes = [];

        if (mandant.disabled) {
            attributes.push(this.$translate.instant('MANDANT.DISABLED'));
        }

        if (mandant.resettable) {
            attributes.push(this.$translate.instant('COMMON.RESETTABLE'));
        }

        return attributes.join(', ');
    }

    public getCSVExport(): void {
        this.isDownloading = true;

        this.benutzerService.getCSVExport()
            .then(tempBlob => {
                this.dvbDownloadCtrl.downloadFileByUrl(tempBlob);
            })
            .catch(error => {
                if (DvbRestUtil.hasNoData(error)) {
                    this.showNoDataAvailable = true;

                    return this.$q.resolve();
                }

                return this.$q.reject(error);
            })
            .finally(() => {
                this.isDownloading = false;
            });
    }
}

componentConfig.controller = DvbMandantenOverview;
angular.module('kitAdmin').component('dvbMandantenOverview', componentConfig);
