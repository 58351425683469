/*
 * Copyright © 2023 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import {AsyncPipe, DecimalPipe, formatNumber} from '@angular/common';
import type {Signal} from '@angular/core';
import {ChangeDetectionStrategy, Component, computed, Inject, Input, LOCALE_ID} from '@angular/core';
import {toSignal} from '@angular/core/rxjs-interop';
import type {AbstractKontaktpersonZahlung, Gebuehr, KinderOrt, Kontaktperson} from '@dv/kitadmin/models';
import {DialogService} from '@dv/kitadmin/ui';
import {DisplayNamePipe} from '@dv/shared/angular';
import {checkPresent, Persisted} from '@dv/shared/code';
import {Translator} from '@dv/shared/translator';
import {TranslocoModule} from '@jsverse/transloco';
import {UIRouterModule} from '@uirouter/angular';
import {StateService} from '@uirouter/core';
import {BsDropdownModule} from 'ngx-bootstrap/dropdown';
import type {Observable} from 'rxjs';
import {map, Subject, switchMap} from 'rxjs';
import {KontaktpersonService} from '../../../common/service/rest/kontaktperson.service';
import {KontaktpersonenZahlungService} from '../../../common/service/rest/kontaktpersonen-zahlung.service';
import {KONTAKTPERSON_STATES} from '../../kontaktperson-states';

type GebuehrenByKinderOrt = { id: string; value: Persisted<Gebuehr>[] };

@Component({
    selector: 'dv-kontaktperson-depots',
    templateUrl: './kontaktperson-depots.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        AsyncPipe,
        BsDropdownModule,
        DecimalPipe,
        TranslocoModule,
        UIRouterModule,
        DisplayNamePipe,
    ],
})
export class KontaktpersonDepotsComponent {

    @Input()
    public set kontaktperson(kontaktperson: Persisted<Kontaktperson>) {
        this.kontaktperson$.next(kontaktperson);
    }

    public kinderOrteById: Record<string, KinderOrt> = {};

    private kontaktperson$ = new Subject<Persisted<Kontaktperson>>();
    private gebuehrenByKinderOrtId$: Observable<GebuehrenByKinderOrt[]> = this.kontaktperson$.pipe(
        switchMap(k => this.kontaktpersonService.getUnsettledDepots$(k.id, {includes: '(kita)'})),
        map(gebuehren => this.mapByKitaId(gebuehren)),
    );

    public gebuehrenByKinderOrtId: Signal<GebuehrenByKinderOrt[]> = toSignal(this.gebuehrenByKinderOrtId$,
        {initialValue: []});

    public hasNoContent = computed(() => this.gebuehrenByKinderOrtId().length < 1);

    public readonly kontaktpersonStates = KONTAKTPERSON_STATES;

    public constructor(
        private readonly dialogService: DialogService,
        private readonly kontaktpersonenZahlungService: KontaktpersonenZahlungService,
        private readonly kontaktpersonService: KontaktpersonService,
        private readonly stateService: StateService,
        private readonly translator: Translator,
        @Inject(LOCALE_ID) private readonly locale: string,
    ) {
    }

    public deleteGebuehr(gebuehr: Persisted<Gebuehr>): void {
        const id = gebuehr.id;

        const gebuehrType = this.translator.instant('COMMON.GEBUEHR.DEPOT');
        const gebuehrText = this.getZahlungsText(gebuehrType, gebuehr);

        const deleteAction: () => Observable<unknown> = () => {
            const deleteGebuehr$ = this.kontaktpersonenZahlungService.deleteGebuehr$(id)
                .pipe(switchMap(_ => this.stateService.reload()));

            return deleteGebuehr$;
        };

        this.dialogService.openDeleteDialog({
            entityText: gebuehrText,
            confirm: deleteAction,
        });
    }

    private getZahlungsText(typeText: string, kontaktpersonZahlung: AbstractKontaktpersonZahlung): string {
        return this.translator.instant('COMMON.ZAHLUNG_TEXT', {
            dtype: typeText,
            datum: kontaktpersonZahlung.datum?.format('D.M.YYYY'),
            betrag: formatNumber(kontaktpersonZahlung.betrag!, this.locale, '1.2-2'),
        });
    }

    private mapByKitaId(gebuehren: Persisted<Gebuehr>[]): GebuehrenByKinderOrt[] {
        const gebuehrenByKinderOrtId: Record<string, Persisted<Gebuehr>[]> = {};

        gebuehren.forEach(gebuehr => {
            const kinderOrtId = gebuehr.kitaId!;
            if (!gebuehrenByKinderOrtId[kinderOrtId]) {
                gebuehrenByKinderOrtId[kinderOrtId] = [];
            }

            if (!this.kinderOrteById[kinderOrtId]) {
                this.kinderOrteById[kinderOrtId] = checkPresent(gebuehr.kita);
            }

            gebuehrenByKinderOrtId[kinderOrtId].push(gebuehr);
        });

        return Object.entries(gebuehrenByKinderOrtId)
            .map(([id, value]) => ({id, value}));
    }
}
