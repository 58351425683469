/*
 * Copyright © 2023 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import {CommonModule} from '@angular/common';
import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import {FormsModule} from '@angular/forms';
import {Rechnung} from '@dv/kitadmin/models';
import type {CompletableDialogModel} from '@dv/kitadmin/ui';
import {CompletableDialogBase} from '@dv/kitadmin/ui';
import {ButtonListComponent, LoadingButtonComponent} from '@dv/shared/angular';
import {TranslocoModule} from '@jsverse/transloco';

export interface ConfirmAusgleichZahlungDialogModel extends CompletableDialogModel<null> {
    rechnung: Rechnung;
    text: string;
}

@Component({
    selector: 'dv-confirm-ausgleich-zahlung',
    templateUrl: './confirm-ausgleich-zahlung.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [CommonModule, TranslocoModule, FormsModule, LoadingButtonComponent, ButtonListComponent],
})
export class ConfirmAusgleichZahlungComponent extends CompletableDialogBase<null>
    implements ConfirmAusgleichZahlungDialogModel {

    @Input() public rechnung!: Rechnung;
    @Input() public text!: string;
}
