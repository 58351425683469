/*
 * Copyright © 2022 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import {NgModule} from '@angular/core';
import type angular from 'angular';
import {AuthService} from '../authentication/service/authService';
import {AppConfigService} from '../common/service/rest/appConfigService';
import {MonatsBelegungService} from '../kind/service/monatsBelegungService';

@NgModule({
    providers: [
        {
            provide: AuthService,
            useFactory: (i: angular.auto.IInjectorService) => i.get<AuthService>('authService'),
            deps: ['$injector'],
        },
        {
            provide: AppConfigService,
            useFactory: (i: angular.auto.IInjectorService) => i.get<AppConfigService>('appConfigService'),
            deps: ['$injector'],
        },
        {
            provide: MonatsBelegungService,
            useFactory: (i: angular.auto.IInjectorService) => i.get<MonatsBelegungService>('monatsBelegungService'),
            deps: ['$injector'],
        },
    ],
})
export class CommonUpgradeModule {
}
