<div class="dvb-firma-kontingent-value-form">
    <div>
        <input type="number"
               name="plaetze"
               dvb-select-on-click
               class="input-anzahl-plaetze-value form-control negativ-abstand-top"
               ng-attr-placeholder="{{'COMMON.ANZAHL_PLAETZE' | translate}}"
               uib-tooltip="{{'COMMON.ANZAHL_PLAETZE' | translate}}"
               tooltip-placement="top-left"
               ng-model="vm.firmenKontingentValue.plaetze"
               required
               ng-pattern="/^\d+(\.\d[05]?)?$/"
               min="0">

        <div class="form-row"
             ng-show="vm.firmenKontingentValue.kitas && vm.firmenKontingentValue.kitas.length > 0">
            <div class="selected-entity"
                 ng-repeat="kita in vm.firmenKontingentValue.kitas | orderBy:'name'"
                 ng-init="searchResultEntry = kita.toSearchResultEntry()">
                <div class="col-xs-8 entity">
                    <dv-search-result-icon [entry]="searchResultEntry"></dv-search-result-icon>
                    <span ng-bind="kita.getDisplayName()"></span>
                </div>
                <div class="col-xs-4 edit">
                    <a href="" ng-click="vm.removeKita(kita)"
                       data-translate="COMMON.LOESCHEN">
                    </a>
                </div>
            </div>
        </div>
        <dvb-search-entity entity-to-search="KINDERORT"
                           on-select="vm.addKita(item)"
                           on-select-clear="true"
                           placeholder-key="COMMON.KINDERORT.AUSWAEHLEN"
                           disabled-entries="vm.firmenKontingentValue.kitas"
                           ng-class="{ 'ng-invalid' : !vm.firmenKontingentValue.isKitaSet() }">
        </dvb-search-entity>

        <dvb-search-get-single-entity entity-to-search="TARIF"
                                      display-label="FIRMA.TITLE_TARIF"
                                      ng-model="vm.tarifSearchResultEntry"
                                      ng-change="vm.updateTarif()"
                                      placeholder-key="COMMON.TARIF.AUSWAEHLEN"
                                      as-col="true">
        </dvb-search-get-single-entity>
    </div>
</div>
