/*
 * Copyright © 2021 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {KinderOrt} from '@dv/kitadmin/models';
import {PERMISSION} from '@dv/shared/authentication/model';
import type {TerminTypeService} from '@dv/shared/backend/api/termin-type.service';
import type {JaxTerminType} from '@dv/shared/backend/model/jax-termin-type';
import type {Persisted, RequiredPermission} from '@dv/shared/code';
import type {Ng2StateDeclaration} from '@uirouter/angular';
import type {Ng1StateDeclaration} from '@uirouter/angularjs';
import type {Transition} from '@uirouter/core';
import type angular from 'angular';
import {firstValueFrom, map} from 'rxjs';
import { UrlParameterTypes } from '@dv/kitadmin/core/uirouter';
import type {Ausbildung} from '../personal/anstellung/models/Ausbildung';
import type {AusbildungService} from '../personal/anstellung/service/ausbildungService';
import type {BetreuungsSchluessel} from '../personal/betreuungs-schluessel/models/BetreuungsSchluessel';
import {
    BetreuungsSchluesselKinderOrtAssignmentController,
} from '../personal/betreuungs-schluessel/models/BetreuungsSchluesselKinderOrtAssignmentController';
import type {
    KinderOrtBetreuungsSchluessel,
} from '../personal/betreuungs-schluessel/models/KinderOrtBetreuungsSchluessel';
import type {BetreuungsSchluesselService} from '../personal/betreuungs-schluessel/service/betreuungsSchluesselService';
import type {PersonalKonfigurationApiService} from '../personal/konfiguration/personal-konfiguration-api.service';
import {PersonalKonfiguration} from '../personal/konfiguration/PersonalKonfiguration';
import {PersonalplanungComponent} from './component/personal/personalplanung/personalplanung.component';
import type {KinderOrtBetreuungsSchluesselService} from './service/kinderOrtBetreuungsSchluesselService';

const PERSONALBEDARF_STATE_NAME = 'base.kinderort.personal .bedarf';

/* eslint-disable sonarjs/no-duplicate-string */
export const PERSONALPLANUNG_STATE = {
    name: 'base.kinderort.personalplanung',
    url: '/personalplanung?:date:displayMode',
    component: PersonalplanungComponent,
    data: {
        subtitle: 'COMMON.NAV_PERSONALPLANUNG',
        permission(toParams: { id: string }): RequiredPermission {
            return [PERMISSION.MODULE.PERSONALPLANUNG, PERMISSION.PERSONAL.VIEW + toParams.id];
        },
    },
    params: {
        date: {
            type: UrlParameterTypes.MOMENT_DATE,
            dynamic: true,
        },
        displayMode: {
            type: 'string',
            dynamic: true,
        },
    },
} satisfies Ng2StateDeclaration;
export const KINDERORT_PERSONAL_STATE = {
    name: 'base.kinderort.personal',
    url: '/personal',
    redirectTo: PERSONALBEDARF_STATE_NAME,
    abstract: true,
    resolve: {
        ausbildungenFlat: [
            'ausbildungService',
            (ausbildungService: AusbildungService): angular.IPromise<Ausbildung[]> => ausbildungService.getFlat(),
        ],
    },
    data: {
        permission: PERMISSION.MODULE.PERSONALPLANUNG,
    },
} satisfies Ng1StateDeclaration;

export const PERSONAL_KONFIGURATION_STATE = {
    name: 'base.kinderort.personalkonfiguration',
    url: '/personalkonfiguration',
    component: 'dvbKinderortPersonalKonfiguration',
    resolve: {
        konfigurationen: [
            'personalKonfigurationApiService', '$transition$', (
                personalKonfigurationService: PersonalKonfigurationApiService,
                $transition$: Transition,
            ): angular.IPromise<PersonalKonfiguration[]> =>
                firstValueFrom(personalKonfigurationService.getForKinderOrt$($transition$.params().id)),
        ],
        newKonfiguration: [
            '$transition$', (
                $transition$: Transition,
            ): PersonalKonfiguration => new PersonalKonfiguration(null, $transition$.params().id),
        ],
    },
    data: {
        subtitle: 'PERSONAL.KONFIGURATION.SINGULAR',
        permission(toParams: { id: string }): string {
            return PERMISSION.PERSONAL.ADMINISTRATE + toParams.id;
        },
    },
} satisfies Ng1StateDeclaration;

export const DIENST_ADMINISTRATION_STATE = {
    name: 'base.kinderort.dienstadministration',
    url: '/dienstadministration',
    template: '<dv-dienst-administration [kinder-ort]="$resolve.kinderOrt"></dv-dienst-administration>',
    data: {
        subtitle: 'PERSONAL.DIENSTKONFIGURATION.TITLE',
        permission(toParams: { id: string }): string[] {
            return [PERMISSION.PERSONAL.ADMINISTRATE + toParams.id, PERMISSION.MODULE.PERSONALPLANUNG];
        },
    },
} satisfies Ng1StateDeclaration;

export const BETREUUNGS_SCHLUESSEL_STATE = {
    name: 'base.kinderort.betreuungsschluessel',
    url: '/betreuungsschluessel',
    component: 'dvbBetreuungsSchluesselAssignment',
    resolve: {
        kinderOrtBetreuungsSchluessel: [
            'kinderOrtBetreuungsSchluesselService', '$transition$',
            (
                kinderOrtBetreuungsSchluesselService: KinderOrtBetreuungsSchluesselService,
                $transition$: Transition,
            ): angular.IPromise<KinderOrtBetreuungsSchluessel[]> =>
                kinderOrtBetreuungsSchluesselService.getAllByKinderOrt($transition$.params().id),
        ],
        betreuungsSchluessel: [
            'betreuungsSchluesselService', (
                betreuungsSchluesselService: BetreuungsSchluesselService,
            ): angular.IPromise<BetreuungsSchluessel[]> =>
                betreuungsSchluesselService.getAll(),
        ],
        controller: [
            'kinderOrtBetreuungsSchluesselService',
            'kinderOrtBetreuungsSchluessel',
            'betreuungsSchluessel',
            'kita',
            (
                kinderOrtBetreuungsSchluesselService: KinderOrtBetreuungsSchluesselService,
                kinderOrtBetreuungsSchluessel: KinderOrtBetreuungsSchluessel[],
                betreuungsSchluessel: BetreuungsSchluessel[],
                kita: Persisted<KinderOrt>,
            ): BetreuungsSchluesselKinderOrtAssignmentController =>
                new BetreuungsSchluesselKinderOrtAssignmentController(
                    kita,
                    kinderOrtBetreuungsSchluessel,
                    betreuungsSchluessel,
                    kinderOrtBetreuungsSchluesselService),
        ],
    },
    data: {
        subtitle: 'PERSONAL.BETREUUNGS_SCHLUESSEL.SINGULAR',
        permission(toParams: { id: string }): RequiredPermission {
            return PERMISSION.PERSONAL.MANAGE + toParams.id;
        },
    },
} satisfies Ng1StateDeclaration;

export const TERMIN_STATE = {
    name: 'base.kinderort.termine',
    url: '/termine',
    component: 'dvbTermine',
    bindings: {
        kinderOrt: 'kita',
    },
    resolve: {
        terminTypes: [
            'terminTypeService', (
                terminTypeService: TerminTypeService,
            ): Promise<JaxTerminType[]> =>
                firstValueFrom(terminTypeService.getAll$().pipe(map(p => p.items))),
        ],
    },
    data: {
        subtitle: 'PERSONAL.TERMIN.TITLE',
        permission(toParams: { id: string }): RequiredPermission {
            return PERMISSION.PERSONAL.VIEW + toParams.id;
        },
    },
} satisfies Ng1StateDeclaration;

export const KINDERORT_PERSONAL_STATES = {
    PERSONALPLANUNG_STATE,
    KINDERORT_PERSONAL_STATE,
    PERSONAL_KONFIGURATION_STATE,
    DIENST_ADMINISTRATION_STATE,
    BETREUUNGS_SCHLUESSEL_STATE,
    TERMIN_STATE,
};
