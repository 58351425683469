/*
 * Copyright © 2024 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import {signal} from '@angular/core';
import type {WorkTimeModel} from '@dv/kitadmin/models';
import type {JaxTimeRange} from '@dv/shared/backend/model/jax-time-range';
import type {IPersistable, SearchResultEntry} from '@dv/shared/code';
import {DayOfWeek, DvbRestUtil, isPresent, TimeRange} from '@dv/shared/code';
import type {
    AusbildungNodeCheckboxFormModel,
} from '../../../component/ausbildung-node-checkbox/ausbildung-node-checkbox.model';
import {getSelectedAusbildungen} from '../../../component/ausbildung-node-checkbox/ausbildung-node-checkbox.util';
import {Anstellung} from '../../models/Anstellung';
import type {
    AnstellungFormModel,
    AnstellungStandortFormModel,
    DayOfWeekAnstellungen,
} from '../../models/anstellung-form-model';
import {AnstellungStandort} from '../../models/AnstellungStandort';
import {DailyAnstellung} from '../../models/DailyAnstellung';

export function toFormModel(
    anstellung: Anstellung | undefined,
    ausbildungen: AusbildungNodeCheckboxFormModel[],
    workTimeModels: WorkTimeModel[],
): Partial<AnstellungFormModel & IPersistable> {
    if (!anstellung) {
        const standort = createStandort(workTimeModels);

        return {
            ausbildungen,
            anstellungStandorte: signal([anstellungStandortToModel(workTimeModels, standort)]),
        };
    }

    return {
        id: anstellung.id ?? null,
        gueltigAb: anstellung.gueltigAb ?? undefined,
        gueltigBis: anstellung.gueltigBis ?? undefined,
        ausbildungen,
        anstellungStandorte: signal(anstellung.anstellungStandorte
            .map(standort => anstellungStandortToModel(workTimeModels, standort))),
    };
}

export function anstellungStandortToModel(
    workTimeModels: WorkTimeModel[],
    anstellungStandort?: AnstellungStandort,
): AnstellungStandortFormModel {

    const standort = anstellungStandort ?? createStandort(workTimeModels);

    return {
        id: standort.id,
        workTimeModelId: signal(standort.workTimeModelId),
        arbeitspensumProzent: signal(standort.arbeitspensumProzent),
        alleKinderOrte: standort.alleKinderOrte,
        alleFraktionen: standort.alleFraktionen,
        selectedKinderOrte: standort.kinderOrte.map(kinderOrt => kinderOrt.toSearchResultEntry()),
        selectedFraktionen: standort.fraktionen.map(fraktion => fraktion.toSearchResultEntry()),

        dailyAnstellungen: dailyAnstellungenToModel(standort.dailyAnstellungen),
        vertretungsperson: standort.vertretungsperson,
    };
}

function dailyAnstellungenToModel(dailyAnstellungen: DailyAnstellung[]): { [day: string]: DayOfWeekAnstellungen } {

    return Object.values(DayOfWeek).reduce<{ [day: string]: DayOfWeekAnstellungen }>((acc, day) => {
        const current = dailyAnstellungen.find(da => da.dayOfWeek === day);
        acc[day] = {
            zeiten: current?.anstellungZeiten ?? [],
            nichtVerfuegbar: signal(current?.nichtVerfuegbar ?? false),
            expectedMinutes: signal(current?.expectedMinutes),
        };

        return acc;
    }, {});
}

export function formModelToAnstellung(model: Partial<AnstellungFormModel & IPersistable>): Anstellung {
    const anstellung = new Anstellung();

    anstellung.id = model.id ?? null;
    anstellung.gueltigAb = model.gueltigAb ?? null;
    anstellung.gueltigBis = model.gueltigBis ?? null;

    anstellung.anstellungStandorte = (model.anstellungStandorte!() ?? []).map(standortModel =>
        modelToAnstellungStandort(standortModel));

    anstellung.ausbildungIds = getSelectedAusbildungen(model.ausbildungen ?? []);

    return anstellung;
}

function modelToAnstellungStandort(model: AnstellungStandortFormModel): AnstellungStandort {
    const anstellungStandort = new AnstellungStandort();
    anstellungStandort.id = model.id ?? null;
    anstellungStandort.alleKinderOrte = model.alleKinderOrte;
    anstellungStandort.alleFraktionen = model.alleFraktionen;
    anstellungStandort.vertretungsperson = model.vertretungsperson;
    anstellungStandort.workTimeModelId = model.workTimeModelId();
    anstellungStandort.arbeitspensumProzent = model.arbeitspensumProzent();

    anstellungStandort.dailyAnstellungen = modelToDailyAnstellungen(model.dailyAnstellungen);
    anstellungStandort.kinderOrtIds =
        model.alleKinderOrte ? [] : model.selectedKinderOrte.map((k: SearchResultEntry) => k.id);
    anstellungStandort.fraktionIds =
        model.alleFraktionen ? [] : model.selectedFraktionen.map((f: SearchResultEntry) => f.id);

    return anstellungStandort;
}

function modelToDailyAnstellungen(
    jaxDailyAnstellungen: { [dayOfWeek: string]: DayOfWeekAnstellungen } | undefined,
): DailyAnstellung[] {
    const dailyAnstellungen: DailyAnstellung[] = [];

    if (!jaxDailyAnstellungen) {
        return dailyAnstellungen;
    }

    Object.keys(jaxDailyAnstellungen).forEach(dayOfWeek => {
        if (dailyAnstellungen) {
            const zeiten = jaxDailyAnstellungen[dayOfWeek].zeiten
                .map(zeit => new TimeRange(
                    DvbRestUtil.localeHHMMTimeToMoment(zeit.von),
                    DvbRestUtil.localeHHMMTimeToMoment(zeit.bis)))
                .filter(zeit => zeit.hasValue());

            const jaxZeiten: JaxTimeRange[] = [];
            zeiten.forEach(zeit => {
                if (zeit.hasValue()) {
                    const jaxTimeRange = {
                        von: DvbRestUtil.momentTolocaleHHMMTime(zeit.von)!,
                        bis: DvbRestUtil.momentTolocaleHHMMTime(zeit.bis)!,
                    };
                    jaxZeiten.push(jaxTimeRange);
                }
            });
            const expectedMinutes = jaxDailyAnstellungen[dayOfWeek].expectedMinutes();
            const nichtVerfuegbar = jaxDailyAnstellungen[dayOfWeek].nichtVerfuegbar();

            if (jaxZeiten.length > 0 || nichtVerfuegbar || isPresent(expectedMinutes)) {
                dailyAnstellungen.push(new DailyAnstellung(
                    null,
                    dayOfWeek as DayOfWeek,
                    jaxZeiten,
                    expectedMinutes,
                    nichtVerfuegbar));
            }
        }
    });

    return dailyAnstellungen;
}

function createStandort(workTimeModels: WorkTimeModel[]): AnstellungStandort {
    const standort = new AnstellungStandort();
    standort.alleFraktionen = true;
    standort.workTimeModelId =
        workTimeModels.length === 1 ? workTimeModels[0].id : workTimeModels.find(m => m.mandantDefault)?.id;

    return standort;
}
