/*
 * Copyright © 2023 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import {NgClass, NgStyle} from '@angular/common';
import {
    AfterViewInit,
    ChangeDetectionStrategy,
    Component,
    computed,
    ElementRef,
    EventEmitter,
    Input,
    NgZone,
    OnDestroy,
    Output,
    Renderer2,
    Signal,
    TemplateRef,
    ViewChild,
} from '@angular/core';
import {OverlayDirective} from '@dv/kitadmin/ui';
import {DatepickerTextfieldComponent, DisplayNamePipe, DvTimeFormatPipe} from '@dv/shared/angular';
import {isPresent} from '@dv/shared/code';
import {TranslocoModule} from '@jsverse/transloco';
import {TooltipDirective, TooltipModule} from 'ngx-bootstrap/tooltip';
import {CalendarEvent} from '../../model/CalendarEvent';
import type {ResizeEvent} from '../../model/ResizeEvent';
import type {ResizeType} from '../../model/ResizeType';
import {TimelineCalendarService} from '../../service/timeline-calendar.service';

@Component({
    selector: 'dv-timeline-event',
    standalone: true,
    imports: [
        TooltipModule,
        NgStyle,
        NgClass,
        DvTimeFormatPipe,
        DisplayNamePipe,
        TranslocoModule,
        OverlayDirective,
        DatepickerTextfieldComponent,
    ],
    templateUrl: './timeline-event.component.html',
    styleUrl: './timeline-event.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TimelineEventComponent implements AfterViewInit, OnDestroy {

    @Input({required: true}) public event!: CalendarEvent;
    @Input() public isOpen?: boolean = false;
    @Input() public editOverlayOpened: boolean = false;
    @Input() public editOverlayTemplate?: TemplateRef<Element>;

    @Output() public readonly resizeEvent: EventEmitter<ResizeEvent> = new EventEmitter();
    @Output() public readonly resizeComplete: EventEmitter<void> = new EventEmitter();
    @Output() public readonly deleteEvent: EventEmitter<CalendarEvent> = new EventEmitter();
    @Output() public readonly editEvent: EventEmitter<{
        element: Element;
        event: CalendarEvent;
    }> = new EventEmitter();
    @Output() public readonly closeEditOverlay: EventEmitter<void> = new EventEmitter();

    @ViewChild('resizeLeft')
    public resizeLeft!: ElementRef;
    @ViewChild('resizeLeftTooltip')
    public resizeLeftTooltip!: TooltipDirective;
    @ViewChild('resizeRight')
    public resizeRight!: ElementRef;
    @ViewChild('resizeRightTooltip')
    public resizeRightTooltip!: TooltipDirective;
    @ViewChild('moveItem')
    public moveItem!: ElementRef;

    public editable: Signal<boolean> = computed(() => this.layerConfig()?.editable === true);
    public deletable: Signal<boolean> = computed(() => this.layerConfig()?.deletable === true);
    private resizable: Signal<boolean> = computed(() => this.layerConfig()?.resizable === true);
    private moveable: Signal<boolean> = computed(() => this.layerConfig()?.movable === true);
    public pointerEvents = computed(() => this.resizable() ? 'auto' : 'none');
    public moveHandleCursor = computed(() => this.moveable() ? 'move' : 'default');
    public cssClasses = computed(() => {
        const layerConfig = this.layerConfig();
        const custom = layerConfig?.customCssClass ? {[layerConfig.customCssClass]: true} : {};

        return {
            ['no-border-radius']: layerConfig?.disableBorderRadius,
            ['full-day-blocks']: this.timelineCalendarService.fullDayBlocks(),
            ...custom,
        };
    });
    public tooltipEnabled = computed(() => !this.timelineCalendarService.resizeActive());
    private layerConfig = computed(() => this.timelineCalendarService.layerConfig().get(this.event.layer));

    private resizeStartPos?: number;

    private unlistenMouseDown: Map<ResizeType, () => void> = new Map();
    private unlistenMouseMove?: () => void;
    private unlistenMouseUp?: () => void;

    public constructor(
        public readonly timelineCalendarService: TimelineCalendarService,
        private readonly zone: NgZone,
        private readonly renderer: Renderer2,
    ) {
    }

    public ngAfterViewInit(): void {
        this.zone.runOutsideAngular(() => {
            this.handleMouseEvents(this.resizeLeft, 'start');
            this.handleMouseEvents(this.resizeRight, 'end');
            this.handleMouseEvents(this.moveItem, 'move');
        });
    }

    public ngOnDestroy(): void {
        const startUnlisten = this.unlistenMouseDown.get('start');
        if (isPresent(startUnlisten)) {
            startUnlisten();
        }
        const endUnlisten = this.unlistenMouseDown.get('end');
        if (isPresent(endUnlisten)) {
            endUnlisten();
        }
    }

    private handleMouseEvents(elem: ElementRef, direction: ResizeType): void {
        const resizable = this.resizable();
        const moveable = this.moveable();

        const unlisten = this.renderer.listen(elem.nativeElement, 'mousedown', mouseDownEvent => {
            if ((direction === 'start' || direction === 'end') && !resizable) {
                return;
            }
            if (direction === 'move' && !moveable) {
                return;
            }

            this.resizeStartPos = mouseDownEvent.clientX;
            mouseDownEvent.preventDefault();
            this.findTooltip(direction)?.show();

            this.unlistenMouseMove = this.renderer.listen('document', 'mousemove', event => {
                event.preventDefault();
                const posX = event.clientX;
                this.resizeEvent.emit({type: direction, pixels: posX - this.resizeStartPos!});
            });

            this.unlistenMouseUp = this.renderer.listen('document', 'mouseup', () => {
                this.unlistenMouseMove?.();
                this.unlistenMouseUp?.();
                this.zone.run(() => this.resizeComplete.emit());
                this.findTooltip(direction)?.hide();
            });
        });

        this.unlistenMouseDown.set(direction, unlisten);
    }

    private findTooltip(direction: ResizeType): TooltipDirective | undefined {
        if (direction === 'start' || direction === 'end') {
            return direction === 'start' ? this.resizeLeftTooltip : this.resizeRightTooltip;
        }

        return undefined;
    }
}
