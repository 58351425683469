<div class="dvb-malformed-phone-numbers">

    <p data-translate="DATA_INTEGRITY.MALFORMED_PHONE_NUMBERS_TEXT"></p>
    <p>
        <a href="" ng-click="vm.getMalformedPhoneNumbers()" ng-disabled="vm.isDownloading">
            <span data-translate="DATA_INTEGRITY.CSV_DOWNLOAD"></span>
            <dvlib-spinner [show]="vm.isDownloading"></dvlib-spinner>
        </a>
    </p>

    <p ng-show="vm.showNoDataAvailable" data-translate="DATA_INTEGRITY.NO_MALFORMED_PHONE_NUMBERS"></p>
</div>
