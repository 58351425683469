@if (timelineEntry.blobInfos.length > 0) {
    <div class="btn-group" dropdown>
        <div dropdownToggle>
            <dvlib-loading-button [asLink]="true" [spinnerRight]="true" [isLoading]="isLoading$ | async">
                <dvlib-icon iconName="dateien"/>&nbsp;<span class="caret"></span>
            </dvlib-loading-button>
        </div>
        <ul class="dropdown-menu" *dropdownMenu>
            @for (blobInfo of timelineEntry.blobInfos; track blobInfo.id; let last = $last) {
                <li [ngClass]="{'smaller-abstand-bottom': !last}">
                    <a dvlibButton="link-button"
                       href
                       [textContent]="blobInfo.filename"
                       (click)="downloadAttachment(blobInfo); $event.preventDefault();">
                    </a>
                </li>
            }
        </ul>
    </div>
}
