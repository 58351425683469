/*
 * Copyright © 2021 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {GranularKinderOrtPermission, KitaPermission, Mandant} from '@dv/kitadmin/models';
import {PERMISSION} from '@dv/shared/authentication/model';
import type {Persisted} from '@dv/shared/code';
import type {Ng1StateDeclaration} from '@uirouter/angularjs';
import type {Transition} from '@uirouter/core';
import type angular from 'angular';
import {trans} from '../common/i18n/trans';
import type {BenutzerService} from '../common/service/rest/benutzer/benutzerService';
import type {
    GranularKinderOrtPermissionsService,
} from '../common/service/rest/benutzer/granularKinderOrtPermissionsService';
import type {KitaPermissionsService} from '../common/service/rest/benutzer/kitaPermissionsService';
import type {MandantService} from '../common/service/rest/mandantService';
import type {Benutzer} from './model/Benutzer';

/* eslint-disable sonarjs/no-duplicate-string */
export const NEW_BENUTZER_STATE = {
    name: 'base.neuer-benutzer',
    url: '/neuer-benutzer',
    component: 'dvbNeuerBenutzer',
    data: {
        permission: PERMISSION.SHARED.MANAGE_USERS_ANY,
    },
    resolve: {
        $title: [
            '$translate', ($translate: angular.translate.ITranslateService): angular.IPromise<string> =>
                trans($translate, 'COMMON.BENUTZER.ERSTELLEN'),
        ],
        mandant: [
            'mandantService', (mandantService: MandantService): angular.IPromise<Mandant | null> =>
                mandantService.getOwnMandant(),
        ],
    },
} satisfies Ng1StateDeclaration;

export const BENUTZER_STATE = {
    name: 'base.benutzer',
    url: '/benutzer/{id:[0-9]+}',
    abstract: true,
    template: '<dvb-benutzer-main benutzer="$resolve.benutzer"></dvb-benutzer-main>',
    resolve: {
        benutzer: [
            'benutzerService', '$transition$',
            (benutzerService: BenutzerService, $transition$: Transition): angular.IPromise<Persisted<Benutzer>> =>
                benutzerService.get($transition$.params().id, {includes: '(angestellte)'}),
        ],
        $title: ['benutzer', (benutzer: Persisted<Benutzer>): string => benutzer.getDisplayName()],
    },
} satisfies Ng1StateDeclaration;

export const BENUTZER_PROFILE_STATE = {
    name: 'base.benutzer.profil',
    url: '',
    component: 'dvbBenutzerProfil',
} satisfies Ng1StateDeclaration;

export const BENUTZER_PRIVILEGES_STATE = {
    name: 'base.benutzer.rechte',
    url: '/rechte',
    template: '<dvb-benutzer-rechte benutzer="$resolve.benutzer" ' +
        'kita-permissions="$resolve.kitaPermissions" ' +
        'granular-kinder-ort-permissions="$resolve.granularKinderOrtPermissions">' +
        '</dvb-benutzer-rechte>',
    resolve: {
        kitaPermissions: [
            'kitaPermissionsService', '$transition$',
            (
                kitaPermissionsService: KitaPermissionsService,
                $transition$: Transition,
            ): angular.IPromise<KitaPermission[]> =>
                kitaPermissionsService.getKitaPermissions($transition$.params().id),
        ],
        granularKinderOrtPermissions: [
            'granularKinderOrtPermissionsService', '$transition$',
            (
                granularKinderOrtPermissionsService: GranularKinderOrtPermissionsService,
                $transition$: Transition,
            ): angular.IPromise<GranularKinderOrtPermission[]> =>
                granularKinderOrtPermissionsService.getForBenutzer($transition$.params().id),
        ],
    },
} satisfies Ng1StateDeclaration;

export const BENUTZER_STATES = {
    NEW_BENUTZER_STATE,
    BENUTZER_STATE,
    BENUTZER_PROFILE_STATE,
    BENUTZER_PRIVILEGES_STATE,
};
