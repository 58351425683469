<div class="dvb-firma-profil dvb-form">

    <div class="row">
        <div class="col-md-7 big-abstand-bottom">
            <!-- Firmenkontingente-->
            <div ng-if="!vm.firmenKontingente || vm.firmenKontingente.length === 0">
                <h2 data-translate="COMMON.FIRMENKONTINGENT.PLURAL"></h2>

                <p data-translate="FIRMA.KEINE_KONTINGENTE"></p>
            </div>

            <div ng-repeat="firmenKontingent in vm.firmenKontingente | sortKontingenteByStichtag:vm.today"
                 ng-class="{'big-abstand-top': !$first}">
                <div class="header-box">
                    <dvb-editable-label class="h2"
                                        maxlength="255"
                                        ng-model="firmenKontingent.name"
                                        is-valid="vm.isRequired(param)"
                                        ng-attr-placeholder="{{'COMMON.KONTINGENT' | translate}}"
                                        ng-change="vm.saveFirmenKontingent(firmenKontingent)"
                                        dvb-auth
                                        require-permission="kita:betreuung_admin:*"
                                        on-unauthorised-disable>
                    </dvb-editable-label>
                    <div class="text-right small-padding-right" ng-if="!vm.faktoren.show">
                        <div ng-if="firmenKontingent.weeklyPlatzFrequency"
                             class="inline-block"
                             ng-class="{'small-padding-right': firmenKontingent.pensumFaktor}"
                             uib-tooltip="{{'COMMON.WEEKLY_PLATZ_FREQUENCY' | translate}}"
                             tooltip-placement="top-left">
                            <i class="fa fa-repeat"></i>
                            <span ng-bind="firmenKontingent.weeklyPlatzFrequency"></span>
                        </div>
                        <dv-pensum-icon
                            ng-if="firmenKontingent.pensumFaktor"
                            [value]="firmenKontingent.pensumFaktor"
                            [tooltip]="'COMMON.PENSUM_FAKTOR'">
                        </dv-pensum-icon>
                        <button class="btn btn-link"
                                type="button"
                                dvb-auth
                                require-role="SUPPORT_ADMIN"
                                ng-click="vm.editFaktoren(firmenKontingent)">
                            <i class="fa fa-pencil small-abstand-left"
                               uib-tooltip="{{'COMMON.PENSUM_FAKTOR_ANPASSEN' | translate}}"
                               tooltip-placement="top-left"> </i>
                        </button>
                    </div>
                    <form ng-if="vm.faktoren.show" ng-submit="vm.saveFirmenKontingent(firmenKontingent)" novalidate>
                        <input type="number"
                               class="form-control"
                               name="weeklyPlatzFrequency"
                               ng-model="vm.faktoren.weekly"
                               ng-pattern="/^\d*(\.\d{0,2})?$/"
                               min="0.01"
                               ng-attr-placeholder="{{'COMMON.WEEKLY_PLATZ_FREQUENCY' | translate}}"
                               uib-tooltip="{{'COMMON.WEEKLY_PLATZ_FREQUENCY' | translate}}"
                               tooltip-placement="top-left">
                        <input type="number"
                               class="form-control"
                               name="pensumFaktor"
                               ng-model="vm.faktoren.pensum"
                               ng-pattern="/^\d*(\.\d{0,2})?$/"
                               min="0.01"
                               ng-attr-placeholder="{{'COMMON.PENSUM_FAKTOR' | translate}}"
                               uib-tooltip="{{'COMMON.PENSUM_FAKTOR' | translate}}"
                               tooltip-placement="top-left">

                        <dvb-submit-cancel-buttons is-loading="vm.isLoading"
                                                   on-cancel="vm.resetFaktoren()">
                        </dvb-submit-cancel-buttons>
                    </form>
                </div>
                <dvb-firmen-plaetze-verlauf firmen-kontingent-values="firmenKontingent.values"
                                            on-revert-value="vm.revertFirmenKontingentValue(value)">
                </dvb-firmen-plaetze-verlauf>

                <!-- Kontingent Footer -->
                <div class="normal-abstand-top" dvb-auth require-permission="kita:betreuung_admin:*">
                    <dvb-create-firmen-kontingent-value ng-if="vm.showCreateFirmenKontingentValue[$index]"
                                                        on-save-firmen-kontingent-value="vm.saveFirmenKontingentValue(firmenKontingentValue, firmenKontingent); vm.showCreateFirmenKontingentValue = false"
                                                        on-reset-firmen-kontingent-value="vm.showCreateFirmenKontingentValue[$index] = false"
                                                        title-translation-key="FIRMA.KONTINGENT_NEUE_PLAETZE"
                                                        init-kitas="vm.getActiveOrLatestKitasOfKontingent(firmenKontingent)"
                                                        init-tarif="vm.getActiveOrLatestTarifOfKontingent(firmenKontingent)"
                                                        plaetze-placeholder-translation-key="FIRMA.ANZAHL_PLAETZE">
                    </dvb-create-firmen-kontingent-value>

                    <!-- generelle Kontingent actions-->
                    <ul class="list-inline" ng-hide="vm.showCreateFirmenKontingentValue[$index]">
                        <li ng-click="vm.showCreateFirmenKontingentValue[$index] = true">
                            <a href="" data-translate="FIRMA.KONTINGENT_NEUE_PLAETZE"></a>
                        </li>
                        <li>
                            <div ng-if="firmenKontingent" class="">
                                <a href=""
                                   ng-click="vm.terminateFirmenKontingent(firmenKontingent)"
                                   data-translate="FIRMA.KONTINGENT_BEENDEN"></a>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>

            <!-- generelle Firma actions -->
            <div class="big-abstand-top" ng-if="vm.neuesFirmenKontingent">
                <form name="neuesFirmenKontingentForm" ng-submit="vm.saveNeuesFirmenKontingent()" novalidate>
                    <h2 data-translate="FIRMA.NEUES_KONTINGENT"></h2>

                    <div class="normal-abstand-top neues-firmenKontingent">
                        <div class="row">
                            <div class="col-md-12">
                                <input type="text"
                                       class="form-control"
                                       name="firmenKontingentName"
                                       maxlength="255"
                                       ng-model="vm.neuesFirmenKontingent.name"
                                       ng-attr-placeholder="{{'COMMON.NAME' | translate}}"
                                       uib-tooltip="{{'COMMON.NAME' | translate}}"
                                       tooltip-placement="top-left"
                                       required>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-md-6">
                                <!-- Durch ng-if wird der Date Picker immer wieder neu initialisiert -->
                                <dvb-datepicker-text-field ng-model="vm.neuerFirmenKontingentValue.gueltigAb"
                                                           required
                                                           ng-attr-placeholder="{{'COMMON.GUELTIG_AB' | translate}}">
                                </dvb-datepicker-text-field>
                            </div>
                            <div class="col-md-6">
                                <input type="number"
                                       class="form-control"
                                       name="neuesFirmenKontingentPlaetze"
                                       dvb-select-on-click
                                       ng-attr-placeholder="{{'COMMON.ANZAHL_PLAETZE' | translate}}"
                                       uib-tooltip="{{'COMMON.ANZAHL_PLAETZE' | translate}}"
                                       tooltip-placement="top-left"
                                       ng-model="vm.neuerFirmenKontingentValue.plaetze"
                                       required
                                       ng-pattern="/^\d+$/"
                                       min="0">
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-6">
                                <div class="form-row"
                                     ng-show="vm.neuerFirmenKontingentValue.kitas && vm.neuerFirmenKontingentValue.kitas.length > 0">
                                    <div class="row selected-entity"
                                         ng-repeat="kita in vm.neuerFirmenKontingentValue.kitas"
                                         ng-init="searchResultEntry = kita.toSearchResultEntry()">
                                        <div class="col-xs-8 entity">
                                            <dv-search-result-icon [entry]="searchResultEntry"></dv-search-result-icon>
                                            <span ng-bind="kita.getDisplayName()"></span>
                                        </div>
                                        <div class="col-xs-4 edit">
                                            <a href=""
                                               ng-click="vm.neuerFirmenKontingentValue.removeKita(kita)"
                                               data-translate="COMMON.LOESCHEN">
                                            </a>
                                        </div>
                                    </div>
                                </div>
                                <dvb-search-entity entity-to-search="KINDERORT"
                                                   on-select="vm.addKita(vm.neuerFirmenKontingentValue,item)"
                                                   on-select-clear="true"
                                                   placeholder-key="COMMON.KINDERORT.AUSWAEHLEN"
                                                   disabled-entries="vm.neuerFirmenKontingentValue.kitas"
                                                   ng-class="{ 'ng-invalid' : !vm.neuerFirmenKontingentValue.isKitaSet() }">
                                </dvb-search-entity>
                            </div>
                            <div class="col-md-6">
                                <dvb-search-get-single-entity entity-to-search="TARIF"
                                                              ng-model="vm.tarifSearchResultEntry"
                                                              ng-change="vm.updateTarif(vm.neuerFirmenKontingentValue)"
                                                              display-label="FIRMA.TITLE_TARIF"
                                                              placeholder-key="COMMON.TARIF.AUSWAEHLEN"
                                                              as-col="true">
                                </dvb-search-get-single-entity>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-md-6">
                                <input type="number"
                                       class="form-control"
                                       name="pensumFaktor"
                                       ng-model="vm.neuesFirmenKontingent.pensumFaktor"
                                       ng-pattern="/^\d*(\.\d{0,2})?$/"
                                       min="0.01"
                                       ng-attr-placeholder="{{'COMMON.PENSUM_FAKTOR' | translate}}"
                                       uib-tooltip="{{'COMMON.PENSUM_FAKTOR' | translate}}"
                                       tooltip-placement="top-left">
                            </div>
                            <div class="col-md-6">
                                <input type="number"
                                       class="form-control"
                                       name="weeklyPlatzFrequency"
                                       ng-model="vm.neuesFirmenKontingent.weeklyPlatzFrequency"
                                       ng-pattern="/^\d*(\.\d{0,2})?$/"
                                       min="0.01"
                                       ng-attr-placeholder="{{'COMMON.WEEKLY_PLATZ_FREQUENCY' | translate}}"
                                       uib-tooltip="{{'COMMON.WEEKLY_PLATZ_FREQUENCY' | translate}}"
                                       tooltip-placement="top-left">
                            </div>
                        </div>
                    </div>

                    <ul class="list-inline main-actions">
                        <li>
                            <dvb-loading-button type="submit" is-loading="vm.isLoadingSaveNeuesFirmenKontingent">
                                <span data-translate="COMMON.SPEICHERN"></span>
                            </dvb-loading-button>
                        </li>
                        <li>
                            <a href="" data-translate="COMMON.ABBRECHEN" ng-click="vm.resetNewFirmenKontingent()"></a>
                        </li>
                    </ul>
                </form>
            </div>

            <div class="main-actions" dvb-auth require-permission="kita:betreuung_admin:*">
                <ul class="list-inline" ng-hide="vm.neuesFirmenKontingent">
                    <li>
                        <button type="button" class="btn btn-primary" ng-click="vm.neuesFirmenKontingentErfassen()">
                            <span data-translate="FIRMA.NEUES_KONTINGENT"></span>
                        </button>
                    </li>
                    <li>
                        <div class="form-section">
                            <a href="" ng-click="vm.confirmDeleteFirma()" data-translate="FIRMA.FIRMA_DELETE"></a>
                        </div>
                    </li>
                </ul>
            </div>
        </div>

        <div class="col-md-4 col-md-offset-1">
            <dvb-firma-stammdaten firma="vm.firma"></dvb-firma-stammdaten>
        </div>
    </div>

</div>
