<form name="rechnungsKonfigurationBearbeitenForm" (ngSubmit)="submit(null)" novalidate>
    <div class="modal-body">
        <h1 [transloco]="'KINDERORT.KONFIGURATION_EDIT_WARNUNG_COUNT'"
            [translocoParams]="{anzahl: stats.totalRechnungenAffected}">
        </h1>

        @if (stats.excludedRechnungen > 0) {
            <h3 [innerHtml]="'KINDERORT.KONFIGURATION_EDIT_EXCLUDED_RECHNUNGEN' | transloco:{anzahl: stats.excludedRechnungen}"></h3>
        }

        <p [transloco]="'KINDERORT.KONFIGURATION_EDIT_WARNUNG'"></p>
    </div>
    <div class="modal-footer big-abstand-top">
        <dvlib-button-list>
            <dvlib-loading-button [type]="'submit'" [isLoading]="isLoading$ | async">
                <span [transloco]="'COMMON.FORTFAHREN'"></span>
            </dvlib-loading-button>
            <button type="button"
                    class="btn btn-link"
                    [transloco]="'COMMON.ABBRECHEN'"
                    (click)="hide()">
            </button>
        </dvlib-button-list>
    </div>
</form>
