/*
 * Copyright © 2020 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {KinderOrt, MandantFeaturePermissions} from '@dv/kitadmin/models';
import {Kita, MandantFeaturePermission} from '@dv/kitadmin/models';
import type { MandantSearchFilter} from '@dv/kitadmin/search';
import {mandantIdFilter} from '@dv/kitadmin/search';
import {FeatureType} from '@dv/shared/backend/model/feature-type';
import type {SearchResultEntry} from '@dv/shared/code';
import {DvbUtil} from '@dv/shared/code';
import angular from 'angular';

const componentConfig: angular.IComponentOptions = {
    transclude: false,
    bindings: {
        mandantFeatures: '<',
    },
    template: require('./dvb-mandant-feature-form.html'),
    controllerAs: 'vm',
};

export class DvbMandantFeatureForm implements angular.IController {
    public static $inject: readonly string[] = [];

    public readonly mandantFeatures!: MandantFeaturePermissions;

    public mandantFilter?: MandantSearchFilter;
    public availableFeatures!: FeatureType[];

    public $onChanges(onChangesObj: angular.IOnChangesObject): void {
        const mandantFeatures: angular.IChangesObject<MandantFeaturePermissions> = onChangesObj.mandantFeatures;
        if (!mandantFeatures?.currentValue) {
            return;
        }

        this.initFormModel();
    }

    public addFeature(type: FeatureType): void {
        this.mandantFeatures.features.push(new MandantFeaturePermission(type, true));
        DvbUtil.removeFromArray(type, this.availableFeatures);
    }

    public removeFeature(feature: MandantFeaturePermission): void {
        DvbUtil.removeFromArray(feature, this.mandantFeatures.features);
        this.availableFeatures.push(feature.featureType);
    }

    public addKinderOrt(feature: MandantFeaturePermission, item: SearchResultEntry): void {
        const kita = new Kita();
        kita.name = item.text;
        kita.id = item.id;
        feature.kinderOrte.push(kita);
    }

    public removeKinderOrt(feature: MandantFeaturePermission, kinderOrt: KinderOrt): void {
        DvbUtil.removeFromArray(kinderOrt, feature.kinderOrte);
    }

    private initFormModel(): void {
        this.mandantFilter = mandantIdFilter(this.mandantFeatures.mandantId);
        this.availableFeatures = Object.values(FeatureType)
            .filter(f => this.mandantFeatures.features.every(wcf => wcf.featureType !== f));
    }
}

componentConfig.controller = DvbMandantFeatureForm;
angular.module('kitAdmin').component('dvbMandantFeatureForm', componentConfig);
