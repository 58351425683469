<strong>{{ korrespondenz().subject }}</strong>

<dv-collapsible-panel [isLast]="true"
                      [compact]="true">
    <div class="truncate" panel-header>
        {{ headerContent() }}
    </div>

    <ng-container panel-content>
        @if (showEmailContentPlainText()) {
            <div class="nl2br">{{ korrespondenz().emailContentPlainText }}</div>
        }
        @if (showEmailContentHTML()) {
            <div [innerHTML]="korrespondenz().emailContentHTML"></div>
        }
    </ng-container>

</dv-collapsible-panel>
