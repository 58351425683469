import {CommonModule, DecimalPipe} from '@angular/common';
import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import {AbstractLeistungsPosition} from '@dv/kitadmin/models';

@Component({
    selector: 'dv-leistungsposition',
    standalone: true,
    imports: [CommonModule, DecimalPipe],
    templateUrl: './leistungsposition.component.html',
    styleUrl: './leistungsposition.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LeistungspositionComponent {
    @Input({required: true}) public leistungsPosition!: AbstractLeistungsPosition;
}
