/*
 * Copyright © 2021 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {ErrorService} from '@dv/kitadmin/core/errors';
import type {DialogService} from '@dv/kitadmin/ui';
import type {AuthStore} from '@dv/shared/angular';
import {WILDCARD_TOKEN} from '@dv/shared/angular';
import {PERMISSION} from '@dv/shared/authentication/model';
import {checkPresent, DvbDateUtil} from '@dv/shared/code';
import type {StateService} from '@uirouter/core';
import angular from 'angular';
import {finalize, from, take, tap} from 'rxjs';
import type {Schliesstage} from '../../models/Schliesstage';
import type {SchliesstageService} from '../../service/schliesstageService';

const componentConfig: angular.IComponentOptions = {
    transclude: false,
    bindings: {
        schliesstage: '<',
    },
    template: require('./dvb-schliesstage-main.html'),
    controllerAs: 'vm',
};

export class DvbSchliesstageMain {
    public static $inject: readonly string[] = [
        '$state',
        'schliesstageService',
        'errorService',
        'dialogService',
        'authStore',
    ];

    public schliesstage!: Schliesstage;

    public workingCopySchliesstage: Schliesstage | null = null;
    public isDisabled: boolean = false;
    public editMode: boolean = false;
    public isLoading: boolean = false;
    public editAllowed: boolean = false;

    public constructor(
        private $state: StateService,
        private schliesstageService: SchliesstageService,
        private errorService: ErrorService,
        private dialogService: DialogService,
        private authStore: AuthStore,
    ) {
    }

    public $onInit(): void {
        this.editAllowed = this.authStore.hasPermission(PERMISSION.SHARED.SCHLIESSTAGE_MANAGE + WILDCARD_TOKEN);
    }

    public edit(): void {
        if (this.isDisabled) {
            return;
        }

        this.workingCopySchliesstage = angular.copy(this.schliesstage);
        this.workingCopySchliesstage.schliesstagDateRanges =
            DvbDateUtil.sortLimitedEntitiesByGueltigAbAsc(this.workingCopySchliesstage.schliesstagDateRanges);
        this.editMode = true;
    }

    public onSave(): void {
        const schliesstage = checkPresent(this.workingCopySchliesstage);
        this.isLoading = true;

        this.schliesstageService.createOrUpdateSchliesstag(schliesstage).then(() => {
            this.editMode = false;
            this.errorService.clearAll();
            this.schliesstage = schliesstage;
        }).finally(() => {
            this.isLoading = false;
        });
    }

    public onCancel(): void {
        this.editMode = false;
        this.workingCopySchliesstage = this.schliesstage;
    }

    public confirmDeleteSchliesstag(): void {
        this.dialogService.openDeleteDialog({
            entityText: 'COMMON.SCHLIESSTAGE.PLURAL',
            confirm: () => from(this.schliesstageService.delete(checkPresent(this.schliesstage.id)))
                .pipe(
                    take(1),
                    tap(() => this.$state.go('base.voreinstellungen.schliesstage')),
                    finalize(() => {
                        this.isLoading = false;
                        this.editMode = false;
                    })),
        });
    }

}

componentConfig.controller = DvbSchliesstageMain;
angular.module('kitAdmin').component('dvbSchliesstageMain', componentConfig);
