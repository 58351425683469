<div class="dvb-kita-faktura-massenaktion-error">
    <div class="modal-body normal-abstand-bottom">
        <h2 [transloco]="'FAKTURA.MASSENAKTIONEN.ERROR_TITLE'"></h2>
        @for (error of errors; track $index) {
            <div class="small-abstand-bottom">
                <p>
                    <strong>{{ error.kontaktpersonDisplayName }}</strong>,
                    <a [uiSref]="'base.rechnung.uebersicht'"
                       [uiParams]="{rechnungId: error.rechnungsId}"
                       [transloco]="'FAKTURA.MASSENAKTIONEN.ERROR_RECH_NO'"
                       [translocoParams]="{rechnr: error.rechnungsNummer}"></a>
                </p>
                <p>{{ error.errorText }}</p>
            </div>
        }
    </div>

    <div class="modal-footer">
        <dvlib-button-list class="normal-abstand-bottom">
            <button type="button"
                    class="btn btn-link"
                    (click)="hide()"
                    [transloco]="'COMMON.ABBRECHEN'">
            </button>
        </dvlib-button-list>
    </div>
</div>
