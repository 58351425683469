/*
 * Copyright © 2020 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import angular from 'angular';
import type {PlatzTypen, Firma, ColorMode} from '@dv/kitadmin/models';
import {DvbUtil} from '@dv/shared/code';

const directive: angular.IDirective = {
    replace: true,
    restrict: 'E',
    scope: {
        platzTypen: '<',
        colorMode: '<?',
    },
    template: require('./dvb-platz-typen.html'),
    controllerAs: 'vm',
    bindToController: true,
};

export class DvbPlatzTypen implements angular.IController {
    public static $inject: readonly string[] = ['$translate'];

    public platzTypen!: PlatzTypen;
    public colorMode?: ColorMode;

    public constructor(private $translate: angular.translate.ITranslateService) {
    }

    public getFirmenNames(firmen: Firma[], symbol: string): string {
        const firmenNames = firmen.filter(f => f.symbol === symbol)
            .map(f => f.name)
            .join(', ');

        return this.$translate.instant('COMMON.PLATZART.TITLE', {name: firmenNames});
    }

    public getFirmenSymbols(firmen: Firma[]): string[] {
        if (!Array.isArray(firmen)) {
            return [];
        }

        return DvbUtil.uniqueArray(firmen.map(f => f.symbol!));
    }
}

directive.controller = DvbPlatzTypen;
angular.module('kitAdmin').directive('dvbPlatzTypen', () => directive);
