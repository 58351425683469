/*
 * Copyright © 2023 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import {NgClass, NgStyle} from '@angular/common';
import {ChangeDetectionStrategy, Component, computed, input} from '@angular/core';
import {DvbDateUtil} from '@dv/shared/code';
import {TooltipModule} from 'ngx-bootstrap/tooltip';
import type {Badge} from '../../model/Badge';
import type {GridEntry} from '../../model/GridEntry';
import {TimelineBadgesComponent} from '../timeline-badges/timeline-badges.component';

@Component({
    selector: 'dv-timeline-grid',
    standalone: true,
    imports: [
        NgStyle,
        NgClass,
        TooltipModule,
        TimelineBadgesComponent,
    ],
    templateUrl: './timeline-grid.component.html',
    styleUrl: './timeline-grid.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TimelineGridComponent {

    public grid = input.required<GridEntry[]>();
    public noContent = input(false);
    public infoBadges = input<Badge[]>([]);

    public gridWithBadges = computed(() => {
        const infoBadges = this.infoBadges();

        return this.grid().map(entry => {

            return {
                ...entry,
                infoBadges: infoBadges.filter(badge => DvbDateUtil.isSameHour(badge.date, entry.time)),
            };
        });
    });
}
