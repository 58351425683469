import type {Signal} from '@angular/core';
import {signal} from '@angular/core';
import {handleResponse} from '@dv/shared/angular';
import {isPresent} from '@dv/shared/code';
import {shareState} from '@dv/shared/rxjs-utils';
import type {Observable} from 'rxjs';
import {concatMap, Subject, tap} from 'rxjs';

export type ApiStore<T, O = any> = {
    isLoading: Signal<boolean>;
    request$: Observable<O>;
    source$: Subject<T>;
};

export function apiStore<T, O>(
    apiFunction: (params: T) => Observable<O>,
    requestSuccessFunction?: () => unknown,
): ApiStore<T, O> {
    const source$ = new Subject<T>();
    const isLoading = signal(false);

    const request$ = source$.pipe(
        tap(() => isLoading.set(true)),
        concatMap(payload => apiFunction(payload).pipe(
            handleResponse({
                next: () => {
                    if (isPresent(requestSuccessFunction)) {
                        requestSuccessFunction();
                    }
                },
                finalize: () => {
                    isLoading.set(false);
                },
            }),
        )),
        shareState(),
    );

    return {source$, isLoading, request$};
}
