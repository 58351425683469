/*
 * Copyright © 2018 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {ErrorService} from '@dv/kitadmin/core/errors';
import type {ApplicationRole, KitaPermission} from '@dv/kitadmin/models';
import type {ConfirmDialogModel, DialogService} from '@dv/kitadmin/ui';
import type {AccordionContext} from '@dv/kitadmin/ui/ajs';
import {PERMISSION} from '@dv/shared/authentication/model';
import {KitaPermissionSet} from '@dv/shared/backend/model/kita-permission-set';
import {PersonalPermissionSet} from '@dv/shared/backend/model/personal-permission-set';
import {WorkTimeControllingPermissionSet} from '@dv/shared/backend/model/work-time-controlling-permission-set';
import type {FunctionType, Persisted} from '@dv/shared/code';
import {checkPresent, isPresent, TypeUtil} from '@dv/shared/code';
import {UserRole} from '@dv/shared/roles';
import type {StateService} from '@uirouter/core';
import angular from 'angular';
import type {Observable} from 'rxjs';
import {from} from 'rxjs';

import type {AuthorisationService} from '../../../authorisation/service/authorisation.service';
import type {BenutzerService} from '../../../common/service/rest/benutzer/benutzerService';
import type {Benutzer} from '../../model/Benutzer';

const componentConfig: angular.IComponentOptions = {
    transclude: false,
    bindings: {
        benutzer: '<',
        kitaPermissions: '<',
        readOnly: '<',
        onUpdate: '&',
    },
    template: require('./dvb-standard-recht.html'),
    controllerAs: 'vm',
};

export class DvbStandardRecht implements angular.IController {
    public static $inject: readonly string[] = [
        'benutzerService',
        'authorisationService',
        'dialogService',
        '$state',
        'errorService',
    ];

    public benutzer!: Persisted<Benutzer>;
    public kitaPermissions!: KitaPermission[];
    public readOnly!: boolean;
    public onUpdate!: FunctionType;

    public workingCopyBenutzer?: Persisted<Benutzer>;
    public userRoles: UserRole[] = [];
    public kitaPermissionSets: KitaPermissionSet[] = [];
    public personalPermissionSets: PersonalPermissionSet[] = [];
    public workTimeControllingPermissionSets: WorkTimeControllingPermissionSet[] = [];

    public constructor(
        private benutzerService: BenutzerService,
        private authorisationService: AuthorisationService,
        private dialogService: DialogService,
        private $state: StateService,
        private errorService: ErrorService,
    ) {
    }

    public $onInit(): void {
        this.workingCopyBenutzer = angular.copy(this.benutzer);
        Object.values(UserRole)
            .filter(role => this.authorisationService.isAuthorised(role))
            .forEach((role: UserRole) => this.userRoles.push(role));
        if (!this.authorisationService.hasPermission(PERMISSION.SHARED.MANAGE_USERS_ANY)) {
            return;
        }

        this.kitaPermissionSets = Object.values(KitaPermissionSet);
        this.personalPermissionSets = Object.values(PersonalPermissionSet);
        this.workTimeControllingPermissionSets = Object.values(WorkTimeControllingPermissionSet);
    }

    public save(context: AccordionContext): void {
        this.promptAndOrSave(context);
    }

    public cancel(): void {
        this.workingCopyBenutzer = angular.copy(this.benutzer);
    }

    private promptAndOrSave(context: AccordionContext): void {
        const workingCopyBenutzer = checkPresent(this.workingCopyBenutzer);

        const role = workingCopyBenutzer.role;
        const roleValid = role.isValid();
        this.errorService.handleValidationError(roleValid, 'BENUTZER.ERR_INVALID_APPLICATION_ROLE');
        if (!roleValid) {
            context.cancelSubmission();

            return;
        }

        let kitaPermissionsWillBeDeleted = false;
        if (this.kitaPermissions.length > 0) {
            const hasPrivilegedRole = this.authorisationService.hasPrivilegedRole(role);
            const kitaPermissionGreaterOrEqual = this.isPermissionGreaterOrEqual(role, 'kitaPermissionSet');
            const personalPermissionGreaterOrEqual = this.isPermissionGreaterOrEqual(role, 'personalPermissionSet');
            kitaPermissionsWillBeDeleted =
                hasPrivilegedRole || kitaPermissionGreaterOrEqual || personalPermissionGreaterOrEqual;
        }

        if (!kitaPermissionsWillBeDeleted) {
            this.doSave().then(() => context.completeSubmission())
                .catch(() => context.cancelSubmission());

            return;
        }

        const confirm = (): Observable<unknown> => from(this.doSave()
            // kitaPermissions will have changed
            .then(() => {
                context.completeSubmission();

                return this.$state.reload();
            }).catch(() => context.cancelSubmission()));

        const options: ConfirmDialogModel = {
            title: 'ERRORS.ERR_KITA_RECHTE_WERDEN_GELOESCHT',
            confirm,
            cancel: () => {
                this.cancel();
                context.cancelSubmission();
            },
        };

        this.dialogService.openConfirmDialog(options);
    }

    private isPermissionGreaterOrEqual(
        standardRole: ApplicationRole,
        permission: 'kitaPermissionSet' | 'personalPermissionSet',
    ): boolean {
        const standardPermission = standardRole[permission];

        let equalPermission = false;
        const permissions = this.kitaPermissions.map(kp => {
            if (kp[permission] && kp[permission] === standardPermission) {
                equalPermission = true;
            }

            return kp[permission];
        }).filter(isPresent);

        if (permissions.length === 0) {
            return false;
        }

        if (equalPermission) {
            return true;
        }

        return this.authorisationService.isPermissionGreater(standardPermission, permissions, permission);
    }

    private doSave(): angular.IPromise<void> {
        return this.benutzerService.update(checkPresent(this.workingCopyBenutzer)).then(() => {
            this.benutzer.role = checkPresent(this.workingCopyBenutzer).role;

            if (TypeUtil.isFunction(this.onUpdate)) {
                this.onUpdate();
            }
        }).finally();
    }
}

componentConfig.controller = DvbStandardRecht;
angular.module('kitAdmin').component('dvbStandardRecht', componentConfig);
