/*
 * Copyright © 2022 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import {CommonModule} from '@angular/common';
import type {OnInit} from '@angular/core';
import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import type {NgForm} from '@angular/forms';
import {FormsModule} from '@angular/forms';
import {ErrorService} from '@dv/kitadmin/core/errors';
import type {Rechnung} from '@dv/kitadmin/models';
import type {CompletableDialogModel} from '@dv/kitadmin/ui';
import {CompletableDialogBase} from '@dv/kitadmin/ui';
import {ButtonListComponent, DatepickerTextfieldComponent, LoadingButtonComponent} from '@dv/shared/angular';
import {DvbDateUtil, Persisted} from '@dv/shared/code';
import {TranslocoModule} from '@jsverse/transloco';
import moment from 'moment';

export interface FakturaFristAendernDialogModel extends CompletableDialogModel<moment.Moment> {
    rechnung: Rechnung;
}

@Component({
    selector: 'dv-faktura-frist-aendern',
    templateUrl: './faktura-frist-aendern.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        CommonModule,
        FormsModule,
        TranslocoModule,
        LoadingButtonComponent,
        DatepickerTextfieldComponent,
        ButtonListComponent,
    ],
})
export class FakturaFristAendernComponent extends CompletableDialogBase<moment.Moment>
    implements FakturaFristAendernDialogModel, OnInit {

    @Input() public rechnung!: Persisted<Rechnung>;

    public zahlungsFrist: moment.Moment | null = null;

    public constructor(
        private readonly errorService: ErrorService,
    ) {
        super();
    }

    public ngOnInit(): void {
        this.zahlungsFrist = moment(this.rechnung.zahlungsFrist);
    }

    public submitForm(form: NgForm): void {
        this.errorService.clearAll();
        const validFrist = DvbDateUtil.isValidMoment(this.zahlungsFrist);
        this.errorService.handleValidationError(validFrist, 'ERRORS.ERR_INVALID_DATE');

        if (form.invalid ?? !validFrist) {
            return;
        }

        this.submit(this.zahlungsFrist!);
    }
}
