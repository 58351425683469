<div class="group-heading" [ngClass]="{'normal-abstand-top': !first}">
    <h3 class="pull-left truncate"
        [ngClass]="{'sortable': group.sortable}"
        [tooltip]="group | displayName">{{ group | displayName }}</h3>
    @if (group.sortable) {
        <button class="btn btn-link pull-right smaller-abstand-right" (click)="sortGroup.emit()">
            <i class="fa fa-sort"></i>
        </button>
    }
</div>


@for (resource of group.resources; track resource.id) {
    <div class="item-row"
         [attr.data-resource-id]="resource.id"
         [style.height.px]="timelineCalendarService.rowsByGroup()[group.id][resource.id] * rowHeight + 1">
        <div class="resource">
            <div class="icons">
                @if (resource.inMultipleGroups) {
                    <i class="icon fa fa-chain"
                       dvResourceHover
                       [dvResourceHoverId]="resource.id">
                    </i>
                }
                @for (icon of resource.getIcons(); track $index) {
                    <i
                        class="icon"
                        [ngClass]="icon.icon"
                        [tooltip]="icon.description | transloco">
                    </i>
                }
            </div>
            @if (resource.link) {
                <a class="item-name truncate"
                   [uiSref]="resource.link"
                   [uiParams]="resource.linkParams"
                   [tooltip]="resource | displayName">
                    {{ resource | displayName }}
                </a>
            }
            @if (!resource.link) {
                <span class="item-name truncate"
                      [class.small-abstand-left]="resource.getIcons().length === 0"
                      [tooltip]="resource | displayName">
                    {{ resource | displayName }}
                </span>
            }
            @if (group.extendable) {
                <div class="right">
                    <dvlib-icon [iconName]="'trash'"
                                class="icon"
                                (click)="resourceRemove.emit({group, resource})"/>
                </div>
            }
        </div>
    </div>
}
@if (group.extendable) {
    <div class="item-row resource add-resource">
        <div class="icons"></div>
        <dv-timeline-group-add-resource [group]="group"
                                        class="truncate"
                                        [availableResources]="availableResources"
                                        (resourceAdd)="resourceAdd.emit($event)"
                                        [calendarTranslation]="calendarTranslation">
        </dv-timeline-group-add-resource>
    </div>
}
