/*
 * Copyright © 2022 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import {ZeitraumFeld} from '@dv/kitadmin/models';
import type {DayOfWeek, IZeitraum} from '@dv/shared/code';
import type {ZeitraumFeldSection} from './ZeitraumFeldSection';

export type IColoredZeitraum = IZeitraum & { color?: string };

/**
 * A dynamically created ZeitraumFeld for any arbitrary Zeitraum.
 * Not necessarily reflecting any persisted predefined Zeitraum but rather generated based on e.g. all the adjacent
 * assigned dienste of an angestellte on a day.
 */
export class DynamicZeitraumFeld extends ZeitraumFeld {

    private static readonly SPACER_COLOR = 'transparent';
    private static readonly ABSENZ_DESCRIPTION_MAX_LENGTH = 20;

    /**
     * Sub sections of this ZeitraumFeld, e.g. for included termine that have to be visualized.
     */
    public sections: ZeitraumFeldSection[] = [];

    public text: string | null = null;

    public constructor(
        zeitraum: IZeitraum,
        dayOfWeek: DayOfWeek,
    ) {
        super(zeitraum, dayOfWeek);
        this.initText();
    }

    private initText(): void {
        this.text = `${this.zeitraum.von!.format('HH:mm')}-${this.zeitraum.bis!.format('HH:mm')}`;
    }
}
