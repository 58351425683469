/*
 * Copyright © 2018 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {KinderOrtFraktion, TarifParameter} from '@dv/kitadmin/models';
import {LeistungsTyp, TarifParameterHistoryEntry, TarifParameterValue} from '@dv/kitadmin/models';
import type {ConfirmDialogModel, DialogService} from '@dv/kitadmin/ui';
import type {AuthStore} from '@dv/shared/angular';
import {PERMISSION} from '@dv/shared/authentication/model';
import type {EntityId} from '@dv/shared/backend/model/entity-id';
import {TarifParameterType} from '@dv/shared/backend/model/tarif-parameter-type';
import type {Persisted} from '@dv/shared/code';
import {checkPresent, DvbDateUtil, DvbUtil, isPresent} from '@dv/shared/code';
import angular from 'angular';
import moment from 'moment';
import type {Observable} from 'rxjs';
import {from} from 'rxjs';
import type {Debouncer, DebounceService} from '../../../common/service/debounceService';
import {BELEGUNG_INCLUDES} from '../../../common/service/rest/kind/belegungsService';
import type {KindService} from '../../../common/service/rest/kind/kindService';
import type {StundenKontingentService} from '../../../kinderort/service/stundenKontingentService';
import type {MonatsblattApiSendStatus} from '../../models/MonatsblattApiSendStatus';
import type {MonatsBlattEntry, MonatsBlattEntryTarif} from '../../models/MonatsBlattEntry';
import type {MonatsBlattApiService} from '../../service/monatsBlattApiService';
import type {MonatsBlattService} from '../../service/monatsBlattService';

const componentConfig: angular.IComponentOptions = {
    transclude: false,
    bindings: {
        fraktion: '<',
        periode: '<?',
        kindId: '<?',
    },
    template: require('./dvb-fraktion-monatsblatt.html'),
    controllerAs: 'vm',
};

type MonatsBlattEntryWithDirty = MonatsBlattEntry & {
    dirty?: boolean;
    showCreateZusatzBelegung?: boolean;
    zusatzFraktionBelegungId?: string;
};

export class DvbFraktionMonatsblatt implements angular.IController, angular.IOnInit {
    public static $inject: readonly string[] = [
        'monatsBlattService',
        'kindService',
        'debounceService',
        '$q',
        'stundenKontingentService',
        'dialogService',
        '$translate',
        'monatsBlattApiService',
        'authStore',
        'monatsBlattApiService',
    ];

    public fraktion!: Persisted<KinderOrtFraktion>;

    public readonly leistungsTyp: typeof LeistungsTyp = LeistungsTyp;

    public periode?: moment.Moment;
    public periodeBis?: moment.Moment;
    public kindId?: string;
    public isLoading: boolean = false;
    public entries: MonatsBlattEntryWithDirty[] = [];
    public parameterIdsWithStundenKontingentRelevance: EntityId[] = [];

    public isLeistungsRechnungLoading: { [index: string]: boolean } = {};
    public isMonatsBlattEntryLoading: { [index: string]: boolean } = {};
    public displayBelegung: boolean = false;
    public currentEntry?: MonatsBlattEntry;
    public apiState: MonatsblattApiSendStatus | null = null;

    private readonly debouncer: Debouncer;

    public constructor(
        private readonly monatsBlattService: MonatsBlattService,
        private readonly kindService: KindService,
        debounceService: DebounceService,
        private readonly $q: angular.IQService,
        private readonly stundenKontingentService: StundenKontingentService,
        private readonly dialogService: DialogService,
        private readonly $translate: angular.translate.ITranslateService,
        private readonly monatsBlattApiService: MonatsBlattApiService,
        private readonly authStore: AuthStore,
        private readonly monatsblattApiService: MonatsBlattApiService,
    ) {
        this.debouncer = debounceService.create();
    }

    private static updateZusatzBelegungen(entries: MonatsBlattEntryWithDirty[]): void {
        entries.forEach(e => DvbFraktionMonatsblatt.updateZusatzBelegung(e));
    }

    private static updateZusatzBelegung(entry: MonatsBlattEntryWithDirty): void {
        delete entry.showCreateZusatzBelegung;
        delete entry.zusatzFraktionBelegungId;

        if (DvbFraktionMonatsblatt.hasNoPrivat(entry)) {
            entry.showCreateZusatzBelegung = true;

            // checking zusatzFraktionBelegung is not needed in this case

            return;
        }

        const found = entry.tarife.find(t => !!t.zusatzFraktionBelegungId);
        if (!found) {
            return;
        }

        entry.zusatzFraktionBelegungId = found.zusatzFraktionBelegungId!;
    }

    private static hasNoPrivat(entry: MonatsBlattEntryWithDirty): boolean {
        return entry.tarife.every(t => t.leistungsTyp !== LeistungsTyp.PRIVAT);
    }

    public $onInit(): void {
        if (!this.periode) {
            this.periode = DvbDateUtil.today();
            this.periode = this.getStartOfMonth();
        }

        this.reload();
    }

    public reload(): void {
        this.isLoading = true;
        this.entries = [];
        this.periodeBis = this.getEndOfMonth();

        const params = {
            includes: `(${BELEGUNG_INCLUDES})`,
        };

        const fraktionId = this.fraktion.id;
        const periode = checkPresent(this.periode);

        this.clearRightColumn();
        this.loadApiStatus(periode);

        this.monatsBlattService.getMonatsBlattEntries(fraktionId, periode, params)
            .then(monatsBlattEntries => {
                this.entries = monatsBlattEntries.entries;
                this.parameterIdsWithStundenKontingentRelevance =
                    monatsBlattEntries.parameterIdsWithStundenKontingentRelevance;
            })
            .finally(() => {
                this.isLoading = false;
            })
            // already displaying results, and performing calculation in the background
            .then(() => DvbFraktionMonatsblatt.updateZusatzBelegungen(this.entries));
    }

    public getAbzugRelevantTarifParameterSum(monatsBlattEntryTarif: MonatsBlattEntryTarif): number | null {
        return monatsBlattEntryTarif.parameterValues
            .filter(paramValue => this.parameterIdsWithStundenKontingentRelevance.includes(paramValue.parameterId))
            .map(paramValue => paramValue.value)
            .filter(value => isPresent(value))
            .reduce((sum, value) => sum + value, 0);
    }

    public refreshLeistungsrechnung(entry: MonatsBlattEntryWithDirty): void {
        entry.dirty = false;
        this.isLeistungsRechnungLoading[checkPresent(entry.leistungsrechnung.id)] = true;

        this.kindService.getLeistungsrechnungen(checkPresent(entry.kind.id), {
            gueltigAb: this.getStartOfMonth(),
            gueltigBis: this.getEndOfMonth(),
        }).then(leistungsrechungen => {

            const relevantLeistungsrechnungen = leistungsrechungen
                .filter(leistungsrechung => leistungsrechung.kitaId === checkPresent(this.fraktion.kita).id);

            if (relevantLeistungsrechnungen.length !== 1) {
                throw new Error('Reload of Leistungsrechnungen returned unexpected amount');
            }

            // mapping refreshed result back into our data structure
            entry.leistungsrechnung = relevantLeistungsrechnungen[0];

            this.updateStundenKontingente(entry);
        }).finally(() => {
            entry.dirty = false;
            this.isLeistungsRechnungLoading[checkPresent(entry.leistungsrechnung.id)] = false;
        });
    }

    public getParamValue(paramValues: TarifParameterValue[], param: TarifParameter): TarifParameterValue {
        const value = DvbUtil.findFirst(paramValues, val => checkPresent(val.parameter).id === param.id);

        if (value === null) {
            const tarifParameterValue = new TarifParameterValue(null, checkPresent(param.id), param, null);

            paramValues.push(tarifParameterValue);

            return tarifParameterValue;
        }

        return value;
    }

    public updateParamValue(
        entry: MonatsBlattEntryWithDirty,
        entryTarif: MonatsBlattEntryTarif,
    ): void {
        const historyEntry = new TarifParameterHistoryEntry(null,
            TarifParameterType.MONATS_BASIERT,
            entryTarif.gueltigAb,
            entryTarif.gueltigBis,
            entryTarif.parameterValues.filter(val => val.value !== null),
            this.fraktion.id,
            entryTarif.tarif.id,
            entryTarif.leistungsTyp,
            entryTarif.kontingentId,
        );

        this.debouncer.debounce(() => {
            this.kindService.createTarifParameterHistoryEntry(checkPresent(entry.kind.id), historyEntry).then(() => {
                entry.dirty = true;
                this.loadApiStatus(this.periode!);
            });
        });
    }

    public updateStunden(entry: MonatsBlattEntryWithDirty, entryTarif: MonatsBlattEntryTarif): void {
        this.monatsBlattService.updateStunden(this.fraktion.id, checkPresent(entry.kind.id), entryTarif)
            .then(() => {
                entry.dirty = true;
                this.loadApiStatus(this.periode!);
            });
    }

    public selectedEntry(entry: MonatsBlattEntryWithDirty, selected: boolean): void {
        if (!selected) {
            if (this.currentEntry === entry) {
                this.clearRightColumn();
            }

            if (entry.dirty) {
                this.refreshLeistungsrechnung(entry);
            }

            return;
        }

        if (this.currentEntry !== entry) {
            this.currentEntry = entry;
            this.displayBelegung = true;
        }
    }

    public addZusatzBelegung(entry: MonatsBlattEntryWithDirty): void {
        const fraktionId = this.fraktion.id;
        const kindId = checkPresent(entry.kind.id);
        const ab = this.getStartOfMonth();
        const bis = this.getEndOfMonth();

        this.isMonatsBlattEntryLoading[checkPresent(entry.leistungsrechnung.id)] = true;

        this.monatsBlattService.createZusatzFraktionBelegung(fraktionId, kindId, ab, bis)
            .then(() => this.refreshMonatsBlattEntry(entry))
            .finally(() => {
                this.isMonatsBlattEntryLoading[checkPresent(entry.leistungsrechnung.id)] = false;
            });
    }

    public deleteZusatzBelegung(entry: MonatsBlattEntryWithDirty): void {

        const confirm = (): Observable<unknown> => {
            this.isMonatsBlattEntryLoading[checkPresent(entry.leistungsrechnung.id)] = true;
            const fraktionId = checkPresent(entry.zusatzFraktionBelegungId);

            return from(this.monatsBlattService.deleteZusatzFratkionBelegung(fraktionId)
                .then(() => this.refreshMonatsBlattEntry(entry))
                .finally(() => {
                    this.isMonatsBlattEntryLoading[checkPresent(entry.leistungsrechnung.id)] = false;
                }));
        };

        this.dialogService.openDeleteDialog({
            entityText: 'FRAKTION.MONATSBLATT.CONFIRM_DELETE_ZUSAETZLICHE_BELEGUNG',
            confirm,
        });
    }

    public sendToApi(): void {

        const monthYear = this.periode?.format('MMMM YYYY');
        const name = this.fraktion.getDisplayName();

        const confirm = (): Observable<unknown> => {
            this.isLoading = true;

            return from(this.monatsBlattApiService.sendFraktion(this.fraktion.id, checkPresent(this.periode))
                .then(() => this.loadApiStatus(this.periode!))
                .finally(() => {
                    this.isLoading = false;
                }));
        };

        const options: ConfirmDialogModel = {
            title: this.$translate.instant('FRAKTION.MONATSBLATT.API.SEND_TITLE', {monthYear}),
            subtitle: this.$translate.instant('FRAKTION.MONATSBLATT.API.SEND_INFO_BETREUUNGSPERSON', {name}),
            confirm,
        };

        this.dialogService.openConfirmDialog(options);
    }

    private refreshMonatsBlattEntry(entry: MonatsBlattEntryWithDirty): angular.IPromise<MonatsBlattEntryWithDirty> {
        const fraktionId = this.fraktion.id;
        const kindId = checkPresent(entry.kind.id);
        const periode = checkPresent(this.periode);

        return this.monatsBlattService.getMonatsBlattEntries(fraktionId, periode, {kindId})
            .then(monatsBlattEntries => monatsBlattEntries.entries[0])
            .then(refreshedEntry => {
                entry.tarife = refreshedEntry.tarife;
                entry.leistungsrechnung = refreshedEntry.leistungsrechnung;
                entry.stundenKontingente = refreshedEntry.stundenKontingente;
                entry.dirty = false;
                DvbFraktionMonatsblatt.updateZusatzBelegung(entry);

                return entry;
            });
    }

    private updateStundenKontingente(entry: MonatsBlattEntryWithDirty): void {
        const ids = entry.stundenKontingente.map(k => checkPresent(k.stundenKontingent.id));

        entry.stundenKontingente = [];

        // keeps the order consistent
        const promises = ids.map(id => this.stundenKontingentService.getCalculatedStundenKontingent(id));

        this.$q.all(promises)
            .then(stundenKontingente => {
                entry.stundenKontingente = stundenKontingente;
            });
    }

    private getEndOfMonth(): moment.Moment {
        return DvbDateUtil.endOfMonth(moment(this.periode));
    }

    private getStartOfMonth(): moment.Moment {
        return DvbDateUtil.startOfMonth(moment(this.periode));
    }

    private clearRightColumn(): void {
        this.currentEntry = undefined;
        this.displayBelegung = false;
    }

    private loadApiStatus(periode: moment.Moment): void {
        this.apiState = null;
        if (!this.authStore.hasAnyPermission([
            `${PERMISSION.FEATURE.MONATSBLATT_API_KIBE_PLUS}:${this.fraktion.kinderOrtId}`,
            `${PERMISSION.FEATURE.MONATSBLATT_API_NANNY_KIBE_PLUS}:${this.fraktion.kinderOrtId}`,
        ])) {
            return;
        }

        this.monatsblattApiService.getState(this.fraktion.id, periode)
            .then(state => {
                this.apiState = state;
            });
    }
}

componentConfig.controller = DvbFraktionMonatsblatt;
angular.module('kitAdmin').component('dvbFraktionMonatsblatt', componentConfig);
