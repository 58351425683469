/*
 * Copyright © 2019 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {ErrorService} from '@dv/kitadmin/core/errors';
import {MandantRechnung, TempBlob} from '@dv/kitadmin/models';
import {handleResponse} from '@dv/shared/angular';
import type {MandantRechnungenService} from '@dv/shared/backend/api/mandant-rechnungen.service';
import type {StatisticsService} from '@dv/shared/backend/api/statistics.service';
import {DvbDateUtil, DvbRestUtil, isPresent} from '@dv/shared/code';
import angular from 'angular';
import moment from 'moment';
import {map, type Subscription} from 'rxjs';
import type {DvbDownload} from '../../../base/directive/dvb-download/dvb-download';
import {DvbRestUtilAngularJS} from '../../../common/service/rest/dvbRestUtilAngularJS';

const componentConfig: angular.IComponentOptions = {
    transclude: false,
    require: {
        dvbDownloadCtrl: '^dvbDownload',
    },
    bindings: {
        preSelectedRechnungIndex: '<?',
    },
    template: require('./dvb-administration-mandanten-abrechnung.html'),
    controllerAs: 'vm',
};

const MIDDLE_OF_MONTH = 15;

export class DvbAdministrationMandantenAbrechnung implements angular.IController {

    public static $inject: readonly string[] = [
        'mandantRechnungenService',
        'statisticsService',
        '$filter',
        'errorService',
    ];

    public preSelectedRechnungIndex?: number;
    public searchText: string = '';
    public mandantRechnungen: MandantRechnung[] = [];
    public filteredMandantRechnungen: MandantRechnung[] = [];
    public isLoading: boolean = false;
    public selectedRechnungIndex: number | null = null;
    public einkommen: number = 0;
    public kitaCount: number = 0;
    public tageselternCount: number = 0;
    public totalKitaCount: number = 0;
    public totalTageselternCount: number = 0;
    public coronaKinderOrteCharged: number = 0;
    public stichtag?: moment.Moment;
    public isDownloading: boolean = false;

    private timeout?: angular.IDeferred<unknown>;
    private readonly dvbDownloadCtrl!: DvbDownload;
    private subscription?: Subscription;

    public constructor(
        private readonly mandantRechnungenService: MandantRechnungenService,
        private readonly statisticsService: StatisticsService,
        private readonly $filter: angular.IFilterService,
        private readonly errorService: ErrorService,
    ) {
    }

    public $onDestroy(): void {
        DvbRestUtilAngularJS.cancelRequest(this.timeout);
    }

    public $onInit(): void {
        this.stichtag = this.initStichtag(DvbDateUtil.today());
        this.statisticsService.countCoronaKinderOrtFees$().subscribe(count => {
            this.coronaKinderOrteCharged = count.count;
        });
        this.loadRechnungen();
    }

    public filterMandanten(): void {
        this.filteredMandantRechnungen = this.$filter('filter')(this.mandantRechnungen, this.searchText);
    }

    public isPersisted(): boolean {
        return this.filteredMandantRechnungen.some(mandantRechnung => mandantRechnung.id !== null);

    }

    /**
     * @param initialDate
     * @return when before MIDDLE_OF_MONTH, load previous month, otherwise load this month
     */
    public initStichtag(initialDate: moment.Moment): moment.Moment {
        return initialDate.subtract(MIDDLE_OF_MONTH - 1, 'days');
    }

    public loadRechnungen(): void {
        this.subscription?.unsubscribe();
        this.isLoading = true;
        this.mandantRechnungen = [];
        this.filteredMandantRechnungen = [];
        this.einkommen = 0;
        const stichtag = DvbRestUtil.momentToLocalDateChecked(moment(this.stichtag).date(MIDDLE_OF_MONTH));

        this.subscription = this.mandantRechnungenService.getMandantRechnungen$({mandantrechnungen: {stichtag}}).pipe(
            map(({rechnungen}) => DvbRestUtil.transformArray(rechnungen, MandantRechnung)),
            handleResponse({
                next: mandantRechnungen => {
                    this.mandantRechnungen = mandantRechnungen;
                    this.filteredMandantRechnungen = this.$filter('filter')(mandantRechnungen, this.searchText);
                    this.einkommen = 0;
                    this.kitaCount = 0;
                    this.tageselternCount = 0;
                    this.totalKitaCount = 0;
                    this.totalTageselternCount = 0;
                    for (const rech of mandantRechnungen) {
                        this.einkommen += rech.summe ?? 0;
                        this.kitaCount += rech.kitaCount ?? 0;
                        this.tageselternCount += rech.tageselternCount ?? 0;
                        this.totalKitaCount += rech.totalKitaCount ?? 0;
                        this.totalTageselternCount += rech.totalTageselternCount ?? 0;
                    }
                },
                finalize: () => {
                    this.isLoading = false;
                },
            }),
        ).subscribe();
    }

    public $onChanges(changes: angular.IOnChangesObject): void {
        if (changes.preSelectedRechnungIndex) {
            this.selectedRechnungIndex = changes.preSelectedRechnungIndex.currentValue;
        }
    }

    public select(index: number): void {
        if (this.selectedRechnungIndex === index) {
            this.selectedRechnungIndex = null;

            return;
        }
        this.selectedRechnungIndex = index;
    }

    public createRechnungsPdf(mandantRechnung: MandantRechnung | null): void {
        this.errorService.clearAll();
        this.isDownloading = true;
        const selectedIds: string[] = [];
        const id = mandantRechnung?.id;
        if (isPresent(id)) {
            selectedIds.push(id);
        } else {
            this.mandantRechnungen.forEach(rechnung => {
                if (rechnung.id) {
                    selectedIds.push(rechnung.id);
                }
            });
        }

        if (selectedIds.length === 0) {
            // Error: No Rechnung found
            this.errorService.handleValidationError(false, 'ADMINISTRATION.ERR_INVALID_MANDANT_RECHNUNG');
            this.isDownloading = false;

            return;
        }

        this.mandantRechnungenService.downloadPDFs$({jaxMandantRechnungsPDF: {rechnungsIds: selectedIds}}).pipe(
            handleResponse({
                next: data => {
                    this.dvbDownloadCtrl.downloadFileByUrl(TempBlob.apiResponseTransformer(data));
                },
                finalize: () => {
                    this.isDownloading = false;
                },
            }),
        ).subscribe();
    }
}

componentConfig.controller = DvbAdministrationMandantenAbrechnung;
angular.module('kitAdmin').component('dvbAdministrationMandantenAbrechnung', componentConfig);
