import type {Ng2StateDeclaration} from '@uirouter/angular';
import {STUNDEN_KONTINGENT_PARAMETER_GUELTIGKEIT} from './stunden-kontingent-parameter-gueltigkeit.states';

export const STUNDEN_KONTINGENT_PARAMETER_GUELTIGKEIT_FEATURE = {
    name: `${STUNDEN_KONTINGENT_PARAMETER_GUELTIGKEIT.name}.**`,
    url: STUNDEN_KONTINGENT_PARAMETER_GUELTIGKEIT.url,
    loadChildren: () =>
        import('./stunden-kontingent-parameter-gueltigkeit.module').then(
            m => m.StundenKontingentParameterGueltigkeitModule,
        ),
} satisfies Ng2StateDeclaration;
