/*
 * Copyright © 2019 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {Mandant} from '@dv/kitadmin/models';
import {ModulPreise} from '@dv/kitadmin/models';
import type {KitadminModul} from '@dv/shared/backend/model/kitadmin-modul';
import type {FunctionType} from '@dv/shared/code';
import {TypeUtil} from '@dv/shared/code';
import angular from 'angular';

const componentConfig: angular.IComponentOptions = {
    transclude: false,
    bindings: {
        mandant: '<',
        availableModules: '<',
        isDisabled: '<',
        onChange: '&',
    },
    template: require('./dvb-kitadmin-module.html'),
    controllerAs: 'vm',
};

export class DvbKitadminModule implements angular.IController {

    public static $inject: readonly string[] = [];

    public mandant!: Mandant;
    public availableModules: KitadminModul[] = [];
    public isDisabled?: boolean;

    public modules: { [k in KitadminModul]?: boolean } = {};
    public preise: ModulPreise[] = [];
    public isLoading: boolean = true;

    private onChange?: FunctionType;

    public $onChanges(): void {
        const modulPreise = new Map(this.availableModules.map(m => [m, new ModulPreise(m)]));
        this.mandant.preise.forEach((value, key) => {
            modulPreise.set(key, value);
        });

        this.preise = Array.from(modulPreise.values());

        for (const modul of modulPreise.keys()) {
            this.modules[modul] = this.mandant.preise.has(modul);
        }
    }

    public update(modulPreise: ModulPreise): void {
        if (this.modules[modulPreise.modul]) {
            this.mandant.preise.set(modulPreise.modul, modulPreise);
        } else {
            this.mandant.preise.delete(modulPreise.modul);
        }

        if (TypeUtil.isFunction(this.onChange)) {
            this.onChange();
        }
    }
}

componentConfig.controller = DvbKitadminModule;
angular.module('kitAdmin').component('dvbKitadminModule', componentConfig);
