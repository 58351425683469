<div class="timeline-events">
    @for (event of events; track event.id; let i = $index) {
        @if (event.spaceBefore !== 0) {
            <div
                [ngStyle]="{'flex-grow': event.spaceBefore}"
                class="event-line">
            </div>
        }

        <div [ngStyle]="{'flex-grow': event.completeDuration}" class="event-line">
            <dv-timeline-event [style.visibility]="event.spaceBefore >= 0 && event.spaceAfter >= 0 ? 'visible' : 'hidden'"
                               [event]="event"
                               [editOverlayOpened]="calendarEventWithOpenedEditOverlay === event"
                               [editOverlayTemplate]="calendarEventEditOverlay"
                               (closeEditOverlay)="closeCalendarEventEditOverlay.emit()"
                               (resizeEvent)="resizeEvent.emit({event, resizeEvent: $event, eventIndex: i, events})"
                               (resizeComplete)="resizeComplete.emit(event)"
                               (deleteEvent)="deleteEvent.emit($event)"
                               (editEvent)="editEvent.emit($event)">
            </dv-timeline-event>
            @if (event.subEvents.length && timelineCalendarService.layerConfig().has(event.subEvents[0].layer)) {
                <dv-timeline-events class="subevents"
                                    [events]="event.subEvents"
                                    (resizeEvent)="resizeEvent.emit($event)"
                                    (resizeComplete)="resizeComplete.emit($event)"
                                    (deleteEvent)="deleteEvent.emit($event)"
                                    (editEvent)="editEvent.emit($event)">
                </dv-timeline-events>
            }
        </div>

        @if (event.spaceAfter !== 0) {
            <div [ngStyle]="{'flex-grow': event.spaceAfter}" class="event-line"></div>
        }
    }
</div>
