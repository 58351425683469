<div class="dvb-anmeldung row dvb-form">
    <div class="col-md-6 col-md-offset-3">

        <h1 data-translate="KIND.ANMELDUNG"></h1>

        <form name="anmeldeForm"
              ng-submit="!anmeldeForm.$pending && vm.validateAndSaveKind(anmeldeForm.$valid)"
              novalidate>
            <dvb-anmeldung-kind default-anmelde-datum="vm.defaultAnmeldeDatum"
                                on-kind-created="vm.onKindCreated(kind)"
                                geschwister-id="{{vm.geschwisterId}}">
            </dvb-anmeldung-kind>

            <div class="form-section">

                <h2 data-translate="COMMON.BETREUUNGSWUNSCH"></h2>

                <div class="form-row" ng-show="vm.bewerbung.kitas && vm.bewerbung.kitas.length > 0">
                    <div class="row selected-entity"
                         ng-repeat="kita in vm.bewerbung.kitas"
                         ng-init="searchResultEntry = kita.toSearchResultEntry()">
                        <div class="col-xs-8 entity">
                            <dv-search-result-icon [entry]="searchResultEntry"></dv-search-result-icon>
                            <span ng-bind="kita.getDisplayName()"></span>
                        </div>
                        <div class="col-xs-4 edit">
                            <a href="" ng-click="vm.bewerbung.removeKita(kita)" data-translate="COMMON.LOESCHEN"></a>
                        </div>
                    </div>
                </div>
                <dvb-search-entity class="form-row"
                                   entity-to-search="KINDERORT"
                                   on-select="vm.addKita(item)"
                                   on-select-clear="true"
                                   placeholder-key="COMMON.KINDERORT.AUSWAEHLEN"
                                   disabled-entries="vm.bewerbung.kitas"
                                   ng-class="{ 'ng-invalid' : !vm.bewerbung.isKitaSet() }">
                </dvb-search-entity>

                <div class="row">
                    <div class="col-md-3">
                        <input type="number"
                               name="pensum"
                               class="form-control"
                               min="1"
                               ng-blur="vm.bewerbung.pensum.update()"
                               ng-model="vm.bewerbung.pensum.von"
                               ng-attr-placeholder="{{'COMMON.PENSUM_IN_PROZENT' | translate}}"
                               uib-tooltip="{{'COMMON.PENSUM_IN_PROZENT' | translate}}"
                               tooltip-placement="top-left"
                               required>
                    </div>
                    <div class="col-md-1 form-linker" data-translate="COMMON.BIS"></div>
                    <div class="col-md-3">
                        <input type="number"
                               class="form-control"
                               min="1"
                               ng-blur="vm.bewerbung.pensum.update()"
                               ng-model="vm.bewerbung.pensum.bis"
                               ng-attr-placeholder="{{'COMMON.PENSUM_IN_PROZENT' | translate}}"
                               uib-tooltip="{{'COMMON.PENSUM_IN_PROZENT' | translate}}"
                               tooltip-placement="top-left">
                    </div>
                </div>
                <div class="row form-row">
                    <div class="col-md-12">
                        <dvb-wochenplan distinct-betreuungs-zeitraeume="vm.distinctZeitraeume"
                                        week-days="vm.enabledDays"
                                        interactive="true"
                                        show-null-value-as-plain="true"
                                        on-zeitraum-feld-clicked="vm.handleZeitraumFeldToggle(zeitraumFeld)"
                                        zeitraum-felder="vm.zeitraumFelder"
                                        show-betreuungs-zeitraum-name="vm.wochenplan.showBetreuungsZeitraumName">
                        </dvb-wochenplan>
                    </div>
                </div>
                <div class="form-row">
                    <div class="row">
                        <div class="col-md-6">
                            <div class="checkbox">
                                <label>
                                    <input type="checkbox"
                                           value="true"
                                           ng-model="vm.bewerbung.subventionierterPlatz">
                                    <span data-translate="COMMON.SUBVENTIONIERTER_PLATZ"></span>
                                </label>
                            </div>
                            <div class="checkbox">
                                <label>
                                    <input type="checkbox" value="true" ng-model="vm.bewerbung.privaterPlatz">
                                    <span data-translate="COMMON.PRIVATER_PLATZ"></span>
                                </label>
                            </div>
                            <div class="checkbox" ng-repeat="firma in vm.bewerbung.firmenSelection">
                                <label>
                                    <input type="checkbox"
                                           value="firma.id"
                                           ng-model="firma.selected"
                                           ng-change="toggleFirma(firma)">
                                    <span data-translate="COMMON.PLATZART.TITLE"
                                          data-translate-value-name="{{firma.name}}"></span>
                                </label>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <span class="error-message"
                                  ng-show="!vm.bewerbung.isPlatzSelected()"
                                  data-translate="ERRORS.ERR_REQUIRED_PLATZ"></span>
                        </div>
                    </div>
                    <dvb-search-on-click-entity entity-to-search="FIRMA"
                                                on-select="vm.addFirmaToSelection(item)"
                                                on-select-clear="true"
                                                add-label-key="COMMON.PLATZART.HINZUFUEGEN"
                                                disabled-entries="vm.bewerbung.firmenSelection"
                                                placeholder-key="COMMON.PLATZART.AUSWAEHLEN">
                    </dvb-search-on-click-entity>
                </div>

                <div class="row">
                    <div class="col-md-6">
                        <dvb-datepicker-text-field ng-model="vm.bewerbung.gewuenschteBetreuungAb"
                                                   ng-attr-placeholder="{{'KIND.EINTRITTSDATUM_AB' | translate}}"
                                                   required>
                        </dvb-datepicker-text-field>
                    </div>
                </div>

                <div class="row form-row">
                    <div class="col-md-12">
                        <div class="checkbox">
                            <label>
                                <input type="checkbox" ng-model="vm.bewerbung.kindergarten">
                                <span data-translate="COMMON.KINDERGARTEN"></span>
                            </label>
                        </div>
                    </div>
                </div>
            </div>

            <div class="form-section">
                <h2 data-translate="KIND.BETREUUNGSGRUND"></h2>

                <dvb-betreuungsgrund betreuungsgruende-ids="vm.kind.betreuungsGruendeIds"
                                     on-change="vm.betreuungsgruendeChanged(prioritaet)">
                </dvb-betreuungsgrund>
            </div>

            <div class="form-section">
                <h2 data-translate="KIND.PRIORITAET_TITEL"></h2>

                <dvb-bewerbung-prioritaet prioritaet="vm.bewerbung.prioritaet"
                                          on-change="vm.bewerbung.prioritaet = prioritaet"
                                          layout="row">
                </dvb-bewerbung-prioritaet>
            </div>

            <div class="row">
                <div class="col-md-12">
                    <ul class="list-inline">
                        <li>
                            <dvb-loading-button type="submit" is-loading="anmeldeForm.$pending || vm.isLoading">
                                <span data-translate="KIND.KIND_ANMELDEN"></span>
                            </dvb-loading-button>
                        </li>
                        <li><a href="" data-translate="COMMON.ABBRECHEN" ng-click="vm.goBack()"></a></li>
                    </ul>
                </div>
            </div>
        </form>
    </div>
</div>
