/*
 * Copyright © 2023 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {OnInit} from '@angular/core';
import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import {IconComponent} from '@dv/shared/angular';
import type {IconName} from '@dv/shared/code';
import {TooltipModule} from 'ngx-bootstrap/tooltip';
import {ITimelineEntry} from '../ITimelineEntry';
import {ITimelineService} from '../ITimelineService';

@Component({
    selector: 'dv-timeline-icon',
    standalone: true,
    imports: [IconComponent, TooltipModule],
    templateUrl: './timeline-icon.component.html',
    styleUrl: './timeline-icon.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TimelineIconComponent implements OnInit {
    @Input({required: true}) public timelineService!: ITimelineService<any>;
    @Input({required: true}) public timelineEntry!: ITimelineEntry;
    @Input() public isLast: boolean = false;

    public icon: IconName = 'no-icon';
    public iconTooltipText: string = '';

    public ngOnInit(): void {
        this.icon = this.timelineService.getIcon(this.timelineEntry);
        this.iconTooltipText = this.timelineService.getIconTooltipText(this.timelineEntry);
    }
}
