<div class="dvb-dashboard-tasks panel panel-default panel-list-container big-abstand-top">
    <div class="panel-heading">
        <h2 data-translate="DASHBOARD.AUFGABENLISTE"></h2>
        <button type="button" ng-show="vm.showReset" ng-click="vm.resetTaskFilter()" class="btn btn-link">
            <i class="fa fa-share"></i>
            <span data-translate="COMMON.RESET_FILTER"></span>
        </button>
        <div class="dropdown" uib-dropdown auto-close="outsideClick">
            <a href="" class="dropdown-toggle" uib-dropdown-toggle>
                <i ng-if="vm.selectedCategoryCount <= 0" class="fa fa-sliders"></i>
                <dv-pensum-icon
                    ng-if="vm.selectedCategoryCount > 0"
                    class="small"
                    [value]="vm.selectedCategoryCount"
                    [inverted]="true"
                    [color-mode]="'bright'">
                </dv-pensum-icon>
            </a>
            <ul class="dropdown-menu categories" uib-dropdown-menu>
                <h3 data-translate="DASHBOARD.TASK_FILTER_TITLE" class="small-abstand-bottom"></h3>
                <li ui-sref-active-eq="active"
                    class="floating-text"
                    ng-repeat="category in vm.taskFilter.filterCategories">
                    <div class="checkbox">
                        <label>
                            <input type="checkbox"
                                   ng-model="category.selected"
                                   ng-change="vm.updateSelectedTasks()">
                            <span data-translate="{{'DASHBOARD.TASK_FILTER.' + category.id}}"></span>
                        </label>
                    </div>
                </li>
            </ul>
        </div>
    </div>
    <div class="row">
        <div class="col-md-12">
            <div class="ort-filter">
                <dvb-search-on-click-entity add-label-key="DASHBOARD.ORT_HINZUFUEGEN"
                                            entity-to-search="TASK_ORT"
                                            on-select="vm.filterOptionHelper.addFromSearchResult(vm.taskFilter.orte, item)"
                                            on-select-clear="true"
                                            disabled-entries="vm.taskFilter.orte">
                </dvb-search-on-click-entity>
                <ul class="filter-option-list">
                    <li ng-repeat="filter in vm.taskFilter.orte | filter:{sticky:true} | orderBy: 'name'"
                        class="filter-element">
                        <dvb-filter-option label="filter.name"
                                           is-selected="filter.selected"
                                           closeable="true"
                                           on-close="vm.filterOptionHelper.remove(vm.taskFilter.orte, filter)"
                                           on-toggle="vm.filterOptionHelper.onToggle(filter, isSelected)">
                        </dvb-filter-option>
                    </li>
                </ul>
            </div>
        </div>
    </div>
    <div class="panel-body-footer" dvb-inside-scroll>
        <div class="panel-body">
            <ul class="panel-list">
                <li ng-if="!vm.isLoading && vm.pageContainer.items.length == 0 && vm.favoriten.length > 0"
                    class="list-item"
                    data-translate="DASHBOARD.KEINE_AUFGABEN_VORHANDEN">
                </li>
                <li ng-if="!vm.isLoading && vm.pageContainer.items.length === 0 && vm.favoriten.length === 0"
                    class="list-item"
                    data-translate="DASHBOARD.KEINE_AUFGABEN_OHNE_FAVORITEN">
                </li>
                <div ng-if="vm.isLoading || vm.pageContainer.items.length > 0" class="pages-wrapper">
                    <dvb-pages items="vm.pageContainer.items"
                               total-items="vm.pageContainer.count"
                               is-loading="vm.isLoading"
                               max-size="1"
                               items-per-page-options="[5, 10, 25, 100, 200]"
                               items-per-page="vm.itemsPerPage"
                               current-page="vm.currentPage"
                               entity-name="'COMMON.TASKS.MF'"
                               force-ellipses="false"
                               on-update-page="vm.updatePage(page)"
                               on-update-items-per-page="vm.onUpdateItemsPerPage(itemsPerPage)">
                        <page-items>
                            <li ng-repeat="task in vm.pageContainer.items">
                                <a class="list-item" ng-href="{{vm.getEntityHref(task)}}">
                                    <dv-display-task [task]="task"></dv-display-task>
                                </a>
                            </li>
                        </page-items>
                    </dvb-pages>
                </div>
            </ul>
        </div>
    </div>
</div>
