import {ChangeDetectionStrategy, Component, EventEmitter, Input, Output} from '@angular/core';
import type {NgForm} from '@angular/forms';
import {FormsModule} from '@angular/forms';
import type {FormGroupSpec} from '@dv/shared/angular';
import {ButtonComponent, ButtonListComponent, ValidBisDirective, ValidVonDirective} from '@dv/shared/angular';
import type {BackendLocalTimeHHMM} from '@dv/shared/backend/model/backend-local-time-HHMM';
import type {ITimeRange} from '@dv/shared/code';
import {DvbRestUtil} from '@dv/shared/code';
import {TranslocoModule} from '@jsverse/transloco';
import {TooltipModule} from 'ngx-bootstrap/tooltip';
import {CalendarEvent} from 'src/app/calendar/timeline/model/CalendarEvent';

@Component({
    selector: 'dv-zuweisung-edit-time-form',
    standalone: true,
    imports: [
        FormsModule,
        ValidVonDirective,
        ValidBisDirective,
        TranslocoModule,
        TooltipModule,
        ButtonComponent,
        ButtonListComponent,
    ],
    templateUrl: './zuweisung-edit-time-form.component.html',
    styleUrl: './zuweisung-edit-time-form.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ZuweisungEditTimeFormComponent {

    @Input({required: true})
    public set event(event: CalendarEvent) {
        this._event = event;

        this.model = {
            von: DvbRestUtil.momentTolocaleHHMMTime(event.von) ?? undefined,
            bis: DvbRestUtil.momentTolocaleHHMMTime(event.bis) ?? undefined,
        };
    }

    public get event(): CalendarEvent {
        return this._event;
    }

    private _event!: CalendarEvent;

    @Output() public readonly updateTimes: EventEmitter<{
        event: CalendarEvent;
        times: ITimeRange;
    }> = new EventEmitter();

    @Output() public readonly editFinished: EventEmitter<void> = new EventEmitter();

    public model: Partial<{
        von: BackendLocalTimeHHMM | undefined;
        bis: BackendLocalTimeHHMM | undefined;
    }> = {};

    public submitForm(ngForm: NgForm): void {
        const formGroup: FormGroupSpec<{
            von: BackendLocalTimeHHMM | undefined;
            bis: BackendLocalTimeHHMM | undefined;
        }> = ngForm.form;

        const valid = formGroup.valid || false;

        if (valid && !formGroup.pristine && this._event) {
            this.updateTimes.emit({
                event: this._event,
                times: {
                    von: DvbRestUtil.localeHHMMTimeToMoment(this.model.von),
                    bis: DvbRestUtil.localeHHMMTimeToMoment(this.model.bis),
                },
            });
        }

        this.editFinished.emit();
    }

    public cancel(): void {
        this.editFinished.emit();
    }
}
