<ul class="dvb-platz-typen list-inline">
    <li ng-if="vm.platzTypen.privat">
        <span class="custom-icon custom-icon-privat"
              uib-tooltip="{{'COMMON.ZUWEISUNG_IST_PRIVAT' | translate}}"
              tooltip-placement="top-left">
        </span>
    </li>
    <li ng-if="vm.platzTypen.subventioniert">
        <span class="custom-icon custom-icon-subventioniert"
              uib-tooltip="{{'COMMON.ZUWEISUNG_IST_SUBVENTIONIERT' | translate}}"
              tooltip-placement="top-left">
        </span>
    </li>
    <li ng-if="vm.platzTypen.firmen.length > 0"
        ng-repeat="symbol in vm.getFirmenSymbols(vm.platzTypen.firmen)">
        <dv-pensum-icon
            [value]="symbol"
            [tooltip]="vm.getFirmenNames(vm.platzTypen.firmen, symbol)"
            [tooltip-translated]="true"
            [color-mode]="vm.colorMode">
        </dv-pensum-icon>
    </li>
</ul>
