/*
 * Copyright © 2021 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {ErrorService} from '@dv/kitadmin/core/errors';
import type {MandantSearchFilter} from '@dv/kitadmin/search';
import {ANY_MANDANT} from '@dv/kitadmin/search';
import type {SearchResultEntry} from '@dv/shared/code';
import angular from 'angular';
import type moment from 'moment';
import type {MaintenanceService} from '../../service/maintenanceService';

const componentConfig: angular.IComponentOptions = {
    transclude: false,
    bindings: {},
    template: require('./dvb-maintenance-delete-rechnungs-positionen.html'),
    controllerAs: 'vm',
};

export class DvbMaintenanceDeleteRechnungsPositionen {
    public static $inject: readonly string[] = [
        'maintenanceService',
        'errorService',
    ];

    public kinderOrt: SearchResultEntry | null = null;
    public gueltigAb: moment.Moment | null = null;
    public gueltigBis: moment.Moment | null = null;

    public isLoading: boolean = false;
    public mandantFilter: MandantSearchFilter = ANY_MANDANT;

    public constructor(
        private readonly maintenanceService: MaintenanceService,
        private readonly errorService: ErrorService,
    ) {
    }

    public deleteMandantRechnungsPositionen(form: angular.IFormController): void {
        this.errorService.clearAll();
        this.errorService.handleValidationError(form.$valid, 'ERRORS.ERR_INCOMPLETE_FORM');

        if (!form.$valid || !this.gueltigAb || !this.gueltigBis) {
            return;
        }

        this.isLoading = true;
        this.maintenanceService.deleteRechnungsPositionen(this.kinderOrt!.id, this.gueltigAb, this.gueltigBis)
            .finally(() => this.isLoading = false);
    }

}

componentConfig.controller = DvbMaintenanceDeleteRechnungsPositionen;
angular.module('kitAdmin').component('dvbMaintenanceDeleteRechnungsPositionen', componentConfig);
