<div class="dvb-monats-belegung" ng-class="{'readonly': vm.readonly}">
    <form name="monatsbelegungForm" novalidate>
        <table class="table">
            <thead class="table-header">
            <tr>
                <td data-translate="KIND.MONATSBELEGUNG.TAG"></td>
                <td><!-- Belegung Icon --></td>
                <td data-translate="KIND.MONATSBELEGUNG.VON" class="reduce-width"></td>
                <td data-translate="KIND.MONATSBELEGUNG.BIS" class="reduce-width"></td>
                <td data-translate="COMMON.GRUPPE.SINGULAR"></td>
                <td ng-if="!vm.parentView" data-translate="KIND.MONATSBELEGUNG.PLATZART"></td>
            </tr>
            </thead>

            <tbody ng-repeat="inputRow in vm.inputRows"
                   ng-init="rowNr = $index">

            <tr ng-if="inputRow.betreuungsZeitraeumeIds.length === 0 || (inputRow.areAllSchliesstage && inputRow.allEmpty)"
                class="empty-day">
                <td ng-bind="inputRow.date | amDateFormat:'dd, DD.MM.YYYY'"></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td ng-if="!vm.parentView"></td>
            </tr>

            <tr ng-if="!inputRow.areAllSchliesstage || !inputRow.allEmpty">
                <td>
                    <strong ng-bind="inputRow.date | amDateFormat:'dd, DD.MM.YYYY'"></strong>
                    <i ng-show="inputRow.isOverMaxDailyHours"
                       class="fa fa-exclamation-triangle"
                       uib-tooltip="{{'KIND.MONATSBELEGUNG.MAX_DAILY_HOURS_WARNING' | translate: {stunden: vm.kita.maxDailyHours} }}">
                    </i>
                </td>
                <td ng-if="inputRow.customFieldValues.length > 0 && (!vm.readonly || inputRow.hasCustomFieldValues)"
                    colspan="{{vm.parentView ? 4 : 5}}">
                    <div ng-class="{'hidden-print': !inputRow.hasCustomFieldValues}"
                         class="smaller-abstand-top">
                        <h3 data-translate="COMMON.CUSTOM_FIELD.MONATSBELEGUNG_MF"
                            data-translate-interpolation="messageformat"
                            data-translate-value-count="{{inputRow.customFieldValues.length}}">
                        </h3>
                        <dvb-custom-field-values ng-if="inputRow.customFieldValues"
                                                 custom-field-values="inputRow.customFieldValues"
                                                 click-to-edit="false"
                                                 small="true"
                                                 on-change="inputRow.initHasCustomFieldValues()"
                                                 dvb-auth
                                                 require-permission="{{vm.parentView ? 'module:anwesenheit' : 'kita:manage:general'}}"
                                                 require-condition="!vm.readonly"
                                                 on-unauthorised-disable>
                        </dvb-custom-field-values>
                    </div>
                </td>
            </tr>


            <tr ng-show="!inputRow.areAllSchliesstage || !inputRow.allEmpty"
                ng-repeat-start="betreuungsZeitraumId in inputRow.betreuungsZeitraeumeIds"
                ng-init="zeitInput = inputRow.inputsByZeitraumId.get(betreuungsZeitraumId)"
                ng-class="{'hidden-print': !zeitInput.anwesenheit.von && !zeitInput.anwesenheit.bis && !$first}">

                <td rowspan="{{zeitInput.rowSpan}}" class="no-border-top">
                    <div ng-if="inputRow.inputsByZeitraumId.size > 1"
                         ng-class="{'hidden-print': !zeitInput.anwesenheit.von && !zeitInput.anwesenheit.bis}"
                         class="small-padding-left">
                        <p ng-bind="zeitInput.anwesenheit.betreuungsZeitraum.name"></p>
                        <p ng-if="zeitInput.showInput" class="text-nowrap"
                           ng-bind="zeitInput.anwesenheit.betreuungsZeitraum.von.format('HH:mm') + ' - ' + zeitInput.anwesenheit.betreuungsZeitraum.bis.format('HH:mm')">
                        </p>
                    </div>
                    <p ng-if="inputRow.areAllSchliesstage"
                       class="warning"
                       data-translate="COMMON.SCHLIESSTAGE.SINGULAR">
                    </p>
                </td>
                <td>
                    <dvb-belegungs-zustand-icon belegungs-zustand="zeitInput.belegungsZustand">
                    </dvb-belegungs-zustand-icon>
                </td>
                <td>
                    <input ng-if="zeitInput.showInput && (!zeitInput.isSchliesstag || zeitInput.anwesenheit.von)"
                           type="text"
                           name="{{'von-' + rowNr + '-' + betreuungsZeitraumId}}"
                           tabindex="0"
                           ng-disabled="vm.readonly"
                           ng-model="zeitInput.anwesenheit.von"
                           ng-model-options="{allowInvalid: true}"
                           ng-change="vm.onChangeZeit(inputRow)"
                           dvb-select-on-click
                           dvb-time-input
                           dvb-anwesenheits-zeit-constraint
                           constraints="vm.constraintsByWeekDay[inputRow.dayOfWeek]['BRING_ZEITEN']"
                           maxlength="10"
                           class="form-control input-sm no-margin time-input"
                           ng-class="{'hidden-print': !zeitInput.anwesenheit.von && !zeitInput.anwesenheit.bis}"
                           uib-tooltip="{{monatsbelegungForm['von-' + rowNr + '-' + betreuungsZeitraumId].$invalid ?
                                ('KIND.MONATSBELEGUNG.ALLOWED_TIMES' | translate:{'constraints': vm.constraintStringsByWeekDay[inputRow.dayOfWeek]['BRING_ZEITEN']}) :
                                ('KIND.MONATSBELEGUNG.VON' | translate)}}"
                           tooltip-placement="top-left">
                </td>
                <td>
                    <input ng-if="zeitInput.showInput && (!zeitInput.isSchliesstag || zeitInput.anwesenheit.bis)"
                           type="text"
                           name="{{'bis-' + rowNr + '-' + betreuungsZeitraumId}}"
                           tabindex="0"
                           ng-disabled="vm.readonly"
                           ng-model="zeitInput.anwesenheit.bis"
                           ng-model-options="{allowInvalid: true}"
                           ng-change="vm.onChangeZeit(inputRow)"
                           dvb-select-on-click
                           dvb-time-input
                           dvb-anwesenheits-zeit-constraint
                           constraints="vm.constraintsByWeekDay[inputRow.dayOfWeek]['ABHOL_ZEITEN']"
                           maxlength="10"
                           class="form-control input-sm no-margin time-input"
                           ng-class="{'hidden-print': !zeitInput.anwesenheit.von && !zeitInput.anwesenheit.bis}"
                           uib-tooltip="{{monatsbelegungForm['bis-' + rowNr + '-' + betreuungsZeitraumId].$invalid ?
                                ('KIND.MONATSBELEGUNG.ALLOWED_TIMES' | translate:{'constraints': vm.constraintStringsByWeekDay[inputRow.dayOfWeek]['ABHOL_ZEITEN']}) :
                                ('KIND.MONATSBELEGUNG.BIS' | translate)}}"
                           tooltip-placement="top-left">
                </td>
                <td>
                    <div ng-class="{'hidden-print': !zeitInput.anwesenheit.von && !zeitInput.anwesenheit.bis}">
                        <dvb-monats-belegung-select-gruppe
                            ng-if="!vm.readonly && (!vm.parentView || zeitInput.availableGruppen.length > 1)"
                            current-gruppe="zeitInput.selectedGruppe"
                            gruppen="zeitInput.availableGruppen"
                            active="true"
                            select-gruppe="vm.selectGruppe(gruppe, zeitInput, inputRow)"
                            dvb-right-click="vm.repeatGruppe($event, zeitInput, inputRow)">
                        </dvb-monats-belegung-select-gruppe>
                        <span ng-if="vm.readonly || vm.parentView && zeitInput.availableGruppen.length <= 1"
                              ng-bind="zeitInput.selectedGruppe.getDisplayName()"></span>
                    </div>
                </td>
                <td ng-if="!vm.parentView">
                    <button type="button"
                            tabindex="1"
                            class="btn btn-link btn-margin-sm feld"
                            ng-click="vm.openPopover($event, zeitInput.platzTyp)"
                            dvb-right-click="vm.repeatZuweisung($event, zeitInput.platzTyp)">
                        <dvb-platz-typen class="inline-icons no-margin"
                                         platz-typen="zeitInput.platzTyp"
                                         color-mode="'guide'">
                        </dvb-platz-typen>
                    </button>
                </td>
            </tr>

            <tr ng-repeat-end=""
                ng-if="zeitInput.anwesenheit.customFieldValues.length > 0 && zeitInput.showInput && !zeitInput.isSchliesstag"
                class="custom-fields">
                <td colspan="{{vm.parentView ? 4 : 5}}">
                    <div ng-if="!vm.readonly || zeitInput.hasCustomFieldValues"
                         ng-class="{'hidden-print': !zeitInput.hasCustomFieldValues}">
                        <h3 data-translate="COMMON.CUSTOM_FIELD.MONATSBELEGUNG_MF"
                            data-translate-interpolation="messageformat"
                            data-translate-value-count="{{zeitInput.anwesenheit.customFieldValues.length}}">
                        </h3>
                        <dvb-custom-field-values ng-if="zeitInput.anwesenheit.customFieldValues"
                                                 custom-field-values="zeitInput.anwesenheit.customFieldValues"
                                                 click-to-edit="false"
                                                 small="true"
                                                 on-change="zeitInput.initHasCustomFieldValues()"
                                                 dvb-auth
                                                 require-permission="{{vm.parentView ? 'module:anwesenheit' : 'kita:manage:general'}}"
                                                 require-condition="!vm.readonly"
                                                 on-unauthorised-disable>
                        </dvb-custom-field-values>
                    </div>
                </td>
            </tr>
            </tbody>
        </table>
    </form>

    <button ng-if="!vm.readonly"
            type="button"
            class="btn btn-link hidden-print"
            data-translate="KIND.MONATSBELEGUNG.ZEITEN_LEEREN"
            ng-click="vm.onEmptyAnwesenheitsZeiten()">
    </button>

    <dvb-zuweisen-popover ng-if="!vm.parentView"
                          element-id="'anwesenheitsformPopover'"
                          kita="vm.kita"
                          zuweisung-ab="vm.zuweisungAb"
                          set-subventioniert="vm.lastSelectedPlatzTypAnwesenheitsZeit.setSubventioniert(subventioniertesKontingent)"
                          set-privat="vm.lastSelectedPlatzTypAnwesenheitsZeit.setPrivat()"
                          set-firma="vm.lastSelectedPlatzTypAnwesenheitsZeit.setFirma(firmenKontingent)">
    </dvb-zuweisen-popover>
</div>
