<div class="dvb-mandant-features">
    <div class="col-md-7 big-abstand-bottom">
        <h2 data-translate="ADMINISTRATION.MANDANTEN"></h2>

        <div class="row">
            <div class="col-md-6">
                <input type="text"
                       ng-model="vm.searchText"
                       ng-change="vm.filterMandanten()"
                       ng-attr-placeholder="{{'ADMINISTRATION.FILTER_BY_NAME' | translate}}"
                       uib-tooltip="{{'ADMINISTRATION.FILTER_BY_NAME' | translate}}"
                       tooltip-placement="top-left"
                       class="form-control">
            </div>
        </div>

        <div class="big-abstand-bottom">
            <dvb-pages items="vm.filteredMandantFeatures"
                       total-items="vm.filteredMandantFeatures.length"
                       is-loading="vm.isLoading"
                       items-per-page="vm.itemsPerPage"
                       current-page="vm.currentPage"
                       entity-name="'COMMON.MANDANT.MF'"
                       on-update-page="vm.updatePage(page)"
                       on-update-items-per-page="vm.onUpdateItemsPerPage(itemsPerPage)">
                <page-items dvb-accordions>

                    <dvb-accordion is-first="$first"
                                   enable-edit="true"
                                   on-submit="vm.updateFeaturePermissions(permissions, context)"
                                   on-cancel="vm.cancel(permissions)"
                                   ng-repeat="permissions in (vm.filteredMandantFeatures | limitTo: vm.itemsPerPage : ((vm.currentPage - 1) * vm.itemsPerPage)) track by permissions.mandantId">
                        <panel-header class="full-width-block">
                            <div class="row">
                                <div class="col-xs-9">
                                    <p ng-bind="permissions.mandantName"></p>
                                </div>
                                <div class="col-xs-3">
                                    <p ng-bind="permissions.features.length"></p>
                                </div>
                            </div>
                        </panel-header>
                        <panel-content>
                            <div ng-repeat="feature in permissions.features" class="big-abstand-bottom">
                                <h3 data-translate="{{'ADMINISTRATION.MANDANT_FEATURES.TYPES.' + feature.featureType}}"></h3>

                                <p data-translate="ADMINISTRATION.MANDANT_FEATURES.ENABLED_FOR_MF"
                                   data-translate-interpolation="messageformat"
                                   data-translate-value-for-all="{{feature.allEnabled}}"
                                   data-translate-value-count="{{feature.kinderOrte.length}}">
                                </p>

                                <div ng-if="!feature.allEnabled">
                                    <p ng-repeat="kinderOrt in feature.kinderOrte"
                                       ng-init="searchResultEntry = kinderOrt.toSearchResultEntry()">
                                        <a ui-sref="base.kinderort.profil({id: kinderOrt.id})">
                                            <dv-search-result-icon [entry]="searchResultEntry"></dv-search-result-icon>
                                            <span ng-bind="kinderOrt.getDisplayName()"></span>
                                        </a>
                                    </p>
                                </div>
                            </div>
                        </panel-content>
                        <edit-content>
                            <dvb-mandant-feature-form mandant-features="permissions.workingCopy">
                            </dvb-mandant-feature-form>
                        </edit-content>
                    </dvb-accordion>

                </page-items>
            </dvb-pages>
        </div>
    </div>
</div>
