/*
 * Copyright © 2022 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {OnChanges, SimpleChanges} from '@angular/core';
import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import {DisplayTask} from '@dv/kitadmin/models';
import {Translator} from '@dv/shared/translator';
import {TranslocoModule} from '@jsverse/transloco';

@Component({
    selector: 'dv-display-task',
    templateUrl: './display-task.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        TranslocoModule,
    ],
})
export class DisplayTaskComponent implements OnChanges {

    @Input()
    public task!: DisplayTask;

    public translationParams: { [index: string]: string | number } = {};

    public constructor(
        public readonly translator: Translator,
    ) {
    }

    public ngOnChanges(changes: SimpleChanges): void {
        if (changes.task) {
            this.translationParams = changes.task.currentValue.getTranslationParams(this.translator);
            this.translationParams.entityDisplayName = this.task.principalEntity?.displayName ?? '';
        }
    }
}
