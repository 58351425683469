<form (ngSubmit)="submit()" novalidate *transloco="let t">
    <div class="modal-body">
        <h1>{{ t('CHECK_IN.CUSTOM_FIELD.LINK') }}</h1>
        @if (customFields.length) {
            <select #customField class="form-control">
                @for (field of customFields; track field.id) {
                    <option [value]="field.id">{{ field.name }}</option>
                }
            </select>
        } @else {
            <span>{{ t('CHECK_IN.CUSTOM_FIELD.NO_LINK_FIELD_AVAILABLE') }}</span>
        }
    </div>
    <div class="modal-footer big-abstand-top">
        <dvlib-button-list>
            <dvlib-loading-button type="submit"
                                  [disabled]="customFields.length === 0"
                                  [isLoading]="(isLoading$ | async)">
                <span>{{ t('CHECK_IN.CUSTOM_FIELD.CREATE') }}</span>
            </dvlib-loading-button>
            <button type="button" class="btn btn-link" (click)="hide()">
                {{ t('COMMON.ABBRECHEN') }}
            </button>
        </dvlib-button-list>
    </div>
</form>
