/*
 * Copyright © 2018 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import {KIND_ABWEICHUNGS_MELDUNGEN} from '@dv/kitadmin/kind-abweichungs-meldungen';
import type {DisplayTask, Favorit} from '@dv/kitadmin/models';
import {AbstractPagesController, TASK_CATEGORY} from '@dv/kitadmin/models';
import type {SearchService} from '@dv/kitadmin/search';
import {checkPresent, DvbRestUtil, DvbUriUtil, isPresent, NamedEntityType, SEARCH_RESULT_TYPES} from '@dv/shared/code';
import type {StateService} from '@uirouter/core';
import angular from 'angular';
import type {DvbPagination} from '../../../common/directive/dvb-pagination';
import {DvbRestUtilAngularJS} from '../../../common/service/rest/dvbRestUtilAngularJS';
import type {TasksService} from '../../../common/service/rest/tasksService';
import {FilterOptionHelper} from '../../../filter/shared/FilterOptionHelper';
import {VERFUEGUNG_OVERVIEW_STATE} from '../../../kibon/kibon-states';
import {TAGESINFO_STATE} from '../../../kind/kind-states';
import {MONAT_ZUWEISEN_STATE, ZUWEISEN_MULTIPLE_SIMPLE_STATE} from '../../../kind/zuweisung';
import {EXTERNAL_ANMELDUNGEN_STATE} from '../../../kinderort/kinderort-external-anmeldung-states';
import {KIBON_MUTATIONSLAUF_STATE} from '../../../kinderort/kinderort-settings-states';
import type {TaskFilter} from '../../models/TaskFilter';

const componentConfig: angular.IComponentOptions = {
    transclude: false,
    bindings: {
        loadTasks: '<',
        favoriten: '<',
        onLoading: '&',
    },
    require: {
        pagination: 'dvbPagination',
    },
    template: require('./dvb-dashboard-tasks.html'),
    controllerAs: 'vm',
};

export class DvbDashboardTasks extends AbstractPagesController<DisplayTask> implements angular.IController {
    public static override $inject: readonly string[] = ['$q', '$state', 'searchService', 'tasksService'];

    public readonly pagination!: DvbPagination<DisplayTask>;
    public readonly onLoading?: () => void;
    public loadTasks!: boolean;
    public favoriten: Favorit[] = [];

    public taskFilter!: TaskFilter;
    public selectedCategoryCount: number = 0;
    public showReset: boolean = false;
    public filterOptionHelper: FilterOptionHelper = new FilterOptionHelper(this.updateSelectedTasks.bind(this));

    private timeout?: angular.IDeferred<undefined> = undefined;

    public constructor(
        private readonly $q: angular.IQService,
        private $state: StateService,
        private searchService: SearchService,
        private tasksService: TasksService,
    ) {
        super();
        this.itemsPerPage = 5;
        this.taskFilter = this.tasksService.getTaskFilter();
    }

    public $onChanges(onChangesObj: angular.IOnChangesObject): void {
        if (!onChangesObj.loadTasks?.currentValue) {
            return;
        }

        this.pagination.reset();
        this.updateSelectedTasks();
        if (this.onLoading) {
            this.onLoading();
        }
    }

    public updateSelectedTasks(): void {
        this.selectedCategoryCount = this.taskFilter.getSelectedCategories().length;
        this.showReset = this.taskFilter.isFilterSet();

        this.pagination.reset();
        this.reloadItems();
    }

    public resetTaskFilter(): void {
        this.taskFilter.reset();
        this.updateSelectedTasks();
    }

    public reloadItems(): void {

        DvbRestUtilAngularJS.cancelRequest(this.timeout);
        this.timeout = this.$q.defer();
        this.isLoading = true;

        const params = {};
        this.setPagionationValues(params);
        const config = {timeout: this.timeout.promise};

        this.tasksService.getAllFiltered(this.taskFilter, params, config)
            .then(pageContainer => {
                this.pageContainer = pageContainer;
                this.isLoading = false;
            })
            .catch(err => {
                if (!DvbRestUtil.isRequestCancelled(err)) {
                    this.isLoading = false;
                }
            });
    }

    // eslint-disable-next-line complexity
    public getEntityHref(task: DisplayTask): string {
        const namedEntity = task.principalEntity!;
        const principalEntityId = checkPresent(namedEntity.entityId);
        const dtype = namedEntity.dtype === NamedEntityType.BELEGUNG ?
            NamedEntityType.BELEGUNG :
            SEARCH_RESULT_TYPES.check(checkPresent(namedEntity.dtype));

        switch (task.category) {
            case TASK_CATEGORY.KINDERORT_TARIF_FIRMENKONTINGENT: {
                const namedFirma = task.findEntity(NamedEntityType.FIRMA);

                return this.searchService.getEntityHref(
                    SEARCH_RESULT_TYPES.check(checkPresent(namedFirma.dtype)),
                    checkPresent(namedFirma.entityId));
            }
            case TASK_CATEGORY.KIND_BELEGUNG_PROVISORISCH:
            case TASK_CATEGORY.KIND_BELEGUNG_ANGEBOT: {
                const params = {
                    kinderOrtIds: DvbUriUtil.arrayToUriParam(
                        task.findEntities([NamedEntityType.KITA, NamedEntityType.TAGES_ELTERN])
                            .map(entity => entity.entityId!)),
                    kindId: principalEntityId,
                    zuweisungAb: DvbRestUtil.momentToLocalDate(task.zeitraumVon),
                };

                return this.$state.href(ZUWEISEN_MULTIPLE_SIMPLE_STATE.name, params);
            }
            case TASK_CATEGORY.KIND_RECHNUNGSDIFFERENZ:
                return this.$state.href('base.kind.leistungen',
                    {kindId: principalEntityId});
            case TASK_CATEGORY.GEBURTSTAG_KIND:
                return this.$state.href('base.kind.profil',
                    {kindId: principalEntityId});
            case TASK_CATEGORY.KONTAKTPERSON_ADRESSE:
                return this.$state.href('base.kontaktperson.profil',
                    {kontaktpersonId: principalEntityId});
            case TASK_CATEGORY.RECHNUNG_ZAHLUNGEN:
                return this.$state.href('base.rechnung.zahlungen',
                    {rechnungId: principalEntityId});
            case TASK_CATEGORY.MONATSBLATT: {
                const fraktionDtype = dtype.toString().toLowerCase();

                return this.$state.href(`base.fraktion.${fraktionDtype}.monatsblatt`, {
                    id: principalEntityId,
                    periode: task.zeitraumVon,
                });
            }
            case TASK_CATEGORY.MANDATORY_CUSTOM_FIELD:
                return this.getCustomFieldHref(task, principalEntityId);
            case TASK_CATEGORY.KIND_AUSTRITT_PROVISORISCH:
                return this.searchService.getEntityHref(
                    SEARCH_RESULT_TYPES.check(dtype),
                    principalEntityId,
                    'betreuung');
            case TASK_CATEGORY.COMMUNICATION_BRING_ABHOLZEITEN:
                return this.getKontaktEmailHref(task, principalEntityId);
            case TASK_CATEGORY.KIBON_EXCHANGE_VERFUEGUNG:
                return this.$state.href(VERFUEGUNG_OVERVIEW_STATE.name, {});
            case TASK_CATEGORY.KIBON_EXCHANGE_MUTATION:
                return this.$state.href(KIBON_MUTATIONSLAUF_STATE.name, {
                    id: principalEntityId,
                });
            case TASK_CATEGORY.CHECK_OUT_MISSING:
                return this.$state.href(TAGESINFO_STATE.name,
                    {kindId: principalEntityId, datum: task.zeitraumVon});
            case TASK_CATEGORY.KIND_ABWEICHUNGS_MELDUNG:
                return this.$state.href(KIND_ABWEICHUNGS_MELDUNGEN.name, {
                    id: principalEntityId,
                });
            case TASK_CATEGORY.PENDING_EXTERNAL_ANMELDUNG:
                return this.$state.href(EXTERNAL_ANMELDUNGEN_STATE.name, {
                    id: principalEntityId,
                });
            // eslint-disable-next-line no-fallthrough
            default: {
                const subRoute = namedEntity.dtype === NamedEntityType.KIND ? 'tarife.uebersicht' : 'tarife';

                return this.searchService.getEntityHref(
                    SEARCH_RESULT_TYPES.check(dtype),
                    principalEntityId,
                    subRoute);
            }
        }
    }

    private getCustomFieldHref(task: DisplayTask, principalEntityId: string): string {
        switch (task.principalType) {
            case NamedEntityType.BELEGUNG: {
                const kind = task.findEntity(NamedEntityType.KIND);
                const {to, params} =
                    this.searchService.getEntityHrefArgs('KIND', checkPresent(kind.entityId), 'zuweisen.multiple');

                params.zuweisungAb = task.zeitraumVon!.format('yyyy-MM-DD');
                params.kinderOrtIds =
                    DvbUriUtil.arrayToUriParam(
                        task.findEntities([NamedEntityType.KITA, NamedEntityType.BETREUUNGS_PERSON])
                            .map(entity => entity.entityId)
                            .filter(isPresent));

                return this.$state.href(to, params);
            }
            case NamedEntityType.BETREUUNGS_PERSON:
            case NamedEntityType.KIND:
            case NamedEntityType.KONTAKTPERSON: {
                return this.searchService
                    .getEntityHref(task.principalType, principalEntityId);
            }
            default: {
                throw new Error(`No CustomField Route implemented for Entity ${task.principalType}`);
            }
        }
    }

    private getKontaktEmailHref(task: DisplayTask, kindId: string): string {
        const kinderOrtId = task.findEntities([NamedEntityType.KITA, NamedEntityType.TAGES_ELTERN])[0].entityId;
        const gruppeId = task.findEntities(NamedEntityType.GRUPPE)[0].entityId;

        return this.$state.href(MONAT_ZUWEISEN_STATE.name, {
            kindId,
            kinderOrtId,
            gruppeId,
            zuweisungAb: DvbRestUtil.momentToLocalDate(task.zeitraumVon),
        });
    }
}

componentConfig.controller = DvbDashboardTasks;
angular.module('kitAdmin').component('dvbDashboardTasks', componentConfig);
