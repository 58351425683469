/*
 * Copyright © 2018 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import {UserRole} from '@dv/shared/roles';
import type {StateProvider} from '@uirouter/angularjs/lib/stateProvider';
import type {UIRouter} from '@uirouter/core';
import angular from 'angular';
import {trans} from '../common/i18n/trans';
import {INSTITUTION_STATE} from '../kibon/kibon-states';

angular.module('kitAdmin').config(administrationConfig);

export * from './component/dvb-active-users/dvb-active-users';
export * from './component/dvb-administration-main/dvb-administration-main';
export * from './component/dvb-administration-mandant-rechnung-heading/dvb-administration-mandant-rechnung-heading';
export * from './component/dvb-administration-mandanten-abrechnung/dvb-administration-mandanten-abrechnung';
export * from './component/dvb-maintenance-actions/dvb-maintenance-actions';
export * from './component/dvb-maintenance-config/dvb-maintenance-config';
export * from './component/dvb-maintenance-delete-angestellte/dvb-maintenance-delete-angestellte';
export * from './component/dvb-maintenance-delete-rechnungs-positionen/dvb-maintenance-delete-rechnungs-positionen';
export * from './component/dvb-maintenance-email-test/dvb-maintenance-email-test';
export * from './component/dvb-maintenance-kontakt-iban-migration/dvb-maintenance-kontakt-iban-migration';
export * from './component/dvb-maintenance-recalculate-mandant-rechnung/dvb-maintenance-recalculate-mandant-rechnung';
export * from './component/dvb-maintenance-timeout-test/dvb-maintenance-timeout-test';
export * from './component/dvb-maintenance/dvb-maintenance';
export * from './component/dvb-mandanten-overview/dvb-mandanten-overview';
export * from './component/dvb-mandant-features/dvb-mandant-features';
export * from './component/dvb-mandant-feature-form/dvb-mandant-feature-form';
export * from './component/dvb-module-list/dvb-module-list';
export * from './component/dvb-notifications/dvb-notifications';
export * from './component/dvb-recalculate-controlling-kinderort/dvb-recalculate-controlling-kinderort';
// Add New Component JS Above

administrationConfig.$inject = ['$stateProvider', '$uiRouterProvider'];

/* eslint-disable sonarjs/no-duplicate-string */
function administrationConfig($stateProvider: StateProvider, $uiRouterProvider: UIRouter): void {
    $stateProvider.state('base.administration', {
        url: '/administration',
        abstract: true,
        template: '<dvb-administration-main></dvb-administration-main>',
        resolve: {
            $title: [
                '$translate', ($translate: angular.translate.ITranslateService): angular.IPromise<string> =>
                    trans($translate, 'COMMON.ADMINISTRATION'),
            ],
        },
        data: {
            role: UserRole.MANDANT_ADMIN,
        },
    });
    $stateProvider.state('base.administration.mandanten', {
        url: '/mandanten',
        template: '<dvb-mandanten-overview></dvb-mandanten-overview>',
        resolve: {
            $title: [
                '$translate', ($translate: angular.translate.ITranslateService): angular.IPromise<string> =>
                    trans($translate, 'ADMINISTRATION.NAV_MANDANTEN_UEBERSICHT'),
            ],
        },
    });
    $stateProvider.state('base.administration.abrechnung', {
        url: '/abrechnung',
        template: '<dvb-administration-mandanten-abrechnung></dvb-administration-mandanten-abrechnung>',
        resolve: {
            $title: [
                '$translate', ($translate: angular.translate.ITranslateService): angular.IPromise<string> =>
                    trans($translate, 'ADMINISTRATION.NAV_MANDANTEN_ABRECHNUNG'),
            ],
        },
    });
    $stateProvider.state('base.administration.maintenance', {
        url: '/wartung',
        template: '<dvb-maintenance></dvb-maintenance>',
        data: {
            role: UserRole.MANDANT_ADMIN,
        },
        resolve: {
            $title: [
                '$translate', ($translate: angular.translate.ITranslateService): angular.IPromise<string> =>
                    trans($translate, 'ADMINISTRATION.NAV_MANDANTEN_MAINTENANCE'),
            ],
        },
    });
    $stateProvider.state('base.administration.kibonexchange', {
        url: '/kibonexchange',
        redirectTo: INSTITUTION_STATE.name,
    });
    $stateProvider.state('base.administration.features', {
        url: '/features',
        template: '<dvb-mandant-features></dvb-mandant-features>',
        resolve: {
            $title: [
                '$translate', ($translate: angular.translate.ITranslateService): angular.IPromise<string> =>
                    trans($translate, 'ADMINISTRATION.NAV_FEATURES'),
            ],
        },
    });
    $stateProvider.state('base.administration.users', {
        url: '/benutzer',
        abstract: true,
    });
    $stateProvider.state('base.administration.users.active', {
        url: '/aktiv',
        template: '<dvb-active-users></dvb-active-users>',
        resolve: {
            $title: [
                '$translate', ($translate: angular.translate.ITranslateService): angular.IPromise<string> =>
                    trans($translate, 'ADMINISTRATION.NAV_USERS'),
            ],
        },
    });

    $stateProvider.state('base.administration.users.notification', {
        url: '/benachrichtigung',
        template: '<dvb-notifications></dvb-notifications>',
        resolve: {
            $title: [
                '$translate', ($translate: angular.translate.ITranslateService): angular.IPromise<string> =>
                    trans($translate, 'ADMINISTRATION.NAV_NOTIFICATION'),
            ],
        },
    });
    /* Add New States Above */

    // Redirect to the default child state
    $uiRouterProvider.urlService.rules.when('/administration', '/administration/mandanten');
}
