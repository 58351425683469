/*
 * Copyright © 2022 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {ExternalAnmeldungConfig} from '@dv/kitadmin/models';
import {WILDCARD_TOKEN} from '@dv/shared/angular';
import {PERMISSION} from '@dv/shared/authentication/model';
import type {NoContent} from '@dv/shared/code';
import {UserRole} from '@dv/shared/roles';
import type {Ng1StateDeclaration} from '@uirouter/angularjs';
import type {Transition} from '@uirouter/core';
import type angular from 'angular';
import {trans} from '../common/i18n/trans';
import type {ExternalAnmeldungConfigService} from '../external/service/externalAnmeldungConfigService';
import * as schliesstageStates from '../schliesstage/schliesstage-states';

/* eslint-disable sonarjs/no-duplicate-string */
export const VOREINSTELLUNGEN_BASE_STATE = {
    name: 'base.voreinstellungen',
    url: '/voreinstellungen?currentPage&itemsPerPage',
    params: {
        currentPage: {value: '1', squash: true},
        itemsPerPage: {value: '10', squash: true},
    },
    abstract: true,
    template: '<dvb-voreinstellungen-main></dvb-voreinstellungen-main>',
    resolve: {
        currentPage: [
            '$transition$', ($transition$: Transition): string => $transition$.params().currentPage,
        ],
        itemsPerPage: [
            '$transition$', ($transition$: Transition): string => $transition$.params().itemsPerPage,
        ],
        $title: [
            '$translate', ($translate: angular.translate.ITranslateService): angular.IPromise<string> =>
                trans($translate, 'COMMON.NAV_VOREINSTELLUNGEN'),
        ],
    },
    data: {
        permission: {
            oneOf: [
                PERMISSION.KITA.VIEW_ANY,
                PERMISSION.SHARED.SCHLIESSTAGE_VIEW_ANY,
            ],
        },
    },
} satisfies Ng1StateDeclaration;
export const VOREINSTELLUNGEN_TARIFE_STATE = {
    name: `${VOREINSTELLUNGEN_BASE_STATE.name}.tarife`,
    url: '/tarife',
    template: `<dvb-entity-list type="tarif"
            title-key="COMMON.TARIF.MF"
            base-state="base.tarif"
            base-state-create="base.tarif-erstellen"
            current-page="$resolve.currentPage"
            items-per-page="$resolve.itemsPerPage"
            create-link-title-key="COMMON.TARIF.ERSTELLEN">
            <aside>
            <p><a ui-sref="base.voreinstellungen.parameter"
               data-translate="VOREINSTELLUNGEN.PARAMETER_SORTIERUNG"></a></p>
            <p dvb-auth require-role="ADMIN"><a ui-sref="base.voreinstellungen.stunden-kontingent-parameter-gueltigkeit"
               data-translate="VOREINSTELLUNGEN.STUNDEN_KONTINGENT_PARAMETER_GUELTIGKEIT"></a></p>
            <p><a ui-sref="base.voreinstellungen.tarife-archived({currentPage: 1})"
               data-translate="VOREINSTELLUNGEN.ARCHIVIERTE_TARIFE"></a></p>
            </aside>
        </dvb-entity-list>`,
    resolve: {
        $title: [
            '$translate', ($translate: angular.translate.ITranslateService): angular.IPromise<string> =>
                trans($translate, 'COMMON.TARIF.PLURAL'),
        ],
    },
    data: {
        permission: PERMISSION.KITA.VIEW_ANY,
    },
} satisfies Ng1StateDeclaration;
export const VOREINSTELLUNGEN_TARIFE_ARCHIVED_STATE = {
    name: `${VOREINSTELLUNGEN_BASE_STATE.name}.tarife-archived`,
    url: '/archived',
    template: `<dvb-entity-list type="tarif"
        title-key="COMMON.ARCHIVED_TARIF.MF"
        base-state="base.tarif"
        current-page="$resolve.currentPage"
        show-archived="true"
        items-per-page="$resolve.itemsPerPage"></dvb-entity-list>`,
    resolve: {
        $title: [
            '$translate', ($translate: angular.translate.ITranslateService): angular.IPromise<string> =>
                trans($translate, 'COMMON.ARCHIVED_TARIF.PLURAL'),
        ],
    },
} satisfies Ng1StateDeclaration;
export const VOREINSTELLUNGEN_PARAMETER_STATE = {
    name: `${VOREINSTELLUNGEN_BASE_STATE.name}.parameter`,
    url: '/parameter',
    template: '<dvb-tarif-parameter></dvb-tarif-parameter>',
    resolve: {
        $title: [
            '$translate', ($translate: angular.translate.ITranslateService): angular.IPromise<string> =>
                trans($translate, 'VOREINSTELLUNGEN.PARAMETER_SORTIERUNG'),
        ],
    },
} satisfies Ng1StateDeclaration;
export const VOREINSTELLUNGEN_DOKUMENT_LAYOUT_STATE = {
    name: `${VOREINSTELLUNGEN_BASE_STATE.name}.dokumentlayouts`,
    url: '/dokumentlayouts',
    template: `<dvb-entity-list type="dokumentlayout"
        title-key="COMMON.DOKUMENT_LAYOUT.MF"
        base-state="base.dokumentlayout"
        base-state-create="base.dokumentlayout-erstellen"
        current-page="$resolve.currentPage"
        items-per-page="$resolve.itemsPerPage"
        create-link-title-key="COMMON.DOKUMENT_LAYOUT.ERSTELLEN"></dvb-entity-list>`,
    resolve: {
        $title: [
            '$translate', ($translate: angular.translate.ITranslateService): angular.IPromise<string> =>
                trans($translate, 'COMMON.DOKUMENT_LAYOUT.PLURAL'),
        ],
    },
    data: {
        permission: PERMISSION.KITA.VIEW_ANY,
    },
} satisfies Ng1StateDeclaration;
export const VOREINSTELLUNGEN_BETREUUNGSFAKTOR_STATE = {
    name: `${VOREINSTELLUNGEN_BASE_STATE.name}.betreuungsfaktorregeln`,
    url: '/betreuungsfaktorregeln',
    template: `<dvb-entity-list type="betreuungsfaktor"
        title-key="COMMON.BETREUUNGSFAKTOR_REGEL.MF"
        base-state="base.betreuungsfaktorregel"
        base-state-create="base.betreuungsfaktorregel-erstellen"
        current-page="$resolve.currentPage"
        items-per-page="$resolve.itemsPerPage"
        create-link-title-key="COMMON.BETREUUNGSFAKTOR_REGEL.ERSTELLEN"></dvb-entity-list>`,
    resolve: {
        $title: [
            '$translate', ($translate: angular.translate.ITranslateService): angular.IPromise<string> =>
                trans($translate, 'COMMON.BETREUUNGSFAKTOR_REGEL.PLURAL'),
        ],
    },
    data: {
        permission: PERMISSION.KITA.VIEW_ANY,
    },
} satisfies Ng1StateDeclaration;
export const VOREINSTELLUNGEN_SCHLIESSTAGE_STATE = {
    name: `${VOREINSTELLUNGEN_BASE_STATE.name}.schliesstage`,
    url: '/schliesstage',
    template: `<dvb-entity-list type="schliesstage"
            title-key="COMMON.SCHLIESSTAGE.MF"
            base-state="${schliesstageStates.BASE_STATE.name}"
            base-state-create="${schliesstageStates.BASE_STATE_CREATE.name}"
            current-page="$resolve.currentPage"
            items-per-page="$resolve.itemsPerPage"
            create-permission="shared:schliesstage_manage:*"
            create-link-title-key="COMMON.SCHLIESSTAGE.ERSTELLEN"></dvb-entity-list>`,
    data: {
        permission: [
            {oneOf: [PERMISSION.MODULE.ANWESENHEIT, PERMISSION.MODULE.WORK_TIME_CONTROLLING]},
            PERMISSION.SHARED.SCHLIESSTAGE_VIEW + WILDCARD_TOKEN,
        ],
    },
    resolve: {
        $title: [
            '$translate', ($translate: angular.translate.ITranslateService): angular.IPromise<string> =>
                trans($translate, 'COMMON.SCHLIESSTAGE.PLURAL'),
        ],
    },
} satisfies Ng1StateDeclaration;
export const VOREINSTELLUNGEN_CUSTOM_FIELD_STATE = {
    name: `${VOREINSTELLUNGEN_BASE_STATE.name}.felder`,
    url: '/felder',
    template: '<dvb-voreinstellungen-custom-fields></dvb-voreinstellungen-custom-fields>',
    data: {
        role: UserRole.SUPPORT_ADMIN,
    },
    resolve: {
        $title: [
            '$translate', ($translate: angular.translate.ITranslateService): angular.IPromise<string> =>
                trans($translate, 'COMMON.CUSTOM_FIELD.PLURAL'),
        ],
    },
} satisfies Ng1StateDeclaration;
export const VOREINSTELLUNGEN_WOCHENPLAN_STATE = {
    name: `${VOREINSTELLUNGEN_BASE_STATE.name}.wochenplaene`,
    url: '/wochenplaene',
    template: `<dvb-entity-list type="wochenplan"
        title-key="COMMON.WOCHENPLAN.MF"
        base-state="base.wochenplan"
        current-page="$resolve.currentPage"
        items-per-page="$resolve.itemsPerPage"></dvb-entity-list>`,
    resolve: {
        $title: [
            '$translate', ($translate: angular.translate.ITranslateService): angular.IPromise<string> =>
                trans($translate, 'COMMON.WOCHENPLAN.PLURAL'),
        ],
    },
} satisfies Ng1StateDeclaration;

export const VOREINSTELLUNGEN_EXTERNAL_ANMELDUNG_STATE = {
    name: `${VOREINSTELLUNGEN_BASE_STATE.name}.externalanmeldung`,
    url: '/external-anmeldung',
    component: 'dvbVoreinstellungenExternalAnmeldung',
    data: {
        permission: PERMISSION.KITA.BETREUUNG_ADMIN + WILDCARD_TOKEN,
    },
    resolve: {
        config: [
            'externalAnmeldungConfigService', (
                externalAnmeldungConfigService: ExternalAnmeldungConfigService,
            ): angular.IPromise<ExternalAnmeldungConfig | NoContent> =>
                externalAnmeldungConfigService.getCurrentConfig(),
        ],
        $title: [
            '$translate', ($translate: angular.translate.ITranslateService): angular.IPromise<string> =>
                trans($translate, 'EXTERNAL_ANMELDUNG.CONFIG.TITLE'),
        ],
    },
} satisfies Ng1StateDeclaration;

export const VOREINSTELLUNGEN_STATES = {
    VOREINSTELLUNGEN_BASE_STATE,
    VOREINSTELLUNGEN_TARIFE_STATE,
    VOREINSTELLUNGEN_TARIFE_ARCHIVED_STATE,
    VOREINSTELLUNGEN_PARAMETER_STATE,
    VOREINSTELLUNGEN_DOKUMENT_LAYOUT_STATE,
    VOREINSTELLUNGEN_BETREUUNGSFAKTOR_STATE,
    VOREINSTELLUNGEN_SCHLIESSTAGE_STATE,
    VOREINSTELLUNGEN_CUSTOM_FIELD_STATE,
    VOREINSTELLUNGEN_WOCHENPLAN_STATE,
    VOREINSTELLUNGEN_EXTERNAL_ANMELDUNG_STATE,
};
