/**
 * Kita-Scheduler
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

export type WorkTimeControllingPermissionSet = 'ADMIN' | 'BETRIEBS_LEITUNG' | 'CONTROL';

export const WorkTimeControllingPermissionSet = {
    ADMIN: 'ADMIN' as WorkTimeControllingPermissionSet,
    BETRIEBS_LEITUNG: 'BETRIEBS_LEITUNG' as WorkTimeControllingPermissionSet,
    CONTROL: 'CONTROL' as WorkTimeControllingPermissionSet
};

