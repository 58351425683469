/*
 * Copyright © 2023 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import {CommonModule} from '@angular/common';
import type {AfterViewInit, OnInit} from '@angular/core';
import {ChangeDetectionStrategy, Component, effect, ElementRef, inject, Input, signal, ViewChild} from '@angular/core';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {FormsModule} from '@angular/forms';
import {ErrorService} from '@dv/kitadmin/core/errors';
import {type KinderOrt} from '@dv/kitadmin/models';
import {ConfirmComponent} from '@dv/kitadmin/ui';
import {
    ButtonComponent,
    ButtonListComponent,
    ConfirmDialogComponent,
    DialogComponent,
    IconComponent,
    LoadingButtonComponent,
    RequirePermissionStructuralDirective,
    SpinnerComponent,
} from '@dv/shared/angular';
import {isPresent, Persisted} from '@dv/shared/code';
import {TranslocoModule} from '@jsverse/transloco';
import type {UiOnParamsChanged} from '@uirouter/angular';
import {UIRouterModule} from '@uirouter/angular';
import {Transition} from '@uirouter/core';
import type moment from 'moment';
import {debounceTime, fromEvent, merge, take, tap} from 'rxjs';
import {TerminFormComponent} from 'src/app/personal/termin/termin-form/termin-form.component';
import {
    TimelineCalendarComponent,
} from '../../../../calendar/timeline/component/timeline-calendar/timeline-calendar.component';
import type {CalendarTranslation} from '../../../../calendar/timeline/model/CalendarTranslation';
import {TerminFormDialogComponent} from '../../../../personal/termin/termin-form-dialog/termin-form-dialog.component';
import {PersonalTimelineStore} from '../service/personal-timeline.store';
import {PersonalplanungCopyFormComponent} from './copy/personalplanung-copy-form.component';
import {PersonalplanungCopyStore} from './copy/personalplanung-copy.store';
import {PersonalplanungEmailFormComponent} from './email/personalplanung-email-form.component';
import {PersonalplanungEmailStore} from './email/personalplanung-email.store';
import {PersonalSortDialogComponent} from './personal-sort-dialog/personal-sort-dialog.component';
import {PersonalplanungEditTimesStore} from './personalplanung-edit-times.store';
import {PersonalplanungFilterComponent} from './personalplanung-filter/personalplanung-filter.component';
import {PersonalplanungTermineStore} from './personalplanung-termine.store';
import {PersonalplanungStore} from './personalplanung.store';
import {TerminDeleteDialogComponent} from './termin-delete-dialog/termin-delete-dialog.component';
import {TerminUpdateModeDialogComponent} from './termin-update-mode-dialog/termin-update-mode-dialog.component';
import {ZuweisbareDiensteComponent} from './zuweisbare-dienste/zuweisbare-dienste.component';
import {ZuweisungEditTimeFormComponent} from './zuweisung-edit-time-form/zuweisung-edit-time-form.component';

const RESIZE_DEBOUNCE_TIME = 20;

@Component({
    selector: 'dv-personalplanung',
    standalone: true,
    imports: [
        CommonModule,
        TimelineCalendarComponent,
        ZuweisbareDiensteComponent,
        PersonalplanungFilterComponent,
        SpinnerComponent,
        TranslocoModule,
        PersonalplanungCopyFormComponent,
        PersonalSortDialogComponent,
        DialogComponent,
        ConfirmComponent,
        FormsModule,
        LoadingButtonComponent,
        TerminFormComponent,
        TerminFormDialogComponent,
        PersonalplanungEmailFormComponent,
        UIRouterModule,
        IconComponent,
        TerminUpdateModeDialogComponent,
        ZuweisungEditTimeFormComponent,
        TerminDeleteDialogComponent,
        ButtonListComponent,
        ButtonComponent,
        RequirePermissionStructuralDirective,
        ConfirmDialogComponent,
    ],
    providers: [
        PersonalplanungStore,
        PersonalplanungCopyStore,
        PersonalplanungTermineStore,
        PersonalplanungEmailStore,
        PersonalplanungEditTimesStore,
    ],
    styleUrl: './personalplanung.component.scss',
    templateUrl: './personalplanung.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PersonalplanungComponent implements OnInit, UiOnParamsChanged, AfterViewInit {

    @Input({required: true})
    public set kinderOrt(kinderOrt: Persisted<KinderOrt>) {
        this.store.kinderOrt.set(kinderOrt);
    }

    @Input({required: true})
    public $transition$!: Transition;

    @ViewChild('personalplanungHeader')
    public personalplanungHeader!: ElementRef;

    public readonly element = inject(ElementRef);
    public readonly store = inject(PersonalplanungStore);
    public readonly timelineStore = inject(PersonalTimelineStore);
    public readonly copyStore = inject(PersonalplanungCopyStore);
    public readonly emailStore = inject(PersonalplanungEmailStore);
    public readonly terminStore = inject(PersonalplanungTermineStore);
    private readonly errorService = inject(ErrorService);

    public calendarTranslation: CalendarTranslation = {
        groupAddResourceButton: 'PERSONAL.PLANUNG.ADDITIONAL_ANGESTELLTE',
        resourceSearchPlaceholder: 'PERSONAL.PLANUNG.SEARCH_ANGESTELLTE',
        dayInfo: 'PERSONAL.PLANUNG.DAY_INFO',
    };

    private pathParamsInitialized = false;
    private applyPathParamsEffect = effect(() => {
        const displayMode = this.timelineStore.displayMode();
        const date = this.timelineStore.selectedDate();

        if (this.pathParamsInitialized) {
            this.$transition$.router.stateService.go('.', {displayMode, date}, {location: true});
        }

        this.pathParamsInitialized = true;
    });

    private windowResize$ = merge(
        fromEvent(window, 'resize'),
        fromEvent(window, 'scroll').pipe(take(1)),
    ).pipe(
        takeUntilDestroyed(),
        debounceTime(RESIZE_DEBOUNCE_TIME),
        tap(() => this.setupCalendarStickyValues()),
    ).subscribe();
    public calendarScrollBeforeSticky = signal(0);
    public calendarStickyTopPos = signal(0);

    private offsetToCalc: number = 0;

    public ngOnInit(): void {
        this.uiOnParamsChanged(this.$transition$.params());
    }

    public uiOnParamsChanged(newParams: { [paramName: string]: any }): void {
        if (isPresent(newParams.displayMode)) {
            this.timelineStore.displayMode.set(newParams.displayMode);
        }
        if (isPresent(newParams.date)) {
            this.timelineStore.selectedDate.set(newParams.date);
        }
    }

    public closeConflicDialog(): void {
        this.errorService.clearAll();
        this.copyStore.copyConflictDialogOpen.set(false);
    }

    public selectDate(date: moment.Moment): void {
        this.timelineStore.displayMode.set('day');
        this.timelineStore.selectedDate.set(date);
    }

    public ngAfterViewInit(): void {
        const myselfElement = this.element.nativeElement.getBoundingClientRect();
        this.offsetToCalc = myselfElement.top - this.element.nativeElement.offsetTop;
    }

    private setupCalendarStickyValues(): void {
        this.calendarScrollBeforeSticky.set(this.element.nativeElement.offsetTop + this.offsetToCalc);
        const navElement = document.getElementsByTagName('nav')[0];
        const navigationPlusHeaderSize = navElement.offsetHeight
            + (this.personalplanungHeader?.nativeElement.offsetHeight || 0);
        this.calendarStickyTopPos.set(navigationPlusHeaderSize);
    }
}
