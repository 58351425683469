import {ChangeDetectionStrategy, Component, EventEmitter, Input, Output} from '@angular/core';
import {Termin} from '@dv/kitadmin/models';
import type {LoadingState} from '@dv/shared/angular';
import {DialogComponent} from '@dv/shared/angular';
import {JaxTerminType} from '@dv/shared/backend/model/jax-termin-type';
import {TerminFormComponent} from '../termin-form/termin-form.component';

@Component({
    selector: 'dv-termin-form-dialog',
    standalone: true,
    imports: [
        DialogComponent,
        TerminFormComponent,
    ],
    templateUrl: './termin-form-dialog.component.html',
    styles: ':host {display: block;}',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TerminFormDialogComponent {
    @Input({required: true}) public termin!: Termin;
    @Input({required: true}) public dialogOpen = false;
    @Input({required: true}) public terminTypes: JaxTerminType[] = [];
    @Output() public readonly cancel: EventEmitter<null> = new EventEmitter();
    @Output() public readonly save: EventEmitter<{ context: LoadingState; termin: Termin }> = new EventEmitter();
}
