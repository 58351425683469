/*
 * Copyright © 2023 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {OnChanges} from '@angular/core';
import {ChangeDetectionStrategy, Component, EventEmitter, Input, Output} from '@angular/core';
import type {NgForm} from '@angular/forms';
import {FormsModule} from '@angular/forms';
import {ErrorService} from '@dv/kitadmin/core/errors';
import {KinderOrt} from '@dv/kitadmin/models';
import {SubmitCancelButtonsComponent} from '@dv/kitadmin/ui';
import {DateSwitcherComponent} from '@dv/shared/angular';
import {DvbDateUtil, DvbRestUtil, isNullish, isPresent} from '@dv/shared/code';
import {TranslocoModule} from '@jsverse/transloco';
import moment from 'moment';
import type {CopyRange} from './copy-event.type';

@Component({
    selector: 'dv-personalplanung-copy-form',
    standalone: true,
    imports: [
        TranslocoModule,
        DateSwitcherComponent,
        SubmitCancelButtonsComponent,
        FormsModule,
    ],
    templateUrl: './personalplanung-copy-form.component.html',
    styleUrl: './personalplanung-copy-form.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PersonalplanungCopyFormComponent implements OnChanges {

    @Input({required: true}) public kinderOrt!: KinderOrt;
    @Input({required: true}) public sourceVon!: moment.Moment;
    @Input({required: true}) public sourceBis!: moment.Moment;

    @Output() public readonly copyZuweisungen: EventEmitter<CopyRange> = new EventEmitter();
    @Output() public readonly cancel: EventEmitter<void> = new EventEmitter();

    public targetVon?: moment.Moment;
    public targetBis?: moment.Moment;

    public constructor(
        private readonly errorService: ErrorService,
    ) {
    }

    public ngOnChanges(): void {
        if (isNullish(this.targetVon) && isNullish(this.targetBis) && isPresent(this.sourceBis)) {
            this.targetVon = DvbDateUtil.startOfWeek(moment(this.sourceVon).add(1, 'week'));
            this.targetBis = DvbDateUtil.endOfWeek(moment(this.targetVon));
        }
    }

    public submitForm(form: NgForm): void {
        const sourceVon = DvbRestUtil.momentToLocalDate(this.sourceVon);
        const sourceBis = DvbRestUtil.momentToLocalDate(DvbDateUtil.endOfWeek(this.sourceBis));
        const targetVon = DvbRestUtil.momentToLocalDate(this.targetVon);
        const targetBis = DvbRestUtil.momentToLocalDate(DvbDateUtil.endOfWeek(this.targetBis));

        const datesValid = isPresent(sourceVon) && isPresent(sourceBis) && isPresent(targetVon) && isPresent(targetBis);
        this.errorService.handleValidationError(datesValid && !!form.valid, 'ERRORS.ERR_INCOMPLETE_FORM');
        if (!form.valid || !datesValid) {
            return;
        }

        this.copyZuweisungen.emit({
            sourceVon,
            sourceBis,
            targetVon,
            targetBis,
        });
    }
}
