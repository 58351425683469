@if (!showInput()) {
    <a href (click)="show($event)" [tooltip]="calendarTranslation.groupAddResourceButton | transloco">
        {{ calendarTranslation.groupAddResourceButton | transloco }}
    </a>
}

<ng-template #customListTemplate let-matches="matches" let-typeaheadTemplateMethods>
    <ul class="dropdown-menu">
        @for (match of matches; track match.item.id) {
            <li [class.active]="typeaheadTemplateMethods.isActive(match)"
                (mouseenter)="typeaheadTemplateMethods.selectActive(match)">
                <a href (click)="typeaheadTemplateMethods.selectMatch(match, $event); $event.preventDefault();">
                    {{ match.item | displayName }}
                </a>
            </li>
        }
    </ul>
</ng-template>

<ng-container *dvlibLet="getSelectableResources(group) as items">
    @if (showInput()) {
        <input #input
               [(ngModel)]="customSelectedResource"
               [optionsListTemplate]="customListTemplate"
               [typeahead]="items"
               (typeaheadOnSelect)="addResource($event, group)"
               typeaheadOptionField="getDisplayName()"
               (keyup)="hideOnEsc($event)"
               [placeholder]="calendarTranslation.resourceSearchPlaceholder | transloco"
               class="form-control input-sm no-margin">
    }
</ng-container>
