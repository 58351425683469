<div class="row" *transloco="let t">
    <div class="col-xs-6">
        <span>{{ rechnungsPosition.text }}</span>
        @if (isDeletable) {
            <button class="btn btn-link pull-right" (click)="onDelete.emit()">
                <i class="fa fa-window-close"></i>
            </button>
        }
    </div>
    <div class="col-xs-2 text-right text-nowrap">
        {{ t('ADMINISTRATION.VALUE_X', {count: rechnungsPosition.count}) }}
    </div>
    <div class="col-xs-2 text-right text-nowrap">
        {{ t('COMMON.BETRAG_VALUE', {betrag: rechnungsPosition.cost | number:'1.2-2'}) }}
    </div>
    <div class="col-xs-2 text-right text-nowrap">
        {{ t('COMMON.BETRAG_VALUE', {betrag: totalAmount | number: '1.2-2'}) }}
    </div>
</div>
