<form name="vereinbarungsTypeForm" (ngSubmit)="submitForm()" novalidate>
    <div class="modal-body">
        <h1 [transloco]="'COMMON.BETREUUNGSVEREINBARUNG.TYPE.AUSWAHL'"></h1>

        <div class="row">
            <div class="col-md-6 col-md-offset-3">
                <select [name]="'vereinbarungType'" class="form-control" [(ngModel)]="selectedType">
                    @for (type of types; track type.id) {
                        <option [ngValue]="type">
                            {{ type.name | dvbI18n }}
                        </option>
                    }
                </select>
            </div>
        </div>
    </div>


    <div class="modal-footer big-abstand-top">
        <dvlib-button-list>
            <dvlib-loading-button [type]="'submit'" [isLoading]="isLoading$ | async">
                <span [transloco]="'COMMON.BETREUUNGSVEREINBARUNG.DOWNLOAD'"></span>
            </dvlib-loading-button>
            <button type="button"
                    class="btn btn-link"
                    [transloco]="'COMMON.ABBRECHEN'"
                    (click)="hide()">
            </button>
        </dvlib-button-list>
    </div>
</form>
