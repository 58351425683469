/*
 * Copyright © 2023 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import {CommonModule} from '@angular/common';
import type {OnChanges, SimpleChanges} from '@angular/core';
import {ChangeDetectionStrategy, Component, EventEmitter, Input, Output} from '@angular/core';
import {NonNullableFormBuilder, ReactiveFormsModule, Validators} from '@angular/forms';
import {ErrorService} from '@dv/kitadmin/core/errors';
import {SubmitCancelButtonsComponent} from '@dv/kitadmin/ui';
import type {FormGroupSpec} from '@dv/shared/angular';
import {DatepickerTextfieldComponent} from '@dv/shared/angular';
import type {BackendLocalTimeHHMM} from '@dv/shared/backend/model/backend-local-time-HHMM';
import {COLOR_PALETTE, DvbRestUtil, isNullish, isPresent, TEXT_COLOR_PALETTE} from '@dv/shared/code';
import {TranslocoModule} from '@jsverse/transloco';
import {TooltipModule} from 'ngx-bootstrap/tooltip';
import {NgxColorsModule, validColorValidator} from 'ngx-colors';
import {Dienst} from '../../../../personal/konfiguration/Dienst';

interface DienstForm {
    name: string;
    kuerzel: string;
    backgroundColor: string;
    textColor: string;
    von: BackendLocalTimeHHMM | null;
    bis: BackendLocalTimeHHMM | null;
    pauseVon: BackendLocalTimeHHMM | null;
    pauseBis: BackendLocalTimeHHMM | null;
}

@Component({
    selector: 'dv-dienst-administration-form',
    standalone: true,
    imports: [
        CommonModule,
        ReactiveFormsModule,
        DatepickerTextfieldComponent,
        TranslocoModule,
        TooltipModule,
        SubmitCancelButtonsComponent,
        NgxColorsModule,
    ],
    templateUrl: './dienst-administration-form.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DienstAdministrationFormComponent implements OnChanges {

    @Input() public dienst?: Dienst;
    @Input() public isLoading?: boolean;

    @Output() public readonly save: EventEmitter<Dienst> = new EventEmitter();
    @Output() public readonly cancel: EventEmitter<void> = new EventEmitter();

    public form: FormGroupSpec<DienstForm> = this.fb.group({
        name: this.fb.control('', Validators.required),
        kuerzel: this.fb.control('', Validators.required),
        backgroundColor: this.fb.control('', [Validators.required, validColorValidator()]),
        textColor: this.fb.control('', [Validators.required, validColorValidator()]),
        von: this.fb.control<BackendLocalTimeHHMM | null>(null, Validators.required),
        bis: this.fb.control<BackendLocalTimeHHMM | null>(null, Validators.required),
        pauseVon: this.fb.control<BackendLocalTimeHHMM | null>(null),
        pauseBis: this.fb.control<BackendLocalTimeHHMM | null>(null),
    });

    protected readonly colorPalette = COLOR_PALETTE;
    protected readonly textColorPalette = TEXT_COLOR_PALETTE;

    public constructor(
        private errorService: ErrorService,
        private fb: NonNullableFormBuilder,
    ) {
    }

    public ngOnChanges(changes: SimpleChanges): void {
        const dienst = changes.dienst?.currentValue;
        if (isPresent(dienst)) {
            this.initializeForm(dienst);
        }
    }

    private initializeForm(dienst: Dienst): void {
        this.form.setValue({
            name: dienst.name ?? '',
            kuerzel: dienst.kuerzel ?? '',
            backgroundColor: dienst.backgroundColor ?? '',
            textColor: dienst.textColor ?? '',
            von: DvbRestUtil.momentTolocaleHHMMTime(dienst.von),
            bis: DvbRestUtil.momentTolocaleHHMMTime(dienst.bis),
            pauseVon: DvbRestUtil.momentTolocaleHHMMTime(dienst.pauseVon),
            pauseBis: DvbRestUtil.momentTolocaleHHMMTime(dienst.pauseBis),
        });

        this.form.updateValueAndValidity();
    }

    public submitForm(): void {
        this.errorService.clearAll();
        if (!this.form.valid || this.invalidPause()) {
            this.errorService.addValidationError('ERRORS.ERR_INCOMPLETE_FORM');

            return;
        }

        const value = this.form.value;
        const dienst = new Dienst(
            null,
            DvbRestUtil.localeHHMMTimeToMoment(value.von),
            DvbRestUtil.localeHHMMTimeToMoment(value.bis),
            value.name,
            DvbRestUtil.localeHHMMTimeToMoment(value.pauseVon),
            DvbRestUtil.localeHHMMTimeToMoment(value.pauseBis),
            value.kuerzel,
            value.backgroundColor,
            value.textColor);

        this.save.emit(dienst);
    }

    public handleCancel(): void {
        this.errorService.clearAll();
        this.cancel.emit();
    }

    private invalidPause(): boolean {
        const {pauseVon, pauseBis} = this.form.value;

        return (isNullish(pauseBis) && isPresent(pauseVon)) || (isNullish(pauseVon) && isPresent(pauseBis));
    }
}
