<form name="emailVersandBestaetigenForm"
      #emailVersandBestaetigenForm="ngForm"
      (ngSubmit)="submitForm(emailVersandBestaetigenForm)"
      novalidate>
    <div class="modal-body">
        <h1 [transloco]="'COMMON.RECHNUNG_VERSENDEN_BESTAETIGEN'"
            [translocoParams]="{nummer: rechnung.rechnungsNummer}">
        </h1>

        <p class="normal-abstand-bottom"
           [transloco]="'COMMON.RECHNUNG_EMAIL_EMPFAENGER'"
           [translocoParams]="{email: rechnung.kontaktpersonEmail}">
        </p>

        <div class="row big-abstand-bottom">
            <div class="col-md-6 col-md-offset-3 layout-row">
                <input class="form-control"
                       type="email"
                       name="replyToAddress"
                       [(ngModel)]="emailVersandProperties.replyToAddress"
                       [placeholder]="'COMMON.ANTWORT_ADRESSE' | transloco"
                       [tooltip]="'COMMON.ANTWORT_ADRESSE' | transloco"
                       required>
                <dv-spf-info [replyToAddress]="emailVersandProperties.replyToAddress"></dv-spf-info>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <dvlib-button-list>
            <dvlib-loading-button [isLoading]="isLoading" [type]="'submit'">
                <span [transloco]="'ADMINISTRATION.EMAIL_SENDEN'"></span>
            </dvlib-loading-button>
            <button type="button"
                    class="btn btn-link"
                    (click)="hide()"
                    [transloco]="'COMMON.ABBRECHEN'">
            </button>
        </dvlib-button-list>
    </div>
</form>
