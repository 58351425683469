/*
 * Copyright © 2024 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import {
    provideHttpClient,
    withInterceptors,
    withInterceptorsFromDi,
    withXsrfConfiguration,
} from '@angular/common/http';
import type {ApplicationConfig} from '@angular/core';
import {importProvidersFrom} from '@angular/core';
import {provideProtractorTestingSupport} from '@angular/platform-browser';
import {provideAnimationsAsync} from '@angular/platform-browser/animations/async';
import {UpgradeModule} from '@angular/upgrade/static';
import {
    AuthorizationProviderJSAdapterService,
    CommonUpgradeModule,
    ELTERN_FEATURE,
    provideSearchService,
    SCHULUNG_FEATURE,
    withNotifyNotAuthorised,
    withRetryOnLoginSuccess,
} from '@dv/kit-admin/lib';
import {ErrorService} from '@dv/kitadmin/core/errors';
import {GROUP_ADMIN_EINSTELLUNGEN_FEATURE} from '@dv/kitadmin/group-admin-einstellungen';
import {KIND_ABWEICHUNGS_MELDUNGEN_FEATURE} from '@dv/kitadmin/kind-abweichungs-meldungen';
import {
    STUNDEN_KONTINGENT_PARAMETER_GUELTIGKEIT_FEATURE,
} from '@dv/kitadmin/stunden-kontingent/stunden-kontingent-parameter-gueltigkeit';
import {WORK_TIME_CONTROLLING_FEATURE} from '@dv/kitadmin/work-time-controlling';
import {
    BackendErrorHandler,
    DISPLAY_NAME_CONFIG_TOKEN,
    FAMILIENNAME_VORNAME,
    HTTP_XSRF_INTERCEPTOR,
    I18nModule,
    provideAuthorization,
    provideNgxBootstrap,
    provideSortList,
    VERSION_INTERCEPTOR,
} from '@dv/shared/angular';
import {authenticationInterceptor} from '@dv/shared/authentication/http-interceptor';
import {XSRF_COOKIE_NAME, XSRF_HEADER_NAME} from '@dv/shared/authentication/model';
import {provideApi, REST_BACKEND} from '@dv/shared/backend/api';
import {DEFAULT_LOCALE} from '@dv/shared/code';
import {ConfigService} from '@dv/shared/config';
import {HTTP_ERROR_INTERCEPTOR} from '@dv/shared/errors/http-interceptor';
import {provideSentry} from '@dv/shared/sentry';
import {defaultStoreProvider} from '@state-adapt/angular';
import {UIRouterModule} from '@uirouter/angular';
import {UIRouterUpgradeModule} from '@uirouter/angular-hybrid';
import {provideOAuthClient} from 'angular-oauth2-oidc';
import {ENVIRONMENT} from '../environments/environment';

export const appConfig: ApplicationConfig = {
    providers: [
        importProvidersFrom(
            // Provide angular upgrade capabilities
            UpgradeModule,
            UIRouterUpgradeModule.forRoot(),
            // Provides the @uirouter/angular directives and registers
            UIRouterModule.forChild({
                states: [
                    ELTERN_FEATURE,
                    KIND_ABWEICHUNGS_MELDUNGEN_FEATURE,
                    GROUP_ADMIN_EINSTELLUNGEN_FEATURE,
                    WORK_TIME_CONTROLLING_FEATURE,
                    SCHULUNG_FEATURE,
                    STUNDEN_KONTINGENT_PARAMETER_GUELTIGKEIT_FEATURE,
                ],
            }),
            I18nModule,
            CommonUpgradeModule,
        ),
        provideAuthorization(AuthorizationProviderJSAdapterService),
        provideProtractorTestingSupport(),
        provideApi(),
        provideNgxBootstrap(),
        provideSentry(),
        provideSortList(),
        defaultStoreProvider,
        provideAnimationsAsync(),
        provideHttpClient(
            withXsrfConfiguration({
                cookieName: XSRF_COOKIE_NAME,
                headerName: XSRF_HEADER_NAME,
            }),
            withInterceptorsFromDi(),
            withInterceptors([
                authenticationInterceptor(withRetryOnLoginSuccess(), withNotifyNotAuthorised()),
                // must run *after* AUTHENTICATION_INTERCEPTOR so new Xsrf-Tokens get picked up by http retrys
                HTTP_XSRF_INTERCEPTOR,
                HTTP_ERROR_INTERCEPTOR,
                VERSION_INTERCEPTOR,
            ]),
        ),
        provideOAuthClient({
            resourceServer: {
                allowedUrls: [`${REST_BACKEND}/api/v1/`],
                sendAccessToken: true,
            },
        }),
        {
            provide: ConfigService,
            useValue: ConfigService.create({
                version: ENVIRONMENT.VERSION,
                defaultLocale: DEFAULT_LOCALE,
                restBackend: REST_BACKEND,
            }),
        },
        {provide: BackendErrorHandler, useExisting: ErrorService},
        {provide: DISPLAY_NAME_CONFIG_TOKEN, useValue: FAMILIENNAME_VORNAME},
        provideSearchService(),
    ],
};
